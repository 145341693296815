import React from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import classNames from 'classnames';

import css from './HostHelpPage.module.css';

const SideBarNav = (props) => {
  const { location, intl } = props;

  const getstartedText = intl.formatMessage({ id: 'HostHelpPage.navItem1' });
  const howText = intl.formatMessage({ id: 'HostHelpPage.navItem2' });
  const communicationText = intl.formatMessage({ id: 'HostHelpPage.navItem3' });
  const gsText = intl.formatMessage({ id: 'HostHelpPage.navItem4' });
  const partnershipText = intl.formatMessage({ id: 'HostHelpPage.navItem5' });
  const accaontText = intl.formatMessage({ id: 'HostHelpPage.navItem6' });
  const createAccountText = intl.formatMessage({ id: 'HostHelpPage.navItem7' });
  const manageAccountText = intl.formatMessage({ id: 'HostHelpPage.navItem8' });
  const safetyIdentificationText = intl.formatMessage({ id: 'HostHelpPage.navItem9' });
  const paymentsTransfersText = intl.formatMessage({ id: 'HostHelpPage.navItem10' });
  const termsText = intl.formatMessage({ id: 'HostHelpPage.navItem11' });
  const guidelinesTipsText = intl.formatMessage({ id: 'HostHelpPage.navItem12' });
  const issuesReportsText = intl.formatMessage({ id: 'HostHelpPage.navItem13' });
  const safetyText = intl.formatMessage({ id: 'HostHelpPage.navItem14' });
  const travelRestrictionsText = intl.formatMessage({ id: 'HostHelpPage.navItem15' });
  const travelAdvisoriesText = intl.formatMessage({ id: 'HostHelpPage.navItem16' });
  const otherText = intl.formatMessage({ id: 'HostHelpPage.navItem17' });
  const rulesRegulationsText = intl.formatMessage({ id: 'HostHelpPage.navItem18' });
  const privacyPolicyText = intl.formatMessage({ id: 'HostHelpPage.navItem19' });
  const termsAndConditions = intl.formatMessage({ id: 'HostHelpPage.navItem20' });
  const dataProtectionPolicy = intl.formatMessage({ id: 'HostHelpPage.navItem21' });

  const getstartedSlug = intl.formatMessage({ id: 'HostHelpPage.GetStartedSlug' });
  const howSlug = intl.formatMessage({ id: 'HostHelpPage.HowSlug' });
  const communicationSlug = intl.formatMessage({ id: 'HostHelpPage.CommunicationSlug' });
  const gsSlug = intl.formatMessage({ id: 'HostHelpPage.GsSlug' });
  const partnershipSlug = intl.formatMessage({ id: 'HostHelpPage.PartnershipSlug' });
  const accountSlug = intl.formatMessage({ id: 'HostHelpPage.AccountSlug' });
  const createAccountSlug = intl.formatMessage({ id: 'HostHelpPage.CreateAccountSlug' });
  const manageAccountSlug = intl.formatMessage({ id: 'HostHelpPage.ManageAccountSlug' });
  const safetyIdentificationSlug = intl.formatMessage({ id: 'HostHelpPage.SafetyIdentificationSlug' });
  const paymentsTransfersSlug = intl.formatMessage({ id: 'HostHelpPage.PaymentsTransfersSlug' });
  const termsSlug = intl.formatMessage({ id: 'HostHelpPage.TermsSlug' });
  const guidelinesTipsSlug = intl.formatMessage({ id: 'HostHelpPage.GuidelinesTipsSlug' });
  const issuesReportsSlug = intl.formatMessage({ id: 'HostHelpPage.IssuesReportsSlug' });
  const safetySlug = intl.formatMessage({ id: 'HostHelpPage.SafetySlug' });
  const travelRestrictionsSlug = intl.formatMessage({ id: 'HostHelpPage.TravelRestritionsSlug' });
  const travelAdvisoriesSlug = intl.formatMessage({ id: 'HostHelpPage.travelAdvisoriesSlug' });
  const otherSlug = intl.formatMessage({ id: 'HostHelpPage.OtherSlug' });
  const rulesRegulationsSlug = intl.formatMessage({ id: 'HostHelpPage.RulesSlug' });
  const privacyPolicySlug = intl.formatMessage({ id: 'HostHelpPage.PrivacyPolicySlug' });
  const termsAndConditionsSlug = intl.formatMessage({ id: 'HostHelpPage.TermsConditionsSlug' });
  const dataProtectionPolicySlug = intl.formatMessage({ id: 'HostHelpPage.DataProtectionSlug' });

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -110;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const navItems = [
    { link: `#${getstartedSlug}`, text: getstartedText, type: 'title', className: css.title },
    { link: `#${howSlug}`, text: howText, type: 'subtitle', className: css.subtitle },
    { link: `#${communicationSlug}`, text: communicationText, type: 'subtitle', className: css.subtitle },
    { link: `#${gsSlug}`, text: gsText, type: 'subtitle', className: css.subtitle },
    { link: `#${partnershipSlug}`, text: partnershipText, type: 'subtitle', className: css.subtitle },
    { link: `#${accountSlug}`, text: accaontText, type: 'title', className: css.title },
    { link: `#${createAccountSlug}`, text: createAccountText, type: 'subtitle', className: css.subtitle },
    { link: `#${manageAccountSlug}`, text: manageAccountText, type: 'subtitle', className: css.subtitle },
    { link: `#${safetyIdentificationSlug}`, text: safetyIdentificationText, type: 'subtitle', className: css.subtitle },
    { link: `#${paymentsTransfersSlug}`, text: paymentsTransfersText, type: 'subtitle', className: css.subtitle },
    { link: `#${termsSlug}`, text: termsText, type: 'title', className: css.title },
    { link: `#${guidelinesTipsSlug}`, text: guidelinesTipsText, type: 'subtitle', className: css.subtitle },
    { link: `#${issuesReportsSlug}`, text: issuesReportsText, type: 'subtitle', className: css.subtitle },
    { link: `#${safetySlug}`, text: safetyText, type: 'subtitle', className: css.subtitle },
    { link: `#${travelRestrictionsSlug}`, text: travelRestrictionsText, type: 'subtitle', className: css.subtitle },
    { link: `#${travelAdvisoriesSlug}`, text: travelAdvisoriesText, type: 'subtitle', className: css.subtitle },
    { link: `#${otherSlug}`, text: otherText, type: 'subtitle', className: css.subtitle },
    { link: `#${rulesRegulationsSlug}`, text: rulesRegulationsText, type: 'title', className: css.title },
    { link: `#${privacyPolicySlug}`, text: privacyPolicyText, type: 'subtitle', className: css.subtitle },
    { link: `#${termsAndConditionsSlug}`, text: termsAndConditions, type: 'subtitle', className: css.subtitle },
    { link: `#${dataProtectionPolicySlug}`, text: dataProtectionPolicy, type: 'subtitle', className: css.subtitle },
  ];

  return navItems.map(item => {
    return (
      <li className={css.navItem} key={item.link}>
        {<NavLink
          className={
            location.hash === item.link && item.type === 'title' ? classNames(css.selected, css.title)
            : location.hash === item.link && item.type === 'subtitle' ? classNames(css.selected, css.subtitle)
            : item.type === 'title' ? css.title
            : item.type === 'subtitle' ? css.subtitle
            : css.subtitle
          }
          to={item.link}
          smooth
          scroll={el => scrollWithOffset(el)} activeClassName={css.selected}>
          {item.text}
        </NavLink>}
      </li>
    )
  })
};

export default SideBarNav;
