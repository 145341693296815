import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = props => {
  const { className, ...rest } = props;

  return (
    <svg
      className={className}
      {...rest}
      width="36"
      height="25"
      viewBox="0 0 36 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.639,7.496l0.043,-0.047c-0.102,0.148 -0.201,0.299 -0.296,0.449c-0.599,0.948 -1.177,2.071 -1.791,3.308c-0.237,0.477 -0.477,0.953 -0.718,1.426c-0.24,0.472 -0.482,0.941 -0.723,1.405c-0.481,0.928 -0.964,1.834 -1.449,2.694c-0.486,0.861 -0.975,1.676 -1.468,2.425c-0.492,0.749 -0.99,1.431 -1.493,2.024c-0.503,0.594 -1.012,1.098 -1.528,1.492c-0.516,0.394 -1.039,0.678 -1.571,0.829c-0.532,0.151 -1.072,0.17 -1.623,0.034c11.43,8.182 15.745,-20.727 20.871,-17.281c1.467,0.986 2.979,2.559 4.534,4.705c1.554,2.145 3.151,4.863 4.791,8.14c-0.858,-0.456 -1.688,-1.059 -2.29,-1.348c-1.026,-0.492 -2.024,-0.868 -2.991,-1.129c-0.968,-0.259 -1.906,-0.404 -2.815,-0.431c-0.908,-0.029 -1.788,0.06 -2.638,0.264c-0.85,0.204 -1.67,0.524 -2.462,0.96c-0.791,0.436 -1.553,0.989 -2.286,1.657c-0.732,0.668 -1.436,1.453 -2.109,2.353c-0.674,0.9 -1.319,1.917 -1.934,3.05c1.069,-1.011 2.106,-1.848 3.114,-2.528c1.007,-0.68 1.985,-1.203 2.938,-1.586c0.952,-0.383 1.878,-0.627 2.781,-0.749c0.904,-0.121 1.784,-0.12 2.645,-0.015c0.861,0.106 1.702,0.317 2.527,0.616c0.825,0.299 1.633,0.686 2.428,1.143c0.796,0.458 1.597,0.962 2.35,1.567c5.998,4.819 8.537,-1.587 4.638,-7.307c-1.275,-2.023 -2.546,-4.098 -3.827,-6.049c-1.28,-1.951 -2.571,-3.779 -3.884,-5.309c-1.313,-1.53 -2.649,-2.762 -4.021,-3.523c-3.949,-2.187 -6.05,0.926 -7.939,3.828c-0.546,0.838 -1.144,1.808 -1.804,2.933Zm21.457,8.534c0.007,-0.137 0.013,-0.276 0.018,-0.414c-0.005,0.139 -0.011,0.278 -0.018,0.414Z"
        fillRule="evenodd"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
