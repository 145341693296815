import React from 'react';
import PropTypes from 'prop-types';

import css from './IconActivity.module.css';

const IconActivityOther = props => {
  const { className, type } = props;

  if (type === "avalancheSafety") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M12.823 32.5l3.493-9.297c0-.083.083-.25.25-.25l1.58-.922.25-.251 2.245-5.695c.083-.252.416-.42.666-.252l.582.252c.25.083.582 0 .665-.335l2.495-7.873c.167-.503.915-.503.998 0l1.83 7.035c.083.335.416.503.749.335l.332-.167c.333-.168.666 0 .749.335l1.543 5.835m-7.615-8.6l.333.252a1.42 1.42 0 001.414 0c.333-.251.748-.251 1.081-.167l.665.25m-7.568-3.6l-3.493 8.542m-3.993 1.759c.832-1.005 2.08-1.675 3.494-1.675.915 0 1.83.335 2.495.837m-6.072.838a3.427 3.427 0 00-1.33-.251c-1.747 0-3.161 1.424-3.161 3.182 0 1.173.582 2.178 1.497 2.68m.998-1.424c-.499.587-.832 1.34-.832 2.178 0 1.759 1.414 3.183 3.161 3.183a2.98 2.98 0 001.83-.587m-2.662-3.852c-.832 0-1.58-.67-1.58-1.592 0-.837.665-1.59 1.58-1.59m10.73-13.317l-2.163 5.192m9.785 10.056l-2.935 4.696c-.245.42-.815.42-1.141 0l-1.223-1.845m7.907-4.192a.565.565 0 00-.57-.587c-4.32-.504-5.218-2.6-5.218-2.6s-.896 2.096-5.217 2.6a.565.565 0 00-.57.587c-.164 1.928-.164 8.134 5.787 10.146 6.033-1.928 5.951-8.218 5.788-10.146z"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M12.823 32.5l3.493-9.297c0-.083.083-.25.25-.25l1.58-.922.25-.251 2.245-5.695c.083-.252.416-.42.666-.252l.582.252c.25.083.582 0 .665-.335l2.495-7.873c.167-.503.915-.503.998 0l1.83 7.035c.083.335.416.503.749.335l.332-.167c.333-.168.666 0 .749.335l1.543 5.835m-7.615-8.6l.333.252a1.42 1.42 0 001.414 0c.333-.251.748-.251 1.081-.167l.665.25m-7.568-3.6l-3.493 8.542m-3.993 1.759c.832-1.005 2.08-1.675 3.494-1.675.915 0 1.83.335 2.495.837m-6.072.838a3.427 3.427 0 00-1.33-.251c-1.747 0-3.161 1.424-3.161 3.182 0 1.173.582 2.178 1.497 2.68m.998-1.424c-.499.587-.832 1.34-.832 2.178 0 1.759 1.414 3.183 3.161 3.183a2.98 2.98 0 001.83-.587m-2.662-3.852c-.832 0-1.58-.67-1.58-1.592 0-.837.665-1.59 1.58-1.59m10.73-13.317l-2.163 5.192m9.785 10.056l-2.935 4.696c-.245.42-.815.42-1.141 0l-1.223-1.845m7.907-4.192a.565.565 0 00-.57-.587c-4.32-.504-5.218-2.6-5.218-2.6s-.896 2.096-5.217 2.6a.565.565 0 00-.57.587c-.164 1.928-.164 8.134 5.787 10.146 6.033-1.928 5.951-8.218 5.788-10.146z"
        ></path>
      </svg>
    );
  } else if (type === "triathlon") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M6.35 25.95l2.981-2.235c.497-.35 1.136-.28 1.42.21l2.696 3.284m-8.09-.07l.426.419a1.8 1.8 0 002.484-.07l.142-.14a1.819 1.819 0 012.554 0c.71.699 1.846.699 2.555 0l.071-.07a1.714 1.714 0 012.413-.14l.568.49c.638.559 1.632.559 2.341 0l.781-.63M8.41 30.424l.142-.14a1.819 1.819 0 012.554 0c.71.699 1.845.699 2.555 0l.071-.07a1.714 1.714 0 012.413-.14l.568.49m7.664-10.133l.993-3.563c.142-.56.781-.909 1.349-.699l3.477 1.118v3.354c0 .56.497 1.048 1.064 1.048h3.265m-14.619 9.503l10.29-13.835m-5.464 10.691l4.825-2.446c.497-.21.497-.908.071-1.188l-3.051-2.166m-8.516-5.59h-6.387l3.406-5.38h6.103l-3.122 5.38zm0 0L13.73 8.901m8.658 4.332L20.615 9.81s-.284-.419 0-.838c.284-.28.71-.28.71-.28h1.986s.852 0 .923.909c0 .838-.923.978-.923.978m-8.303-1.887h-3.264m10.148 11.46c-1.135-.629-1.987-1.887-1.987-3.284 0-2.096 1.703-3.773 3.832-3.773a3.95 3.95 0 012.768 1.118m-9.838 9.956a1.667 1.667 0 11-3.334 0 1.667 1.667 0 013.334 0zm17.268-9.198c0 .92-.758 1.667-1.693 1.667-.934 0-1.692-.747-1.692-1.667s.758-1.667 1.692-1.667c.935 0 1.693.746 1.693 1.667zm-18.288 1.827c0 2.084-1.716 3.773-3.832 3.773-2.116 0-3.832-1.69-3.832-3.773 0-2.084 1.716-3.773 3.832-3.773 2.116 0 3.832 1.69 3.832 3.773z"
        ></path>
      </svg>
    );
  } else {
    return null;
  }
};

IconActivityOther.defaultProps = { className: null };

const { string } = PropTypes;

IconActivityOther.propTypes = {
  className: string,
};

export default IconActivityOther;
