import React from 'react';
import css from './ListingPage.module.css';
import Tabs, { TabPane } from "rc-tabs";
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import classNames from 'classnames';
import {FormattedMessage} from "../../util/reactIntl";

const SectionDetails = ({ listing, intl }) => {
  const {
    description_long = "",
    included = [],
    terms_and_conditions = [],
    not_included = [],
  } = listing.attributes.publicData;

  const callback = key => {};

  const descriptionTab = intl.formatMessage({ id: 'SectionPackage.descriptionTab' });
  const includedTab = intl.formatMessage({ id: 'SectionPackage.includedTab' });
  const notIncludedTab = intl.formatMessage({ id: 'SectionPackage.notIncludedTab' });
  const termsAndConditionsTab = intl.formatMessage({ id: 'SectionPackage.termsAndConditionsTab' });

  return (
    <div className={classNames(css.reviewsSectionListing, css.blockDetails)}>
      <h3 className={css.spotlightTitle}>
        <FormattedMessage id="UserCard.detailsTitle" />
      </h3>
      <Tabs
        className={css.reviewBlock}
        defaultActiveKey="1"
        onChange={callback}
        renderTabBar={() => <ScrollableInkTabBar/>}
        renderTabContent={() => <TabContent/>}
      >
        {description_long.length > 0 && <TabPane tab={descriptionTab} key="1">
          <div className={css.normalTextDescription} dangerouslySetInnerHTML={{__html: description_long}}></div>
        </TabPane>}
        {included && included.length > 0 && <TabPane tab={includedTab} key="2">
          <ul className={css.includedList}>
            {included.map((item, i) => <li key={i}>{item}</li>)}
          </ul>
        </TabPane>}
        {not_included.length > 0 && <TabPane tab={notIncludedTab} key="3">
          <ul className={css.includedList}>
            {not_included.map((item, i) => <li key={i}>{item}</li>)}
          </ul>
        </TabPane>}
        {terms_and_conditions.length > 0 && <TabPane tab={termsAndConditionsTab} key="4">
          <ul className={classNames(css.includedList, css.clearList)}>
            {terms_and_conditions.map(term => (
              <li key={term?.terms ?? term}>
                {/* {term?.terms ?? term} */}
                <div dangerouslySetInnerHTML={{ __html: term?.terms ?? term }} />
              </li>
            ))}
          </ul>
        </TabPane>}
      </Tabs>
    </div>
  );
};

export default SectionDetails;
