/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import yoga from './components/SectionHero/icons/Yoga.svg';
import yogaWhite from './components/SectionHero/icons/YogaWhite.svg';
import roadbiking from './components/SectionHero/icons/Roadbiking.svg';
import roadbikingWhite from './components/SectionHero/icons/RoadbikingWhite.svg';
import kitesurfing from './components/SectionHero/icons/Kitesurfing.svg';
import kitesurfingWhite from './components/SectionHero/icons/KitesurfingWhite.svg';
import skitouring from './components/SectionHero/icons/Ski-Touring.svg';
import skitouringWhite from './components/SectionHero/icons/Ski-TouringWhite.svg';
import running from './components/SectionHero/icons/Running.svg';
import runningWhite from './components/SectionHero/icons/RunningWhite.svg';
import hiking from './components/SectionHero/icons/Hiking.svg';
import hikingWhite from './components/SectionHero/icons/HikingWhite.svg';
import surfing from './components/SectionHero/icons/Surfing.svg';
import surfingWhite from './components/SectionHero/icons/SurfingWhite.svg';
import level1 from './assets/Level 1.svg';
import level2 from './assets/Level 2.svg';
import level3 from './assets/Level 3.svg';
import beginner from './assets/1.svg';
import novice from './assets/2.svg';
import intermediate from './assets/3.svg';
import advanced from './assets/4.svg';
import expert from './assets/5.svg';


export const imgFormat = [ "PNG","JPG","GIF","JPEG","TIFF", "PSD", "BMP" ]

export const translateOptions = [
  { key: 'en', label: 'English' },
  { key: 'de', label: 'German' },
  { key: 'fr', label: 'French' },
  { key: 'es', label: 'Spanish' },
];

export const amenities = [
  {
    key: 'equipment_rental',
    label: 'Equipment rental',
  },
  {
    key: 'airport_transfer_included',
    label: 'Airport transfer',
  },
  {
    key: 'instant_booking',
    label: 'Instant Booking',
  },
  {
    key: 'flexible_cancellation_policy',
    label: 'Flexible cancellation policy',
  },
  {
    key: 'all_meals_included',
    label: 'All meals included',
  },
  {
    key: 'swimming_pool',
    label: 'Swimming pool',
  },
  {
    key: 'single_friendly',
    label: 'Single friendly',
  },
  {
    key: 'family_friendly',
    label: 'Family friendly',
  },
];

export const amenitiesFr = [
  {
    key: 'equipment_rental',
    label: 'Location d\'équipement',
  },
  {
    key: 'airport_transfer_included',
    label: 'Transfert d\'aéroport',
  },
  {
    key: 'instant_booking',
    label: 'Réservation instantanée',
  },
  {
    key: 'flexible_cancellation_policy',
    label: 'Politique d\'annulation flexible',
  },
  {
    key: 'all_meals_included',
    label: 'Tous les repas sont inclus',
  },
  {
    key: 'swimming_pool',
    label: 'Piscine',
  },
  {
    key: 'single_friendly',
    label: 'Unique et amical',
  },
  {
    key: 'family_friendly',
    label: 'Famille',
  },
];

export const amenitiesDe = [
  {
    key: 'equipment_rental',
    label: 'Ausrüstungsverleih',
  },
  {
    key: 'airport_transfer_included',
    label: 'Flughafen-Transfer',
  },
  {
    key: 'instant_booking',
    label: 'Sofort-Buchung',
  },
  {
    key: 'flexible_cancellation_policy',
    label: 'Flexible Stornierungsrichtlinie',
  },
  {
    key: 'all_meals_included',
    label: 'Mahlzeiten inbegriffen',
  },
  {
    key: 'swimming_pool',
    label: 'Schwimmbad',
  },
  {
    key: 'single_friendly',
    label: 'Single-freundlich',
  },
  {
    key: 'family_friendly',
    label: 'Familienfreundlich',
  },
];

export const categories = [
  {
    key: 'yoga',
    label: 'Yoga',
    image: yoga,
    imageWhite: yogaWhite
  },
  {
    key: 'cycling',
    label: 'Cycling',
    image: roadbiking,
    imageWhite: roadbikingWhite
  },
  {
    key: 'kitesurfing',
    label: 'Kitesurfing',
    image: kitesurfing,
    imageWhite: kitesurfingWhite
  },
  {
    key: 'skitouring',
    label: 'Skitouring',
    image: skitouring,
    imageWhite: skitouringWhite
  },
  {
    key: 'running',
    label: 'Running',
    image: running,
    imageWhite: runningWhite
  },
  {
    key: 'trekking',
    label: 'Trekking',
    image: hiking,
    imageWhite: hikingWhite
  },
  {
    key: 'windsurfing',
    label: 'Windsurfing',
    image: surfing,
    imageWhite: surfingWhite
  },
  {
    key: 'hiking',
    label: 'Hiking',
    image: hiking,
    imageWhite: hikingWhite
  },
];

export const categoriesFr = [
  {
    key: 'yoga',
    label: 'Yoga',
    image: yoga,
    imageWhite: yogaWhite
  },
  {
    key: 'cycling',
    label: 'Cyclisme',
    image: roadbiking,
    imageWhite: roadbikingWhite
  },
  {
    key: 'kitesurfing',
    label: 'Kitesurfing',
    image: kitesurfing,
    imageWhite: kitesurfingWhite
  },
  {
    key: 'skitouring',
    label: 'Ski de Randonnée',
    image: skitouring,
    imageWhite: skitouringWhite
  },
  {
    key: 'running',
    label: 'La Course',
    image: running,
    imageWhite: runningWhite
  },
  {
    key: 'trekking',
    label: 'Trekking',
    image: hiking,
    imageWhite: hikingWhite
  },
  {
    key: 'windsurfing',
    label: 'Planche à voile',
    image: surfing,
    imageWhite: surfingWhite
  },
  {
    key: 'hiking',
    label: 'Randonnées',
    image: hiking,
    imageWhite: hikingWhite
  },
];

export const categoriesDe = [
  {
    key: 'yoga',
    label: 'Yoga',
    image: yoga,
    imageWhite: yogaWhite
  },
  {
    key: 'cycling',
    label: 'Radfahren',
    image: roadbiking,
    imageWhite: roadbikingWhite
  },
  {
    key: 'kitesurfing',
    label: 'Kitesurfen',
    image: kitesurfing,
    imageWhite: kitesurfingWhite
  },
  {
    key: 'skitouring',
    label: 'Skitouren',
    image: skitouring,
    imageWhite: skitouringWhite
  },
  {
    key: 'running',
    label: 'Laufende',
    image: running,
    imageWhite: runningWhite
  },
  {
    key: 'trekking',
    label: 'Trekking',
    image: hiking,
    imageWhite: hikingWhite
  },
  {
    key: 'windsurfing',
    label: 'Windsurfen',
    image: surfing,
    imageWhite: surfingWhite
  },
  {
    key: 'hiking',
    label: 'Wandern',
    image: hiking,
    imageWhite: hikingWhite
  },
];

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 10000,
  step: 5,
};

export const durationRangeFilterConfig = {
  min: 0,
  max: 25,
  step: 1,
};


// Activate booking dates filter on search page
export const dateRangeFilterConfig = {
  active: true,
};

// Activate keyword filter on search page

// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
  active: true,
};

export const skillLevelFilterConfig = [
  {
    key: 'Beginner',
    label: 'Beginner',
    image: beginner
  },
  {
    key: 'Novice',
    label: 'Novice',
    image: novice
  },
  {
    key: 'Intermediate',
    label: 'Intermediate',
    image: intermediate
  },
  {
    key: 'Advanced',
    label: 'Advanced',
    image: advanced
  },
  {
    key: 'Expert',
    label: 'Expert',
    image: expert
  },
];

export const skillLevelFilterConfigFr = [
  {
    key: 'Beginner',
    label: 'Débutant',
    image: beginner
  },
  {
    key: 'Novice',
    label: 'Novice',
    image: novice
  },
  {
    key: 'Intermediate',
    label: 'Intermédiaire',
    image: intermediate
  },
  {
    key: 'Advanced',
    label: 'Avancé',
    image: advanced
  },
  {
    key: 'Expert',
    label: 'Expert',
    image: expert
  },
];

export const skillLevelFilterConfigDe = [
  {
    key: 'Beginner',
    label: 'Anfänger',
    image: beginner
  },
  {
    key: 'Novice',
    label: 'Einsteiger',
    image: novice
  },
  {
    key: 'Intermediate',
    label: 'Intermediär',
    image: intermediate
  },
  {
    key: 'Advanced',
    label: 'Fortgeschrittene',
    image: advanced
  },
  {
    key: 'Expert',
    label: 'Experte',
    image: expert
  },
];


export const fitnessLevelFilterConfig = [
  {
    key: 'Fair',
    label: 'Fair',
    image: level1
  },
  {
    key: 'Good',
    label: 'Good',
    image: level2
  },
  {
    key: 'Excellent',
    label: 'Excellent',
    image: level3
  }
];

export const fitnessLevelFilterConfigFr = [
  {
    key: 'Fair',
    label: 'Élémentaire',
    image: level1
  },
  {
    key: 'Good',
    label: 'Bon',
    image: level2
  },
  {
    key: 'Excellent',
    label: 'Excellent',
    image: level3
  }
];

export const fitnessLevelFilterConfigDe = [
  {
    key: 'Fair',
    label: 'Normal',
    image: level1
  },
  {
    key: 'Good',
    label: 'Gut',
    image: level2
  },
  {
    key: 'Excellent',
    label: 'Ausgezeichnet',
    image: level3
  }
];


export const waterOptions = [
  { key: "Kayaking", translationKey: "kayaking", icon: "kayaking" },
  { key: "Sailing", translationKey: "sailing", icon: "sailing" },
  { key: "Freediving", translationKey: "freediving", icon: "freediving" },
  { key: "Snorkeling", translationKey: "snorkeling", icon: "snorkeling" },
  { key: "Kitesurfing", translationKey: "kitesurfing", icon: "kitesurfing" },
  { key: "Swimming", translationKey: "swimming", icon: "swimming" },
  { key: "Open Water Swimming", translationKey: "openWaterSwimming", icon: "openWaterSwimming" },
  { key: "Standup Paddling", translationKey: "standupPaddling", icon: "standupPaddling" },
  { key: "Surfing", translationKey: "surfing",  icon: "surfing" },
  { key: "Windsurfing", translationKey: "windsurfing", icon: "windsurfing" },
]

export const bikeOptions = [
  { key: "Bikepacking / Crosscountry", translationKey: "bikepackingCrossCountry", icon: "bikepackingCrossCountry" },
  { key: "Road Cycling", translationKey: "roadCycling", icon: "roadCycling" },
  { key: "Mountain biking", translationKey: "mountainBiking", icon: "mountainBiking" },
]

export const footOptions = [
  { key: "Walking", translationKey: "walking", icon: "walking" },
  { key: "Running", translationKey: "running", icon: "running" },
  { key: "Canyoning", translationKey: "canyoning", icon: "canyoning" },
  { key: "Ice climbing", translationKey: "iceClimbing", icon: "iceClimbing" },
  { key: "Hiking", translationKey: "hiking", icon: "hiking" },
  { key: "Mountaineering", translationKey: "mountaineering", icon: "mountaineering" },
  { key: "Via Ferrata", translationKey: "viaFerrata", icon: "viaFerrata" },
  { key: "Rock climbing", translationKey: "rockClimbing", icon: "rockClimbing" },
  { key: "Trekking", translationKey: "trekking", icon: "trekking" },
  { key: "Trail running", translationKey: "trailRunning", icon: "trailRunning" },
]

export const snowOptions = [
  { key: "Cross Country Skiing", translationKey: "crossCountrySkiing", icon: "crossCountrySkiing" },
  { key: "Snowshoeing", translationKey: "snowshoeing", icon: "snowshoeing" },
  { key: "Freeriding", translationKey: "freeriding", icon: "freeriding" },
  { key: "Heli Skiing", translationKey: "heliSkiing", icon: "heliSkiing" },
  { key: "Ski Touring", translationKey: "skiTouring", icon: "skiTouring" },
  { key: "Splitboarding", translationKey: "splitboarding", icon: "splitboarding" },
  { key: "Telemark", translationKey: "telemark", icon: "telemark" },
]

export const otherOptions = [
  { key: "Avalanche Safety", translationKey: "avalancheSafety", icon: "avalancheSafety" },
  { key: "Triathlon", translationKey: "triathlon", icon: "triathlon" },
]

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const configFilters = ['badgesForFilters', 'categoriesForFilters'];
