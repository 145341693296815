import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '../../containers';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  TopbarImgWrapper,
  LayoutWrapperContent, Iubenda,
} from '../../components';
import config from '../../config';
import imgTerms from './terms_next.png'

import TagManager from "react-gtm-module";

import css from './TermsOfServicePage.module.css';
import {LANGUAGE_KEY} from "../../util/languages";
import {getPrivacyPolicyText} from "../../ducks/PrivacyPolicy.duck";

const TermsOfServicePageComponent = props => {
  const { scrollingDisabled, intl, onGetPrivacyPolicyText, location } = props;

  useEffect(() => {

    let lang = 'en';
    if(typeof localStorage !== 'undefined') {
      lang = localStorage.getItem(LANGUAGE_KEY) === 'en' ? 'en' : localStorage.getItem(LANGUAGE_KEY) === 'de' ? 'deu'  : 'en';
    }

    onGetPrivacyPolicyText(lang, 'terms');

    if(typeof window !== 'undefined') {
      const tagManagerArgs = {
        dataLayer: {
          userId: '001',
          userProject: 'Activebreak',
          page: 'TermsOfServicePage'
        },
        events: {
          pageView: 'pageview',
        },
        page: {
          path: window.location.pathname,
          title: 'TermsOfServicePage',
          type: 'terms',
        },
        dataLayerName: 'TermsOfServicePageDataLayer'
      };

      TagManager.dataLayer(tagManagerArgs);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pageview',
        page: {
          path: window.location.pathname,
          locale: localStorage.getItem(LANGUAGE_KEY), // language code of displayed page
          title: 'TermsOfServicePage',
          type: 'terms',
        },
      });
    }
  }, []);

  const tabs = [
    {
      text: intl.formatMessage({ id: 'TermsOfServicePage.imprintTabTitle' }),
      selected: false,
      linkProps: {
        name: 'ImprintPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'TermsOfServicePage.privacyTabTitle' }),
      selected: false,
      linkProps: {
        name: 'PrivacyPolicyPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'TermsOfServicePage.cookieTabTitle' }),
      selected: false,
      linkProps: {
        name: 'CookiePolicyPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'TermsOfServicePage.tosTabTitle' }),
      selected: true,
      linkProps: {
        name: 'TermsOfServicePage',
      },
    },
  ];

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'TermsOfServicePage.schemaTitle' }, { siteTitle });
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: schemaTitle,
  };
  const topText = intl.formatMessage({ id: 'TermsOfServicePage.subText' })
  const subText = intl.formatMessage({ id: 'TermsOfServicePage.topText' })

  let iubendaEn = {
    title: "Terms and Conditions",
    text: "Terms and Conditions",
    idForm: "terms-and-conditions/48988523",
  }
  let iubendaDe = {
    title: "AGBs",
    text: "AGBs",
    idForm: "nutzungsbedingungen/84666627",
  }

  let iubendaId
  if(typeof localStorage !== 'undefined') {
    iubendaId = localStorage.getItem(LANGUAGE_KEY) === 'de'
      ? iubendaDe
      : iubendaEn
  }

  return (
    <Page title={schemaTitle} scrollingDisabled={scrollingDisabled} schema={schema}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar className={css.noStickImg}>
          <TopbarContainer currentPage="TermsOfServicePage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperContent>
          <TopbarImgWrapper
            topBar
            img={imgTerms}
            topText={topText}
            subText={subText}
          />
        </LayoutWrapperContent>
        <LayoutWrapperSideNav className={css.wrapperSideNav} tabs={tabs} />
        <LayoutWrapperMain className={css.wrapperContent}>
          <div className={css.content}>
            <Iubenda iubendaId={iubendaId} />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer currentPage="TermsOfServicePage" location={location}/>
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const { bool, func } = PropTypes;

TermsOfServicePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  onGetPrivacyPolicyText: func,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { privacyPolicyText } = state.PrivacyPolicy;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    privacyPolicyText,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetPrivacyPolicyText: (lang, type) => dispatch(getPrivacyPolicyText(lang, type)),
});

const TermsOfServicePage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(TermsOfServicePageComponent);

export default TermsOfServicePage;
