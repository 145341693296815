import React, { Component } from 'react';
import { array, bool, func, number, oneOf, object, shape, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { debounce, unionWith, _ } from 'lodash';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import {
  createLocalizeResourceLocatorString,
  pathByRouteName
} from '../../util/routes';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { getCategories } from '../../ducks/Categories.duck';
import { SearchMap, ModalInMobile, Page, Footer, LayoutWrapperFooter } from '../../components';
import { TopbarContainer } from '../../containers';
import {types as sdkTypes} from "../../util/sdkLoader";
import { updateProfile } from "../ProfileSettingsPage/ProfileSettingsPage.duck";
import { loadData, searchMapListings, setActiveListing } from './SearchPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';
import MainPanel from './MainPanel';
import css from './SearchPage.module.css';
import { LANGUAGE_KEY, languagesHelper } from "../../util/languages";
// import { ensureCurrentUser, ensureListing } from '../../util/data';


const { LatLng, LatLngBounds } = sdkTypes;
// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

const getMinPackagesPrice = (packages) => {
  let minPrice;

  packages.forEach(({rooms}) => {
    rooms.forEach(({price, available}) => {
      if ((!minPrice && available) || (available && price.amount < minPrice)) {
        minPrice = price.amount;
      }
    });
  });

  return minPrice;
}

const getDepartureDate = (packages) => {
  let minDate;

  packages.forEach(({rooms, departure_date}) => {
    const isAvailable = rooms.find(({available}) => available);

    const timeInMls = new Date(departure_date).getTime();

    if (
      (!minDate && isAvailable) ||
      (isAvailable && timeInMls < new Date(minDate).getTime())
    ) {
      minDate = departure_date;
    }
  });

  return minDate;
}

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
    };

    this.searchMapListingsInProgress = false;

    this.filters = this.filters.bind(this);
    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
    this.pushDataLayer = this.pushDataLayer.bind(this);
  }

  pushDataLayer() {
    const { listings = [] } = this.props;

    if (typeof window !== 'undefined' && listings.length) {
      const currentlyUrl = typeof window !== 'undefined' && window.location.pathname;
      const query = window.location.query;
      const dataLayerExistItems = window.dataLayer?.ecommerce?.items ?? [];

      const dataLayerItemList = listings.map(({attributes, id, author}, index) => {

        if (dataLayerExistItems.find(({item_id}) => id.uuid === item_id)) {
          return null;
        }

        const {
          title,
          publicData: {
            categories = [],
            themes = [],
            skill_levels = [],
            duration_days,
            guided,
            packages = [],
          },
        } = attributes || {};

        const partnerId = author.id.uuid;
        const minPrice = getMinPackagesPrice(packages);
        const departureDate = getDepartureDate(packages);

        return ({
          item_name: title,
          item_id: id.uuid,
          price: minPrice,
          currency: config.currency,
          discount: 0.00,
          item_brand: partnerId,
          item_category: categories.map(({title}) => title).join("|"), // pipe alphabetically to separate
          item_category2: themes.join("|"), // pipe alphabetically to separate
          item_category3: skill_levels.map(({title}) => title).join("|"), // pipe to separate the skill
          item_category4: duration_days,
          item_category5: `${departureDate.slice(0,4)}|${departureDate.slice(5,7)}`, // as "Year | Month"
          item_variant: guided.title,
          location_id: '[XXX]', // use google places id (added in Backend)
          index: index+1,
          quantity: 1,
        });
      }).filter(Boolean);

      const tagManagerArgs = {
        dataLayer: {
          event: "view_item_list",
          ecommerce: {
            item_list_name: 'Search Results', // page on which the list was displayed on: "Landing Page", "Favorites Page", "Search Results"
            item_list_id: currentlyUrl+query, // Search string & parameters from URL
            items: dataLayerItemList,
          },
        },
        events: {
          pageView: 'pageview',
        },
        page: {
          path: currentlyUrl,
          locale: typeof window !== 'undefined' ? localStorage.getItem(LANGUAGE_KEY) : "en", // language code of displayed page
          title: 'Search Results Page',
          type: 'catalog',
        },
        dataLayerName: 'CartListDataLayer',
      };

      TagManager.dataLayer(tagManagerArgs);
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          item_list_name: 'Search Results', // page on which the list was displayed on: "Landing Page", "Favorites Page", "Search Results"
          item_list_id: currentlyUrl+query,
          items: dataLayerItemList,
        },
        page: {
          path: currentlyUrl,
          locale: typeof window !== 'undefined' ? localStorage.getItem(LANGUAGE_KEY) : "en", // language code of displayed page
          title: 'Search Results Page',
          type: 'catalog',
        },
      });
    }
  }

  componentDidMount() {
    this.props.onGetCategories();
    this.pushDataLayer();
  }

  componentDidUpdate() {
    this.pushDataLayer();
  }

  filters() {
    const {
      // amenities,
      priceFilterConfig,
      dateRangeFilterConfig,
      durationRangeFilterConfig,
      keywordFilterConfig,
      // skillLevelFilterConfig,
      // fitnessLevelFilterConfig,
      // categories,
      categoriesForFilters,
      badgesForFilters,
    } = this.props;

    // let amenitiesConfig = amenities;
    // let categoriesConfig = categories;
    // let skillLevelFilterTranslatedConfig = skillLevelFilterConfig;
    // let fitnessLevelFilterTranslatedConfig = fitnessLevelFilterConfig;

    // if(typeof localStorage !== 'undefined'){
      // const langKey = localStorage.getItem(LANGUAGE_KEY);
      // amenitiesConfig = langKey === 'en' ? amenities : langKey === 'de' ? config.custom.amenitiesDe : config.custom.amenitiesFr;
      // categoriesConfig = langKey === 'en' ? categories : langKey === 'de' ? config.custom.categoriesDe : config.custom.categoriesFr;
      // skillLevelFilterTranslatedConfig = langKey === 'en' ? skillLevelFilterConfig : langKey === 'de' ? config.custom.skillLevelFilterConfigDe : config.custom.skillLevelFilterConfigFr;
      // fitnessLevelFilterTranslatedConfig = langKey === 'en' ? fitnessLevelFilterConfig : langKey === 'de' ? config.custom.fitnessLevelFilterConfigDe : config.custom.fitnessLevelFilterConfigFr;
    // }

    // Note: "category" and "amenities" filters are not actually filtering anything by default.
    // Currently, if you want to use them, we need to manually configure them to be available
    // for search queries. Read more from extended data document:
    // https://www.sharetribe.com/docs/references/extended-data/#data-schema

    const currentCategories = categoriesForFilters.map(category => {
      return {
        key: category.toLowerCase(),
        label: category,
        image: category.toLowerCase(),
        imageWhite: category.toLowerCase() + 'White'
      }
    });

    const currentAmenities = badgesForFilters.map(badge => {
      return {
        key: badge.toLowerCase(),
        label: badge,
        image: badge.toLowerCase(),
        imageWhite: badge.toLowerCase() + 'White'
      }
    });

    // let filteredCurrentCategories = [];

    // if(currentCategories.length > 0) {
    //   categoriesConfig.forEach(({key}) => {
    //     const exist = currentCategories.find((category) => category.key === key);
    //     if (exist) filteredCurrentCategories.push(exist);
    //   });
      // categoriesConfig.map( c => {
      //   currentCategories.map(currentCategory => {
      //     if( c.key === currentCategory.key) filteredCurrentCategories.push(c)
      //   })
      // });
    // }

    return {
      categoryFilter: {
        paramName: 'meta_categoriesForFilters',
        options: currentCategories,
      },
      amenitiesFilter: {
        paramName: 'meta_badgesForFilters',
        options: currentAmenities,
      },
      priceFilter: {
        paramName: 'price',
        config: priceFilterConfig,
      },
      dateRangeFilter: {
        paramName: 'dates',
        config: dateRangeFilterConfig,
      },
      keywordFilter: {
        paramName: 'keywords',
        config: keywordFilterConfig,
      },
      // skillLevelFilter: {
      //   paramName: 'pub_SkillLevels',
      //   options: skillLevelFilterTranslatedConfig,
      // },
      // fitnessLevelFilter: {
      //   paramName: 'pub_FitnessLevels',
      //   options: fitnessLevelFilterTranslatedConfig,
      //   mode: "has_any",
      // },
      durationRangeFilter: {
        paramName: 'pub_duration_days',
        config: durationRangeFilterConfig,
      }
    };
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const currentLang = languagesHelper.get();

    const boundsValid = viewportBounds.ne.lat !== viewportBounds.sw.lat &&
      viewportBounds.ne.lng !== viewportBounds.sw.lng;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentLang === 'en'
      ? currentPath === searchPagePath
      : (currentPath.slice(3)) === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage && boundsValid) {
      const { history, location } = this.props;

      // parse query parameters, including a custom attribute named category
      const { address, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};
      const searchParams = {
        address,
        ...originMaybe,
        // bounds: currentLocale === "de" ? viewportBounds : null,
        bounds: viewportBounds,
        // mapSearch: true,
        ...validFilterParams(rest, this.filters()),
      };

      history.push(createLocalizeResourceLocatorString(searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  render() {
    const {
      intl,
      listings,
      location,
      mapListings,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      isAuthenticated,
      searchParams,
      activeListingId,
      onActivateListing,
      currentUser,
      onUpdateProfile,
      listingsImages,
    } = this.props;

    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, sort, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });
    const filters = this.filters();

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filters);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, filters));
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filters);

    // const isWindowDefined = typeof window !== 'undefined';
    // const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    // const shouldShowSearchMap = !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);
    const shouldShowSearchMap = !this.state.isSearchMapOpenOnMobile

    const onMapIconClick = () => {
      this.setState({ isSearchMapOpenOnMobile: true });
    };

    const { address, origin } = searchInURL || {};
    // const boundsOfTheWorld = new LatLngBounds(new LatLng(85, -180), new LatLng(-85, 180));
    const boundsOfTheWorld = new LatLngBounds(new LatLng(65.20386613, 34.04336417), new LatLng(27.32636204, -12.68233692));


    const bounds = searchInURL.bounds !== undefined ? searchInURL.bounds : boundsOfTheWorld;
    const { title, description, schema } = createSearchResultSchema(listings, address, intl);

    // Set topbar class based on if a modal is open in
    // a child component
    //
    // const topbarClasses = this.state.isMobileModalOpen
    //   ? classNames(css.topbarBehindModal, css.topbar)
    //   : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
        pageName={'SearchPage'}
      >
        <TopbarContainer
          className={css.topbar}
          currentPage="SearchPage"
          currentSearchParams={urlQueryParams}
        />
        <div className={css.container}>
          <MainPanel
            currentUser={currentUser}
            urlQueryParams={validQueryParams}
            sort={sort}
            isAuthenticated={isAuthenticated}
            listings={listings}
            searchInProgress={searchInProgress}
            searchListingsError={searchListingsError}
            searchParamsAreInSync={searchParamsAreInSync}
            onActivateListing={onActivateListing}
            onManageDisableScrolling={onManageDisableScrolling}
            onOpenModal={this.onOpenMobileModal}
            onCloseModal={this.onCloseMobileModal}
            onMapIconClick={onMapIconClick}
            onUpdateProfile={onUpdateProfile}
            pagination={pagination}
            searchParamsForPagination={parse(location.search)}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            currentSearchParams={urlQueryParams}
            primaryFilters={{
              categoryFilter: filters.categoryFilter,
              amenitiesFilter: filters.amenitiesFilter,
              priceFilter: filters.priceFilter,
              dateRangeFilter: filters.dateRangeFilter,
              keywordFilter: filters.keywordFilter,
              durationRangeFilter: filters.durationRangeFilter,
            }}
            secondaryFilters={{
              categoryFilter: filters.categoryFilter,
              amenitiesFilter: filters.amenitiesFilter,
              priceFilter: filters.priceFilter,
              dateRangeFilter: filters.dateRangeFilter,
              durationRangeFilter: filters.durationRangeFilter,
              keywordFilter: filters.keywordFilter,
              // skillLevelFilter: filters.skillLevelFilter,
              // fitnessLevelFilter: filters.fitnessLevelFilter,
            }}
            listingsImages={listingsImages}
          />
          <ModalInMobile
            className={css.mapPanel}
            id="SearchPage.map"
            isModalOpenOnMobile={this.state.isSearchMapOpenOnMobile}
            onClose={() => this.setState({ isSearchMapOpenOnMobile: false })}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.mapWrapper}>
              {shouldShowSearchMap ? (
                <SearchMap
                  reusableContainerClassName={css.map}
                  activeListingId={activeListingId}
                  bounds={bounds}
                  center={origin}
                  isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                  location={location}
                  currentUser={this.props.currentUser}
                  listings={mapListings || []}
                  onMapMoveEnd={this.onMapMoveEnd}
                  onCloseAsModal={() => {
                    onManageDisableScrolling('SearchPage.map', false);
                  }}
                  messages={intl.messages}
                  images={listingsImages}
                />
              ) : null}
            </div>
          </ModalInMobile>
        </div>
        <LayoutWrapperFooter>
          <Footer currentPage="SearchPage" location={location} className={css.wrapperFooter} custom/>
        </LayoutWrapperFooter>
      </Page>
    );
  }
}


SearchPageComponent.defaultProps = {
  listings: [],
  similarListings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  availableCategories: [],
  categories: config.custom.categories,
  amenities: config.custom.amenities,
  priceFilterConfig: config.custom.priceFilterConfig,
  dateRangeFilterConfig: config.custom.dateRangeFilterConfig,
  durationRangeFilterConfig: config.custom.durationRangeFilterConfig,
  keywordFilterConfig: config.custom.keywordFilterConfig,
  skillLevelFilterConfig: config.custom.skillLevelFilterConfig,
  fitnessLevelFilterConfig: config.custom.fitnessLevelFilterConfig,
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  similarListings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onUpdateProfile: func,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  availableCategories: array,
  categories: array,
  amenities: array,
  priceFilterConfig: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
  }),
  dateRangeFilterConfig: shape({ active: bool.isRequired }),

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    similarListingsResultIds,
    similarListingsSearchInProgress,
    similarListingsSearchSuccess,
    activeListingId,
    searchSuccess,
    images,
  } = state.SearchPage;
  const { currentUser } = state.user;
  // const { availableCategories } = state.Categories;
  const pageListings = getListingsById(state, currentPageResultIds);
  const similarListings = getListingsById(state, similarListingsResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  const configFilters = config.custom.configFilters;

  const filters = {};
  configFilters.forEach(f => {
    filters[f] = state.Categories[f] ?? [];
  });

  return {
    listings: pageListings,
    // availableCategories,
    similarListings,
    mapListings,
    currentUser,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    isAuthenticated,
    similarListingsSearchInProgress,
    similarListingsSearchSuccess,
    searchSuccess,
    listingsImages: images,
    ...filters,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  // onSearchListings: searchParams => dispatch(searchListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
  onGetCategories: () => dispatch(getCategories()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SearchPageComponent);

SearchPage.loadData = (params, search) => {
  return loadData(params, search);
};


export default SearchPage;
