import React, { useState } from 'react';
import { string, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { currenciesList, getCurrencySymbol, getListCurrencies } from '../../util/currency';
import { updateUserCurrency } from '../../ducks/user.duck';

import css from './CurrencySelect.module.css';


export const CurrencySelectComponent = ({ currentUser, onUpdateUserCurrency }) => {
  const [selectSymbol, setSelectSymbol] = useState(null);
  const [list, setList] = useState(getListCurrencies());

  const updateLocalCurrency = (value, list) => {
    const result = updateCurrency(value, list);
    if (typeof window !== 'undefined') {
      localStorage.setItem('currentCode', result);
    }
  };

  const updateCurrency = (value, list) => {
    if (value === '') {
      return false;
    }
    const result = list.find(e => e.label === value).key;
    setSelectSymbol(result);
    return result;
  };

  const updateUserCurrencyBefore = (value, list) => {
    const result = updateCurrency(value, list);
    onUpdateUserCurrency(result);
  };

  const getSelectValue = (key, selectSymbol, label, symbol) => {
    if (key !== selectSymbol) {
      return label;
    }
    setList(currenciesList.filter(item => item.key !== key));
    return symbol;
  };

  const currencySymbol = getCurrencySymbol(currentUser);
  const selectCurrency = !list ? null : (
    <select
      className={css.currencySelect}
      onChange={
        currentUser
          ? e => updateUserCurrencyBefore(e.target.value, currenciesList)
          : e => updateLocalCurrency(e.target.value, currenciesList)
      }
    >
      <option value='' className={css.defaultOption}>
        {currencySymbol}
      </option>
      {list.map(c => (
        <option key={c.key} value={c.label}>
          {getSelectValue(c.key, selectSymbol, c.label, c.symbol)}
        </option>
      ))}
    </select>
  );

  return (
    <div className={css.langMenuHolder}>
      {selectCurrency}
    </div>
  );
};

CurrencySelectComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

CurrencySelectComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  onUpdateUserCurrency: func.isRequired
};


const mapStateToProps = state => {
  const {currentUser} = state.user;
  return {
    currentUser
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateUserCurrency: params => dispatch(updateUserCurrency(params))
});

const CurrencySelect = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(CurrencySelectComponent);


export default CurrencySelect;
