import React, { Component } from 'react';
import { object, string, bool, number, func, shape, array } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import omit from 'lodash/omit';
import LocationFilterPlain from '../LocationFilterPlain/LocationFilterPlain';
import DurationFilter from '../DurationFilter/DurationFilter';
import filter from "../../assets/filter.png"
import config from '../../config';

import routeConfiguration from '../../routeConfiguration';
import { parseDateFromISO8601, stringifyDateToISO8601 } from '../../util/dates';
import {createLocalizeResourceLocatorString, createResourceLocatorString} from '../../util/routes';
import {
  ModalInMobile,
  Button,
  KeywordFilter,
  PriceFilter,
  SelectSingleFilter,
  SelectMultipleFilter,
  SortBy,
  BookingDateRangeFilter,
  SelectMultipleWithIconsFilter, IconCategory,
} from '../../components';
import { propTypes } from '../../util/types';
import css from './SearchFiltersMobile.module.css';

const RADIX = 10;
const FILTER_DROPDOWN_OFFSET = -14;

class SearchFiltersMobileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isFiltersOpenOnMobile: false, initialQueryParams: null };

    this.openFilters = this.openFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.closeFilters = this.closeFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.handleSelectSingle = this.handleSelectSingle.bind(this);
    this.handleSelectMultiple = this.handleSelectMultiple.bind(this);
    this.handlePrice = this.handlePrice.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleKeyword = this.handleKeyword.bind(this);
    this.handleSortBy = this.handleSortBy.bind(this);
    this.initialValue = this.initialValue.bind(this);
    this.initialValues = this.initialValues.bind(this);
    this.initialPriceRangeValue = this.initialPriceRangeValue.bind(this);
    this.initialDateRangeValue = this.initialDateRangeValue.bind(this);
  }

  // Open filters modal, set the initial parameters to current ones
  openFilters() {
    const { onOpenModal, urlQueryParams } = this.props;
    onOpenModal();
    this.setState({ isFiltersOpenOnMobile: true, initialQueryParams: urlQueryParams });
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    const { history, onCloseModal } = this.props;
    history.push(createLocalizeResourceLocatorString(this.state.initialQueryParams))
    onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false, initialQueryParams: null });
  }

  // Close the filter modal
  closeFilters() {
    this.props.onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false });
  }

  handleSelectSingle(urlParam, option) {
    const { urlQueryParams, history } = this.props;
    // query parameters after selecting the option
    // if no option is passed, clear the selection for the filter
    const queryParams = option
      ? { ...urlQueryParams, [ urlParam ]: option }
      : omit(urlQueryParams, urlParam);
    history.push(createLocalizeResourceLocatorString(queryParams))
  }


  handleSelectOptions = (urlParam, options) => {
    const { urlQueryParams, history } = this.props;
    const queryParams = options && options.length > 0
        ? { ...urlQueryParams, [ urlParam ]: options.join(',') }
        : omit(urlQueryParams, urlParam);

    history.push(createLocalizeResourceLocatorString(queryParams))
  };

  handleSelectMultiple(urlParam, options) {
    const { urlQueryParams, history } = this.props;
    const queryParams =
      options && options.length > 0
        ? { ...urlQueryParams, [ urlParam ]: options.join(',') }
        : omit(urlQueryParams, urlParam);

    history.push(createLocalizeResourceLocatorString(queryParams))
  }

  handlePrice(urlParam, range) {
    const { urlQueryParams, history } = this.props;
    const { minPrice, maxPrice } = range || {};
    const queryParams =
      minPrice != null && maxPrice != null
        ? { ...urlQueryParams, [ urlParam ]: `${minPrice},${maxPrice}` }
        : omit(urlQueryParams, urlParam);

    history.push(createLocalizeResourceLocatorString(queryParams))
  }

  handleDateRange(urlParam, dateRange) {
    const { urlQueryParams, history } = this.props;
    const hasDates = dateRange && dateRange.dates;
    const { startDate, endDate } = hasDates ? dateRange.dates : {};

    const start = startDate ? stringifyDateToISO8601(startDate) : null;
    const end = endDate ? stringifyDateToISO8601(endDate) : null;

    const queryParams =
      start != null && end != null
        ? { ...urlQueryParams, [ urlParam ]: `${start},${end}` }
        : omit(urlQueryParams, urlParam);
    history.push(createLocalizeResourceLocatorString(queryParams))
  }

  handleKeyword(urlParam, keywords) {
    const { urlQueryParams, history } = this.props;
    const queryParams = urlParam
      ? { ...urlQueryParams, [ urlParam ]: keywords }
      : omit(urlQueryParams, urlParam);

    history.push(createLocalizeResourceLocatorString(queryParams))
  }

  handleSortBy(urlParam, sort) {
    const { urlQueryParams, history } = this.props;
    const queryParams = urlParam
      ? { ...urlQueryParams, [ urlParam ]: sort }
      : omit(urlQueryParams, urlParam);

    history.push(createLocalizeResourceLocatorString(queryParams))
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history } = this.props;

    history.push(createLocalizeResourceLocatorString(queryParams))

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  // resolve initial value for a single value filter
  initialValue(paramName) {
    return this.props.urlQueryParams[ paramName ];
  }

  // resolve initial values for a multi value filter
  initialValues(paramName) {
    const urlQueryParams = this.props.urlQueryParams;
    if(!!urlQueryParams[ paramName ] && Array.isArray(urlQueryParams[ paramName ])) return urlQueryParams[ paramName ];
    return !!urlQueryParams[ paramName ] ? urlQueryParams[ paramName ].split(',') : [];
  }

  initialPriceRangeValue(paramName) {
    const urlQueryParams = this.props.urlQueryParams;
    const price = urlQueryParams[ paramName ];
    const valuesFromParams = !!price ? price.split(',').map(v => Number.parseInt(v, RADIX)) : [];

    return !!price && valuesFromParams.length === 2
      ? {
        minPrice: valuesFromParams[ 0 ],
        maxPrice: valuesFromParams[ 1 ],
      }
      : null;
  }
  handleDuration = (urlParam, range) => {
    const { urlQueryParams, history } = this.props;
    const { minDuration, maxDuration } = range || {};
    const queryParams = minDuration != null && maxDuration != null
      ? { ...urlQueryParams, [ urlParam ]: `${minDuration},${maxDuration}` }
      : omit(urlQueryParams, urlParam);
    history.push(createLocalizeResourceLocatorString(queryParams))
  };

  initialDateRangeValue(paramName) {
    const urlQueryParams = this.props.urlQueryParams;
    const dates = urlQueryParams[ paramName ];
    const rawValuesFromParams = !!dates ? dates.split(',') : [];
    const valuesFromParams = rawValuesFromParams.map(v => parseDateFromISO8601(v));
    const initialValues =
      !!dates && valuesFromParams.length === 2
        ? {
          dates: { startDate: valuesFromParams[ 0 ], endDate: valuesFromParams[ 1 ] },
        }
        : { dates: null };

    return initialValues;
  }

  render() {
    const {
      rootClassName,
      className,
      sort,
      listingsAreLoaded,
      resultsCount,
      searchInProgress,
      showAsModalMaxWidth,
      onMapIconClick,
      onManageDisableScrolling,
      selectedFiltersCount,
      categoryFilter,
      amenitiesFilter,
      priceFilter,
      dateRangeFilter,
      keywordFilter,
      skillLevelFilter,
      fitnessLevelFilter,
      currentUser,
      history,
      intl,
      urlQueryParams,
      categoryFilterName,
      durationRangeFilter,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const resultsFound = (
      <FormattedMessage id="SearchFilters.foundResultsMobile" values={{ count: resultsCount }}/>
    );
    const noResults = <FormattedMessage id="SearchFilters.noResultsMobile"/>;
    const loadingResults = <FormattedMessage id="SearchFilters.loadingResultsMobile"/>;
    const filtersHeading = intl.formatMessage({ id: 'SearchFiltersMobile.heading' });
    const modalCloseButtonMessage = intl.formatMessage({ id: 'SearchFiltersMobile.cancel' });

    const showListingsLabel = intl.formatMessage(
      { id: 'SearchFiltersMobile.showListings' },
      { count: resultsCount }
    );

    const filtersButtonClasses = selectedFiltersCount > 0 ? css.filtersButtonSelected : css.filtersButton;


    const initialRangeValue = (queryParams, paramName, type) => {
      const price = queryParams[ paramName ];
      const valuesFromParams = !!price ? price.split(',').map(v => Number.parseInt(v, RADIX)) : [];

      return !!price && valuesFromParams.length === 2
        ? {
          [ `min${type}` ]: valuesFromParams[ 0 ],
          [ `max${type}` ]: valuesFromParams[ 1 ],
        }
        : null;
    };
    const initialDurationRange = durationRangeFilter
      ? initialRangeValue(urlQueryParams, durationRangeFilter.paramName, 'Duration')
      : null;




    const categoryLabel = intl.formatMessage({ id: 'SearchFilters.categoryLabel' });
    const skillLevelLabel = intl.formatMessage({ id: 'SearchFilters.skillLevelLabel' });
    const fitnessLevelLabel = intl.formatMessage({ id: 'SearchFilters.fitnessLevelLabel' });

    const initialSkillLevel = skillLevelFilter ? this.initialValues(skillLevelFilter.paramName) : null;
    const initialFitnessLevel = fitnessLevelFilter ? this.initialValues(fitnessLevelFilter.paramName) : null;
    const initialCategory = categoryFilter ? this.initialValues(categoryFilter.paramName) : null;
    const skillLevelFilterElement = skillLevelFilter ? (
      <SelectMultipleWithIconsFilter
        id={'SearchFilters.skillLevelFilter'}
        name="skills"
        urlParam={skillLevelFilter.paramName}
        label={skillLevelLabel}
        onSubmit={this.handleSelectOptions}
        options={skillLevelFilter.options}
        initialValues={initialSkillLevel}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      />
    ) : null;

    const fitnessLevelFilterElement = fitnessLevelFilter ? (
      <SelectMultipleWithIconsFilter
        id={'SearchFilters.fitnessLevelFilter'}
        name="fitness"
        urlParam={fitnessLevelFilter.paramName}
        label={fitnessLevelLabel}
        onSubmit={this.handleSelectOptions}
        options={fitnessLevelFilter.options}
        initialValues={initialFitnessLevel}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      />
    ) : null;
    // const categoryFilterElement = categoryFilter ? (
    //   <SelectSingleFilter
    //     urlParam={categoryFilter.paramName}
    //     label={categoryLabel}
    //     onSelect={this.handleSelectSingle}
    //     liveEdit
    //     options={categoryFilter.options}
    //     initialValue={initialCategory}
    //     intl={intl}
    //   />
    // ) : null;

    const categoryFilterElement = categoryFilter ? (
      <SelectMultipleFilter
        id={'SearchFilters.categoryFilter'}
        name="category"
        urlParam={categoryFilter.paramName}
        label={categoryLabel}
        onSubmit={this.handleSelectOptions}
        liveEdit
        options={categoryFilter.options}
        initialValues={initialCategory}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      />
    ) : null;

    const amenitiesLabel = intl.formatMessage({ id: 'SearchFilters.amenitiesLabel' });

    const initialAmenities = this.initialValues(amenitiesFilter.paramName);

    const amenitiesFilterElement = amenitiesFilter ? (
      <SelectMultipleFilter
        id="SearchFiltersMobile.amenitiesFilter"
        name="amenities"
        urlParam={amenitiesFilter.paramName}
        label={amenitiesLabel}
        onSubmit={this.handleSelectOptions}
        liveEdit
        options={amenitiesFilter.options}
        initialValues={initialAmenities}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      />
    ) : null;

    const initialPriceRange = this.initialPriceRangeValue(priceFilter.paramName);

    const priceFilterElement = priceFilter ? (
      <PriceFilter
        id="SearchFiltersMobile.priceFilter"
        urlParam={priceFilter.paramName}
        onSubmit={this.handlePrice}
        liveEdit
        custom={true}
        {...priceFilter.config}
        initialValues={initialPriceRange}
        currentuser={currentUser}
      />
    ) : null;

    const durationFilterElement = durationRangeFilter ? (
      <DurationFilter
        currentUser={currentUser}
        id="SearchFilters.durationFilter"
        urlParam={durationRangeFilter.paramName}
        onSubmit={this.handleDuration}
        {...durationRangeFilter.config}
        initialValues={initialDurationRange}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        custom
      />
    ) : null;

    const initialDateRange = this.initialDateRangeValue(dateRangeFilter.paramName);
    const dateRangeFilterElement =
      dateRangeFilter && dateRangeFilter.config.active ? (
        <BookingDateRangeFilter
          id="SearchFiltersMobile.dateRangeFilter"
          urlParam={dateRangeFilter.paramName}
          onSubmit={this.handleDateRange}
          liveEdit
          showAsPopup={false}
          initialValues={initialDateRange}
        />
      ) : null;

    const initialKeyword = this.initialValue(keywordFilter.paramName);
    const keywordLabel = intl.formatMessage({
      id: 'SearchFiltersMobile.keywordLabel',
    });
    const keywordFilterElement =
      keywordFilter && keywordFilter.config.active ? (
        <KeywordFilter
          id={'SearchFiltersMobile.keywordFilter'}
          name="keyword"
          urlParam={keywordFilter.paramName}
          label={keywordLabel}
          onSubmit={this.handleKeyword}
          liveEdit
          showAsPopup={false}
          initialValues={initialKeyword}
        />
      ) : null;

    const isKeywordFilterActive = !!initialKeyword;

    const sortBy = config.custom.sortConfig.active ? (
      <SortBy
        rootClassName={css.sortBy}
        menuLabelRootClassName={css.sortByMenuLabel}
        sort={sort}
        showAsPopup
        isKeywordFilterActive={isKeywordFilterActive}
        onSelect={this.handleSortBy}
      />
    ) : null;

    const locationFilter = <LocationFilterPlain history={history} intl={intl}/>;

    return (
      <div className={classes}>
        <div className={css.searchResultSummary}>
          {listingsAreLoaded && resultsCount > 0 ? resultsFound : null}
          {listingsAreLoaded && resultsCount === 0 ? noResults : null}
          {searchInProgress ? loadingResults : null}
        </div>

        <div className={css.buttons}>
          <span  className={css.activityText}>{categoryFilterName}</span>
          <Button rootClassName={filtersButtonClasses} onClick={this.openFilters}>
            {/*<span className={css.imgFilter}> </span>*/}
            <IconCategory cat='filter' />
            <FormattedMessage id="SearchFilters.filtersButtonLabel"/>
          </Button>
          {sortBy}
          <div className={css.mapIcon} onClick={onMapIconClick}>
            <FormattedMessage id="SearchFilters.openMapView" className={css.mapIconText}/>
          </div>
        </div>
        <ModalInMobile
          id="SearchFiltersMobile.filters"
          isModalOpenOnMobile={this.state.isFiltersOpenOnMobile}
          onClose={this.cancelFilters}
          showAsModalMaxWidth={showAsModalMaxWidth}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainerss}
          closeButtonMessage={modalCloseButtonMessage}
        >
          {/*<div className={css.modalHeadingWrapper}>*/}
          {/*  <span className={css.modalHeading}>{filtersHeading}</span>*/}
          {/*  <button className={css.resetAllButton} onClick={e => this.resetAll(e)}>*/}
          {/*    <FormattedMessage id={'SearchFiltersMobile.resetAll'}/>*/}
          {/*  </button>*/}
          {/*</div>*/}
          {this.state.isFiltersOpenOnMobile ? (
            <div className={css.filtersWrapper}>
              {/*{keywordFilterElement}*/}
              {categoryFilterElement}
              {locationFilter}
              {amenitiesFilterElement}
              {initialDateRange.dates
                ? priceFilterElement
                : <div>
                    <span className={css.pricesHeading}>
                     <FormattedMessage id={'PriceFilter.priceLabel'} />
                    </span>
                    <FormattedMessage id={'PriceFilter.priceWithoutDateMessage'}/>
                  </div>
              }

              {/*{priceFilterElement}*/}
              {dateRangeFilterElement}

              {durationFilterElement}

              {skillLevelFilterElement}
              {fitnessLevelFilterElement}
            </div>
          ) : null}

          { this.state.isFiltersOpenOnMobile && <div onClick={this.closeFilters} className={css.showListingsContainer}>
            {/*<Button className={css.showListingsButton} onClick={this.closeFilters}>*/}
            {/*  {showListingsLabel}*/}
            {/*</Button>*/}
            <span className={css.rightArrow}> </span>
            <div className={css.titleReturnFilter}>
              <span className={css.returnFilter}>
                <FormattedMessage id={'SearchFiltersMobile.returnFilter'}/>
              </span>
            </div>
          </div> }
        </ModalInMobile>
      </div>
    );
  }
}

SearchFiltersMobileComponent.defaultProps = {
  rootClassName: null,
  className: null,
  sort: null,
  resultsCount: null,
  searchingInProgress: false,
  selectedFiltersCount: 0,
  filterParamNames: [],
  categoryFilter: null,
  amenitiesFilter: null,
  priceFilter: null,
  dateRangeFilter: null,
};

SearchFiltersMobileComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  sort: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchingInProgress: bool,
  showAsModalMaxWidth: number.isRequired,
  onMapIconClick: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  selectedFiltersCount: number,
  filterParamNames: array,
  categoriesFilter: propTypes.filterConfig,
  // amenitiesFilter: propTypes.filterConfig,
  // priceFilter: propTypes.filterConfig,
  // dateRangeFilter: propTypes.filterConfig,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const SearchFiltersMobile = injectIntl(withRouter(SearchFiltersMobileComponent));

export default SearchFiltersMobile;
