export default {
  "LandingPage": "Routes.LandingPage",
  "AboutPage": "Routes.AboutPage",
  "TravelerHelpPage": "Routes.TravelerHelpPage",
  "HostHelpPage": "Routes.HostHelpPage",
  "TrustAndSafetyPage": "Routes.TrustAndSafetyPage",
  "FavoritesPage": "Routes.FavoritesPage",
  "FaqPage": "Routes.FaqPage",
  "HelpPage": "Routes.HelpPage",
  "SearchPage": "Routes.SearchPage",
  "SearchFiltersPage": "Routes.SearchFiltersPage",
  "SearchListingsPage": "Routes.SearchListingsPage",
  "SearchMapPage": "Routes.SearchMapPage",
  "ListingBasePage": "Routes.ListingBasePage",
  "ListingPage": "Routes.ListingPage",
  "CheckoutPage": "Routes.CheckoutPage",
  "ListingPageVariant": "Routes.ListingPageVariant",
  "ListingPageTab": "Routes.ListingPageTab",
  "NewListingPage": "Routes.NewListingPage",
  "EditListingPage": "Routes.EditListingPage",
  "EditListingStripeOnboardingPage": "Routes.EditListingStripeOnboardingPage",
  "ListingPageCanonical": "Routes.ListingPageCanonical",
  "ProfileBasePage": "Routes.ProfileBasePage",
  "ProfilePage": "Routes.ProfilePage",
  "ProfileSettingsPage": "Routes.ProfileSettingsPage",
  "LoginPage": "Routes.LoginPage",
  "SignupPage": "Routes.SignupPage",
  "ConfirmPage": "Routes.ConfirmPage",
  "PasswordRecoveryPage": "Routes.PasswordRecoveryPage",
  "InboxBasePage": "Routes.InboxBasePage",
  "InboxPage": "Routes.InboxPage",
  "OrderPage": "Routes.OrderPage",
  "OrderDetailsPage": "Routes.OrderDetailsPage",
  "SalePage": "Routes.SalePage",
  "SaleDetailsPage": "Routes.SaleDetailsPage",
  "ManageListingsPage": "Routes.ManageListingsPage",
  "AccountSettingsPage": "Routes.AccountSettingsPage",
  "ContactDetailsPage": "Routes.ContactDetailsPage",
  "PasswordChangePage": "Routes.PasswordChangePage",
  "CommunicationsPage": "Routes.CommunicationsPage",
  "StripePayoutPage": "Routes.StripePayoutPage",
  "StripePayoutOnboardingPage": "Routes.StripePayoutOnboardingPage",
  "PaymentMethodsPage": "Routes.PaymentMethodsPage",
  "ContactPage": "Routes.ContactPage",
  "ImprintPage": "Routes.ImprintPage",
  "TermsOfServicePage": "Routes.TermsOfServicePage",
  "PrivacyPolicyPage": "Routes.PrivacyPolicyPage",
  "CookiePolicyPage": "Routes.CookiePolicyPage",
  "Styleguide": "Routes.Styleguide",
  "StyleguideGroup": "Routes.StyleguideGroup",
  "StyleguideComponent": "Routes.StyleguideComponent",
  "StyleguideComponentExample": "Routes.StyleguideComponentExample",
  "StyleguideComponentExampleRaw": "Routes.StyleguideComponentExampleRaw",
  "NotFoundPage": "Routes.NotFoundPage",
  "PasswordResetPage": "Routes.PasswordResetPage",
  "EmailVerificationPage": "Routes.EmailVerificationPage"
}
