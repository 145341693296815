import axios from 'axios';
import { storableError } from '../util/errors';

// ================ Action types ================ //
export const GET_CURRENCY_RATE_REQUEST = 'app/Currency/GET_CURRENCY_RATE_REQUEST';
export const GET_CURRENCY_RATE_SUCCESS = 'app/Currency/GET_CURRENCY_RATE_SUCCESS';
export const GET_CURRENCY_RATE_ERROR = 'app/Currency/GET_CURRENCY_RATE_ERROR';

const initialState = {
  currencyRates: {},
  getCurrencyRateInProgress: false,
  getCurrencyRateSuccess: false,
  getCurrencyRateError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_CURRENCY_RATE_REQUEST:
      return {
        ...state,
        getCurrencyRateInProgress: true,
        getCurrencyRateSuccess: false,
        getCurrencyRateError: false,
      };
    case GET_CURRENCY_RATE_SUCCESS:
      return {
        ...state,
        getCurrencyRateInProgress: false,
        getCurrencyRateSuccess: true,
        getCurrencyRateError: false,
        currencyRates: payload,
      };
    case GET_CURRENCY_RATE_ERROR:
      return {
        ...state,
        getCurrencyRateInProgress: false,
        getCurrencyRateSuccess: false,
        getCurrencyRateError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //
export const getCurrencyRateRequest = () => ({ type: GET_CURRENCY_RATE_REQUEST });
export const getCurrencyRateSuccess = (data) => {
  return ({type: GET_CURRENCY_RATE_SUCCESS, payload: data})
};
export const getCurrencyRateError = error => ({ type: GET_CURRENCY_RATE_ERROR, payload: error, error: true });


/* ================ Thunks ================ */



const API_URL = 'https://openexchangerates.org';


export const getCurrencyRate = (base) => (dispatch) => {
  dispatch(getCurrencyRateRequest());
  console.log(base)

  const appId='8bed561201f8483bab525ef97698e8e0';

  return axios.get(`${API_URL}/api/latest.json?app_id=${appId}&base=${base}`)
    .then(function (response) {
      console.log(response)
      dispatch(getCurrencyRateSuccess(response.data));
      return response.data;
    })
    .catch(e => {
      dispatch(getCurrencyRateError(storableError(e)));
    });
};

