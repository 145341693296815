import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from '../SelectMultipleWithIconsFilter.module.css';

const IconBeginner = props => {
  const { rootClassName, className, fillColor } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg width="20px"
         height="20px"
         viewBox="0 0 20 20"
         version="1.1"
         className={classes}
         xmlns="http://www.w3.org/2000/svg">

      <g id="Icons/Level/1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M2.85714626,17.2698607 L2.85714626,19.6508126 C2.85714626,19.8740304 2.70089608,20.0476418 2.5,20.0476418 L0.357143283,20.0476418 C0.156250186,20.0476418 0,19.8740304 0,19.6508126 L0,17.2698607 C0,17.0466461 0.156250186,16.8730348 0.357143283,16.8730348 L2.5,16.8730348 C2.70089608,16.8730348 2.85714626,17.0466461 2.85714626,17.2698607 Z" id="Path" fill="#146ACC"></path>
        <path d="M7.14286566,15.6825572 L7.14286566,19.6508159 C7.14286566,19.8740304 6.98661547,20.0476418 6.78571939,20.0476418 L4.64286268,20.0476418 C4.44196958,20.0476418 4.28571939,19.8740304 4.28571939,19.6508159 L4.28571939,15.6825572 C4.28571939,15.4593426 4.44196958,15.2857313 4.64286268,15.2857313 L6.78571939,15.2857313 C6.98661547,15.2857313 7.14286566,15.4593426 7.14286566,15.6825572 Z" id="Path" fill="#969696"></path>
        <path d="M11.4285851,12.5079502 L11.4285851,19.6508159 C11.4285851,19.8740304 11.2723349,20.0476418 11.0714388,20.0476418 L8.92858207,20.0476418 C8.72768898,20.0476418 8.57143879,19.8740304 8.57143879,19.6508159 L8.57143879,12.5079502 C8.57143879,12.2847357 8.72768898,12.1111244 8.92858207,12.1111244 L11.0714388,12.1111244 C11.2723349,12.1111244 11.4285851,12.2847357 11.4285851,12.5079502 Z" id="Path" fill="#969696"></path>
        <path d="M15.7143044,7.74603979 L15.7143044,19.6508159 C15.7143044,19.8740304 15.5580543,20.0476418 15.3571582,20.0476418 L13.2143015,20.0476418 C13.0134084,20.0476418 12.8571582,19.8740304 12.8571582,19.6508159 L12.8571582,7.74603979 C12.8571582,7.52282524 13.0134084,7.34921392 13.2143015,7.34921392 L15.3571582,7.34921392 C15.5580543,7.34921392 15.7143044,7.52282524 15.7143044,7.74603979 Z" id="Path" fill="#969696"></path>
        <path d="M20.0000238,1.39682587 L20.0000238,19.6508159 C20.0000238,19.8740304 19.8437737,20.0476418 19.6428776,20.0476418 L17.5000209,20.0476418 C17.2991278,20.0476418 17.1428776,19.8740304 17.1428776,19.6508159 L17.1428776,1.39682587 C17.1428776,1.17361132 17.2991278,1 17.5000209,1 L19.6428776,1 L20.0000238,1.39682587 Z" id="Path" fill="#969696"></path>
      </g>
    </svg>
    );
};

IconBeginner.defaultProps = {
  rootClassName: null,
  className: null,
  fillColor: null,
};

IconBeginner.propTypes = {
  rootClassName: string,
  className: string,
  fillColor: string,
};

export default IconBeginner;
