import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import routeConfiguration from '../../routeConfiguration';
// import { createResourceLocatorString } from '../../util/routes';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  IconSpinner
} from '../../components';
// import { LocationSearchForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import config from '../../config';
import HeroSearchForm from '../../forms/HeroSearchForm/HeroSearchForm';
import css from './NotFoundPage.module.css';

export class NotFoundPageComponent extends Component {
  constructor(props) {
    super(props);
    // The StaticRouter component used in server side rendering
    // provides the context object. We attach a `notfound` flag to
    // the context to tell the server to change the response status
    // code into a 404.
    this.props.staticContext.notfound = true;
  }

  render() {
    const { intl, scrollingDisabled, location } = this.props;

    const title = intl.formatMessage({
      id: 'NotFoundPage.title',
    });

    if (typeof window == 'undefined') {
      return null
    }

    // const handleSearchSubmit = values => {
    //   const { search, selectedPlace } = values.location;
    //   const { origin, bounds } = selectedPlace;
    //   const searchParams = { address: search, origin, bounds };
    //   history.push(
    //     createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
    //   );
    // };



    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="NotFoundPage"/>
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.root}>
              <div className={css.content}>
                {window.localStorage && window.localStorage.getItem('lang') !== config.locale ? (
                  <IconSpinner />
                ) : (
                  <>
                    {/*<div className={css.number}>404</div>*/}
                    <h1 className={css.heading}>
                      <FormattedMessage id="NotFoundPage.heading" />
                    </h1>
                    <p className={css.description}>
                      <FormattedMessage id="NotFoundPage.description" />
                    </p>
                    {/*<LocationSearchForm className={css.searchForm} onSubmit={handleSearchSubmit} />*/}
                  </>
                )}
              </div>
              <HeroSearchForm intl={intl} />
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer currentPage="NotFoundPage" location={location}/>
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

NotFoundPageComponent.defaultProps = {
  staticContext: {},
};

const { bool, func, object, shape } = PropTypes;

NotFoundPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // context object from StaticRouter, injected by the withRouter wrapper
  staticContext: object,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const NotFoundPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(NotFoundPageComponent);

export default NotFoundPage;
