import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  TopbarImgWrapper,
  LayoutWrapperContent,
} from '../../components';

import { injectIntl } from '../../util/reactIntl';

import css from './TrustAndSafetyPage.module.css';
import imgTrust from './imgTrust.png';
import TrustAndSafetyContent from "./TrustAndSafetyContent";

const TrustAndSafetyPage = ({location,intl}) => {
  const title = intl.formatMessage({ id: 'TrustAndSafetyPage.title' })
  const topText = intl.formatMessage({ id: 'TrustAndSafetyPage.subText' })
  const subText = intl.formatMessage({ id: 'TrustAndSafetyPage.topText' })
  const nextText = intl.formatMessage({ id: 'TrustAndSafetyPage.nextText' })

  // prettier-ignore
  return (
    <StaticPage
      title={title}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'TrustAndSafetyPage',
        description: 'Trust and safety',
        name: 'Trust and safety page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="TrustAndSafetyPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperContent>
          <TopbarImgWrapper
            topBar
            img={imgTrust}
            topText={topText}
            subText={subText}
            nextText={nextText}
          />
        </LayoutWrapperContent>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <TrustAndSafetyContent intl={intl}/>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer currentPage="TrustAndSafetyPage" location={location}/>
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default injectIntl(TrustAndSafetyPage);
