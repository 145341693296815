import React, { Component } from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { LINE_ITEM_NIGHT } from '../../util/types';
import { stringifyDateToISO8601 } from '../../util/dates';
import { Form as FinalForm } from 'react-final-form';
// import config from '../../config';
import {
  FieldDateRangeInput,
  IconCategory,
  NamedLink,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  Form,
} from '../../components';

import css2 from '../../components/SelectSingleFilter/SelectSingleFilterPopup.module.css';
import css from '../../components/SectionHero/SectionHero.module.css';
import css3 from './HeroSearchForm.module.css';
import { compose } from 'redux';
import { LANGUAGE_KEY } from '../../util/languages';
import TagManager from 'react-gtm-module';


const statisticValue = (option) => {
  if (typeof window !== 'undefined' && option) {

    const tagManagerArgs = {
      dataLayer: {
        event: 'pageview',
        page: {
          path: window.location.pathname,
          locale: localStorage.getItem(LANGUAGE_KEY), // language code of displayed page
          title: 'LandingPage',
          type: 'landing',
          category: option?.key, // Category name in English, if known and selected
        },
      },
      events: {
        pageView: 'pageview',
      },
      page: {
        path: window.location.pathname,
        title: 'LandingPage',
        type: 'landing',
      },
      dataLayerName: 'LandingPage',
    };

    TagManager.dataLayer(tagManagerArgs);

    window.dataLayer = window.dataLayer || [];
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: 'pageview',
      page: {
        path: window.location.pathname,
        locale: localStorage.getItem(LANGUAGE_KEY), // language code of displayed page
        title: 'LandingPage',
        type: 'landing',
        category: option?.key, // Category name in English, if known and selected
      },
    });
  }
};


class HeroSearchFormComponent extends Component {
  state = {
    focusedInput: null,
    startDate: null,
    endDate: null,
    selectedValue: '',
  };

  // componentDidMount() {
  //   if (typeof window !== 'undefined') {
  //
  //     const tagManagerArgs = {
  //       dataLayer: {
  //         event: 'pageview',
  //         page: {
  //           path: window.location.pathname,
  //           locale: localStorage.getItem(LANGUAGE_KEY), // language code of displayed page
  //           title: 'LandingPage',
  //           type: 'landing',
  //           category: this.state.selectedValue?.key, // Category name in English, if known and selected
  //         },
  //       },
  //       events: {
  //         pageView: 'pageview',
  //       },
  //       page: {
  //         path: window.location.pathname,
  //         title: 'LandingPage',
  //         type: 'landing',
  //       },
  //       dataLayerName: 'LandingPage',
  //     };
  //
  //     TagManager.dataLayer(tagManagerArgs);
  //
  //     window.dataLayer = window.dataLayer || [];
  //     dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  //     window.dataLayer.push({
  //       event: 'pageview',
  //       page: {
  //         path: window.location.pathname,
  //         locale: localStorage.getItem(LANGUAGE_KEY), // language code of displayed page
  //         title: 'LandingPage',
  //         type: 'landing',
  //         category: this.state.selectedValue?.key, // Category name in English, if known and selected
  //       },
  //     });
  //   }
  // }

  render() {
    const { selectedValue } = this.state;
    // let categoriesConfig = config.custom.categories;
    // if (typeof localStorage !== 'undefined') {
    //   categoriesConfig = localStorage.getItem(LANGUAGE_KEY) === 'en' ? config.custom.categories : localStorage.getItem(LANGUAGE_KEY) === 'de' ? config.custom.categoriesDe : config.custom.categoriesFr;
    // }

    let avaliablecategories = this.props.availableCategories ? this.props.availableCategories : [];
    // const { intl } = this.props;

    const options = avaliablecategories.map(category => {
      return {
        key: category.toLowerCase(),
        label: category,
        image: category.toLowerCase(),
        imageWhite: category.toLowerCase() + 'White',
      };
    });

    // let filteredCurrentCategories = [];

    // if (options.length > 0) {
    //   categoriesConfig.map(c => {
    //     options.map(currentCategory => {
    //       if (c.key === currentCategory.key) filteredCurrentCategories.push(c);
    //     });
    //   });
    // }

    const toSearchPageLink = (values) => {
      let start = '';
      let end = '';

      if (values.dates) {
        const { startDate, endDate } = values.dates;
        start = startDate ? stringifyDateToISO8601(startDate) : null;
        end = endDate ? stringifyDateToISO8601(endDate) : null;
      }

      const selectedValue = this.state.selectedValue;

      const dates = `${start && end ? `?dates=${start},${end}` : ""}`;
      const hasAnd = selectedValue && start && end ? "&" : "";
      const categories = `${selectedValue ? `${hasAnd}meta_categoriesForFilters=${selectedValue.key}` : ""}`;

      return (
        <NamedLink
          name='SearchPage'
          to={{ search: `${dates}${categories}`}}
          className={css.submitButton}
        >
          <FormattedMessage id='SectionHero.search' />
        </NamedLink>
      );
    };
    // const datesPlaceholderMessage = intl.formatMessage({ id: 'HeroSection.dates' });

    return (
      <FinalForm onSubmit={e => console.log(e)} render={(fieldRenderProps) => {
        const { values } = fieldRenderProps;
        return (
          <Form
            className={css.heroForm}
            onSubmit={e => console.log(e)}
            action={'#'}
          >
            {options.length ? <div className={css.activityHolder}>
              <Menu className={css.heroSelect}>
                <MenuLabel className={css.heroSelectLabel}>
                  <span className={css.labelActivities}>
                    <FormattedMessage id='HeroSection.labelActivities' />
                  </span>
                  {selectedValue
                    ? <div className={css.selectedValueWrapper}><IconCategory
                      cat={selectedValue.key} /><span>{selectedValue.label}</span></div>
                    : <div className={css.selectedValueWrapper}>
                      <IconCategory cat={'default'} /> <FormattedMessage
                      id={'HeroSection.activity'} />
                    </div>}
                </MenuLabel>
                <MenuContent className={css2.menuContent}>
                  {options.map(option => {
                    const selected = selectedValue ? selectedValue.key === option.key : false;
                    const menuItemBorderClass = selected ? css2.menuItemBorderSelected : css2.menuItemBorder;

                    return (
                      <MenuItem key={option.key}>
                        <button
                          className={css2.menuItem}
                          onClick={(e) => {
                            e.preventDefault();
                            statisticValue(option);
                            this.setState({ selectedValue: option });
                          }}
                        >
                          <span className={menuItemBorderClass} />
                          <IconCategory cat={option.key} />
                          {option.label}
                        </button>
                      </MenuItem>
                    );
                  })}
                  <MenuItem className={css.clearButtonHolder} key={'clearLink'}>
                    <button className={css2.clearMenuItem} onClick={(e) => {
                      e.preventDefault();
                      this.setState({ selectedValue: null });
                    }}>
                      <FormattedMessage id={'SelectSingleFilter.popupClear'} />
                    </button>
                  </MenuItem>
                </MenuContent>
              </Menu>
            </div> : null}
            <FieldDateRangeInput
              className={classNames(css3.heroSearchDateRangePicker, {[css3.oneFilter]: !options.length})}
              name='dates'
              titleText={<FormattedMessage id={'SelectSingleFilter.selectDate'} />}
              titleStyle={css.titleStyle}
              unitType={LINE_ITEM_NIGHT}
              inputClassName={css3.input}
              startDateId={`startDate`}
              placeholder={this.props.datepickerPlaceholder}
              startDatePlaceholderText={this.props.datepickerPlaceholder}
              endDateId={`endDate`}
              endDatePlaceholderText={' '}
              numberOfMonths={typeof window != 'undefined' ? window.innerWidth >= 768 ? 2 : 1 : 1}
              showDefaultInputIcon={true}
              daySize={typeof window != 'undefined' ? window.innerWidth >= 768 ? 40 : 35 : 35}
              orientation={typeof window != 'undefined' ? window.innerWidth >= 768 ? 'horizontal' : 'vertical' : 'vertical'}
            />
            {toSearchPageLink(values)}</Form>
        );
      }
      }>
      </FinalForm>
    );
  }

}

HeroSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

HeroSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
};

const HeroSearchForm = compose(injectIntl)(HeroSearchFormComponent);

export default HeroSearchForm;
