import React, { useState } from 'react';
import classNames from 'classnames';

import EyeIcon from '../EyeIcon';
import { FormattedMessage } from "../../../../util/reactIntl";

import css from '../../ItineraryBlock.module.css';


const ItineraryByDay = ({ itinerary, setActivePlace }) => {
  const [open, setOpen] = useState([]);

  const handleShowDesc = (id) => {
    if (open.includes(id)) {
      const newOpen = open.filter(item => item !== id);
      setOpen(newOpen);
    } else {
      setOpen([...open, id]);
    }
  }

  return (
    <div>
      <h2><FormattedMessage id="ActivityFeed.itineraryTitle" /></h2>
      <ul>
        {itinerary.map(({ description, distance, title, images = [], locations = [], order }, i) => {
          const locationNameFirst = locations[0]?.name ?? null;
          const locationNameSecond = locations[locations.length - 1]?.name ?? null;

          return (
            <li
              className={classNames(css.itineraryListItem, { [css.itemOpen]: open.includes(i) })}
              key={title + i}
              onMouseEnter={() => setActivePlace(order)}
              onMouseLeave={() => setActivePlace(null)}
            >
              <div className={css.itineraryItemNumberContainer}>
                <span className={css.itineraryItemNumber}>{i+1}</span>
              </div>
              <div className={css.itineraryContentContainer}>
                {images.length ? <div className={css.imageContainer}>
                  <img src={images[0].url} className={css.image} />
                </div> : null}
                <div>
                  <h3 className={css.itineraryTitle}>{title}</h3>
                  {locations.length ?
                    <span className={css.itineraryName}>
                      {locationNameFirst === locationNameSecond ? locationNameFirst : `${locationNameFirst}/${locationNameSecond}`}
                    </span> : null}
                  {distance ? <span className={css.itineraryName}>{distance}</span> : null}
                  <div
                    className={classNames(css.itineraryDescription, {[css.showDesc]: open.includes(i) })}
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </div>
              </div>
              <div className={css.eyeIconContainer} onClick={() => handleShowDesc(i)}><EyeIcon/></div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ItineraryByDay;
