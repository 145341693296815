import React from 'react';

import config from '../../config';

import IconActivityWater from './IconActivityWater';
import IconActivityBike from './IconActivityBike';
import IconActivityFoot from './IconActivityFoot';
import IconActivitySnow from './IconActivitySnow';
import IconActivityOther from './IconActivityOther';

const IconActivity = props => {
  const { type } = props;

  const {
    waterOptions,
    bikeOptions,
    footOptions,
    snowOptions,
    otherOptions
  } = config.custom;

  const isWaterType = !!waterOptions.find(item => item.icon === type);
  const isBikeType = !!bikeOptions.find(item => item.icon === type);
  const isFootType = !!footOptions.find(item => item.icon === type);
  const isSnowType = !!snowOptions.find(item => item.icon === type);
  const isOtherType = !!otherOptions.find(item => item.icon === type);


  if (isWaterType) {
    return (
      <IconActivityWater type={type} />
    );
  } else if (isBikeType) {
    return (
      <IconActivityBike type={type} />
    );
  } else if (isFootType) {
    return (
      <IconActivityFoot type={type} />
    );
  } else if (isSnowType) {
    return (
      <IconActivitySnow type={type} />
    );
  } else if (isOtherType) {
    return (
      <IconActivityOther type={type} />
    );
  } else {
    return null;
  }
};


export default IconActivity;
