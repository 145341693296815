/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  InlineTextButton,
  NamedLink,
  LanguageSelect,
  CurrencySelect,
  IconFavorite,
  IconAccount,
  IconHelpInfo,
  IconGlobe,
  HostActivity,
} from '../../components';

import css from './TopbarMobileMenu.module.css';
import config from '../../config';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    onLogout,
    currentPage,
    location,
    // currentUser,
    becomeAhostSuccess,
    hubspotFormSubmit
  } = props;

  const signup = (
    <NamedLink name="SignupPage" className={css.menuLink}>
      <FormattedMessage id="TopbarMobileMenu.signupLink" />
    </NamedLink>
  );

  const login = (
    <NamedLink name="LoginPage" className={css.menuLink}>
      <FormattedMessage id="TopbarMobileMenu.loginLink" />
    </NamedLink>
  );

  const lang = config.locale;

  return (
    <div className={css.root}>
      <div className={css.content}>
        <NamedLink
          className={classNames(css.menuCategory, css.menuLink)}
          name="FavoritesPage"
          params={{ lang }}
        >
          <IconFavorite />
          <FormattedMessage id="TopbarMobileMenu.yourFavorites" />
        </NamedLink>

        {isAuthenticated && <NamedLink
          className={classNames(css.menuCategory, css.menuLink)}
          name="AccountSettingsPage"
        >
          <IconAccount />
          <FormattedMessage id="TopbarMobileMenu.accountLink" />
        </NamedLink>}

        {isAuthenticated && <InlineTextButton className={css.menuLink}
          rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>}


        {!isAuthenticated && <div className={css.menuCategory}>
          <IconAccount />
          <FormattedMessage id="TopbarMobileMenu.accountLink" />
        </div>}
        {!isAuthenticated && signup}
        {!isAuthenticated && login}

        <NamedLink className={css.menuLink} name="ManageListingsPage">
          <FormattedMessage id="ManageListingsPage.yourListings" />
        </NamedLink>

        <NamedLink className={css.menuLink} name="ProfileSettingsPage">
          <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
        </NamedLink>

        <div className={css.menuCategory}>
          <IconHelpInfo />
          <FormattedMessage id="TopbarMobileMenu.helpCategory" />
        </div>

        <NamedLink className={css.menuLink} name="TravelerHelpPage">
          <FormattedMessage id="TopbarMobileMenu.travelHelpLink" />
        </NamedLink>

        <NamedLink className={css.menuLink} name="HostHelpPage">
          <FormattedMessage id="TopbarMobileMenu.hostHelpLink" />
        </NamedLink>

        <NamedLink className={css.menuLink} name="AboutPage">
          <FormattedMessage id="TopbarMobileMenu.aboutLink" />
        </NamedLink>

        <NamedLink className={css.menuLink} name="TrustAndSafetyPage">
          <FormattedMessage id="TopbarMobileMenu.trustLink" />
        </NamedLink>


        <div className={css.menuCategory}>
          <IconGlobe />
          <FormattedMessage id="TopbarMobileMenu.localeCategory" />
        </div>
        <div className={css.languageCurrencyMenuMobile}>
          <LanguageSelect currentPage={currentPage} location={location} />
          <CurrencySelect />
        </div>
      </div>
      <div className={css.footer}>
        {/*<NamedLink className={css.createNewListingLink} name="NewListingPage">*/}
        {/*<FormattedMessage id="TopbarMobileMenu.newListingLink"/>*/}
        {/*</NamedLink>*/}
        <div className={css.headerButton}>
          <HostActivity
            becomeAhostSuccess={becomeAhostSuccess}
            hubspotFormSubmit={hubspotFormSubmit}
          />
        </div>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
