import React, { useEffect } from 'react';
import css from './ListingPage.module.css';
import { FormattedMessage } from '../../util/reactIntl';

import logoPartner1 from './images/partnerLogo1.svg'
import logoPartner2 from './images/partnerLogo2.svg'
import logoPartner3 from './images/partnerLogo3.svg'
// import defaultImg from './images/defaultImg.svg'
// import { IconCategory, IconRuning } from '../../components';
import classNames from 'classnames';
import TagManager from 'react-gtm-module';


const InfoBookingModal = ({
  listing,
  isOpen,
  openListing,
  onClose,
  formattedPrice,
  images,
}) => {
  const {
    attributes: {
      publicData: {
        categories = [],
      } = {},
      title = '',
    },
    author: {
      profileImage
    } = {}
  } = listing || {};
  const avatar = profileImage?.attributes.variants[ "square-small" ] ?? null;

  const {
    duration,
    minGroupSize,
    formattedPrice: price,
  } = openListing || {}

  useEffect( () => {
    if(typeof window !== 'undefined' && listing && isOpen) {
      const {
        attributes: {
          metadata: {
            partner
          } = {},
          publicData: {
            categories,
            themes,
            skill_levels,
            listing_languages,
            duration_days,
          } = {},
          title = '',
        },
        id,
      } = listing || {}
      const schemaCategories = categories.map(img => img.title).join('|');
      const schemaThemes = themes.join('|');
      const schemaSkillLevels = skill_levels.map(el => el.title).join('|');
      const schemaLanguages = listing_languages.map(el => el.code).join('|');

      const tagManagerArgs = {
        dataLayer: {
          event: "add_to_cart",
          ecommerce: {
            items: [{
              item_name: title,
              item_id: id,
              price: formattedPrice,
              item_brand: partner?.flex_user_id,
              item_category: schemaCategories,
              item_category2: schemaThemes,
              item_category3: schemaSkillLevels,
              item_category4: duration_days,
              item_variant: schemaLanguages,
              item_list_name: "CartList", // page on which the list was displayed on
              item_list_id: window.location.pathname, // Search string & parameters from URL
              index: 1,
              quantity: 2
            }]
          }
        },
        events: {
          pageView: 'pageview',
        },
        page: {
          path: window.location.pathname,
          title: 'CartListPage',
          type: 'listing',
        },
        dataLayerName: 'CartListDataLayer',
      };

      TagManager.dataLayer(tagManagerArgs);

      window.dataLayer = window.dataLayer || [];
      dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          items: [{
            item_name: title,
            item_id: id,
            price: formattedPrice,
            item_brand: partner?.flex_user_id,
            item_category: schemaCategories,
            item_category2: schemaThemes,
            item_category3: schemaSkillLevels,
            item_category4: duration_days,
            item_variant: schemaLanguages,
            item_list_name: "CartList", // page on which the list was displayed on
            item_list_id: window.location.pathname, // Search string & parameters from URL
            index: 1,
            quantity: 2
          }]
        }
      });
    }

    if(isOpen) {
      setTimeout( ()=> {
        window.open(openListing?.partnerListingUrl);
        onClose();
      }, 3500)
    }
  },[])

  const listingImg = images.length ? images[0] : null;

  return (
    <div>
      <div className={css.blockHeaderModal}>
        <h2 className={css.modalTitle}>
          <FormattedMessage id="ListingPage.modalBookingTitle" />
        </h2>
        <h4 className={css.modalSubTitle}>
          <FormattedMessage id="ListingPage.modalBookingSubTitle" />
        </h4>
        <div className={css.partnerLogo}>
          <img src={logoPartner1} alt='logo1' />
          <img src={logoPartner2} alt='logo2' className={css.imgDots} />
          {avatar ? <img className={css.hostAvatar} src={avatar.url} alt='logo3' /> : <img src={logoPartner3} alt='logo3' />}
        </div>
      </div>
      <div className={css.cartListInfo}>
        {listingImg ? <div className={css.listingImg}>
          <img className={css.bookingImg} src={listingImg.url} alt='imgListing' />
        </div> : null}
        <div className={css.infoListing}>
          <h3 className={css.infoListingTitle}>{title}</h3>
          <ul className={css.infoListingList}>
            {duration ? (
              <li className={css.infoListingListItem}>
                <FormattedMessage id="ListingPage.durationDays" values={{ countDays: duration }} />
              </li>
            ) : null}
            <li className={css.infoListingListItem}>
              {minGroupSize ? <div>{minGroupSize}+</div> : null}
              <div className={css.smallText}>
                {minGroupSize
                  ? <FormattedMessage id="ListingPage.available" />
                  : <FormattedMessage id="ListingPage.notAvailable" />
                }
              </div>
            </li>
            <li className={css.infoListingListItem}>
              <div className={css.infoListingListItemPrice}>
                {price ?? formattedPrice}
              </div>
              <div className={css.infoListingListItemPerPerson}>
                <FormattedMessage id="ListingPage.perPerson" />
              </div>
            </li>
            <li className={classNames(css.infoListingListItem, css.infoListingListItemFlex)}>
              {/* {category === "cycling" ? (
                <>
                  <IconCategory cat={'cycling'}/>
                  <FormattedMessage id="ListingPage.cycling" />
                </>
              ) : (
                <>
                  <IconRuning />
                  <FormattedMessage id="ListingPage.running" />
                </>
              )} */}
              {categories.map(({ icon, title }) => (
                <div key={icon} className={css.category}>
                  <img  src={icon} alt='icon' />
                  <span>{title}</span>
                </div>
              ))}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default InfoBookingModal;
