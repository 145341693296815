import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '../../containers';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  TopbarImgWrapper,
  LayoutWrapperContent,
} from '../../components';
import config from '../../config';
import imprint from './imprint.png';

import css from './ImprintPage.module.css';
import TagManager from 'react-gtm-module';
import { LANGUAGE_KEY } from '../../util/languages';


const ImprintPageComponent = ({ scrollingDisabled, intl, location }) => {

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tagManagerArgs = {
        dataLayer: {
          userId: '001',
          userProject: 'Activebreak',
          page: 'ImprintPage',
        },
        events: {
          pageView: 'pageview',
        },
        page: {
          path: window.location.pathname,
          title: schemaTitle,
          type: 'terms',
        },
        dataLayerName: 'ImprintPageDataLayer',
      };

      TagManager.dataLayer(tagManagerArgs);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pageview',
        page: {
          path: window.location.pathname,
          locale: localStorage.getItem(LANGUAGE_KEY), // language code of displayed page
          title: 'ImprintPage',
          type: 'terms',
        },
      });
    }
  });

  const tabs = [
    {
      text: intl.formatMessage({ id: 'TermsOfServicePage.imprintTabTitle' }),
      selected: true,
      linkProps: {
        name: 'ImprintPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'TermsOfServicePage.privacyTabTitle' }),
      selected: false,
      linkProps: {
        name: 'PrivacyPolicyPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'TermsOfServicePage.cookieTabTitle' }),
      selected: false,
      linkProps: {
        name: 'CookiePolicyPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'TermsOfServicePage.tosTabTitle' }),
      selected: false,
      linkProps: {
        name: 'TermsOfServicePage',
      },
    },
  ];

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'ImprintPage.schemaTitle' }, { siteTitle });
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: schemaTitle,
  };

  const topText = intl.formatMessage({ id: 'ImprintPage.topText' })
  const subText = intl.formatMessage({ id: 'ImprintPage.subText' })

  return (
    <Page title={schemaTitle} scrollingDisabled={scrollingDisabled} schema={schema}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage='ImprintPage' />
        </LayoutWrapperTopbar>
        <LayoutWrapperContent>
          <TopbarImgWrapper
            topBar
            img={imprint}
            topText={topText}
            subText={subText}
          />
        </LayoutWrapperContent>
        <LayoutWrapperSideNav className={css.wrapperSideNav} tabs={tabs} />
        <LayoutWrapperMain className={css.wrapperContent}>
          <div className={css.content}>
            <h1 className={css.heading}>
              <FormattedMessage id='ImprintPage.title' />
            </h1>
            <br />
            <p><strong><FormattedMessage id='ImprintPage.introCompany' /></strong></p>
            <p><FormattedMessage id='ImprintPage.companyName' /></p>

            <div className={css.addressHolder}>
              <p><FormattedMessage id='ImprintPage.street' /></p>
              <p><FormattedMessage id='ImprintPage.city' /></p>
              <p><FormattedMessage id='ImprintPage.country' /></p>
              </div>
            <div className={css.addressHolder}>
              <p><FormattedMessage id='ImprintPage.phoneLabel' /><FormattedMessage id='ImprintPage.phone' /></p>
              <p><FormattedMessage id='ImprintPage.emailLabel' /><FormattedMessage id='ImprintPage.email' /></p>
            </div>

            <div className={css.dataHolder}>
              <p className={css.dataLabel}><FormattedMessage id='ImprintPage.chiefLabel' /></p>
              <p className={css.dataText}><FormattedMessage id='ImprintPage.chief' /></p>
            </div>

            <div className={css.dataHolder}>
              <p className={css.dataLabel}><FormattedMessage id='ImprintPage.districtLabel' /></p>
              <p className={css.dataText}><FormattedMessage id='ImprintPage.district' /></p>
            </div>

            <div className={css.dataHolder}>
              <p className={css.dataLabel}><FormattedMessage id='ImprintPage.vatLabel' /></p>
              <p className={css.dataText}><FormattedMessage id='ImprintPage.vat' /></p>
            </div>

            <div className={css.dataHolder}>
              <p className={css.dataLabel}>
                <FormattedMessage id='ImprintPage.responsibleLable' />
              </p>
              <div className={css.dataText}>
                <p><FormattedMessage id='ImprintPage.responsible1' /></p>
                {/*<p><FormattedMessage id="ImprintPage.responsible2"/></p>*/}
                {/*<p><FormattedMessage id="ImprintPage.responsible3"/></p>*/}
              </div>
            </div>
            <div className={css.legalHolder}>
              <p className={css.bottomTitle}><strong><FormattedMessage id='ImprintPage.legalTitle' /></strong>
              </p>
              <p className={css.bottomText}><a href='https://ec.europa.eu/consumers/odr/'>
                <FormattedMessage id='ImprintPage.legalText1' />
              </a><FormattedMessage id='ImprintPage.legalText2' /></p>
              <p className={css.bottomTitle}><strong><FormattedMessage id='ImprintPage.linksTitle' /></strong>
              </p>
              <p className={css.bottomText}><FormattedMessage id='ImprintPage.linksText' /></p>
              <p className={css.bottomTitle}><strong><FormattedMessage
                id='ImprintPage.contentTitle' /></strong></p>
              <p className={css.bottomText}><FormattedMessage id='ImprintPage.contentText' /></p>
              <p className={css.bottomTitle}><strong><FormattedMessage id='ImprintPage.copyrightTitle' /></strong>
              </p>
              <p className={css.bottomText}><FormattedMessage id='ImprintPage.copyrightText' /></p>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer currentPage='ImprintPage' location={location}/>
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const { bool } = PropTypes;

ImprintPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const ImprintPage = compose(
  connect(mapStateToProps),
  injectIntl,
)(ImprintPageComponent);

export default ImprintPage;
