import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconClose.module.css';
const SIZE_SMALL = 'small';
const CUSTOM_STYLE = 'custom';

const IconClose = props => {
  const { className, rootClassName, size } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (size === SIZE_SMALL) {
    return (
      <svg className={classes} width="9" height="9" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.175 8.396l2.482-2.482 2.482 2.482a.889.889 0 1 0 1.258-1.257L5.914 4.657l2.482-2.483A.89.89 0 0 0 7.139.917L4.657 3.4 2.175.918A.888.888 0 1 0 .917 2.174L3.4 4.657.918 7.139a.889.889 0 1 0 1.257 1.257"
          fillRule="evenodd"
        />
      </svg>
    );
  }

  if (size === CUSTOM_STYLE) {
    return (
      <svg className={classes} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.46644 0.734731C1.27118 0.539469 0.954597 0.539468 0.759334 0.734731C0.564072 0.929993 0.564072 1.24658 0.759334 1.44184L1.46644 0.734731ZM20.5583 21.2408C20.7536 21.4361 21.0702 21.4361 21.2654 21.2408C21.4607 21.0456 21.4607 20.729 21.2654 20.5337L20.5583 21.2408ZM21.253 1.45422C21.4483 1.25896 21.4483 0.942375 21.253 0.747113C21.0578 0.551851 20.7412 0.551851 20.5459 0.747113L21.253 1.45422ZM0.746952 20.5461C0.55169 20.7414 0.55169 21.0579 0.746952 21.2532C0.942214 21.4485 1.2588 21.4485 1.45406 21.2532L0.746952 20.5461ZM0.759334 1.44184L20.5583 21.2408L21.2654 20.5337L1.46644 0.734731L0.759334 1.44184ZM20.5459 0.747113L0.746952 20.5461L1.45406 21.2532L21.253 1.45422L20.5459 0.747113Z" fill="#101010"/>
      </svg>
    );
  }

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill="#101010"
        d="M1.466.733a.5.5 0 00-.707.707l.707-.707zm19.092 20.506a.5.5 0 00.707-.707l-.707.707zm.695-19.787a.5.5 0 10-.707-.707l.707.707zM.747 20.544a.5.5 0 00.707.707l-.707-.707zM.759 1.44l19.8 19.799.706-.707L1.466.732.76 1.44zM20.546.745L.747 20.545l.707.706L21.253 1.452l-.707-.707z"
      ></path>
    </svg>
  );
};

const { string } = PropTypes;

IconClose.defaultProps = {
  className: null,
  rootClassName: null,
};

IconClose.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconClose;
