import React from 'react';
import classNames from 'classnames';
import css from './TopbarImgWrapper.module.css'

const TopbarImgWrapper = props => {

  const {
    img,
    className,
    classNameImg,
    topText,
    subText,
    nextText,
    children,
    topBar,
    classWrapperNameImg
  } = props

  const classes = classNames(css.root, className, {
    [css.isShowInLaptop]: topBar
  });
  const classesImg = classNames(css.img, classNameImg);
  const classesWrapperImg = classNames(css.textWrapperImg, classWrapperNameImg);

  return (
    <div className={classes}>
      { img &&
        <>
          <img
            className={classesImg}
            src={img}
            alt='img' />
          <div className={classesWrapperImg}>
            <h4 className={css.topTextImg}>{topText}</h4>
            <h1 className={css.subTextImg}>{subText}</h1>
            <h1 className={css.subTextImg}>{nextText}</h1>
          </div>
          {children}
        </>
      }
    </div>
  );
};

export default TopbarImgWrapper;
