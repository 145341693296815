import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from '../../util/reactIntl';
import classNames from 'classnames';
import {lazyLoadWithDimensions} from '../../util/contextHelpers';
import {NamedLink} from '../../components';
import moment from 'moment';
import NoImageIcon from './images/NoImageIcon';
import PdfIcon from './images/PdfIcon';
import {ensureCurrentUser} from '../../util/data';
import Slider from 'react-slick';
import {parse} from "../../util/urlHelpers";

import css from './SectionRecentSearches.module.css';

class LocationImage extends Component {
  render() {
    const {alt, ...rest} = this.props;
    return <img alt={alt} {...rest} />;
  }
}

const LazyImage = lazyLoadWithDimensions(LocationImage);


const SectionRecentSearches = props => {
  const {rootClassName, className, currentUser} = props;
  const user = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!user.id;
  const classes = classNames(rootClassName || css.root, className);

  function SampleNextArrow(props) {
    const {onClick} = props;
    return (
      <div
        className={css.sliderNextArrow}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const {onClick} = props;
    return (
      <div
        className={css.sliderPrevArrow}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        }
      }
    ]
  };

  const recentSearches = currentUser && currentUser.attributes.profile.publicData.recentSearches ? currentUser.attributes.profile.publicData.recentSearches : [];
  const lang = (typeof localStorage !== 'undefined' && localStorage.getItem('lang')) ? localStorage.getItem('lang') : 'de';
  const recentSearchesFiltered = recentSearches.filter(r => r.language === lang);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionRecentSearches.title"/>
      </div>
      <div className={css.locations}>
        <Slider {...settings} className={css.slickTrack}>
          {recentSearchesFiltered.map((search, i) => {
            const queryParams = parse(search.link);
            // console.log('------queryParams', queryParams)

            const linkCategory = queryParams.pub_category ? queryParams.pub_category : queryParams['/s?pub_category'];
            const monthName = queryParams.dates ? moment(queryParams.dates.substring(0, 10)).format('MMMM') : '';
            const linkAmenities = queryParams.pub_amenities ? queryParams.pub_amenities : queryParams['/s?pub_amenities'];
            const linkKeywords = queryParams.keywords ? queryParams.keywords : queryParams['/s?keywords'];
            const linkAddress = queryParams.address ? queryParams.address : queryParams['/s?address'];

            return (
              <NamedLink name="SearchPage" to={{search: search.link}}
                         className={css.location}
                         key={i}>
                {search.image !== '' && search.image.indexOf("pdf") === -1 ? (
                    <div className={css.imageWrapper}>
                      <div className={css.aspectWrapper}>
                        <LazyImage
                          src={search.image}
                          alt={'name'}
                          className={css.locationImage}
                        />
                      </div>
                    </div>) :
                  search.image !== '' && search.image.indexOf("pdf") !== -1 ?
                    (<div className={css.noImageContainer}>
                      <div className={css.noImageWrapper}>
                        <PdfIcon className={css.pdfIcon}/>
                      </div>
                    </div>)
                    :
                    (<div className={css.noImageContainer}>
                      <div className={css.noImageWrapper}>
                        <NoImageIcon className={css.noImageIcon}/>
                        <div className={css.noImageText}>no image</div>
                      </div>
                    </div>)}
                <div
                  className={search.image !== '' && search.image.indexOf("pdf") === -1 ? css.linkText : css.noImageLinkText}>
                  {linkCategory}
                  {/*{linkAmenities}*/}
                  {/*{linkKeywords}*/}
                  {monthName &&
                    <span>
                      <span className={css.pre}>
                        {linkCategory && ` in`}
                      </span> {linkAddress}
                    </span>}
                  {monthName &&
                    <span>
                      <span className={css.pre}>
                        {linkAddress && ` in`}
                      </span> {monthName}
                    </span>}
                </div>
              </NamedLink>)
          })}
        </Slider>
      </div>
    </div>
  );
};

SectionRecentSearches.defaultProps = {rootClassName: null, className: null};

const {string} = PropTypes;

SectionRecentSearches.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionRecentSearches;
