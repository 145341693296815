import React from 'react';
import PropTypes from 'prop-types';


const IconArrowDown = props => {

  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 11" width="21" height="10" style={{marginLeft: 7}}>
      <g>
        <path d="M20.2715 0.721701C20.2715 0.526701 20.1534 0.352326 19.9734 0.277326C19.7915 0.204201 19.5852 0.247326 19.4484 0.387951L10.1877 9.64858L0.927111 0.387951C0.80711 0.262326 0.62711 0.211701 0.460236 0.256701C0.291485 0.299826 0.160234 0.431077 0.117109 0.599826C0.0721092 0.766701 0.122734 0.946701 0.24836 1.0667L9.84836 10.6667C10.0359 10.8542 10.3396 10.8542 10.5271 10.6667L20.1271 1.0667C20.2209 0.976701 20.2715 0.852952 20.2715 0.721701Z" fill="black"/>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconArrowDown.defaultProps = {
  className: null,
  rootClassName: null,
};

IconArrowDown.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconArrowDown;
