import React, { useEffect } from 'react';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  TopbarImgWrapper,
  LayoutWrapperContent,
} from '../../components';

import SideBarNav from './SideBarNav';

import css from './HostHelpPage.module.css';
import TagManager from 'react-gtm-module';
import imgTop from './img/imgTop.jpg';
import img1 from './img/img1.jpg';
import img2 from './img/img2.jpg';
import classNames from 'classnames';
import { LANGUAGE_KEY } from '../../util/languages';

const HostHelpPage = ({ location, intl }) => {

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tagManagerArgs = {
        dataLayer: {
          userId: '001',
          userProject: 'Activebreak',
          page: 'HostHelpPage',
        },
        events: {
          pageView: 'pageview',
        },
        page: {
          path: window.location.pathname,
          title: 'Host Help Page',
          type: 'support',
        },
        dataLayerName: 'HostHelpPageDataLayer',
      };

      TagManager.dataLayer(tagManagerArgs);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pageview',
        page: {
          path: window.location.pathname,
          locale: localStorage.getItem(LANGUAGE_KEY), // language code of displayed page
          title: 'Host Help Page',
          type: 'support',
        },
      });
    }
  });

  const privacyPolicyLink = (
    <NamedLink name='PrivacyPolicyPage'>
      <FormattedMessage id='HostHelpPage.navItemLink' />
    </NamedLink>
  );

  const termsLink = (
    <NamedLink name='PrivacyPolicyPage'>
      <FormattedMessage id='HostHelpPage.navItemLink' />
    </NamedLink>
  );

  const topText = intl.formatMessage({ id: 'HostHelpPage.topText' });
  const subText = intl.formatMessage({ id: 'HostHelpPage.subText' });
  const nextText = intl.formatMessage({ id: 'HostHelpPage.nextText' });
  const title = intl.formatMessage({ id: 'HostHelpPage.title' });

  const getstartedSlug = intl.formatMessage({ id: 'HostHelpPage.GetStartedSlug' });
  const howSlug = intl.formatMessage({ id: 'HostHelpPage.HowSlug' });
  const communicationSlug = intl.formatMessage({ id: 'HostHelpPage.CommunicationSlug' });
  const gsSlug = intl.formatMessage({ id: 'HostHelpPage.GsSlug' });
  const partnershipSlug = intl.formatMessage({ id: 'HostHelpPage.PartnershipSlug' });
  const accountSlug = intl.formatMessage({ id: 'HostHelpPage.AccountSlug' });
  const createAccountSlug = intl.formatMessage({ id: 'HostHelpPage.CreateAccountSlug' });
  const manageAccountSlug = intl.formatMessage({ id: 'HostHelpPage.ManageAccountSlug' });
  const safetyIdentificationSlug = intl.formatMessage({ id: 'HostHelpPage.SafetyIdentificationSlug' });
  const paymentsTransfersSlug = intl.formatMessage({ id: 'HostHelpPage.PaymentsTransfersSlug' });
  const termsSlug = intl.formatMessage({ id: 'HostHelpPage.TermsSlug' });
  const guidelinesTipsSlug = intl.formatMessage({ id: 'HostHelpPage.GuidelinesTipsSlug' });
  const issuesReportsSlug = intl.formatMessage({ id: 'HostHelpPage.IssuesReportsSlug' });
  const safetySlug = intl.formatMessage({ id: 'HostHelpPage.SafetySlug' });
  const travelRestrictionsSlug = intl.formatMessage({ id: 'HostHelpPage.TravelRestritionsSlug' });
  const travelAdvisoriesSlug = intl.formatMessage({ id: 'HostHelpPage.travelAdvisoriesSlug' });
  const otherSlug = intl.formatMessage({ id: 'HostHelpPage.OtherSlug' });
  const rulesRegulationsSlug = intl.formatMessage({ id: 'HostHelpPage.RulesSlug' });
  const privacyPolicySlug = intl.formatMessage({ id: 'HostHelpPage.PrivacyPolicySlug' });
  const termsAndConditionsSlug = intl.formatMessage({ id: 'HostHelpPage.TermsConditionsSlug' });
  const dataProtectionPolicySlug = intl.formatMessage({ id: 'HostHelpPage.DataProtectionSlug' });

  // prettier-ignore
  return (
    <StaticPage
      title={title}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Host help',
        description: 'Host help',
        name: 'Host help page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage='HostHelpPage' />
        </LayoutWrapperTopbar>
        <LayoutWrapperContent>
          <TopbarImgWrapper
            topBar
            classWrapperNameImg={css.imgText}
            img={imgTop}
            topText={topText}
            subText={subText}
            nextText={nextText}
          />
        </LayoutWrapperContent>
        <LayoutWrapperMain className={css.staticPageWrapper}>

          <div className={css.contentWrapper}>
            <div className={css.contentSide}>
              <ul className={css.navList}>
                <SideBarNav intl={intl} location={location} />
              </ul>
            </div>

            <div className={css.contentMain}>

              <p><FormattedMessage id='HostHelpPage.tip' /></p>

              <h2 className={css.title} id={getstartedSlug}>
                <FormattedMessage id='HostHelpPage.navItem1' />
              </h2>

              <h3 className={css.subtitle} id={howSlug}>
                <FormattedMessage id='HostHelpPage.navItem2' />
              </h3>
              <p><FormattedMessage id='HostHelpPage.navItem2List1' /></p>
              <ul className={css.listNumbers}>
                <li><FormattedMessage id='HostHelpPage.navItem2List2' /></li>
                <li><FormattedMessage id='HostHelpPage.navItem2List3' /></li>
                <li><FormattedMessage id='HostHelpPage.navItem2List4' /></li>
                <li><FormattedMessage id='HostHelpPage.navItem2List5' /></li>
              </ul>

              <TopbarImgWrapper
                img={img1}
                className={css.imgBlock}
              />

              <h3 className={css.subtitle} id={communicationSlug}>
                <FormattedMessage id='HostHelpPage.navItem3' />
              </h3>
              <p><FormattedMessage id='HostHelpPage.navItem3List1' /></p>

              <ul className={css.listNumbers}>

                <li><FormattedMessage id='HostHelpPage.navItem3List2' /></li>
                <li><FormattedMessage id='HostHelpPage.navItem3List3' /></li>
                <li><FormattedMessage id='HostHelpPage.navItem3List4' /></li>
                <li><FormattedMessage id='HostHelpPage.navItem3List5' /></li>
                <li><FormattedMessage id='HostHelpPage.navItem3List6' /></li>
              </ul>

              <p><FormattedMessage id='HostHelpPage.navItem3List7' /></p>

              <h3 className={css.subtitle} id={gsSlug}>
                <FormattedMessage id='HostHelpPage.navItem4' />
              </h3>
              <p><FormattedMessage id='HostHelpPage.navItem4List1' /></p>
              <ul className={css.listNumbers}>

                <li><FormattedMessage id='HostHelpPage.navItem4List2' /></li>
                <li><FormattedMessage id='HostHelpPage.navItem4List3' /></li>
                <li><FormattedMessage id='HostHelpPage.navItem4List4' /></li>
                <li><FormattedMessage id='HostHelpPage.navItem4List5' /></li>
                <li><FormattedMessage id='HostHelpPage.navItem4List6' /></li>
              </ul>
              <p><FormattedMessage id='HostHelpPage.navItem4List7' /></p>

              <h3 className={css.subtitle} id={partnershipSlug}>
                <FormattedMessage id='HostHelpPage.navItem5' />
              </h3>

              <p><FormattedMessage id='HostHelpPage.navItem5List1' /></p>
              <ul className={css.listNumbers}>
                <li><FormattedMessage id='HostHelpPage.navItem5List2' /></li>
                <li><FormattedMessage id='HostHelpPage.navItem5List3' /></li>
                <li><FormattedMessage id='HostHelpPage.navItem5List4' /></li>
                <li><FormattedMessage id='HostHelpPage.navItem5List5' /></li>
                <li><FormattedMessage id='HostHelpPage.navItem5List6' /></li>
              </ul>
              <p><FormattedMessage id='HostHelpPage.navItem5List7' /></p>


              <h2 className={css.title} id={accountSlug}>
                <FormattedMessage id='HostHelpPage.navItem6' />
              </h2>
              <h3 className={css.subtitle} id={createAccountSlug}>
                <FormattedMessage id='HostHelpPage.navItem7' />
              </h3>
              <p><FormattedMessage id='HostHelpPage.navItem7List1' /></p>
              <p><FormattedMessage id='HostHelpPage.navItem7List2' /></p>
              <p><FormattedMessage id='HostHelpPage.navItem7List3' /></p>
              <p><FormattedMessage id='HostHelpPage.navItem7List4' /></p>

              <h3 className={css.subtitle} id={manageAccountSlug}>
                <FormattedMessage id='HostHelpPage.navItem8' />
              </h3>

              <p><FormattedMessage id='HostHelpPage.navItem8List1' /></p>
              <p><FormattedMessage id='HostHelpPage.navItem8List2' /></p>
              <p><FormattedMessage id='HostHelpPage.navItem8List3' /></p>
              <p><FormattedMessage id='HostHelpPage.navItem8List4' /></p>
              <p><FormattedMessage id='HostHelpPage.navItem8List5' /></p>


              <h3 className={css.subtitle} id={safetyIdentificationSlug}>
                <FormattedMessage id='HostHelpPage.navItem9' />
              </h3>
              <p><FormattedMessage id='HostHelpPage.navItem9List1' /></p>
              <p><FormattedMessage id='HostHelpPage.navItem9List2' /></p>
              <p><FormattedMessage id='HostHelpPage.navItem9List3' /></p>
              <p><FormattedMessage id='HostHelpPage.navItem9List4' /></p>
              <p><FormattedMessage id='HostHelpPage.navItem9List5' /></p>

              <h3 className={css.subtitle} id={paymentsTransfersSlug}>
                <FormattedMessage id='HostHelpPage.navItem10' />
              </h3>
              <p><FormattedMessage id='HostHelpPage.navItem10List1' /></p>
              <p><FormattedMessage id='HostHelpPage.navItem10List2' /></p>

              <h2 className={css.title} id={termsSlug}>
                <FormattedMessage id='HostHelpPage.navItem11' />
              </h2>

              <h3 className={css.subtitle} id={guidelinesTipsSlug}>
                <FormattedMessage id='HostHelpPage.navItem12' />
              </h3>

              <p><FormattedMessage id='HostHelpPage.navItem12List1' /></p>
              <p><FormattedMessage id='HostHelpPage.navItem12List2' /></p>
              <p><FormattedMessage id='HostHelpPage.navItem12List3' /></p>
              <p><FormattedMessage id='HostHelpPage.navItem12List4' /></p>

              <h3 className={css.subtitle} id={issuesReportsSlug}>
                <FormattedMessage id='HostHelpPage.navItem13' />
              </h3>
              <ul className={css.list}>
                <li><FormattedMessage id='HostHelpPage.navItem13List1' /></li>
                <li><FormattedMessage id='HostHelpPage.navItem13List2' /></li>
              </ul>

              <h3 className={css.subtitle} id={safetySlug}>
                <FormattedMessage id='HostHelpPage.navItem14' />
              </h3>
              <ul className={css.list}>
                <li><FormattedMessage id='HostHelpPage.navItem14List1' /></li>
              </ul>

              <h3 className={css.subtitle} id={travelRestrictionsSlug}>
                <FormattedMessage id='HostHelpPage.navItem15' />
              </h3>
              <ul className={css.list}>
                <li><FormattedMessage id='HostHelpPage.navItem15List1' /></li>
              </ul>

              <h3 className={css.subtitle} id={travelAdvisoriesSlug}>
                <FormattedMessage id='HostHelpPage.navItem16' />
              </h3>
              <ul className={css.list}>
                <li><FormattedMessage id='HostHelpPage.navItem16List1' /></li>
                <li><FormattedMessage id='HostHelpPage.navItem16List2' /></li>
              </ul>

              <h3 className={css.subtitle} id={otherSlug}>
                <FormattedMessage id='HostHelpPage.navItem17' />
              </h3>
              <ul className={css.list}>
                <li><FormattedMessage id='HostHelpPage.navItem17List1' /></li>
              </ul>

              <br />

              <h2 className={classNames(css.title, css.customMarginBottom)}
                  id={rulesRegulationsSlug}>
                <FormattedMessage id='HostHelpPage.navItem18' />
              </h2>
              <p className={css.customMarginTop}><FormattedMessage
                id='HostHelpPage.navItem18Text' /></p>

              <h3 className={css.subtitle} id={privacyPolicySlug}>
                <FormattedMessage id='HostHelpPage.navItem19' />
              </h3>
              <p><FormattedMessage id='HostHelpPage.navItem19Text' values={{ privacyPolicyLink }} />
              </p>

              <h3 className={css.subtitle} id={termsAndConditionsSlug}>
                <FormattedMessage id='HostHelpPage.navItem20' />
              </h3>
              <p><FormattedMessage id='HostHelpPage.navItem20Text' values={{ termsLink }} /></p>

              <h3 className={css.subtitle} id={dataProtectionPolicySlug}>
                <FormattedMessage id='HostHelpPage.navItem21' />
              </h3>
              <p><FormattedMessage id='HostHelpPage.navItem21Text' /></p>

              <TopbarImgWrapper
                img={img2}
                className={css.imgBlock}
              />

            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer currentPage='HostHelpPage' location={location} />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default injectIntl(HostHelpPage);
