import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionDescriptionMaybe = ({ description }) => {
  const formattedStr = description
    .replace(/＜/g, "<")
    .replace(/＞/g, ">");

  return description ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.descriptionTitle" />
      </h2>
      <div className={css.description} dangerouslySetInnerHTML={{ __html: formattedStr }}>
      </div>
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
