import React, { Component } from 'react';
import css from './ActivityBlock.module.css';
import Slider from "react-slick";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading, AccordionItemPanel,
} from 'react-accessible-accordion';
import { FormattedMessage } from '../../util/reactIntl';

class ActivityBlock extends Component {

  render() {
    const {
      listing: {
        attributes: {
          publicData: {
            accommodations = []
          }
        }
      }
    } = this.props;

    const attachments = accommodations.reduce((total, { images }) => {
      const uniqueImages = images.filter(({ url }) => !total.find((img) => !img.url === url));
      return [...total, ...uniqueImages];
    }, []);

    const SampleNextArrow = ({ style, onClick }) => {
      return (
        <div
          className={css.slickNext}
          style={{ ...style, right: '0px', display: "block" }}
          onClick={onClick}
        />
      );
    };
    const SamplePrevArrow = ({ style, onClick }) => {
      return (
        <div
          className={css.slickPrev}
          style={{ ...style, left: '0px', display: "block" }}
          onClick={onClick}
        />
      );
    };


    return (
      <div className={css.activityHolder}>
        <div className={css.accordionHolder}>
          <Accordion allowZeroExpanded={true}>
            {accommodations.map(({title, description, url}) =>
              <AccordionItem key={title}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    {title}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {url ? <a
                    className={css.link}
                    href={url}
                    target="_blank"
                  >
                    <FormattedMessage id="ActivityBlock.linkToHotel" />
                  </a> : null}
                  <p className={css.description} dangerouslySetInnerHTML={{__html: description}} />
                </AccordionItemPanel>
              </AccordionItem>
            )}
          </Accordion>
        </div>
        <div className={css.galleryHolder}>
          <Slider
            customPaging={(i) => {
              return (
                <a className={css.thumbLink}>
                  <img src={attachments[i].url} className={css.thumbImage}/>
                </a>
              );
            }}
            dots={true}
            dotsClass={css.galleryDots}
            slidesToShow={1}
            slidesToScroll={1}
            className={css.gallerySlider}
            adaptiveHeight={true}
            nextArrow={<SampleNextArrow/>}
            prevArrow={<SamplePrevArrow/>}
          >
            {attachments.map(a => {
              return <img key={a.url} src={a.url} className="yours-custom-class"/>
            })}
          </Slider>
        </div>
      </div>
    );
  }
}

export default ActivityBlock;
