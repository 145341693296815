import React from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import classNames from 'classnames';
import IconLogo from '../../components/Logo/IconLogo'
import { FormattedMessage } from '../../util/reactIntl';
import { createSlug } from '../../util/urlHelpers';
import { NamedLink, IconMessage } from '../../components';
import { LISTING_STATE_CLOSED } from '../../util/types';
import css from './ListingPage.module.css';
import {ListingFavouritesForm} from "../../forms";
import {ensureCurrentUser} from "../../util/data";

const ROOT_URL = process.env.REACT_APP_CANONICAL_ROOT_URL;

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const save = async values => await sleep(1000);

const getMinPackagesPrice = (packages) => {
  let minPrice;

  packages.forEach(({rooms}) => {
    rooms.forEach(({price, available}) => {
      if ((!minPrice && available) || (available && price.amount < minPrice)) {
        minPrice = price.amount;
      }
    });
  });

  return minPrice;
}

const getDepartureDate = (packages) => {
  let minDate;

  packages.forEach(({rooms, departure_date}) => {
    const isAvailable = rooms.find(({available}) => available);

    const timeInMls = new Date(departure_date).getTime();

    if (
      (!minDate && isAvailable) ||
      (isAvailable && timeInMls < new Date(minDate).getTime())
    ) {
      minDate = departure_date;
    }
  });

  return minDate;
}

const ListingHashNavTopbar = ({
  location,
  listing,
  intl,
  formattedPrice,
  showCustomTopbarButton,
  showShared,
  currentUser,
  isAuthenticated,
  onUpdateProfile,
  images,
}) => {
  const {
    reviews: publicDataReviews,
    faqs: publicDataFaqs,
    skill_levels: publicDataSkillLevels = [],
    fitness_levels: publicDataFitnessLevels = [],
    guided,
    categories = [],
    duration_days,
    themes = [],
    packages = [],
  } = listing.attributes.publicData;

  const partnerId = listing.author.id.uuid;
  const minPrice = getMinPackagesPrice(packages);
  const departureDate = getDepartureDate(packages);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const spotlightTitle = intl.formatMessage({ id: 'SectionSpotlight.spotlightTitle' });
  const tripInsightsTitle = intl.formatMessage({ id: 'SectionSpotlight.tripInsightsTitle' });
  const packageOptionsTitle = intl.formatMessage({ id: 'SectionSpotlight.packageOptionsTitle' });
  const detailsTitle = intl.formatMessage({ id: 'SectionSpotlight.detailsTitle' });
  const FAQsTitle = intl.formatMessage({ id: 'SectionSpotlight.FAQsTitle' });
  const ReviewsTitle = intl.formatMessage({ id: 'SectionSpotlight.ReviewsTitle' });
  const similarTitle = intl.formatMessage({ id: 'SectionSpotlight.similarTitle' });

  const navItems = [
    { link: '#spotlight', name: spotlightTitle, show: true },
    { link: '#insights', name: tripInsightsTitle, show: true },
    { link: '#availability', name: packageOptionsTitle, show: true },
    { link: '#details', name: detailsTitle, show: true },
    { link: 'faq', name: FAQsTitle, show: publicDataFaqs.length > 0 ? true : false },
    { link: '#reviews', name: ReviewsTitle, show: publicDataReviews.length > 0 ? true : false },
    { link: '#similar', name: similarTitle, show: false },
  ];

  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm = hasListingState && !isClosed;

  const slug = listing?.attributes?.title && createSlug(listing.attributes.title);
  const id = listing?.id?.uuid
  const user = ensureCurrentUser(currentUser);

  const listingFavourite = listing ? user.attributes.profile.publicData?.listingFavourite ?? [] : [];
  const init = listingFavourite ? listingFavourite.filter(l => l.listingId === id) : [];
  const avatar = listing && listing.author.profileImage ? listing.author.profileImage.attributes.variants['square-small'] : [];
  const authorName = listing.author.attributes.profile.displayName;

  return (
    <div className={css.navList}>
      <div className={css.navListItem}>
        <NamedLink className={css.logoLink} name="LandingPage">
          <IconLogo className={css.navListLogo} />
        </NamedLink>
        <div className={css.navListWrapper}>
          {navItems && navItems.map(item => {
              return (
                <div key={item.link}>
                  {item.show &&
                    <NavLink
                      className={location.hash === item.link ? classNames(css.selected, css.navItem) : css.navItem}
                      to={item.link}
                      smooth
                      scroll={el => scrollWithOffset(el)}
                      activeClassName={css.selected}
                    >
                      {item.name}
                    </NavLink>
                  }
                </div>
              )
          })}
        </div>
        <div className={css.navListIcon} onClick={showShared}><IconMessage /></div>
        <ListingFavouritesForm
          className={css.textFavorite}
          svgClassName={css.checkbox}
          isAuthenticated={isAuthenticated}
          iconClassName={css.iconShareHash}
          initialValues={{
            [`favourite_${id}`]: init[0]
              ? init[0][`favourite_${id}`]
              : false,
          }}
          onSubmit={values => {
            save(values);
            let currentListingId = Object.keys(values)[0];
            let currentListingValue = values[`favourite_${id}`];
            currentListingId = currentListingId.replace('favourite_', '');
            const hasCurrentListing = listingFavourite.filter(l => l.listingId === currentListingId).length > 0;
            if (!hasCurrentListing && currentListingValue) {
              onUpdateProfile({
                publicData: {
                  listingFavourite: [...listingFavourite, {
                    title,
                    authorName,
                    avatar,
                    skill_levels: publicDataSkillLevels,
                    fitness_levels: publicDataFitnessLevels,
                    listingImages: images,
                    listingId: id,
                    duration_days,
                    categories,
                    guided,
                    themes,
                    partner_id: partnerId,
                    price: minPrice,
                    departure_date: departureDate,
                    [`favourite_${id}`]: true,
                  }],
                },
              });
            }

            if (hasCurrentListing && !currentListingValue) {
              const removeCurrentListing = listingFavourite.filter(l => l.listingId !== currentListingId);
              onUpdateProfile({
                publicData: {
                  listingFavourite: removeCurrentListing,
                },
              });
            }
          }}
          onChange={() => {}}
          listingId={id}
        />
      </div>
      <div className={classNames(css.navListItem, css.navListItemButton)}>
        {showCustomTopbarButton && (
          <>
            <div className={css.fromValue}>
              <span className={css.headingOfInfo}>
                <FormattedMessage id='BookingPanel.priceFrom' />
              </span>
              <span className={classNames(css.fromValueNumber, {
                [css.bigNumber]: formattedPrice && formattedPrice.length > 10,
              })}>{formattedPrice}</span>
              <span className={css.headingOfInfo}>
                <FormattedMessage id='BookingPanel.person' />
              </span>
            </div>
            {showBookingDatesForm ? (
              <a className={css.bookButton} href={`${ROOT_URL}/l/${slug}/${id}#availability`}>
                <FormattedMessage id='BookingDatesForm.requestToBook' />
              </a>
            ) : null}
          </>
        )}
      </div>
    </div>
  )
};

export default ListingHashNavTopbar;
