import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getPrivacyPolicyText } from '../../ducks/PrivacyPolicy.duck';
import { TopbarContainer } from '../../containers';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer, Iubenda, TopbarImgWrapper, LayoutWrapperContent,
} from '../../components';
import config from '../../config';

import TagManager from "react-gtm-module";
import css from './PrivacyPolicyPage.module.css';
import { LANGUAGE_KEY } from "../../util/languages";
import privacy from './privacy.png';

const PrivacyPolicyPageComponent = props => {
  const { scrollingDisabled, intl, location, onGetPrivacyPolicyText } = props;

  useEffect(() => {

    let lang = 'en';
    if(typeof localStorage !== 'undefined') {
      lang = localStorage.getItem(LANGUAGE_KEY) === 'en' ? 'en' : localStorage.getItem(LANGUAGE_KEY) === 'de' ? 'deu' : localStorage.getItem(LANGUAGE_KEY) === 'fr' ? "fra" : "en";
    }

    onGetPrivacyPolicyText(lang, 'privacy');

    if(typeof window !== 'undefined' && document !== 'undefined') {
      const tagManagerArgs = {
        dataLayer: {
          userId: '001',
          userProject: 'Activebreak',
          page: 'PrivacyPolicyPage'
        },
        events: {
          pageView: 'pageview',
        },
        page: {
          path: window.location.pathname,
          title: 'PrivacyPolicyPage',
          type: 'terms',
        },
        dataLayerName: 'PrivacyPolicyPageDataLayer'
      };

      TagManager.dataLayer(tagManagerArgs);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pageview',
        page: {
          path: window.location.pathname,
          locale: localStorage.getItem(LANGUAGE_KEY), // language code of displayed page
          title: 'PrivacyPolicyPage',
          type: 'terms',
        },
      });
    }
  }, []);

  const tabs = [
    {
      text: intl.formatMessage({ id: 'TermsOfServicePage.imprintTabTitle' }),
      selected: false,
      linkProps: {
        name: 'ImprintPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'TermsOfServicePage.privacyTabTitle' }),
      selected: true,
      linkProps: {
        name: 'PrivacyPolicyPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'TermsOfServicePage.cookieTabTitle' }),
      selected: false,
      linkProps: {
        name: 'CookiePolicyPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'TermsOfServicePage.tosTabTitle' }),
      selected: false,
      linkProps: {
        name: 'TermsOfServicePage',
      },
    },
  ];

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'PrivacyPolicyPage.schemaTitle' }, { siteTitle });
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: schemaTitle,
  };

  const topText = intl.formatMessage({ id: 'PrivacyPolicyPage.topText' })
  const subText = intl.formatMessage({ id: 'PrivacyPolicyPage.subText' })

  let iubendaEn = {
    title: "Privacy Policy",
    text: "Privacy Policy",
    idForm: "privacy-policy/48988523",
  }
  let iubendaDe = {
    title: "Datenschutzerklärung",
    text: "Datenschutzerklärung",
    idForm: "privacy-policy/84666627",
  }

  let iubendaId
  if(typeof localStorage !== 'undefined') {
    iubendaId = localStorage.getItem(LANGUAGE_KEY) === 'de'
      ? iubendaDe
      : iubendaEn
  }

  return (
    <Page title={schemaTitle} scrollingDisabled={scrollingDisabled} schema={schema}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="PrivacyPolicyPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperContent>
          <TopbarImgWrapper
            topBar
            img={privacy}
            topText={topText}
            subText={subText}
          />
        </LayoutWrapperContent>
        <LayoutWrapperSideNav tabs={tabs} className={css.wrapperSideNav} />
        <LayoutWrapperMain className={css.wrapperContent}>
          <div className={css.content}>
            <Iubenda iubendaId={iubendaId} />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer currentPage="PrivacyPolicyPage" location={location}/>
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const { bool, func } = PropTypes;

PrivacyPolicyPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  onGetPrivacyPolicyText: func,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { privacyPolicyText } = state.PrivacyPolicy;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    privacyPolicyText,
  };
};


const mapDispatchToProps = dispatch => ({
  onGetPrivacyPolicyText: (lang, type) => dispatch(getPrivacyPolicyText(lang, type)),
});

const PrivacyPolicyPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(PrivacyPolicyPageComponent);

export default PrivacyPolicyPage;
