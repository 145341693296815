import React from 'react';
import classNames from 'classnames';
import groupBy from 'lodash/groupBy';
import { reducedToArray } from '../../../SearchMap/SearchMap.helpers';
import MapSingleLabelWithOverlay from '../MapSingleLabelWithOverlay/MapSingleLabelWithOverlay';
import MapGroupLabelWithOverlay from '../MapGroupLabelWithOverlay/MapGroupLabelWithOverlay';

import css from '../../../SearchMap/SearchMapWithGoogleMaps.module.css';

const LABEL_HANDLE = 'ItineraryMapLabel';

const CustomMarker = props => {
  const {
    map,
    coordinates,
    activePlace,
    infoCardOpen,
    onPlaceClicked,
    mapComponentRefreshToken,
  } = props;

  const placesArraysInLocations = reducedToArray(groupBy(coordinates, c => {
    return `${c.lat}-${c.lng}`;
  }));

  const placesLabels = placesArraysInLocations.map(locations => {

    const isActive = activePlace
      ? !!locations.find(({order}) => activePlace === order)
      : false;

    const classes = classNames(css.labelContainer, LABEL_HANDLE, { [css.activeLabel]: isActive });

    // If location contains only one listing, print price label
    if (locations.length === 1) {
      const place = locations[0];
      const infoCardOpenIds = Array.isArray(infoCardOpen) ? infoCardOpen : [infoCardOpen];

      // if the listing is open, don't print price label
      if (infoCardOpen != null && infoCardOpenIds.includes(place)) {
        return null;
      }

      // Explicit type change to object literal for Google OverlayViews (geolocation is SDK type)
      const latLngLiteral = { lat: place.lat, lng: place.lng };

      return (
        <MapSingleLabelWithOverlay
          key={`${place.lat}-${place.lng}`}
          position={latLngLiteral}
          map={map}
          isActive={isActive}
          className={classes}
          onPlaceClicked={onPlaceClicked}
          mapComponentRefreshToken={mapComponentRefreshToken}
          label={place.order}
        />
      );
    }

    // Explicit type change to object literal for Google OverlayViews (geolocation is SDK type)
    const geolocation = locations[0];
    const latLngLiteral = { lat: geolocation.lat, lng: geolocation.lng };

    return (
      <MapGroupLabelWithOverlay
        key={`${geolocation.lat}-${geolocation.lng}`}
        position={latLngLiteral}
        map={map}
        isActive={isActive}
        className={classes}
        onPlaceClicked={onPlaceClicked}
        mapComponentRefreshToken={mapComponentRefreshToken}
        labels={locations.map(({order}) => order)}
      />
    );
  });

  return placesLabels;
};

export default CustomMarker;
