import React, { useEffect } from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer, TopbarImgWrapper, LayoutWrapperContent,
} from '../../components';
import TagManager from "react-gtm-module";

import css from './AboutPage.module.css';
import SideBarNav from "./SideBarNav";

import topImg from './img/topImg.jpg'
import nextImg from './img/nextImg.jpg'
import { LANGUAGE_KEY } from '../../util/languages';



const AboutPage = ({ location, intl }) => {

  useEffect(() => {
    if(typeof window !== 'undefined') {
      const tagManagerArgs = {
        dataLayer: {
          userId: '001',
          userProject: 'Activebreak',
          page: 'AboutPage'
        },
        events: {
          pageView: 'pageview',
        },
        page: {
          path: window.location.pathname,
          title: 'About Us',
          type: 'support',
        },
        dataLayerName: 'AboutPageDataLayer',
      };

      TagManager.dataLayer(tagManagerArgs);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pageview',
        page: {
          path: window.location.pathname,
          locale: localStorage.getItem(LANGUAGE_KEY), // language code of displayed page
          title: 'About Us',
          type: 'support',
        },
      });
    }
  });

  const topText = intl.formatMessage({ id: 'AboutPage.topText' })
  const subText = intl.formatMessage({ id: 'AboutPage.subText' })
  const nextText = intl.formatMessage({ id: 'AboutPage.nextText' })
  const title = intl.formatMessage({ id: 'AboutPage.title' })

  const howSlug = intl.formatMessage({ id: 'AboutPage.howSlug' });
  const whatSlug = intl.formatMessage({ id: 'AboutPage.whatSlug' });
  const valuesSlug = intl.formatMessage({ id: 'AboutPage.valuesSlug' });
  const communitySlug = intl.formatMessage({ id: 'AboutPage.communitySlug' });

  // prettier-ignore
  return (
    <StaticPage
      title={title}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About Activebreak',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="AboutPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperContent>
          <TopbarImgWrapper
            topBar
            img={topImg}
            topText={topText}
            subText={subText}
            nextText={nextText}
          />
        </LayoutWrapperContent>
        <LayoutWrapperMain className={css.staticPageWrapper}>

          <div className={css.contentWrapper}>
            <div className={css.contentSide}>
              <SideBarNav intl={intl} location={location}/>
            </div>

            <div className={css.contentMain}>
              <h2 id={howSlug}>
                <FormattedMessage id="AboutPage.subtitle1"/>
              </h2>
              <p><FormattedMessage id="AboutPage.content3"/></p>
              <p><FormattedMessage id="AboutPage.content4"/></p>
              <p><FormattedMessage id="AboutPage.content5"/></p>
              <h2 id={whatSlug}>
                <FormattedMessage id="AboutPage.subtitle2"/>
              </h2>
              <p><FormattedMessage id="AboutPage.content7"/></p>
              <p><FormattedMessage id="AboutPage.content8"/></p>
              <p><FormattedMessage id="AboutPage.content9"/></p>
              <p><FormattedMessage id="AboutPage.content10"/></p>
              <p><FormattedMessage id="AboutPage.content11"/></p>
              <p><FormattedMessage id="AboutPage.content12"/></p>

              <TopbarImgWrapper img={nextImg} className={css.imgBlock} />

              <h2 id={valuesSlug}>
                <FormattedMessage id="AboutPage.subtitle3"/>
              </h2>
              <h3><FormattedMessage id="AboutPage.subSubTitle1"/></h3>
              <p><FormattedMessage id="AboutPage.content13"/></p>
              <p><FormattedMessage id="AboutPage.content14"/></p>
              <p><FormattedMessage id="AboutPage.content15"/>
                <a href="https://lnt.org/why/7-principles/">
                  <FormattedMessage id="AboutPage.content16"/>
                </a>
              </p>
              <h3><FormattedMessage id="AboutPage.subSubTitle2"/></h3>
              <p><FormattedMessage id="AboutPage.content17"/></p>
              <p><FormattedMessage id="AboutPage.content18"/></p>
              <p><FormattedMessage id="AboutPage.content19"/></p>

              <h2 id={communitySlug}>
                <FormattedMessage id="AboutPage.subtitle4"/>
              </h2>
              <p><FormattedMessage id="AboutPage.content20"/></p>
              <p><FormattedMessage id="AboutPage.content21"/></p>
              <p><FormattedMessage id="AboutPage.content22"/></p>

            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer currentPage="AboutPage" location={location}/>
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default injectIntl(AboutPage);
