import React from 'react';
import { OverlayView } from "react-google-maps";
import MapGroupLabel from '../MapGroupLabel/MapGroupLabel';

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height),
})

const MapGroupLabelWithOverlay = ({
  position,
  isActive,
  className,
  places,
  onPlaceClicked,
  mapComponentRefreshToken,
  labels,
}) => {
    return (
      <OverlayView
        key={Math.random()}
        position={position}
        getPixelPositionOffset={getPixelPositionOffset}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <MapGroupLabel
          isActive={isActive}
          className={className}
          labels={labels}
          onPlaceClicked={onPlaceClicked}
          mapComponentRefreshToken={mapComponentRefreshToken}
        />
      </OverlayView>
    );
}

export default MapGroupLabelWithOverlay;
