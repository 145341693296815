import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  // LISTING_PAGE_PARAM_TYPE_DRAFT,
  // LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import {formatMoney, minConvertPrice} from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import {
  sendEnquiry,
  loadData,
  setInitialValues,
  fetchTransactionLineItems,
} from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionAvatar from './SectionAvatar';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionHostMaybe from './SectionHostMaybe';
import SectionRulesMaybe from './SectionRulesMaybe';
import SectionSpotlight from './SectionSpotlight';
import SectionPackage from './SectionPackage';
import SectionTabs from './SectionTabs';
import SectionDetails from './SectionDetails';
import SectionReview from './SectionReview';
import ListingHashNav from './ListingHashNav';
import ListingHashNavTopbar from './ListingHashNavTopbar';

import css from './ListingPage.module.css';
import SectionSendEmail from '../../components/SectionSendEmail/SectionSendEmail';
import Modal from '../../components/Modal/Modal';
import SharedLink from './SharedLink';
import moment from "moment";

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID, Money } = sdkTypes;

const priceData = (price, intl) => {
  price.amount *= 100;

  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      openModal: false,
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      showCustomTopbar: false,
      showCustomTopbarButton: true,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.resizeListener = this.resizeListener.bind(this);
  }

  resizeListener(){
    if (!!window && window?.pageYOffset > 750) {
      this.setState({ showCustomTopbar: true });
    } else {
      this.setState({ showCustomTopbar: false });
    }

    if (!!window && window.innerWidth > 1024) {
      if (!!window && window?.pageYOffset < 1900 || window?.pageYOffset > 2500) {
        this.setState({ showCustomTopbarButton: true });
      } else {
        this.setState({ showCustomTopbarButton: false });
      }
    } else {
      if (!!window && window?.pageYOffset < 1700 || window?.pageYOffset > 2200) {
        this.setState({ showCustomTopbarButton: true });
      } else {
        this.setState({ showCustomTopbarButton: false });
     }
  }
}

  componentDidMount() {
    document.addEventListener('scroll', this.resizeListener)
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.resizeListener);
  }

  handleModalClose = () => this.setState({ openModal: false });
  showShared = () => this.setState({ openModal: true });

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingDates, ...bookingData } = values;

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart: bookingDates.startDate,
        bookingEnd: bookingDates.endDate,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {},
      ),
    );
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {}),
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      categoriesConfig,
      onUpdateProfile,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      images,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const listing = getListing(listingId);
    // const Reviews = listing && listing.attributes && listing.attributes.publicData && listing.attributes.publicData.Reviews ? listing.attributes.publicData.Reviews : [];
    const canonicalRootURL = config.canonicalRootURL;
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const {
      reviews: publicDataReviews,
      description: schemaDescription,
      categories: publicDataCategories,
      duration_days,
    } = currentListing.attributes.publicData;

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    // const listingType = isDraftVariant
    //   ? LISTING_PAGE_PARAM_TYPE_DRAFT
    //   : LISTING_PAGE_PARAM_TYPE_EDIT;
    // const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name='ListingPage' params={params} search={location.search} />;
    }

    const {
      description = '',
      title = '',
      publicData,
    } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id='ListingPage.bookingTitle' values={{ title: richTitle }} />
    );
    const bookingSubTitle = intl.formatMessage({ id: 'ListingPage.bookingSubTitle' });

    const topbar = <TopbarContainer currentPage="ListingPage" />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found
      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing
      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id='ListingPage.errorLoadingListingMessage' />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer currentPage="ListingPage" location={location}/>
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing
      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id='ListingPage.loadingListingMessage' />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer currentPage="ListingPage" location={location}/>
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    // const handleViewPhotosClick = e => {
    //   // Stop event from bubbling up to prevent image click handler
    //   // trying to open the carousel as well.
    //   e.stopPropagation();
    //   this.setState({
    //     imageCarouselOpen: true,
    //   });
    // };

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const isActualDay = currentDay => moment(new Date()).format('YYYY-MM-DD') <= currentDay;

    const getMinPrice = (packages) => {
      let minPrice;

      packages.forEach(({ rooms }) => {
        rooms.forEach(({ price }) => {
          if (!minPrice || minPrice.amount > price.amount) {
            minPrice = price;
          }
        });
      });

      return minPrice;
    }

   const packages = currentListing.attributes.publicData?.packages ?? [];
   const minArrPrices = packages.filter(({departure_date}) => isActualDay(departure_date));
   const currentPrice = minArrPrices.length ? getMinPrice(minArrPrices) : getMinPrice(packages);

    const convertedPrice = minConvertPrice(currentUser, currentPrice);
    const convertedPerDayPrice = convertedPrice.amount / duration_days;
    const convertedPriceMoney = new Money(convertedPrice.amount, convertedPrice.currency);
    const convertedPerDayPriceMoney = new Money(convertedPerDayPrice, convertedPrice.currency);
    let { formattedPrice, priceTitle } = priceData(convertedPriceMoney, intl);
    const formattedPerDayPrice = formatMoney(intl, convertedPerDayPriceMoney);
    const formattedPriceBook = formatMoney(intl, convertedPriceMoney);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (window !== undefined && isOwnListing || window !== undefined && isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');

    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));

    const schemaDateCreated = currentListing.attributes.createdAt;
    let listingRatings = publicDataReviews.map(r => r.ratings);
    const reviewCount = publicDataReviews.length;
    listingRatings = listingRatings.reduce((a, b) => a.concat(b), []);
    const ratings = reviewCount > 0 && listingRatings.map(a => {
      if (a.rating) return a.rating;
    }) || [];
    const bestRating = ratings.length !== 0 ? ratings.reduce((a, b) => (parseInt(a) + parseInt(b))) / ratings.length : 0;

    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle: config.siteTitle },
    );
    const canonicalPath = location.pathname;
    const schemaUrl = `${canonicalRootURL}${canonicalPath}`;
    const schemaKeywords = publicDataCategories.map(img => img.title).join(',');

    const schemaCommentsCount = reviews.length;

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name='ListingPage'
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const category =
      publicData && publicData.category ? (
        <span>
          {categoryLabel(categoriesConfig, publicData.category)}
          <span className={css.separator}>•</span>
        </span>
      ) : null;

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType='website'
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        partnerUrl={null}
        pageName={'ListingPage'}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'Product',
          description: schemaDescription,
          name: schemaTitle,
          image: schemaImages,
          url: schemaUrl,
          keywords: schemaKeywords,
          author: authorDisplayName,
          commentCount: schemaCommentsCount,
          dateCreated: schemaDateCreated,
          aggregateRating: {
            '@type': 'AggregateRating',
            bestRating: 5,
            ratingValue: bestRating,
            reviewCount,
            worstRating: 1,
          },
          brand: {
            '@type': 'Thing',
            name: title,
          },
          offers: {
            '@type': 'Offer',
            availability: 'InStock',
            // price: price.amount,
            // priceCurrency: price.currency,
            url: schemaUrl,
          },
          sku: '844',
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              <SectionImages images={images} />
              <div className={css.contentContainer}>
                <SectionAvatar user={currentAuthor} params={params} />
                <div className={css.mainContent}>
                  <SectionHeading
                    onUpdateProfile={onUpdateProfile}
                    isOwnListing={isOwnListing}
                    currentUser={currentUser}
                    isAuthenticated={isAuthenticated}
                    priceTitle={priceTitle}
                    formattedPrice={formattedPriceBook}
                    richTitle={richTitle}
                    category={category}
                    hostLink={hostLink}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                    listing={listing}
                    pricePerNight={formattedPerDayPrice}
                    unitType={unitType}
                    onSubmit={handleBookingSubmit}
                    titleBook={bookingTitle}
                    subTitle={bookingSubTitle}
                    authorDisplayName={authorDisplayName}
                    onManageDisableScrolling={onManageDisableScrolling}
                    timeSlots={timeSlots}
                    fetchTimeSlotsError={fetchTimeSlotsError}
                    differenceInDays={duration_days}
                    formattedPerDayPrice={formattedPerDayPrice}
                    days={duration_days}
                    intl={intl}
                    showShared={this.showShared}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                    showCustomTopbarButton={this.state.showCustomTopbarButton}
                    images={images}
                  />
                  {this.state.openModal &&
                    <Modal
                      className={css.modalCustom}
                      containerClassName={css.containerSharedModal}
                      sizeIcon='custom'
                      id={'sharedModal'}
                      closeButtonMessage={' '}
                      isOpen={this.state.openModal}
                      onManageDisableScrolling={onManageDisableScrolling}
                      onClose={this.handleModalClose}
                    >
                      <SharedLink />
                    </Modal>
                  }
                  {this.state.showCustomTopbar ? (
                    <div className={css.navListTopbar}>
                      <ListingHashNavTopbar
                        currentUser={currentUser}
                        onUpdateProfile={onUpdateProfile}
                        isAuthenticated={isAuthenticated}
                        intl={intl}
                        showShared={this.showShared}
                        listing={listing}
                        location={location}
                        formattedPrice={formattedPriceBook}
                        showCustomTopbarButton={this.state.showCustomTopbarButton}
                        images={images}
                      />
                    </div>
                  ) : (
                    <div className={css.navList}>
                      <ListingHashNav intl={intl} listing={listing} location={location} />
                    </div>
                  )}

                  <div id={'spotlight'} className={css.anchor}></div>
                  <SectionSpotlight listing={listing} />
                  <div id={'insights'} className={css.anchor}></div>
                  <div className={css.blockWrapper}>
                    <SectionTabs
                      intl={intl}
                      id={listingId.uuid}
                      slug={listingSlug}
                      location={location}
                      tab={rawParams.tab}
                      listing={listing}
                    />
                  </div>

                  <div id={'availability'} className={css.anchor}></div>
                  <div className={css.blockWrapper}>
                    <SectionPackage
                      id={listingId.uuid}
                      slug={listingSlug}
                      location={location}
                      tab={rawParams.tab}
                      listing={listing}
                      intl={intl}
                      currentUser={currentUser}
                      formattedPrice={formattedPriceBook}
                      formattedPerDayPrice={formattedPerDayPrice}
                      images={images}
                    />
                  </div>
                  <div id={'reviews'} className={css.anchor}></div>
                  {publicDataReviews.length > 0 &&
                    <div className={css.blockWrapper}>
                      <SectionReview listing={listing} intl={intl} />
                    </div>}

                  <div id={'details'} className={css.anchor}></div>
                  <div className={css.blockWrapper}>
                    <SectionDetails
                      listing={listing}
                      intl={intl}
                    />
                  </div>
                  <SectionDescriptionMaybe description={description} />
                  <SectionRulesMaybe publicData={publicData} />
                  <SectionHostMaybe
                    title={title}
                    listing={currentListing}
                    authorDisplayName={authorDisplayName}
                    onContactUser={this.onContactUser}
                    isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                    onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                    sendEnquiryError={sendEnquiryError}
                    sendEnquiryInProgress={sendEnquiryInProgress}
                    onSubmitEnquiry={this.onSubmitEnquiry}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                </div>
                <BookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  currentUser={currentUser}
                  onSubmit={handleBookingSubmit}
                  title={bookingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  differenceInDays={duration_days}
                  formattedPerDayPrice={formattedPerDayPrice}
                  formattedPrice={formattedPriceBook}
                  days={duration_days}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  showCustomTopbar={this.state.showCustomTopbar}
                  showCustomTopbarButton={this.state.showCustomTopbarButton}
                />
              </div>
              <SectionSendEmail
                onManageDisableScrolling={onManageDisableScrolling}
                intl={intl}
              />
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer currentPage="ListingPage" location={location}/>
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
  categoriesConfig: config.custom.categories,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,
  onUpdateProfile: func.isRequired,
  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  categoriesConfig: array,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    images,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    images,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectIntl,
)(ListingPageComponent);

ListingPage.setInitialValues = initialValues => setInitialValues(initialValues);
ListingPage.loadData = loadData;

export default ListingPage;
