import React, { Component } from 'react';
import { number, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, LocationAutocompleteInput } from '../../components';
import { Field, Form as FinalForm } from 'react-final-form';
import {createLocalizeResourceLocatorString, createResourceLocatorString} from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

// Styles
import css from './LocationFilterPlain.module.css';

class LocationFilterPlain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      hasValue: false
    };

    this.filter = null;
    this.filterContent = null;
  }

  handleChange = location => {
    if (location && location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      // this.handleSubmit({ location });

      this.handleSubmit({ location });


      // blur search input to hide software keyboard
      if (this.searchInput) {
        this.searchInput.blur();
      }
    }
  };

  handleClear = () => {
    const { urlParam } = this.props;
    this.setState({ isOpen: true });
    this.handleSubmit(urlParam, null);
  };

  handleSubmit = values => {
    const { currentSearchParams, history } = this.props;

    if (values && values.location) {
      const { location } = values;
      const { search, selectedPlace } = location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = config.sortSearchByDistance ? { origin } : {};
      const searchParams = {
        ...currentSearchParams,
        ...originMaybe,
        address: search,
        bounds,
      };

      this.setState(
        { hasValue: true, isOpen: true },
        () => history.push(createLocalizeResourceLocatorString(searchParams))
      );
    } else {
      this.setState(
        { hasValue: false, isOpen: true },
        () => history.push(createLocalizeResourceLocatorString())
      );
    }

  };

  identity = v => v;

  render() {
    const {
      rootClassName,
      className,
      intl,
    } = this.props;

    const { isOpen, hasValue } = this.state;
    const classes = classNames(rootClassName || css.root, className);
    const label = intl.formatMessage({ id: 'LocationFilter.label' });
    // const labelStyles = hasValue ? css.labelSelected : css.label;

    const clear = intl.formatMessage({ id: 'FilterForm.clear' });
    // const cancel = intl.formatMessage({ id: 'FilterForm.cancel' });
    // const submit = intl.formatMessage({ id: 'FilterForm.submit' });

    return (
      <div
        className={classes}
        ref={node => {
          this.filter = node;
        }}
      >

        <div className={css.buttonsMobileWrapper}>
          <span className={css.filterLabel}>{label}</span>
          <button className={css.clearButton} type="button" onClick={this.handleClear}>
            {clear}
          </button>
        </div>

        <FinalForm
          {...this.props}
          onSubmit={this.handleSubmit}
          render={formRenderProps => {
            const {
              intl,
              handleSubmit
            } = formRenderProps;

            return (
              <Form
                onSubmit={handleSubmit}
                className={isOpen ? classNames(css.formWrapper, css.formIsOpen) : classNames(css.formWrapper, css.formIsClosed)}
              >

                <Field
                  name="location"
                  format={this.identity}
                  render={({ input, meta }) => {
                    const { onChange, ...restInput } = input;

                    // Merge the standard onChange function with custom behaviur. A better solution would
                    // be to use the FormSpy component from Final Form and pass this.onChange to the
                    // onChange prop but that breaks due to insufficient subscription handling.S
                    // See: https://github.com/final-form/react-final-form/issues/159
                    const searchOnChange = value => {
                      onChange(value);
                      this.handleChange(value);
                    };

                    // const searchSubmit = value => {
                    //   this.handleChange(value);
                    // };

                    const searchInput = { ...restInput, onChange: searchOnChange };
                    return (
                      <LocationAutocompleteInput
                        placeholder={intl.formatMessage({ id: 'LocationSearchForm.placeholder' })}
                        iconClassName={css.searchInputIcon}
                        inputClassName={css.searchInput}
                        predictionsClassName={css.searchPredictions}
                        input={searchInput}
                        meta={meta}
                      />
                    );
                  }}
                />
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

LocationFilterPlain.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
  liveEdit: false,
  currencyConfig: config.currencyConfig,
};

LocationFilterPlain.propTypes = {
  rootClassName: string,
  className: string,
  contentPlacementOffset: number,
  currencyConfig: propTypes.currencyConfig,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(LocationFilterPlain);
