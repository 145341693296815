import {translateOptions} from "../marketplace-custom-config";

export const languagesHelper = {
  set,
  get,
  existLS
};

export const seTranslates = {
  de: {
    homeDatepickerPlaceholder: 'Daten',
    locationFilterPlaceholder: 'Gebe den Standort bitte hier ein...',

  },
  en: {
    homeDatepickerPlaceholder: 'Dates',
    locationFilterPlaceholder: 'Type location here...',
  },
  fr: {
    homeDatepickerPlaceholder: 'Dates',
    locationFilterPlaceholder: 'Type d\'emplacement ici ...',
  },
  es: {
    homeDatepickerPlaceholder: 'Dates',
    locationFilterPlaceholder: 'Escriba la ubicación aquí...',
  }
};

export const LANGUAGE_KEY = 'lang';
export const DEFAULT_LOCALE = 'en';

function existLS() {
  return typeof localStorage !== 'undefined';
}

function set(locale) {
  existLS() && localStorage.setItem(LANGUAGE_KEY, locale);
  reload();
}

// TODO:
function get() {
  let result = DEFAULT_LOCALE;
  if (existLS() && localStorage.getItem(LANGUAGE_KEY)) {
    result = localStorage.getItem(LANGUAGE_KEY);
  }
  // if (existLS() && window.navigator.languages && window.navigator.languages.length) {
  //   let currentLanguage = translateOptions.map(el => el.key)
  //   for(let i = 0; i < currentLanguage.length; i ++) {
  //     if(window.navigator.languages.includes(currentLanguage[i])) {
  //       result = currentLanguage[i]
  //     }
  //   }
  // }
  return result;
}

function reload() {
  document.location.reload(true);
};
