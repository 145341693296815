import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {func, string} from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl, intlShape} from '../../util/reactIntl';
import {updateProfile} from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import {TopbarContainer} from '../../containers';
import {createSearchResultSchema} from './FavoritesPage.helpers';
import {
  Page,
  SectionFavorite,
  SectionRecentSearches,
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  SectionList,
  NamedLink,
  TopbarImgWrapper,
} from '../../components';
import config from '../../config';

import css from './FavoritesPage.module.css';
import {searchListings} from '../SearchPage/SearchPage.duck';
import {getListingsById} from '../../ducks/marketplaceData.duck';
import {isScrollingDisabled} from '../../ducks/UI.duck';
import {ensureCurrentUser} from '../../util/data';
import TagManager from 'react-gtm-module';
import {LANGUAGE_KEY} from '../../util/languages';
import contentImg from './images/contentPageImg.jpg';
import {loadData} from "./FavoritesPage.duck";

export class FavoritesPageComponent extends Component {
  componentDidUpdate(prevProps) {
    const { currentUser } = this.props;
    // Typical usage (don't forget to compare props):
    const user = ensureCurrentUser(currentUser);
    const listingFavourite = user.attributes?.profile?.publicData?.listingFavourite ?? [];

    if (listingFavourite?.length !== prevProps.currentUser?.attributes?.profile?.publicData?.listingFavourite?.length) {
      const currentUserLoaded = !!user.id;
      const favoritesList = currentUserLoaded ? user.attributes?.profile?.publicData?.listingFavourite ?? [] : [];

      if (typeof window !== 'undefined' && favoritesList) {
        const currentlyUrl = typeof window !== 'undefined' && window.location.pathname;
        const query = window.location.query;
        const dataLayerExistItems = window.dataLayer?.ecommerce?.items ?? [];

        const dataLayerItemList = favoritesList.map((listing, index) => {
          const {
            categories,
            themes,
            listingId,
            guided,
            skill_levels,
            title,
            duration_days,
            partner_id,
            price,
            departure_date,
          } = listing;

          if (dataLayerExistItems.find(({ item_id }) => listingId === item_id)) {
            return null;
          }

          return ({
            item_name: title,
            item_id: listingId,
            price: price / 100,
            currency: config.currency,
            discount: 0.00,
            item_brand: partner_id,
            item_category: categories.map(({title}) => title).join("|"), // pipe alphabetically to separate
            item_category2: themes.join("|"), // pipe alphabetically to separate
            item_category3: skill_levels.map(({title}) => title).join("|"), // pipe to separate the skill
            item_category4: duration_days,
            item_category5: `${departure_date.slice(0,4)}|${departure_date.slice(5,7)}`, // as "Year | Month"
            item_variant: guided,
            location_id: '[XXX]', // use google places id (added in Backend)
            index: index+1,
            quantity: 1,
          });
        }).filter(Boolean);


        const tagManagerArgs = {
          dataLayer: {
            event: 'view_item_list',
            ecommerce: {
              item_list_name: 'Favorites Page', // page on which the list was displayed on: "Landing Page", "Favorites Page", "Search Results"
              item_list_id: currentlyUrl+query, // Search string & parameters from URL
              items: dataLayerItemList,
            },
          },
          events: {
            pageView: 'pageview',
          },
          page: {
            path: window.location.pathname,
            title: 'Favorites Page',
            type: 'catalog',
          },
          dataLayerName: 'CartListDataLayer',
        };

        TagManager.dataLayer(tagManagerArgs);
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({ecommerce: null}); // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: 'view_item_list',
          ecommerce: {
            item_list_name: 'Favorites Page', // page on which the list was displayed on: "Landing Page", "Favorites Page", "Search Results"
            item_list_id: currentlyUrl+query, // Search string & parameters from URL
            items: dataLayerItemList,
          },
          page: {
            path: window.location.pathname,
            locale: localStorage.getItem(LANGUAGE_KEY), // language code of displayed page
            title: 'Favorites Page',
            type: 'catalog',
          },
        });
      }
    }
  }


  render() {
    const {
      currentUser,
      onUpdateProfile,
      intl,
      scrollingDisabled,
      isAuthenticated,
      location,
      // recentListings,
      relatedListings,
      updateLists,
    } = this.props;

    const user = ensureCurrentUser(currentUser);
    const recentSearches = currentUser ? currentUser.attributes.profile.publicData?.recentSearches ?? [] : [];
    const currentUserLoaded = !!user.id;
    const favoritesList = currentUserLoaded ? currentUser.attributes.profile?.publicData?.listingFavourite?.reverse() ?? [] : [];
    const { description, schema } = createSearchResultSchema(favoritesList, '', intl);

    const topTitleFavorite = <FormattedMessage id='FavoritesPage.heading'/>;
    const subTitleFavorite = <FormattedMessage id='SectionFavorite.title'/>;
    const subTitleRecent = <FormattedMessage id='SectionRecentSearches.title'/>;
    // const subTitleRecommendations = <FormattedMessage
    //   id='SectionRecentSearches.titleRecommendations'/>;
    const subTitleRelated = <FormattedMessage id='SectionRecentSearches.titleRelated'/>;

    const titleImgBlock = <FormattedMessage id='SectionRecentSearches.titleImgBlock'/>;
    const subTitleImgBlock = <FormattedMessage id='SectionRecentSearches.subTitleImgBlock'/>;

    const title = intl.formatMessage({id: 'FavoritesPage.title'});

    return (
      <Page
        description={description}
        title={title}
        schema={schema}
        scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer
              className={''}
              mobileRootClassName={''}
              desktopClassName={''}
              currentPage='FavoritesPage'
            />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.staticPageWrapper}>
            <div className={css.sections}>
              <section className={css.section}>
                <h1 className={css.topTitle}>{topTitleFavorite}</h1>
              </section>
              {favoritesList && favoritesList.length
                ? <section className={css.section}>
                    <div className={css.sectionContent}>
                      <SectionFavorite
                        updateLists={updateLists}
                        currentUser={currentUser}
                        currentList={favoritesList}
                        onUpdateProfile={onUpdateProfile}
                        isAuthenticated={isAuthenticated}
                        subTitle={subTitleFavorite}
                      />
                    </div>
                  </section>
                : null
              }
              {recentSearches && recentSearches.length
                ? <section className={css.section}>
                  <div className={css.sectionContent}>
                    <SectionRecentSearches
                      currentUser={currentUser}
                      subtitle={subTitleRecent}
                      onUpdateProfile={onUpdateProfile}
                      isAuthenticated={isAuthenticated}
                    />
                  </div>
                </section>
                : null}
              {!currentUserLoaded
                ? <div className={css.anotherSection}>
                  <FormattedMessage id={'FavoritesPage.loginMessageStart'}/>
                  <NamedLink name='LoginPage'>
                    <FormattedMessage id={'FavoritesPage.login'}/>
                  </NamedLink>
                  <FormattedMessage id={'FavoritesPage.loginMessageEnd'}/>
                </div>
                : null}
              <TopbarImgWrapper
                className={css.imgContentStyle}
                img={contentImg}
              >
                <section className={css.imgText}>
                  <h2>{titleImgBlock}</h2>
                  <h4>{subTitleImgBlock}</h4>
                </section>
              </TopbarImgWrapper>
              {favoritesList && favoritesList.length && relatedListings && relatedListings.length
                ? <section className={css.section}>
                  <div className={css.sectionContent}>
                    <SectionList
                      favoritesList={favoritesList}
                      currentUser={currentUser}
                      currentList={relatedListings}
                      onUpdateProfile={onUpdateProfile}
                      isAuthenticated={isAuthenticated}
                      subTitle={subTitleRelated}
                      classNameItem={css.classesItem}
                      classNamelocations={css.locationRelated}
                    />
                  </div>
                </section> : null}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer currentPage='FavoritesPage' location={location}/>
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }

};

FavoritesPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  lang: string.isRequired,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

FavoritesPageComponent.propTypes = {
  onSearchListings: func,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {isAuthenticated} = state.Auth;
  const {currentUser} = state.user;
  const {
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
  } = state.SearchPage;
  const {
    recentData,
    relatedData,
  } = state.FavoritesPage;

  const recentListings = getListingsById(state, recentData);
  const relatedListings = getListingsById(state, relatedData);

  return {
    recentListings,
    relatedListings,
    isAuthenticated,
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    state,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
  onSearchListings: (params, search) => dispatch(searchListings(params, search)),
  updateLists: () => dispatch(loadData()),
});

const FavoritesPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectIntl,
)(FavoritesPageComponent);

FavoritesPage.loadData = (params, search) => {
  return loadData(params, search)
};


export default FavoritesPage;


              {/*          <SectionSimilar*/}
              {/*            isAuthenticated={isAuthenticated}*/}
              {/*            currentUser={currentUser}*/}
              {/*            onUpdateProfile={onUpdateProfile}*/}
              {/*          />*/}

              {/*      <SectionRecentSearches*/}
              {/*        currentUser={currentUser}*/}
              {/*        listings={listings}*/}
              {/*        subtitle={subTitleRecent}*/}
              {/*        onUpdateProfile={onUpdateProfile}*/}
              {/*        isAuthenticated={isAuthenticated}*/}
              {/*      />*/}
