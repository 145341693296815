export const multiLanguage = {
  de: [
    {LandingPage: "/"},
    {AboutPage: "/ueber"},
    {TravelerHelpPage: "/hilfe/buchen-und-reisen"},
    {HostHelpPage: "/hilfe/hosten"},
    {TrustAndSafetyPage: "/hilfe/vertrauen-und-sicherheit"},
    {FavoritesPage: "/favoriten"},
    {FaqPage: "/hilfe/faq"},
    {HelpPage: "/hilfe"},
    {SearchPage: "/s"},
    {SearchFiltersPage: "/s/filter"},
    {SearchListingsPage: "/s/inserate"},
    {SearchMapPage: "/s/karte"},
    {ListingBasePage: "/l"},
    {ListingPage: "/l/:slug/:id"},
    // {CheckoutPage: "/l/:slug/:id/kasse"},
    {ListingPageVariant: "/l/:slug/:id/:variant"},
    {ListingPageTab: "/l/:slug/:id/tab/:tab"},
    {NewListingPage: "/l/neu"},
    {EditListingPage: "/l/:slug/:id/:type/:tab"},
    {EditListingStripeOnboardingPage: "/l/:slug/:id/:type/:tab/:returnURLType"},
    {ListingPageCanonical: "/l/:id"},
    {ProfileBasePage: "/d"},
    {ProfilePage: "/d/:id"},
    {ProfileSettingsPage: "/konto/profil"},
    {LoginPage: "/login"},
    {SignupPage: "/anmeldung"},
    {ConfirmPage: "/bestatigen"},
    {PasswordRecoveryPage: "/konto/passwort"},
    {InboxBasePage: "/konto/inbox"},
    {InboxPage: "/inbox/:tab"},
    {OrderPage: "/bestellen/:id"},
    {OrderDetailsPage: "/bestellen/:id/einzelheiten"},
    {SalePage: "/verkauf/:id"},
    {SaleDetailsPage: "/verkauf/:id/einzelheiten"},
    {ManageListingsPage: "/konto/inserate"},
    {AccountSettingsPage: "/konto"},
    {ContactDetailsPage: "/konto/kontaktdaten"},
    {PasswordChangePage: "/konto/rückgeld-passwort"},
    {CommunicationsPage: "/konto/kommunikation"},
    {StripePayoutPage: "/konto/zahlungen"},
    {StripePayoutOnboardingPage: "/konto/zahlungen/:returnURLType"},
    {PaymentMethodsPage: "/konto/zahlung-methoden"},
    {ContactPage: "/kontakt"},
    {ImprintPage: "/impressum"},
    {TermsOfServicePage: "/allgemeine-geschaeftsbedingungen"},
    {PrivacyPolicyPage: "/datenschutzbestimmungen"},
    {CookiePolicyPage: "/cookie-richtlinie"},
    {NotFoundPage: "/nichtgefunden"},
    {PasswordResetPage: "/zurücksetzen-passwort"},
    {EmailVerificationPage: "/verifizieren-email"}
  ],
  en: [
    {LandingPage: "/"},
    {AboutPage: "/about"},
    {TravelerHelpPage: "/help/booking-and-traveling"},
    {HostHelpPage: "/help/hosting"},
    {TrustAndSafetyPage: "/help/trust-and-safety"},
    {FavoritesPage: "/favorites"},
    {FaqPage: "/help/faq"},
    {HelpPage: "/help"},
    {SearchPage: "/s"},
    {SearchFiltersPage: "/s/filters"},
    {SearchListingsPage: "/s/listings"},
    {SearchMapPage: "/s/map"},
    {ListingBasePage: "/l"},
    {ListingPage: "/l/:slug/:id"},
    // {CheckoutPage: "/l/:slug/:id/checkout"},
    {ListingPageVariant: "/l/:slug/:id/:variant"},
    {ListingPageTab: "/l/:slug/:id/tab/:tab"},
    {NewListingPage: "/l/new"},
    {EditListingPage: "/l/:slug/:id/:type/:tab"},
    {EditListingStripeOnboardingPage: "/l/:slug/:id/:type/:tab/:returnURLType"},
    {ListingPageCanonical: "/l/:id"},
    {ProfileBasePage: "/d"},
    {ProfilePage: "/d/:id"},
    {ProfileSettingsPage: "/account/profile"},
    {LoginPage: "/login"},
    {SignupPage: "/signup"},
    {ConfirmPage: "/confirm"},
    {PasswordRecoveryPage: "/account/password"},
    {InboxBasePage: "/account/inbox"},
    {InboxPage: "/inbox/:tab"},
    {OrderPage: "/order/:id"},
    {OrderDetailsPage: "/order/:id/details"},
    {SalePage: "/sale/:id"},
    {SaleDetailsPage: "/sale/:id/details"},
    {ManageListingsPage: "/account/listings"},
    {AccountSettingsPage: "/account"},
    {ContactDetailsPage: "/account/contact-details"},
    {PasswordChangePage: "/account/change-password"},
    {CommunicationsPage: "/account/communications"},
    {StripePayoutPage: "/account/payments"},
    {StripePayoutOnboardingPage: "/account/payments/:returnURLType"},
    {PaymentMethodsPage: "/account/payment-methods"},
    {ContactPage: "/contact"},
    {ImprintPage: "/imprint"},
    {TermsOfServicePage: "/terms-conditions"},
    {PrivacyPolicyPage: "/privacy-policy"},
    {CookiePolicyPage: "/cookie-policy"},
    {NotFoundPage: "/notfound"},
    {PasswordResetPage: "/reset-password"},
    {EmailVerificationPage: "/verify-email"}
  ],
  es: [
    {LandingPage: "/"},
    {AboutPage: "/sobre"},
    {TravelerHelpPage: "/ayuda/reservas-y-viajes"},
    {HostHelpPage: "/ayuda/alojamiento"},
    {TrustAndSafetyPage: "/ayuda/confianza-y-seguridad"},
    {FavoritesPage: "/favoritos"},
    {FaqPage: "/ayuda/faq"},
    {HelpPage: "/ayuda"},
    {SearchPage: "/s"},
    {SearchFiltersPage: "/s/filtros"},
    {SearchListingsPage: "/s/listados"},
    {SearchMapPage: "/s/mapa"},
    {ListingBasePage: "/l"},
    {ListingPage: "/l/:slug/:id"},
    // {CheckoutPage: "/l/:slug/:id/verificar"},
    {ListingPageVariant: "/l/:slug/:id/:variant"},
    {ListingPageTab: "/l/:slug/:id/pestaña/:tab"},
    {NewListingPage: "/l/nuevo"},
    {EditListingPage: "/l/:slug/:id/:type/:tab"},
    {EditListingStripeOnboardingPage: "/l/:slug/:id/:type/:tab/:returnURLType"},
    {ListingPageCanonical: "/l/:id"},
    {ProfileBasePage: "/d"},
    {ProfilePage: "/d/:id"},
    {ProfileSettingsPage: "/cuenta/perfil"},
    {LoginPage: "/inicio-de-sesion"},
    {SignupPage: "/registrarse"},
    {ConfirmPage: "/confirmar"},
    {PasswordRecoveryPage: "/cuenta/contrasena"},
    {InboxBasePage: "/cuenta/bandeja-de-entrada"},
    {InboxPage: "/bandeja-de-entrada/:tab"},
    {OrderPage: "/ordenar/:id"},
    {OrderDetailsPage: "/ordenar/:id/detalles"},
    {SalePage: "/rebaja/:id"},
    {SaleDetailsPage: "/rebaja/:id/detalles"},
    {ManageListingsPage: "/cuenta/listados"},
    {AccountSettingsPage: "/cuenta"},
    {ContactDetailsPage: "/cuenta/detalles-de-contacto"},
    {PasswordChangePage: "/cuenta/cambio-contrasena"},
    {CommunicationsPage: "/cuenta/comunicaciones"},
    {StripePayoutPage: "/cuenta/pagos"},
    {StripePayoutOnboardingPage: "/cuenta/pagos/:returnURLType"},
    {PaymentMethodsPage: "/cuenta/pago-métodos"},
    {ContactPage: "/contactar-con"},
    {ImprintPage: "/imprimir"},
    {TermsOfServicePage: "/condiciones-generales"},
    {PrivacyPolicyPage: "/politica-de-privacidad"},
    {CookiePolicyPage: "/politica-de-cookies"},
    {NotFoundPage: "/extraviado"},
    {PasswordResetPage: "/reiniciar-contrasena"},
    {EmailVerificationPage: "/verificar-email"}
  ],
  fr: [
    {LandingPage: "/"},
    {AboutPage: "/a-propos-de"},
    {TravelerHelpPage: "/aide/reservation-et-voyage"},
    {HostHelpPage: "/aide/hebergement"},
    {TrustAndSafetyPage: "/aide/confiance-et-securite"},
    {FavoritesPage: "/favoris"},
    {FaqPage: "/aide/faq"},
    {HelpPage: "/aide"},
    {SearchPage: "/s"},
    {SearchFiltersPage: "/s/filtres"},
    {SearchListingsPage: "/s/listes"},
    {SearchMapPage: "/s/carte"},
    {ListingBasePage: "/l"},
    {ListingPage: "/l/:slug/:id"},
    // {CheckoutPage: "/l/:slug/:id/verifier"},
    {ListingPageVariant: "/l/:slug/:id/:variant"},
    {ListingPageTab: "/l/:slug/:id/languette/:tab"},
    {NewListingPage: "/l/nouveau"},
    {EditListingPage: "/l/:slug/:id/:type/:tab"},
    {EditListingStripeOnboardingPage: "/l/:slug/:id/:type/:tab/:returnURLType"},
    {ListingPageCanonical: "/l/:id"},
    {ProfileBasePage: "/re"},
    {ProfilePage: "/re/:id"},
    {ProfileSettingsPage: "/compte/profil"},
    {LoginPage: "/connexion"},
    {SignupPage: "/s-inscrire"},
    {ConfirmPage: "/confirmer"},
    {PasswordRecoveryPage: "/compte/mot-de-passe"},
    {InboxBasePage: "/compte/boite-de-reception"},
    {InboxPage: "/boite-de-reception/:tab"},
    {OrderPage: "/ordre/:id"},
    {OrderDetailsPage: "/ordre/:id/details"},
    {SalePage: "/vendre/:id"},
    {SaleDetailsPage: "/vendre/:id/details"},
    {ManageListingsPage: "/compte/listes"},
    {AccountSettingsPage: "/compte"},
    {ContactDetailsPage: "/compte/details-des-contacts"},
    {PasswordChangePage: "/compte/monnaie-mot-de-passe"},
    {CommunicationsPage: "/compte/communications"},
    {StripePayoutPage: "/compte/paiements"},
    {StripePayoutOnboardingPage: "/compte/paiements/:returnURLType"},
    {PaymentMethodsPage: "/compte/paiement-methods"},
    {ContactPage: "/contact"},
    {ImprintPage: "/empreinte"},
    {TermsOfServicePage: "/termes-conditions"},
    {PrivacyPolicyPage: "/politique-de-confidentialite"},
    {CookiePolicyPage: "/politique-en-matiere-de-cookies"},
    {NotFoundPage: "/pastrouve"},
    {PasswordResetPage: "/reinitialiser-mot-de-passe"},
    {EmailVerificationPage: "/verifier-email"}
  ]
}
