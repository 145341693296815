import React, { Component } from 'react';
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/TabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
// import SwipeableInkTabBar from 'rc-tabs/lib/SwipeableInkTabBar';
import css from './ListingPage.module.css';
import moment from 'moment';
import classNames from 'classnames';
// import AliceCarousel from 'react-alice-carousel';
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from 'react-accessible-accordion';
// import { CheckMark } from '../../components';
import { minConvertPrice, formatMoney } from '../../util/currency';
import { FormattedMessage } from '../../util/reactIntl';
import { types as sdkTypes } from "../../util/sdkLoader";

import './ListingPage.module.css'
// import HubspotForm from 'react-hubspot-form';
import Modal from '../../components/Modal/Modal';
import InfoBookingModal from './InfoBookingModal';
// import TagManager from 'react-gtm-module';

const { Money } = sdkTypes;

// const isMinPrice = value => {
//   if(!value) return ''
//   let currentPrice = {amount: 0, currency: 'EUR'}
//   value && value.rooms && value.rooms.map(el => {
//     if(el.available && el.price?.amount > currentPrice?.amount ||
//       el.available && !currentPrice && el.price?.amount) {
//       currentPrice = el.price
//     }
//   })
//   return currentPrice
// }

const typeRoom = ({ rooms }) => {
  let typeRoom = '';
  let isValidType = false;
  rooms && rooms.forEach(el => {
    if (el.room_type && el.available && rooms.length > 1) {
      typeRoom = el.room_type
    }
    if (el.room_type && !el.available && rooms.length > 1) {
      isValidType = true
    }
    if (el.room_type && el.available && rooms.length===1) {
      typeRoom = el.room_type
      isValidType = true
    }
  })

  return isValidType ? typeRoom : 'Multiple room type'
}

const getMinPrice = (rooms) => {
  let minPrice;

    rooms.forEach(({ price, available }) => {
      if (!minPrice || minPrice.amount > price.amount && available) {
        minPrice = price;
      }
    });

  return minPrice;
}

class SectionPackage extends Component {
  state = {
    time: new Date(),
    currentIndex: 0,
    newIndex: [],
    openModal: false,
    openListing: null,
  };


  isActualDay = currentDay => moment(new Date()).format('YYYY-MM-DD') <= currentDay;

  handleModalOpen = (currentDay, value) => {
    if(this.isActualDay(currentDay)) {
      this.setState({ openModal: true  })
      this.setState({ openListing: value  })
    }
  }
  handleModalClose = () => {
    this.setState({ openModal: false  })
    this.setState({ openListing: null  })
  }

  // slideTo = (e, i, currentEqItemIndex) => {
  //   let newIndex = this.state.newIndex;
  //   newIndex[currentEqItemIndex] = i;
  //   this.setState({ currentIndex: i, newIndex }, () => console.log(this.state.newIndex))
  // };

  // onSlideChanged = (e, currentEqItemIndex) => {
  //   let newIndex = this.state.newIndex;
  //   newIndex[currentEqItemIndex] = e.item;

  //   this.setState({ currentIndex: e.item, newIndex  })
  // };

  // slideNext = (currentEqItemIndex) => {
  //   let newIndex = this.state.newIndex;
  //   newIndex[currentEqItemIndex] = this.state.currentIndex + 1;
  //   this.setState({ currentIndex: this.state.currentIndex + 1, newIndex })
  // };

  // slidePrev = (currentEqItemIndex) => {
  //   let newIndex = this.state.newIndex;
  //   newIndex[currentEqItemIndex] = this.state.currentIndex - 1;
  //   this.setState({ currentIndex: this.state.currentIndex - 1, newIndex })
  // };

  // renderThumbs = (items, currentEqItemIndex) =>
  //   <ul className={css.bottomGallery}>{ items.map((item, i) =>
  //     <li
  //       className={css.bottomGalleryItem}
  //       style={{ backgroundImage: `url(${item.url})` }}
  //       key={i}
  //       onClick={(e) => this.slideTo(e, i, currentEqItemIndex)}
  //     />
  //   )}
  //   </ul>;

  // renderGallery(items, currentEqItemIndex) {
  //   const { newIndex } = this.state;

  //   return (<AliceCarousel
  //     dotsDisabled={true}
  //     buttonsDisabled={true}
  //     slideToIndex={newIndex[currentEqItemIndex]}
  //     onSlideChanged={(e) => this.onSlideChanged(e, currentEqItemIndex)}
  //     mouseDragEnabled={true}
  //   >
  //     {items.map((item, i) => <div key={item} style={{ backgroundImage: `url(${item.url})` }}
  //                                  className={css.carouselItem}/>)}
  //   </AliceCarousel>);
  // }

  // renderLodgingTabs = () => {
  //   const { listing, intl } = this.props;
  //   const partnerListingUrl = listing.attributes && listing.attributes.publicData && listing.attributes.publicData.partner_listing_url;
  //   const Availabilities = listing.attributes.publicData && listing.attributes.publicData.Availabilities ? listing.attributes.publicData.Availabilities : [];
  //   const callback = key => {};

  //   const availableMonths = [];
  //   Availabilities.length > 0 && Availabilities
  //     .sort((a, b) => moment(a.date_from) - moment(b.date_from))
  //     .map(item => {
  //       const monthName = moment(item.date_from).format('MMMM YYYY');
  //       if (availableMonths.indexOf(monthName) === -1 && this.isActualDay(item.date_from)) {
  //         availableMonths.push(monthName);
  //       }
  //     });

  //   return (
  //     <Tabs className={css.blockMonth}
  //           // defaultActiveKey={availableMonths[availableMonths?.length-1]}
  //           onChange={callback}
  //           renderTabBar={() => <ScrollableInkTabBar/>}
  //           renderTabContent={() => <TabContent/>}
  //     >
  //       {availableMonths.map( month => {
  //         return (
  //           <TabPane tab={month} key={month}>
  //             { Availabilities.map((item, index) => {
  //               const monthName = moment(item.date_from).format('MMMM YYYY');
  //               // const durationDays = listing.attributes.publicData && listing.attributes.publicData.duration_days;
  //               const price = new Money(item.prices[ 0 ].price_value, item.prices[ 0 ].price_currency)
  //               const convertedPrice = convertPrice(this.props.currentUser, price);
  //               const convertedPriceMoney = new Money(convertedPrice.amount, convertedPrice.currency);
  //               // const convertedPerDayPrice = convertedPrice.amount / durationDays;
  //               // const convertedPerDayPriceMoney = new Money(convertedPerDayPrice, convertedPrice.currency);
  //               const formattedPrice = formatMoney(intl, convertedPriceMoney);


  //               if (monthName === month) {

  //                 return <div key={index}>
  //                     <div className={css.itemInfo}>
  //                       <div className={css.itemDuration}>
  //                         <span className={css.countDays}>
  //                           <FormattedMessage
  //                             id="BookingBreakdown.dayCount"
  //                             values={{ count: listing.attributes.publicData?.days[0] }}
  //                           />
  //                         </span>
  //                       </div>
  //                       <div className={css.blockBookingPrice}>
  //                         <div className={css.itemDates}>
  //                           <span className={css.subText}>
  //                             <FormattedMessage id="BookingPanel.priceFrom" />
  //                           </span>
  //                           <span>{moment(item.date_from).format('DD MMM YYYY')}</span>
  //                           <span className={css.subText}>
  //                             <FormattedMessage id="BookingPanel.priceTo" />
  //                           </span>
  //                           <span>{moment(item.date_to).format('DD MMM YYYY')}</span>
  //                         </div>
  //                         <div className={css.itemDates}>
  //                           <span>{item.room_type}</span>
  //                           <span>
  //                             <FormattedMessage id="SectionTabs.occupancyCount" />
  //                           </span>
  //                         </div>
  //                         <div className={css.available}>
  //                           {/*<span className={item.available ? css.isAvailable : css.unAvailable}/>*/}
  //                           <span className={classNames( css.textAvailable, {
  //                             [css.isAvailableTen]: !item.available
  //                           })}>10+</span>
  //                           <span className={css.smallText}>{item.available
  //                             ? <FormattedMessage id="BookingPanel.available"/>
  //                             : <FormattedMessage id="BookingPanel.notAvailable"/>
  //                           }</span>
  //                         </div>

  //                         <div className={css.price}>
  //                           <span className={css.normalText}>{formattedPrice}</span>
  //                           <span className={css.smallSubText}><FormattedMessage id="BookingPanel.perPerson"/></span>
  //                         </div>

  //                         <div className={css.isGuarantied}>
  //                           <span className={item.departure_status || item.guaranteed ? css.guaranteed : css.notGuaranteed}/>
  //                           <span>{item.departure_status || item.guaranteed
  //                             ? <FormattedMessage id="BookingPanel.guaranteedBooking"/>
  //                             : <FormattedMessage id="BookingPanel.NotGuaranteedBooking"/>
  //                           }
  //                           </span>
  //                         </div>
  //                       </div>

  //                       <div className={css.priceMobile}>
  //                         <span className={classNames(css.normalTextMobile, css.isAvailableTen)}>{formattedPrice}</span>
  //                         <span className={css.smallSubText}><FormattedMessage id="BookingPanel.perPerson"/></span>
  //                       </div>
  //                       <div
  //                         className={css.buttonHolder}
  //                         onClick={() => this.handleModalOpen(item.date_to, {partnerListingUrl, formattedPrice})}
  //                       >
  //                         <span className={classNames(css.bookNow, {
  //                           [css.activeBookingNow]: this.isActualDay(item.date_to),
  //                           [css.disabledBookingNow]: !this.isActualDay(item.date_to),
  //                         })}>
  //                           <FormattedMessage id="BookingDatesForm.requestBookNow"/>
  //                         </span>
  //                       </div>
  //                     </div>
  //                 </div>
  //               }
  //             })
  //             }
  //           </TabPane>
  //         )
  //       })
  //       }
  //     </Tabs>
  //   )
  // };

  renderLodgingTabsNew = () => {
    const {
      listing: {
        attributes: {
          publicData: {
            packages = [],
          },
          metadata: {
            canonical_ref = null
          } = {},
        }
      },
      intl,
      currentUser,
    } = this.props;
    // const partnerListingUrl = listing.attributes && listing.attributes.metadata && listing.attributes.metadata.canonical_ref;
    // const packages = listing.attributes.publicData && listing.attributes.publicData.packages ? listing.attributes.publicData.packages : [];
    const callback = key => {};

    const availableMonths = [];
    packages
      .sort((a, b) => moment(a.departure_date) - moment(b.return_date))
      .forEach(item => {
        const monthName = moment(item.departure_date).format('MMMM YYYY');
        if (
          availableMonths.indexOf(monthName) === -1 &&
          this.isActualDay(item.departure_date) &&
          item.rooms.some(({ available }) => available)
        ) {
          availableMonths.push(monthName);
        }
      });

    const isBookingsExist = packages.filter(item =>
      !(!item.rooms || (item.rooms && !item.rooms.length) || !typeRoom(item) || !this.isActualDay(item.departure_date))
    );

    return isBookingsExist.length ? (
      <Tabs className={css.blockMonth}
        onChange={callback}
        renderTabBar={() => <ScrollableInkTabBar/>}
        renderTabContent={() => <TabContent/>}
      >
        {availableMonths.map(month => {
          return (
            <TabPane tab={month} key={month}>
              {packages.map((item, index) => {

                if (
                  !item.rooms ||
                  (item.rooms && !item.rooms.length) ||
                  !typeRoom(item) ||
                  !this.isActualDay(item.departure_date)
                ) return null;

                const monthName = moment(item.departure_date).format('MMMM YYYY');
                const minPrice = getMinPrice(item.rooms ?? []);
                const price = minPrice ? new Money(minPrice.amount, minPrice.currency) : null;
                const convertedPrice = price ? minConvertPrice(currentUser, price) : null;
                const convertedPriceMoney = convertedPrice ? new Money(convertedPrice.amount, convertedPrice.currency) : null;
                convertedPriceMoney.amount *= 100;
                const formattedPrice = convertedPriceMoney ? formatMoney(intl, convertedPriceMoney) : null;
                const mFromDate = moment(item.departure_date);
                const mToDate = moment(item.return_date);
                const range = mToDate.diff(mFromDate, "days") + 1;
                const typeCurrentRoom = typeRoom(item);

                if (monthName === month) {
                  return <div key={index} >
                    <div className={css.itemInfo}>
                      <div className={css.itemDuration}>
                        <FormattedMessage
                          id="BookingBreakdown.dayCount"
                          values={{ count: range }}
                        />
                      </div>
                      <div className={css.blockBookingPrice}>
                        <div className={css.itemDates}>
                          <span className={css.subText}>
                            <FormattedMessage id="BookingPanel.priceFrom" />
                          </span>
                          <span>{moment(item.departure_date).format('DD MMM YYYY')}</span>
                          <span className={css.subText}>
                            <FormattedMessage id="BookingPanel.priceTo" />
                          </span>
                          <span>{moment(item.return_date).format('DD MMM YYYY')}</span>
                        </div>
                        {typeCurrentRoom &&
                          <div className={css.itemDates}>
                            <span>{item.room_type}</span>
                            <span>{typeCurrentRoom}</span>
                          </div>
                        }
                        <div className={css.available}>
                          <span className={classNames( css.textAvailable, {
                            [css.isAvailableTen]: !item.available
                          })}>
                            <FormattedMessage id="BookingPanel.spots"/>
                          </span>
                        </div>

                        <div className={css.price}>
                          <span className={css.normalText}>{formattedPrice}</span>
                          <span className={css.smallSubText}><FormattedMessage id="BookingPanel.perPerson"/></span>
                        </div>

                        <div className={css.isGuarantied}>
                          <span className={item.departure_status || item.guaranteed ? css.guaranteed : css.notGuaranteed}/>
                          <span>{item.departure_status || item.guaranteed
                              ? <FormattedMessage id="BookingPanel.guaranteedBooking"/>
                              : <FormattedMessage id="BookingPanel.NotGuaranteedBooking"/>
                            }</span>
                        </div>
                      </div>
                      <div className={css.priceMobile}>
                        <span className={classNames(css.normalTextMobile, css.isAvailableTen)}>{formattedPrice}</span>
                        <span className={css.smallSubText}><FormattedMessage id="BookingPanel.perPerson"/></span>
                      </div>
                      <div
                        className={css.buttonHolder}
                        onClick={() => this.handleModalOpen(item.departure_date, {
                          partnerListingUrl: canonical_ref,
                          formattedPrice,
                          duration: range,
                          minGroupSize: item.min_group_size,
                        })}
                      >
                        <span className={classNames(css.bookNow, {
                          [css.activeBookingNow]: this.isActualDay(item.return_date),
                          [css.disabledBookingNow]: !this.isActualDay(item.return_date),
                        })}>
                          <FormattedMessage id="BookingDatesForm.requestBookNow"/>
                        </span>
                      </div>
                    </div>
                  </div>
                }
              })
              }
            </TabPane>
          )
        })
        }
      </Tabs>
    ) : <p><FormattedMessage id="BookingDatesForm.bookingsAreNotExist"/></p>
  };

  // renderTravelTabs = () => {
  //   const { listing, intl, currentUser } = this.props;
  //   const travel = listing.attributes.publicData && listing.attributes.publicData.Travels ? listing.attributes.publicData.Travels : [];
  //   return travel.map(t => {

  //     const price = new Money(t.prices[ 0 ].price_value, t.prices[ 0 ].price_currency);
  //     const convertedPrice = convertPrice(currentUser, price);
  //     const convertedPriceMoney = new Money(convertedPrice.amount, convertedPrice.currency);
  //     const formattedPrice = formatMoney(intl, convertedPriceMoney);

  //     return <div className={css.travelItem} key={t.title}>
  //       <div className={css.normalText}>
  //         <span>{t.carrier}</span>
  //       </div>
  //       <div className={css.fromTo}>
  //         <span className={css.normalText}>{t.title}</span>
  //       </div>
  //       <div className={css.carrClass}>
  //         <span className={css.normalText}>{t.carr_class}</span>
  //       </div>
  //       <div className={css.priceColumn}>
  //         <span className={css.normalText}>{formattedPrice}</span>
  //         <span className={css.smallText}>per person</span>
  //       </div>
  //       <div className={css.isGuarantied}>
  //         <span className={css.normalText}>{t.bundle}</span>
  //       </div>
  //       <div className={css.buttonHolder}>
  //         <button className={css.bookNow}>Select</button>
  //       </div>
  //     </div>
  //   })
  // };

  // renderEquipmentTabs = () => {
  //   const { listing, intl, currentUser } = this.props;
  //   const equipment = listing.attributes.publicData && listing.attributes.publicData.Equipment ? listing.attributes.publicData.Equipment : [];
  //   return equipment.map((eq, currentEqItemIndex) => {
  //     const price = new Money(eq.prices[ 0 ].price_value, eq.prices[ 0 ].price_currency);
  //     const convertedPrice = convertPrice(currentUser, price);
  //     const convertedPriceMoney = new Money(convertedPrice.amount, convertedPrice.currency);
  //     const formattedPrice = formatMoney(intl, convertedPriceMoney);

  //     return <div className={css.equipmentTab} key={eq.title}>
  //       <div className={css.accordionHolder}>
  //         <Accordion>
  //           <AccordionItem>
  //             <AccordionItemHeading>
  //               <AccordionItemButton>
  //                 {eq.title}
  //               </AccordionItemButton>
  //             </AccordionItemHeading>
  //             <AccordionItemPanel>
  //               <p>Price: {formattedPrice} day</p>
  //               <p>{eq.description}</p>
  //               <div className={css.bookRequest}>
  //                 <CheckMark/>
  //                 <p>Request to book.</p>
  //               </div>
  //             </AccordionItemPanel>
  //           </AccordionItem>
  //         </Accordion>
  //       </div>
  //       {eq.attachments.length > 0 && <div className={css.galleryHolder}>
  //         {this.renderGallery(eq.attachments, currentEqItemIndex)}
  //         <div className={css.GalleryNavHolder}>
  //           <button className={css.GalleryNavPrev} onClick={() => this.slidePrev(currentEqItemIndex)}/>
  //           <button className={css.GalleryNavNext} onClick={() => this.slideNext(currentEqItemIndex)}/>
  //           {this.renderThumbs(eq.attachments, currentEqItemIndex)}
  //         </div>
  //       </div>}
  //     </div>
  //   })
  // };


  render() {
    const { listing, intl, formattedPrice, formattedPerDayPrice, images } = this.props;
    // const equipment = listing.attributes.publicData && listing.attributes.publicData.Equipment ? listing.attributes.publicData.Equipment : [];
    // const travel = listing.attributes.publicData && listing.attributes.publicData.Travels ? listing.attributes.publicData.Travels : [];
    // const Availabilities = listing.attributes.publicData && listing.attributes.publicData.Availabilities ? listing.attributes.publicData.Availabilities : [];
    // const packages = listing.attributes.publicData && listing.attributes.publicData.packages ? listing.attributes.publicData.packages : [];

    const {
      attributes: {
        publicData: {
          packages = [],
        } = {}
      }
    } = listing;

    const callback = key => {};

    const onManageDisableScrolling = (componentId, scrollingDisabled = false) => {
      // We are just checking the value for now
      console.log('Toggling Modal - scrollingDisabled currently:', componentId, scrollingDisabled);
    };

    const lodgingOptions = intl.formatMessage({ id: 'SectionPackage.lodgingOptions' });
    // const travelOptions = intl.formatMessage({ id: 'SectionPackage.travelOptions' });
    // const equipmentOptions = intl.formatMessage({ id: 'SectionPackage.equipmentOptions' });

    return (
      <div className={classNames(css.imgTap, css.reviewsSectionListing)}>
        {this.state.openListing &&
          <Modal
            containerClassName={css.containerClassName}
            closeButtonMessage={' '}
            id={'bookingInfo'}
            isOpen={this.state.openModal}
            onManageDisableScrolling={onManageDisableScrolling}
            onClose={this.handleModalClose}
          >
            <InfoBookingModal
              isOpen={this.state.openModal}
              onClose={this.handleModalClose}
              openListing={this.state.openListing}
              intl={intl}
              listing={listing}
              formattedPrice={formattedPrice}
              formattedPerDayPrice={formattedPerDayPrice}
              images={images}
            />
        </Modal>}

        {packages.length ? <>
          <h3 className={css.spotlightTitle}>
            <FormattedMessage id="SectionPackage.packageOptions" />
          </h3>
          <Tabs defaultActiveKey="1"
            onChange={callback}
            renderTabBar={() => <ScrollableInkTabBar/>}
            renderTabContent={() => <TabContent/>}
          >
            {/* {Availabilities && Availabilities.length > 0 && <TabPane tab={lodgingOptions} key="1">
              {this.renderLodgingTabs()}
            </TabPane>} */}
            {packages.length ? <TabPane tab={lodgingOptions} key="1">
              {this.renderLodgingTabsNew()}
            </TabPane> : null}
            {/* {travel && travel.length > 0 && <TabPane tab={travelOptions} key="2">
              {this.renderTravelTabs()}
            </TabPane>} */}
            {/* {equipment && equipment.length > 0 && <TabPane tab={equipmentOptions} key="3">
              {this.renderEquipmentTabs()}
            </TabPane>} */}
          </Tabs>
        </> : null}
      </div>

    );
  }
}

export default SectionPackage;
