import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';
import config from '../../config';
import TagManager from "react-gtm-module";
import { LANGUAGE_KEY } from '../../util/languages';

export const FaqPageComponent = props => {
  useEffect(() => {
    if(typeof window !== 'undefined') {
      const tagManagerArgs = {
        dataLayer: {
          userId: '001',
          userProject: 'Activebreak',
          page: 'FaqPage'
        },
        events: {
          pageView: 'pageview',
        },
        page: {
          path: location.pathname,
          title: 'FaqPage',
          type: 'support',
        },
        dataLayerName: 'FaqPageDataLayer',
      };

      TagManager.dataLayer(tagManagerArgs);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pageview',
        page: {
          path: window.location.pathname,
          locale: localStorage.getItem(LANGUAGE_KEY), // language code of displayed page
          title: 'FaqPage',
          type: 'support',
        },
      });
    }
  });

  const { intl, scrollingDisabled, location } = props;
  const title = intl.formatMessage({ id: 'FaqPage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={''}
            mobileRootClassName={''}
            desktopClassName={''}
            currentPage="FaqPage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav className={''}>
          <h1 className={''}>
            <FormattedMessage id="FaqPage.heading" />
          </h1>
        </LayoutWrapperSideNav>
        <LayoutWrapperMain>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer currentPage="FaqPage" location={location}/>
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

FaqPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

FaqPageComponent.propTypes = {
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {

};

const FaqPage = compose(
  connect(mapStateToProps),
  injectIntl
)(FaqPageComponent);


export default FaqPage;
