import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const MenuIcon = props => {
  const { className, rootClassName, desktop } = props;
  const classes = classNames(rootClassName || css.rootMenuIcon, className);

  return desktop
    ? <svg
      className={classes}
      width="48"
      height="48"
      viewBox="0 0 30 30"
      // fill="none"
      // viewBox="0 0 29 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/*<path d="M38 16H10" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>*/}
      {/*<path d="M38 25H10" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>*/}
      {/*<path d="M38 34H10" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>*/}
      <g fillRule="evenodd">
        <rect width="29" height="1" rx="1" />
        <rect y="9" width="29" height="1" rx="1" />
        <rect y="18" width="29" height="1" rx="1" />
      </g>
    </svg>
    : <svg
      className={classes}
      width="27"
      height="16"
      viewBox="0 0 27 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="evenodd">
        <rect width="27" height="2" rx="1" />
        <rect y="7" width="27" height="2" rx="1" />
        <rect y="14" width="27" height="2" rx="1" />
      </g>
    </svg>
};

const { string } = PropTypes;

MenuIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

MenuIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default MenuIcon;
