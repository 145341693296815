import React, { Component } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {formatMoney, minConvertPrice} from '../../util/currency';
import { ensureListing } from '../../util/data';
import { ResponsiveImage } from '../../components';
import { types as sdkTypes } from "../../util/sdkLoader";

import css from './SearchMapInfoCard.module.css';
import moment from "moment/moment";

const { Money } = sdkTypes;

const isActualDay = currentDay => moment(new Date()).format('YYYY-MM-DD') <= currentDay;

const getMinPrice = (packages) => {
  let minPrice;

  packages.forEach(({ rooms }) => {
    rooms.forEach(({ price }) => {
      if (!minPrice || minPrice.amount > price.amount) {
        minPrice = price;
      }
    });
  });

  return minPrice;
}

const priceData = (price, intl) => {
  if (price) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  }
  return {};
};

// ListingCard is the listing info without overlayview or carousel controls
const ListingCard = ({
  className,
  clickHandler,
  intl,
  isInCarousel,
  listing,
  urlToListing,
  currentUser,
  images = [],
}) => {
  const {
    title,
    publicData: {
      packages = [],
    }
  } = ensureListing(listing).attributes;

  const minArrPrices = packages.filter(({departure_date}) => isActualDay(departure_date));
  const price = minArrPrices.length ? getMinPrice(minArrPrices) : getMinPrice(packages);

  const convertedPrice = minConvertPrice(currentUser, price);
  const conevertedPriceMoney = new Money(convertedPrice.amount, convertedPrice.currency);
  let { formattedPrice } = priceData(conevertedPriceMoney, intl);

  const firstImage = images.length > 0 ? images[0] : null;

  // listing card anchor needs sometimes inherited border radius.
  const classes = classNames(
    css.anchor,
    css.borderRadiusInheritTop,
    { [ css.borderRadiusInheritBottom ]: !isInCarousel },
    className
  );

  return (
    <a
      alt={title}
      className={classes}
      href={urlToListing}
      onClick={e => {
        e.preventDefault();
        // Use clickHandler from props to call internal router
        clickHandler(listing);
      }}
    >
      <div
        className={classNames(css.card, css.borderRadiusInheritTop, {
          [ css.borderRadiusInheritBottom ]: !isInCarousel,
        })}
      >
        <div className={classNames(css.threeToTwoWrapper, css.borderRadiusInheritTop)}>
          {images.length
            ? <img src={images[0].url} className={css.listingImg} />
            : <ResponsiveImage
                rootClassName={classNames(css.noImage, css.borderRadiusInheritTop)}
                alt={title}
                noImageMessage={intl.formatMessage({ id: 'SearchMapInfoCard.noImage' })}
                image={firstImage}
                variants={[ 'landscape-crop', 'landscape-crop2x' ]}
                sizes="250px"
              />
          }
        </div>
        <div className={classNames(css.info, { [ css.borderRadiusInheritBottom ]: !isInCarousel })}>
          <div className={css.price}>{formattedPrice}</div>
          <div className={css.name}>{title}</div>
        </div>
      </div>
    </a>
  );
};

ListingCard.defaultProps = {
  className: null,
};

ListingCard.propTypes = {
  className: string,
  listing: propTypes.listing.isRequired,
  clickHandler: func.isRequired,
  intl: intlShape.isRequired,
  isInCarousel: bool.isRequired,
};

class SearchMapInfoCard extends Component {
  constructor(props) {
    super(props);

    this.state = { currentListingIndex: 0 };
  }

  render() {
    const {
      className,
      rootClassName,
      intl,
      listings,
      createURLToListing,
      isAuthenticated,
      onListingInfoCardClicked,
      currentUser,
      images,
    } = this.props;
    const currentListing = ensureListing(listings[this.state.currentListingIndex]);
    const listingImages = images.filter(({ listing_id }) => listing_id === currentListing.id.uuid);
    const hasCarousel = listings.length > 1;
    const pagination = hasCarousel ? (
      <div className={classNames(css.paginationInfo, css.borderRadiusInheritBottom)}>
        <button
          className={css.paginationPrev}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            this.setState(prevState => ({
              currentListingIndex:
              (prevState.currentListingIndex + listings.length - 1) % listings.length,
            }));
          }}
        />
        <div className={css.paginationPage}>
          {`${this.state.currentListingIndex + 1}/${listings.length}`}
        </div>
        <button
          className={css.paginationNext}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            this.setState(prevState => ({
              currentListingIndex:
              (prevState.currentListingIndex + listings.length + 1) % listings.length,
            }));
          }}
        />
      </div>
    ) : null;

    const classes = classNames(rootClassName || css.root, className);
    const caretClass = classNames(css.caret, { [ css.caretWithCarousel ]: hasCarousel });

    return (
      <div className={classes}>
        <div className={css.caretShadow}/>
        <ListingCard
          clickHandler={onListingInfoCardClicked}
          urlToListing={createURLToListing(currentListing)}
          listing={currentListing}
          intl={intl}
          isAuthenticated={isAuthenticated}
          isInCarousel={hasCarousel}
          currentUser={currentUser}
          images={listingImages}
        />
        {pagination}
        <div className={caretClass}/>
      </div>
    );
  }
}

SearchMapInfoCard.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchMapInfoCard.propTypes = {
  className: string,
  rootClassName: string,
  listings: arrayOf(propTypes.listing).isRequired,
  onListingInfoCardClicked: func.isRequired,
  createURLToListing: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(SearchMapInfoCard);
