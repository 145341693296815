import React from 'react';
import { bool } from 'prop-types';
import DurationFilterPlain from './DurationFilterPlain';
import DurationFilterPopup from './DurationFilterPopup';

const DurationFilter = props => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? <DurationFilterPopup {...rest} /> : <DurationFilterPlain {...rest} />;
};

DurationFilter.defaultProps = {
  showAsPopup: false,
};

DurationFilter.propTypes = {
  showAsPopup: bool,
};

export default DurationFilter;
