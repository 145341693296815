import React, { Component } from 'react';
import { number, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, LocationAutocompleteInput } from '../../components';
import { Field, Form as FinalForm } from 'react-final-form';
import { DEFAULT_LOCALE, LANGUAGE_KEY, seTranslates } from '../../util/languages';
import {createLocalizeResourceLocatorString} from '../../util/routes';
// import routeConfiguration from '../../routeConfiguration';
import {types as sdkTypes} from "../../util/sdkLoader";

const { LatLng, LatLngBounds } = sdkTypes;

// Styles
import css from './LocationFilterPopup.module.css';
import omit from "lodash/omit";

const KEY_CODE_ESCAPE = 27;

class LocationFilterPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      hasValue: false,
      language: null,
    };

    this.filter = null;
    this.filterContent = null;
  }

  componentDidMount(){
    this.setState({
      language: localStorage.getItem(LANGUAGE_KEY)
    });
  }

  getPlaceholder = () => {
    return seTranslates[this.state.language].locationFilterPlaceholder;
  };

  handleBlur = (event) => {
    // FocusEvent is fired faster than the link elements native click handler
    // gets its own event. Therefore, we need to check the origin of this FocusEvent.
    if (!this.filter.contains(event.relatedTarget)) {
      this.setState({ isOpen: false });
    }
  };

  handleKeyDown = (e) => {
    // Gather all escape presses to close menu
    if (e.keyCode === KEY_CODE_ESCAPE) {
      this.toggleOpen(false);
    }
  };

  toggleOpen = (enforcedState) => {
    if (enforcedState) {
      this.setState({ isOpen: enforcedState });
    } else {
      this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    }
  };

  handleChange = location => {
    if (location && location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      this.handleSubmit({ location });
    }
  };

  handleClear = (e, form) => {
    const { urlQueryParams, history } = this.props;
    this.setState({ isOpen: false });
    // this.handleSubmit(currentSearchParams);
    form.change('location', '');
    // const searchParams = {
    //   ...urlQueryParams,
    //   // ...currentSearchParams,
    //   // coordinates of Europe
    //   bounds: new LatLngBounds(new LatLng(68.57617478, 35.42172456), new LatLng(19.43482736, -13.70913481))
    // };

    const queryParams = omit(urlQueryParams, ['origin', 'bounds', 'address']);

    this.setState(
      { hasValue: false, isOpen: false },
      () => history.push(createLocalizeResourceLocatorString(queryParams))
    );
  };

  handleCancel = (e, form) => {
    // const { initialValues, urlParam } = this.props;
    this.setState({ isOpen: false });
    // form.change('location', '');

    // this.handleSubmit(urlParam, initialValues);
  };

  handleSubmit = values => {
    const { history, urlQueryParams } = this.props;

    // if (values && values.location) {
      const { location } = values;
      const { search, selectedPlace } = location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = config.sortSearchByDistance ? { origin } : {};
        const searchParams = {
        ...urlQueryParams,
        // ...currentSearchParams,
        ...originMaybe,
        address: search,
        bounds,
      };


      // const queryParams = values && values.location
      //   ? searchParams
      //   : omit(urlQueryParams, ['origin', 'bounds', 'address']);
      // console.log('handleSubmitSearch', queryParams);
      // console.log('urlQueryParams', urlQueryParams);

      this.setState(
        { hasValue: true, isOpen: false },
        () => history.push(createLocalizeResourceLocatorString(searchParams))
      );
    // } else {
    //   this.setState(
    //     { hasValue: false, isOpen: false },
    //     () => history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, {}))
    //   );
    // }
  };

  identity = v => v;

  render() {
    const {
      rootClassName,
      className,
      // location,
      intl,
      urlQueryParams,
      // currentSearchParams
    } = this.props;

    const { isOpen } = this.state;
    const classes = classNames(rootClassName || css.root, className);
    const label = intl.formatMessage({ id: 'LocationFilter.label' });
    const address = urlQueryParams[ 'address' ]

    // const labelStyles = hasValue && address
    //   ? css.label
    //   : hasValue
    //     ? css.labelSelected
    //     : css.label;

    const labelStyles = address
      ? css.labelSelected
      : css.label

    const clear = intl.formatMessage({ id: 'FilterForm.clear' });
    const cancel = intl.formatMessage({ id: 'FilterForm.cancel' });
    const submit = intl.formatMessage({ id: 'FilterForm.submit' });

    const locationPlaceholder = this.state.language ? this.getPlaceholder() : seTranslates[DEFAULT_LOCALE].locationFilterPlaceholder;


    return (
      <div
        className={classes}
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        ref={node => {
          this.filter = node;
        }}
      >
        <button className={labelStyles} onClick={() => this.toggleOpen()}>
          {label}
        </button>

        <FinalForm
          {...this.props}
          onSubmit={this.handleSubmit}
          render={formRenderProps => {
            const {
              // intl,
              handleSubmit,
              form
            } = formRenderProps;

            return (
              <Form
                onSubmit={handleSubmit}
                className={isOpen ? classNames(css.formWrapper, css.formIsOpen) : classNames(css.formWrapper, css.formIsClosed)}
              >
                <Field
                  name="location"
                  format={this.identity}
                  render={({ input, meta }) => {
                    const { onChange, ...restInput } = input;

                    // Merge the standard onChange function with custom behaviur. A better solution would
                    // be to use the FormSpy component from Final Form and pass this.onChange to the
                    // onChange prop but that breaks due to insufficient subscription handling.S
                    // See: https://github.com/final-form/react-final-form/issues/159
                    const searchOnChange = value => {
                      onChange(value);
                    };

                    const searchInput = { ...restInput, onChange: searchOnChange };
                    return (
                      <LocationAutocompleteInput
                        placeholder={locationPlaceholder}
                        input={searchInput}
                        inputRef={node => {
                          this.searchInput = node;
                        }}
                        meta={meta}
                      />
                    );
                  }}
                />

                <div className={css.buttonsWrapper}>
                  <button className={css.clearButton} type="button" onClick={(e) => this.handleClear(e, form)}>
                    {clear}
                  </button>
                  <button className={css.cancelButton} type="button" onClick={(e) => this.handleCancel(e, form)}>
                    {cancel}
                  </button>
                  <button className={css.submitButton} type="submit">
                    {submit}
                  </button>
                </div>
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

LocationFilterPopup.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
  liveEdit: false,
  currencyConfig: config.currencyConfig,
};

LocationFilterPopup.propTypes = {
  rootClassName: string,
  className: string,
  contentPlacementOffset: number,
  currencyConfig: propTypes.currencyConfig,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(LocationFilterPopup);
