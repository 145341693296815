import React from 'react';
import { compose } from 'redux';
import { object, string, bool, number, func, shape } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import omit from 'lodash/omit';
import config from '../../config';
import {parse} from "../../util/urlHelpers";

import {
  BookingDateRangeFilter,
  // SelectSingleFilter,
  SelectMultipleFilter,
  PriceFilter,
  // KeywordFilter,
  InlineTextButton,
  // IconClose,
} from '../../components';
// import routeConfiguration from '../../routeConfiguration';
import { parseDateFromISO8601, stringifyDateToISO8601 } from '../../util/dates';
import { propTypes } from '../../util/types';
import css from './SearchFilters.module.css';
import LocationFilterPopup from '../LocationFilterPopup/LocationFilterPopup';
import DurationFilter from '../DurationFilter/DurationFilter';
// import {categories} from "../../marketplace-custom-config";
import {
  // createResourceLocatorString,
  // findRouteByRouteName,
  createLocalizeResourceLocatorString
} from '../../util/routes';
// import * as languages from '../../translations/index';
// import appRoutes from '../../translations/appRoutes';
// Dropdown container can have a positional offset (in pixels)
const FILTER_DROPDOWN_OFFSET = 0;
const RADIX = 10;

// resolve initial value for a single value filter
// const initialValue = (queryParams, paramName) => queryParams[ paramName ];

// resolve initial values for a multi value filter
const initialValues = (queryParams, paramName) => {
  if(!!queryParams[ paramName ] && Array.isArray(queryParams[ paramName ])) return queryParams[ paramName ];
  return !!queryParams[ paramName ] ? queryParams[ paramName ]?.split(',') : [];
};

const initialPriceRangeValue = (queryParams, paramName) => {
  const price = queryParams[ paramName ];
  const valuesFromParams = !!price ? price.split(',').map(v => Number.parseInt(v, RADIX)) : [];

  return !!price && valuesFromParams.length === 2
    ? {
      minPrice: valuesFromParams[ 0 ],
      maxPrice: valuesFromParams[ 1 ],
    }
    : null;
};

const initialDateRangeValue = (queryParams, paramName) => {
  const dates = queryParams[ paramName ];
  const rawValuesFromParams = !!dates ? dates.split(',') : [];
  const valuesFromParams = rawValuesFromParams.map(v => parseDateFromISO8601(v));
  const initialValues =
    !!dates && valuesFromParams.length === 2
      ? {
        dates: { startDate: valuesFromParams[ 0 ], endDate: valuesFromParams[ 1 ] },
      }
      : { dates: null };

  return initialValues;
};

const SearchFiltersComponent = props => {
  const {
    rootClassName,
    className,
    urlQueryParams,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    categoryFilter,
    amenitiesFilter,
    priceFilter,
    dateRangeFilter,
    // keywordFilter,
    isSearchFiltersPanelOpen,
    toggleSearchFiltersPanel,
    searchFiltersPanelSelectedCount,
    durationRangeFilter,
    history,
    intl,
    location,
    currentUser,
    listings,
    categoryFilterName,
    setCategoryFilterName,
    currentSearchParams
  } = props;

  const hasNoResult = listingsAreLoaded && resultsCount === 0;
  const classes = classNames(rootClassName || css.root, { [ css.longInfo ]: hasNoResult }, className);

  const categoryLabel = intl.formatMessage({ id: 'SearchFilters.categoryLabel' });
  const amenitiesLabel = intl.formatMessage({ id: 'SearchFilters.amenitiesLabel' });
  // const keywordLabel = intl.formatMessage({ id: 'SearchFilters.keywordLabel' });

  const customSubmitButton = intl.formatMessage({ id: 'FilterPlain.done' });

  const initialAmenities = amenitiesFilter
    ? initialValues(urlQueryParams, amenitiesFilter.paramName)
    : null;

  const initialCategory = categoryFilter
    ? initialValues(urlQueryParams, categoryFilter.paramName)
    : null;

  const initialPriceRange = priceFilter
    ? initialPriceRangeValue(urlQueryParams, priceFilter.paramName)
    : null;

  const initialDateRange = dateRangeFilter
    ? initialDateRangeValue(urlQueryParams, dateRangeFilter.paramName)
    : null;

  // const initialKeyword = keywordFilter
  //   ? initialValue(urlQueryParams, keywordFilter.paramName)
  //   : null;

  const { address, origin, bounds } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  // Only render current search if full place object is available in the URL params
  const locationFieldsPresent = config.sortSearchByDistance
    ? address && origin && bounds
    : address && bounds;

  const initialSearchFormValues = {
    location: locationFieldsPresent
      ? {
        search: address,
        selectedPlace: { address, origin, bounds },
      }
      : null,
  };

  const initialRangeValue = (queryParams, paramName, type) => {
    const price = queryParams[ paramName ];
    const valuesFromParams = !!price ? price.split(',').map(v => Number.parseInt(v, RADIX)) : [];

    return !!price && valuesFromParams.length === 2
      ? {
        [ `min${type}` ]: valuesFromParams[ 0 ],
        [ `max${type}` ]: valuesFromParams[ 1 ],
      }
      : null;
  };

  const initialDurationRange = durationRangeFilter
    ? initialRangeValue(urlQueryParams, durationRangeFilter.paramName, 'Duration')
    : null;

  const handleSelectOptions = (urlParam, options) => {
    const queryParams =
      options && options.length > 0
        ? { ...urlQueryParams, [ urlParam ]: options.join(',') }
        : omit(urlQueryParams, urlParam);

    history.push(createLocalizeResourceLocatorString(queryParams));
  };

  const handlePrice = ({ price }) => {
    const currentPrice = price && price.split(',') || ''
    const minPrice = currentPrice[0] || ''
    const maxPrice = currentPrice[1] || ''
    const queryParams = minPrice && maxPrice
        ? { ...urlQueryParams, [ 'price' ]: `${minPrice},${maxPrice}` }
        : omit(urlQueryParams, 'price');
    history.push(createLocalizeResourceLocatorString(queryParams));
  };

  const handleDateRange = (urlParam, dateRange) => {
    const hasDates = dateRange && dateRange.dates;
    const { startDate, endDate } = hasDates ? dateRange.dates : {};
    const start = startDate ? stringifyDateToISO8601(startDate) : null;
    const end = endDate ? stringifyDateToISO8601(endDate) : null;

    const queryParams =
      start != null && end != null
        ? { ...urlQueryParams, [ urlParam ]: `${start},${end}` }
        : omit(urlQueryParams, urlParam);

    history.push(createLocalizeResourceLocatorString(queryParams));
  };

  // const handleKeyword = (urlParam, values) => {
  //   const queryParams = values
  //     ? { ...urlQueryParams, [ urlParam ]: values }
  //     : omit(urlQueryParams, urlParam);

  //   history.push(createLocalizeResourceLocatorString(queryParams));
  // };

  const handleDuration = (urlParam, range) => {
    const { urlQueryParams, history } = props;
    const { minDuration, maxDuration } = range || {};
    const queryParams =
      minDuration != null && maxDuration != null
        ? { ...urlQueryParams, [ urlParam ]: `${minDuration},${maxDuration}` }
        : omit(urlQueryParams, urlParam);

    history.push(createLocalizeResourceLocatorString(queryParams));
  };

  const durationFilterElement = durationRangeFilter ? (
    <DurationFilter
      id="SearchFilters.durationFilter"
      urlParam={durationRangeFilter.paramName}
      onSubmit={handleDuration}
      {...durationRangeFilter.config}
      initialValues={initialDurationRange}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      custom
      showAsPopup
    />
  ) : null;


  // const categoryFilterElement = categoryFilter ? (
  //   <SelectSingleFilter
  //     urlParam={categoryFilter.paramName}
  //     label={categoryLabel}
  //     onSelect={handleSelectOption}
  //     showAsPopup
  //     options={categoryFilter.options}
  //     initialValue={initialCategory}
  //     contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
  //   />
  // ) : null;

  const categoryFilterElement = categoryFilter && categoryFilter.options.length ? (
    <SelectMultipleFilter
      id={'SearchFilters.categoryFilter'}
      name="category"
      urlParam={categoryFilter.paramName}
      label={categoryLabel}
      onSubmit={handleSelectOptions}
      showAsPopup
      options={categoryFilter.options}
      initialValues={initialCategory}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    />
  ) : null;

  const amenitiesFilterElement = amenitiesFilter && amenitiesFilter.options.length ? (
    <SelectMultipleFilter
      id={'SearchFilters.amenitiesFilter'}
      name="amenities"
      urlParam={amenitiesFilter.paramName}
      label={amenitiesLabel}
      onSubmit={handleSelectOptions}
      showAsPopup
      options={amenitiesFilter.options}
      initialValues={initialAmenities}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    />
  ) : null;

  const priceFilterElement = priceFilter ? (
    <PriceFilter
      id="SearchFilters.priceFilter"
      urlParam={priceFilter.paramName}
      currentUser={currentUser}
      onSubmit={handlePrice}
      showAsPopup
      {...priceFilter.config}
      initialValues={initialPriceRange}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      // dateRange={initialDateRange}
    />
  ) : null;

  const dateRangeFilterElement =
    dateRangeFilter && dateRangeFilter.config.active ? (
      <BookingDateRangeFilter
        id="SearchFilters.dateRangeFilter"
        urlParam={dateRangeFilter.paramName}
        onSubmit={handleDateRange}
        showAsPopup
        currentUser={currentUser}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        initialValues={initialDateRange}
        numberOfMonths={2}
        className={css.bookingDateRangeFilter}
        customSubmit={customSubmitButton}
        classCustomBlock={css.classCustomBlock}
      />
    ) : null;

  // const keywordFilterElement =
  //   keywordFilter && keywordFilter.config.active ? (
  //     <KeywordFilter
  //       id={'SearchFilters.keywordFilter'}
  //       name="keyword"
  //       urlParam={keywordFilter.paramName}
  //       label={keywordLabel}
  //       onSubmit={handleKeyword}
  //       showAsPopup
  //       initialValues={initialKeyword}
  //       contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
  //     />
  //   ) : null;

  const toggleSearchFiltersPanelButtonClasses =
    isSearchFiltersPanelOpen || searchFiltersPanelSelectedCount > 0
      ? css.searchFiltersPanelOpen
      : css.searchFiltersPanelClosed;

  const toggleSearchFiltersPanelButton = toggleSearchFiltersPanel ? (
    <button
      className={toggleSearchFiltersPanelButtonClasses}
      onClick={() => {
        toggleSearchFiltersPanel(!isSearchFiltersPanelOpen);
      }}
    >
      <FormattedMessage
        id="SearchFilters.moreFiltersButton"
        values={{ count: searchFiltersPanelSelectedCount }}
      />
    </button>
  ) : null;

  // Reset all filter query parameters
  const resetAll = (e) => {
    setCategoryFilterName("Activity")
    history.push(createLocalizeResourceLocatorString());

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  };

  const resetButton = (
    <div className={css.resetAllButtonHolder}>
      <InlineTextButton rootClassName={css.resetAllButton} onClick={resetAll}>
        <FormattedMessage id={'SearchFiltersPanel.resetAllMain'}/>
      </InlineTextButton>
    </div>
  );

  const locationFilter = (
    <LocationFilterPopup
      listings={listings}
      history={history}
      urlQueryParams={urlQueryParams}
      location={location}
      initialValues={initialSearchFormValues}
      intl={intl}
      currentSearchParams={currentSearchParams}
      urlParam="address"
    />
  );

  return (
    <div className={classes}>
      <div className={css.titleSearchResult}>
        <p className={css.titleCategoryActive}>{categoryFilterName}</p>
        {listingsAreLoaded && resultsCount > 0 ? (
          <div className={css.searchResultSummary}>
          <span className={css.resultsFound}>
            <FormattedMessage id="SearchFilters.foundResults" values={{ count: resultsCount }}/>
          </span>
          </div>
        ) : null}
      </div>

      <div className={css.filters}>
        {categoryFilterElement}
        {locationFilter}
        {amenitiesFilterElement}
        {priceFilterElement}
        {dateRangeFilterElement}
        {durationFilterElement}
        {toggleSearchFiltersPanelButton}
        {resetButton}
      </div>

      {hasNoResult ? (
        <div className={css.noSearchResults}>
          <FormattedMessage id="SearchFilters.noResults"/>
        </div>
      ) : null}

      {searchInProgress ? (
        <div className={css.loadingResults}>
          <FormattedMessage id="SearchFilters.loadingResults"/>
        </div>
      ) : null}
    </div>
  );
};

SearchFiltersComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchingInProgress: false,
  categoryFilter: null,
  amenitiesFilter: null,
  priceFilter: null,
  dateRangeFilter: null,
  isSearchFiltersPanelOpen: false,
  toggleSearchFiltersPanel: null,
  searchFiltersPanelSelectedCount: 0,
};

SearchFiltersComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchingInProgress: bool,
  onUpdateProfile: func,
  onManageDisableScrolling: func.isRequired,
  categoriesFilter: propTypes.filterConfig,
  // amenitiesFilter: propTypes.filterConfig,
  // priceFilter: propTypes.filterConfig,
  // dateRangeFilter: propTypes.filterConfig,
  isSearchFiltersPanelOpen: bool,
  toggleSearchFiltersPanel: func,
  searchFiltersPanelSelectedCount: number,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SearchFilters = compose(
  withRouter,
  injectIntl
)(SearchFiltersComponent);

export default SearchFilters;
