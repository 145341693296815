import React from 'react';
import PropTypes from 'prop-types';

const IconShare = props => {
  const { className } = props;

  return (
    <svg
      className={className}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#4a4a4a" stroke="none">
        <path d="M3910 5099 c-271 -70 -483 -284 -551 -558 -18 -71 -20 -104 -16 -205
          3 -67 8 -130 11 -141 6 -20 -187 -135 -1696 -1009 l-119 -69 -27 25 c-143 132
          -375 203 -579 178 -330 -41 -571 -255 -664 -590 -17 -58 -17 -282 0 -340 45
          -162 118 -288 226 -388 259 -243 651 -276 945 -79 25 16 57 41 72 55 l27 25
          88 -51 c48 -28 462 -269 918 -536 818 -478 830 -485 825 -513 -3 -15 -5 -86
          -5 -158 0 -108 4 -142 23 -199 67 -201 194 -353 374 -446 224 -117 502 -109
          720 20 165 98 286 257 340 447 31 110 31 276 -1 388 -37 133 -94 227 -196 331
          -76 76 -105 97 -185 137 -123 60 -207 80 -340 80 -166 0 -323 -53 -449 -153
          l-52 -41 -457 265 c-251 146 -664 387 -917 534 l-460 268 5 35 c3 18 5 86 5
          149 0 63 -2 131 -5 150 l-5 34 908 529 908 530 27 -25 c75 -69 207 -137 320
          -165 109 -26 276 -22 387 11 213 64 395 220 484 418 197 433 -46 941 -507
          1059 -102 26 -278 25 -382 -2z"
        />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconShare.defaultProps = {
  className: null,
  rootClassName: null,
};

IconShare.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconShare;
