import React from 'react';
import loadable from '@loadable/component';
import getPageDataLoadingAPI from './containers/pageDataLoadingAPI';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';

import {
  SearchPage,
  FavoritesPage
} from './containers'
// import config from './config';

const AboutPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/AboutPage/AboutPage'));
const AuthenticationPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ './containers/AuthenticationPage/AuthenticationPage'));
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ './containers/CheckoutPage/CheckoutPage'));
const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ './containers/ContactDetailsPage/ContactDetailsPage'));
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ './containers/EditListingPage/EditListingPage'));
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ './containers/EmailVerificationPage/EmailVerificationPage'));
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ './containers/InboxPage/InboxPage'));
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ './containers/LandingPage/LandingPage'));
const ListingPage = loadable(() => import(/* webpackChunkName: "ListingPage" */ /* webpackPrefetch: true */ './containers/ListingPage/ListingPage'));
const ManageListingsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ './containers/ManageListingsPage/ManageListingsPage'));
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ './containers/PasswordChangePage/PasswordChangePage'));
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ './containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ './containers/PasswordResetPage/PasswordResetPage'));
const PaymentMethodsPage = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage" */ './containers/PaymentMethodsPage/PaymentMethodsPage'));
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ './containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ './containers/ProfilePage/ProfilePage'));
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ './containers/ProfileSettingsPage/ProfileSettingsPage'));
// const SearchPage = loadable(() => import(/* webpackChunkName: "SearchPage" */ /* webpackPrefetch: true */  './containers/SearchPage/SearchPage'));
const StripePayoutPage = loadable(() => import(/* webpackChunkName: "StripePayoutPage" */ './containers/StripePayoutPage/StripePayoutPage'));
const TermsOfServicePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ './containers/TermsOfServicePage/TermsOfServicePage'));
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ './containers/TransactionPage/TransactionPage'));
const HostHelpPage = loadable(() => import(/* webpackChunkName: "HostHelpPage" */ './containers/HostHelpPage/HostHelpPage'));
const ContactPage = loadable(() => import(/* webpackChunkName: "ContactPage" */ './containers/ContactPage/ContactPage'));
const NotFoundPage = loadable(() => import(/* webpackChunkName: "NotFoundPage" */ './containers/NotFoundPage/NotFoundPage'));
const CookiePolicyPage = loadable(() => import(/* webpackChunkName: "CookiePolicyPage" */ './containers/CookiePolicyPage/CookiePolicyPage'));
const TravelerHelpPage = loadable(() => import(/* webpackChunkName: "TravelerHelpPage" */ './containers/TravelerHelpPage/TravelerHelpPage'));
const ImprintPage = loadable(() => import(/* webpackChunkName: "ImprintPage" */ './containers/ImprintPage/ImprintPage'));
const TrustAndSafetyPage = loadable(() => import(/* webpackChunkName: "TrustAndSafetyPage" */ './containers/TrustAndSafetyPage/TrustAndSafetyPage'));
const CommunicationsPage = loadable(() => import(/* webpackChunkName: "CommunicationsPage" */ './containers/CommunicationsPage/CommunicationsPage'));
// const FavoritesPage = loadable(() => import(/* webpackChunkName: "FavoritesPage" */ './containers/FavoritesPage/FavoritesPage'));
const FaqPage = loadable(() => import(/* webpackChunkName: "FaqPage" */ './containers/FaqPage/FaqPage'));
const HelpPage = loadable(() => import(/* webpackChunkName: "HelpPage" */ './containers/HelpPage/HelpPage'));

// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() => import(/* webpackChunkName: "StyleguidePage" */ './containers/StyleguidePage/StyleguidePage'));

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.



const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: props => <LandingPage {...props} />,
    },
    {
      path: `/about`,
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: `/help/booking-and-traveling`,
      name: 'TravelerHelpPage',
      component: TravelerHelpPage,
    },
    {
      path: `/help/hosting`,
      name: 'HostHelpPage',
      component: HostHelpPage,
    },
    {
      path: `/help/trust-and-safety`,
      name: 'TrustAndSafetyPage',
      component: TrustAndSafetyPage,
    },
    {
      path: `/favorites`,
      name: 'FavoritesPage',
      component: props => <FavoritesPage {...props}/>,
      loadData: FavoritesPage.loadData,
    },
    {
      path: `/help/faq`,
      name: 'FaqPage',
      component: props => <FaqPage {...props}/>,
    },
    {
      path: `/help`,
      name: 'HelpPage',
      component: props => <HelpPage {...props}/>,
    },
    {
      path: `/s`,
      name: 'SearchPage',
      component: props => <SearchPage {...props} />,
      loadData: SearchPage.loadData,
    },
    {
      path: `/s/filters`,
      name: 'SearchFiltersPage',
      component: props => <SearchPage {...props} />,
      loadData: SearchPage.loadData,
      extraProps: { tab: 'filters' }
    },
    {
      path: `/s/listings`,
      name: 'SearchListingsPage',
      component: props => <SearchPage {...props} tab="listings" />,
      loadData: SearchPage.loadData,
      extraProps: { tab: 'listings' }
    },
    {
      path: `/s/map`,
      name: 'SearchMapPage',
      component: props => <SearchPage {...props} tab="map" />,
      loadData: SearchPage.loadData,
      extraProps: { tab: 'map' }
    },
    {
      path: `/l`,
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: `/l/:slug/:id`,
      name: 'ListingPage',
      component: props => <ListingPage {...props} />,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: `/l/:slug/:id/checkout`,
      name: 'CheckoutPage',
      auth: true,
      component: props => <CheckoutPage {...props} />,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: `/l/:slug/:id/:variant`,
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ListingPage {...props} />,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: `/l/:slug/:id/tab/:tab`,
      name: 'ListingPageTab',
      component: props => <ListingPage {...props} />,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: `/l/new`,
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: `/l/:slug/:id/:type/:tab`,
      name: 'EditListingPage',
      auth: true,
      component: props => <EditListingPage {...props} />,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: `/l/:slug/:id/:type/:tab/:returnURLType`,
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: props => <EditListingPage {...props} />,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: `/l/:id`,
      name: 'ListingPageCanonical',
      component: props => <ListingPage {...props} />,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: `/d`,
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: `/d/:id`,
      name: 'ProfilePage',
      component: props => <ProfilePage {...props} />,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: `/account/profile`,
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ProfileSettingsPage {...props} />,
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: `/login`,
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: `/signup`,
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
    },
    {
      path: `/confirm`,
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    // TODO:
    {
      path: `/account/password`,
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: `/account/inbox`,
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    },
    {
      path: `/inbox/:tab`,
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <InboxPage {...props} />,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: `/order/:id`,
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: `/order/:id/details`,
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="customer" />,
      loadData: params => pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: `/sale/:id`,
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: `/sale/:id/details`,
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="provider" />,
      loadData: params => pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: `/account/listings`,
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageListingsPage {...props} />,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: `/account`,
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: `/account/contact-details`,
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ContactDetailsPage {...props} />,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    // TODO:
    {
      path: `/account/change-password`,
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PasswordChangePage {...props} />,
    },
    {
      path: `/account/communications`,
      name: 'CommunicationsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <CommunicationsPage {...props} />,
    },
    {
      path: `/account/payments`,
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} />,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: `/account/payments/:returnURLType`,
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} />,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: `/account/payment-methods`,
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PaymentMethodsPage {...props} />,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: `/contact`,
      name: 'ContactPage',
      component: props => <ContactPage {...props} />,
    },
    {
      path: `/imprint`,
      name: 'ImprintPage',
      component: props => <ImprintPage {...props} />,
    },
    {
      path: `/terms-conditions`,
      name: 'TermsOfServicePage',
      component: props => <TermsOfServicePage {...props} />,
    },
    {
      path: `/privacy-policy`,
      name: 'PrivacyPolicyPage',
      component: props => <PrivacyPolicyPage {...props} />,
    },
    {
      path: `/cookie-policy`,
      name: 'CookiePolicyPage',
      component: props => <CookiePolicyPage {...props} />,
    },
    {
      path: `/styleguide`,
      name: 'Styleguide',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: `/styleguide/g/:group`,
      name: 'StyleguideGroup',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: `/styleguide/c/:component`,
      name: 'StyleguideComponent',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: `/styleguide/c/:component/:example`,
      name: 'StyleguideComponentExample',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: `/styleguide/c/:component/:example/raw`,
      name: 'StyleguideComponentExampleRaw',
      component: props => <StyleguidePage raw {...props} />,
    },
    {
      path: `/notfound`,
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: `/reset-password`,
      name: 'PasswordResetPage',
      component: props => <PasswordResetPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: `/verify-email`,
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <EmailVerificationPage {...props} />,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
  ];
};

export default routeConfiguration;
