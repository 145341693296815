import React from 'react';
import { string, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  LanguageSelect,
  CurrencySelect,
  NamedLink,
  HostActivity,
} from '../../components';

import css from './Footer.module.css';
// import { localLang } from '../../util/data';
import moment from 'moment';
import {connect} from "react-redux";
import {becomeAhostSubmit, getCategories} from "../../ducks/Categories.duck";


const renderSocialMediaLinks = (intl) => {
  const {siteFacebookPage, siteInstagramPage, siteTwitterHandle} = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({id: 'Footer.goToFacebook'});
  const goToInsta = intl.formatMessage({id: 'Footer.goToInstagram'});
  const goToTwitter = intl.formatMessage({id: 'Footer.goToTwitter'});

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook/>
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter/>
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram/>
    </ExternalLink>
  ) : null;
  return [
    fbLink,
    twitterLink,
    instragramLink
  ].filter(v => v != null);
};


export const FooterComponent = (props) => {
  const {
    rootClassName,
    className,
    intl,
    custom,
    location,
    currentPage,
    becomeAhostSuccess,
    hubspotFormSubmit
  } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>

          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <Logo format="desktop" className={css.logo}/>
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescription"/>
                </p>
                {!custom && <NamedLink name='LandingPage' className={css.copyrightLinkSmall}>
                  <FormattedMessage id='Footer.copyright' values={{date: moment().year()}} />
                </NamedLink>}
              </div>
            </div>

            <div className={css.infoLinks}>
              <span className={css.titleList}>
                <FormattedMessage id="Footer.travelers"/>
              </span>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="SignupPage" className={css.link}>
                    <FormattedMessage id="Footer.signUp"/>
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="TravelerHelpPage" className={css.link}>
                    <FormattedMessage id="Footer.browseLocation"/>
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="TrustAndSafetyPage" className={css.link}>
                    <FormattedMessage id="Footer.topTours"/>
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="SearchPage" className={css.link}>
                    <FormattedMessage id="Footer.findInspiration"/>
                  </NamedLink>
                </li>
              </ul>
            </div>


            <div className={css.searchesExtra}>
              <span className={css.titleList}>
                <FormattedMessage id="Footer.operators"/>
              </span>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <HostActivity
                    becomeAhostSuccess={becomeAhostSuccess}
                    hubspotFormSubmit={hubspotFormSubmit}
                    linkClassName={css.hostLink}
                    isFooter/>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="HostHelpPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.hostHelp" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <a
                    href="https://www.activebreak.com/host-with-activebreak"
                    target="_blank"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.hostingWithActiveBreak"/>
                  </a>
                </li>
                {/* <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Hawaii%2C%20United%20States%20of%20America&bounds=28.617125%2C-154.655841%2C18.765563%2C-178.54359&pub_category=windsurfing',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchHawaii"/>
                  </NamedLink>
                </li> */}
              </ul>
            </div>
            <div className={css.searchesExtra}>
              {/*<div className={css.languageCurrency}>*/}
              {/*  <LanguageSelect/>*/}
              {/*  <CurrencySelect/>*/}
              {/*</div>*/}
                <span className={css.titleList}>
                  <NamedLink name="AboutPage" className={css.titleLink}>
                    <FormattedMessage id="Footer.aboutActiveBreak"/>
                  </NamedLink>
                </span>
                <ul className={css.tosAndPrivacy}>
                  <li>
                    <NamedLink name="ContactPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.toContactPage"/>
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink name="ImprintPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.imprint"/>
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.privacyPolicy"/>
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                      <FormattedMessage id="Footer.termsOfUse"/>
                    </NamedLink>
                  </li>
                  <li className={css.someLinks}>
                    {socialMediaLinks}
                  </li>
                </ul>
              </div>
          </div>

            <NamedLink name="LandingPage" className={css.copyrightLink}>
              <FormattedMessage id="Footer.copyright" values={{date: moment().year()}}/>
            </NamedLink>
            <div className={css.someLinksSmall}>
              {socialMediaLinks}
              <div className={css.languageCurrencyMenuMobile}>
                <LanguageSelect type currentPage={currentPage} location={location} />
                <CurrencySelect/>
              </div>
            </div>
          <NamedLink name="PrivacyPolicyPage" className={css.privacySmall}>
            <FormattedMessage id="Footer.privacy"/>
          </NamedLink>

          {custom && <NamedLink name='LandingPage' className={classNames(css.copyrightLinkSmall, css.topLastEl)}>
            <FormattedMessage id='Footer.copyright' values={{date: moment().year()}} />
          </NamedLink>}
          {/*<div className={css.copyrightAndTermsMobile}>*/}
          {/*  <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>*/}
          {/*    <FormattedMessage id="Footer.copyright" values={{date: moment().year()}}/>*/}
          {/*  </NamedLink>*/}
          {/*  <div className={css.tosAndPrivacyMobile}>*/}
          {/*    <NamedLink name="PrivacyPolicyPage" className={css.privacy}>*/}
          {/*      <FormattedMessage id="Footer.privacy"/>*/}
          {/*    </NamedLink>*/}
          {/*    <NamedLink name="TermsOfServicePage" className={css.terms}>*/}
          {/*      <FormattedMessage id="Footer.terms"/>*/}
          {/*    </NamedLink>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

FooterComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

FooterComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  onUpdateUserCurrency: func
};

const mapStateToProps = state => {
  const { becomeAhostSuccess } = state.Categories;
  return {
    becomeAhostSuccess,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetCategories: () => dispatch(getCategories()),
  hubspotFormSubmit: (values) => dispatch(becomeAhostSubmit(values)),
});

const Footer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(FooterComponent);

export default Footer;
