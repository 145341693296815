import React from 'react';
// import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import {
  // Avatar,
  // BookingTimeInfo,
  // NamedLink,
  // NotificationBadge,
  Page,
  // PaginationLinks,
  // TabNav,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';
import config from '../../config';

// import { loadData } from './InboxPage.duck';


export const HelpPageComponent = props => {
  const {
    // unitType,
    // currentUser,
    // fetchInProgress,
    // fetchOrdersOrSalesError,
    intl,
    location,
    // pagination,
    // params,
    // providerNotificationCount,
    scrollingDisabled,
    // transactions,
  } = props;

  const title = intl.formatMessage({ id: 'HelpPage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="HelpPage" />
        </LayoutWrapperTopbar>

        <LayoutWrapperSideNav className={''}>
          <h1 className={''}>
            <FormattedMessage id="HelpPage.heading" />
          </h1>
        </LayoutWrapperSideNav>
        <LayoutWrapperMain>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer currentPage="HelpPage" location={location}/>
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

HelpPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

HelpPageComponent.propTypes = {
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {

};

const HelpPage = compose(
  connect(mapStateToProps),
  injectIntl
)(HelpPageComponent);


export default HelpPage;
