import React from 'react';
import {FormattedMessage} from '../../util/reactIntl';
import css from './SectionSendEmail.module.css';

const SectionSendEmail = () => (
  <div className={css.root}>
    <div className={css.sectionDiscount}>
      <div className={css.sectionMount}>
        <p className={css.textUpTo}>
          <FormattedMessage id="SectionBusinessPartners.upTo"/>
        </p>
        <h2 className={css.countDiscount}>50%</h2>
        <p className={css.textDiscount}>
          <FormattedMessage id="SectionBusinessPartners.discount"/>
        </p>
        <p className={css.forLetter}>
          <FormattedMessage id="SectionBusinessPartners.newsletter" values={{lyneBreak: <br/>}}/>
        </p>
      </div>
      <div className={css.sectionMap}>
        <iframe src="https://cdn.forms-content.sg-form.com/315bfec5-bbd8-11ec-b649-a2ab69c1058a"/>
      </div>
    </div>
  </div>
);

export default SectionSendEmail;
