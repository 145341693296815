import React from 'react';
import PropTypes from 'prop-types';

import css from './IconActivity.module.css';

const IconActivityWater = props => {
  const { className, type } = props;

  if (type === "kayaking") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M15.308 24.852h-1.163c-1.661 0-2.99-1.185-2.99-2.708H29.18c0 1.523-1.329 2.708-2.99 2.708h-1.163M7.5 24.937a2.303 2.303 0 013.24.084l.664.593c.914.931 2.492.846 3.323-.085l.166-.169c.913-1.016 2.492-1.016 3.489 0 .913 1.016 2.575 1.016 3.488 0l.083-.084a2.332 2.332 0 013.323-.17l.748.762c.913.846 2.242.846 3.156 0l.997-.931c.914-.847 2.326-.847 3.156 0m-9.137-9.734H19.13c-.581 0-.997.423-1.08 1.015l-.415 3.048c-.083.677.415 1.27 1.08 1.27h1.661m8.224-8.888L16.388 29.169m5.583-18.935a2.527 2.527 0 01-1.839 3.038 2.474 2.474 0 01-3.011-1.822 2.527 2.527 0 011.838-3.038 2.474 2.474 0 013.012 1.822zm7.541-2.226L28.1 9.955c-.498.677-.083 1.693.748 1.777.498.085 1.08-.084 1.91-.592.416-.254.748-.593.997-.931l1.08-1.44c.332-.507 0-1.269-.665-1.269h-1.827a.911.911 0 00-.83.508zM15.474 32.894l1.412-1.947c.499-.677.084-1.693-.747-1.778-.499-.085-1.08.085-1.91.593a3.57 3.57 0 00-.997.93l-1.08 1.355c-.333.508 0 1.27.664 1.27h1.745c.332.084.747-.17.913-.423z"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M15.308 24.852h-1.163c-1.661 0-2.99-1.185-2.99-2.708H29.18c0 1.523-1.329 2.708-2.99 2.708h-1.163M7.5 24.937a2.303 2.303 0 013.24.084l.664.593c.914.931 2.492.846 3.323-.085l.166-.169c.913-1.016 2.492-1.016 3.489 0 .913 1.016 2.575 1.016 3.488 0l.083-.084a2.332 2.332 0 013.323-.17l.748.762c.913.846 2.242.846 3.156 0l.997-.931c.914-.847 2.326-.847 3.156 0m-9.137-9.734H19.13c-.581 0-.997.423-1.08 1.015l-.415 3.048c-.083.677.415 1.27 1.08 1.27h1.661m8.224-8.888L16.388 29.169m5.583-18.935a2.527 2.527 0 01-1.839 3.038 2.474 2.474 0 01-3.011-1.822 2.527 2.527 0 011.838-3.038 2.474 2.474 0 013.012 1.822zm7.541-2.226L28.1 9.955c-.498.677-.083 1.693.748 1.777.498.085 1.08-.084 1.91-.592.416-.254.748-.593.997-.931l1.08-1.44c.332-.507 0-1.269-.665-1.269h-1.827a.911.911 0 00-.83.508zM15.474 32.894l1.412-1.947c.499-.677.084-1.693-.747-1.778-.499-.085-1.08.085-1.91.593a3.57 3.57 0 00-.997.93l-1.08 1.355c-.333.508 0 1.27.664 1.27h1.745c.332.084.747-.17.913-.423z"
        ></path>
      </svg>
    );
  } else if (type === "sailing") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M14.976 30.93h-.563a3.227 3.227 0 01-3.215-3.22H28.4a3.227 3.227 0 01-3.215 3.22h-.724M7.5 31.01a2.256 2.256 0 013.135.081l.643.564c.884.885 2.412.805 3.216-.08l.16-.162c.885-.966 2.412-.966 3.377 0 .884.966 2.492.966 3.376 0l.08-.08a2.284 2.284 0 013.216-.161l.723.724c.884.805 2.17.805 3.055 0l.964-.886c.885-.805 2.251-.805 3.055 0m-13.103-9.903l-4.662.564c-.483.08-.804.563-.724 1.046l.483 2.496c.08.403.16.966.643 1.047h1.848m2.412 1.127L23.095 7.5m1.045 3.382l3.858 12.399s-7.154 3.14-7.074 3.06c4.02-6.04 3.216-15.46 3.216-15.46zm-7.856 5.713a2.502 2.502 0 01-1.831 3.025 2.498 2.498 0 01-3.024-1.83 2.502 2.502 0 011.832-3.025c1.34-.33 2.694.489 3.023 1.83z"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M14.976 30.93h-.563a3.227 3.227 0 01-3.215-3.22H28.4a3.227 3.227 0 01-3.215 3.22h-.724M7.5 31.01a2.256 2.256 0 013.135.081l.643.564c.884.885 2.412.805 3.216-.08l.16-.162c.885-.966 2.412-.966 3.377 0 .884.966 2.492.966 3.376 0l.08-.08a2.284 2.284 0 013.216-.161l.723.724c.884.805 2.17.805 3.055 0l.964-.886c.885-.805 2.251-.805 3.055 0m-13.103-9.903l-4.662.564c-.483.08-.804.563-.724 1.046l.483 2.496c.08.403.16.966.643 1.047h1.848m2.412 1.127L23.095 7.5m1.045 3.382l3.858 12.399s-7.154 3.14-7.074 3.06c4.02-6.04 3.216-15.46 3.216-15.46zm-7.856 5.713a2.502 2.502 0 01-1.831 3.025 2.498 2.498 0 01-3.024-1.83 2.502 2.502 0 011.832-3.025c1.34-.33 2.694.489 3.023 1.83z"
        ></path>
      </svg>
    );
  } else if (type === "freediving") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M17.242 20.12l-1.289-.405c-1.047-.325-2.094-.892-2.9-1.62l-1.53-1.377-3.543-.486 3.221 2.591c1.047.81 2.256 1.296 3.544 1.377l2.497-.08zm0 0l1.127 4.373c.162.486.725.486 1.128.243l1.45-1.944a1.948 1.948 0 011.933-.648l5.154 1.215m-14.416.891l7.007-1.134m1.771 4.455l5.477-4.05 1.289 3.97c.08.323.322.566.564.728l2.818 1.782M9.2 9.856a2.254 2.254 0 013.14.081l.564.486c.886.891 2.416.81 3.222-.08l.161-.162c.886-.973 2.416-.973 3.383 0 .886.971 2.496.971 3.382 0l.08-.081a2.282 2.282 0 013.222-.163l.725.73c.886.81 2.175.81 3.06 0l.967-.892c.886-.81 2.255-.81 3.06 0m-20.87 4.722c.886.89 2.416.81 3.221-.081l.161-.162c.886-.972 2.416-.972 3.383 0 .886.972 2.497.972 3.383 0l.08-.081a2.282 2.282 0 013.222-.162l.724.729c.886.81 2.175.81 3.06 0m1.02 5.422a2.511 2.511 0 011.457 3.228 2.489 2.489 0 01-3.215 1.453 2.511 2.511 0 01-1.459-3.228 2.489 2.489 0 013.216-1.453zM13.536 24.25s-3.221 1.215-5.799 0L5 26.032s4.913 1.701 8.537-1.782z"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M17.242 20.12l-1.289-.405c-1.047-.325-2.094-.892-2.9-1.62l-1.53-1.377-3.543-.486 3.221 2.591c1.047.81 2.256 1.296 3.544 1.377l2.497-.08zm0 0l1.127 4.373c.162.486.725.486 1.128.243l1.45-1.944a1.948 1.948 0 011.933-.648l5.154 1.215m-14.416.891l7.007-1.134m1.771 4.455l5.477-4.05 1.289 3.97c.08.323.322.566.564.728l2.818 1.782M9.2 9.856a2.254 2.254 0 013.14.081l.564.486c.886.891 2.416.81 3.222-.08l.161-.162c.886-.973 2.416-.973 3.383 0 .886.971 2.496.971 3.382 0l.08-.081a2.282 2.282 0 013.222-.163l.725.73c.886.81 2.175.81 3.06 0l.967-.892c.886-.81 2.255-.81 3.06 0m-20.87 4.722c.886.89 2.416.81 3.221-.081l.161-.162c.886-.972 2.416-.972 3.383 0 .886.972 2.497.972 3.383 0l.08-.081a2.282 2.282 0 013.222-.162l.724.729c.886.81 2.175.81 3.06 0m1.02 5.422a2.511 2.511 0 011.457 3.228 2.489 2.489 0 01-3.215 1.453 2.511 2.511 0 01-1.459-3.228 2.489 2.489 0 013.216-1.453zM13.536 24.25s-3.221 1.215-5.799 0L5 26.032s4.913 1.701 8.537-1.782z"
        ></path>
      </svg>
    );
  } else if (type === "snorkeling") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M30.166 8.332s2.286 6.628 2.286 7.193c.081.566-.327 1.051-.898 1.132-.327 0-.735-.08-.898-.404m-14.861 4.445l-1.307.404c-1.061.324-2.204.485-3.347.324l-2.042-.243L5.833 22.8l4.083.323a6.802 6.802 0 003.756-.89l2.123-1.535zm0 0l3.43 2.91c.407.323.897 0 1.06-.404l.164-2.425c.082-.727.49-1.374 1.225-1.616l5.062-1.94m-11.594 8.89l5.144-4.93m4.082 2.667l2.205-6.385 3.348 2.506c.245.162.571.242.898.242l3.348-.161M9.834 9.706a2.311 2.311 0 013.185.08l.571.486c.898.889 2.45.808 3.266-.081l.164-.162c.898-.97 2.45-.97 3.429 0 .898.97 2.531.97 3.43 0l.081-.08a2.34 2.34 0 013.266-.162M13.02 13.239l.571.646c.898.89 2.45.809 3.266-.08l.164-.162c.898-.97 2.45-.97 3.429 0 .898.97 2.531.97 3.43 0l.081-.081m6.834 1.024a2.486 2.486 0 01-1.826 3.018 2.514 2.514 0 01-3.032-1.835 2.486 2.486 0 011.826-3.017 2.514 2.514 0 013.032 1.834zM15.06 26.194s-1.96 2.83-4.818 3.233L9.018 32.5c-.082 0 4.98-1.374 6.042-6.305z"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M30.166 8.332s2.286 6.628 2.286 7.193c.081.566-.327 1.051-.898 1.132-.327 0-.735-.08-.898-.404m-14.861 4.445l-1.307.404c-1.061.324-2.204.485-3.347.324l-2.042-.243L5.833 22.8l4.083.323a6.802 6.802 0 003.756-.89l2.123-1.535zm0 0l3.43 2.91c.407.323.897 0 1.06-.404l.164-2.425c.082-.727.49-1.374 1.225-1.616l5.062-1.94m-11.594 8.89l5.144-4.93m4.082 2.667l2.205-6.385 3.348 2.506c.245.162.571.242.898.242l3.348-.161M9.834 9.706a2.311 2.311 0 013.185.08l.571.486c.898.889 2.45.808 3.266-.081l.164-.162c.898-.97 2.45-.97 3.429 0 .898.97 2.531.97 3.43 0l.081-.08a2.34 2.34 0 013.266-.162M13.02 13.239l.571.646c.898.89 2.45.809 3.266-.08l.164-.162c.898-.97 2.45-.97 3.429 0 .898.97 2.531.97 3.43 0l.081-.081m6.834 1.024a2.486 2.486 0 01-1.826 3.018 2.514 2.514 0 01-3.032-1.835 2.486 2.486 0 011.826-3.017 2.514 2.514 0 013.032 1.834zM15.06 26.194s-1.96 2.83-4.818 3.233L9.018 32.5c-.082 0 4.98-1.374 6.042-6.305z"
        ></path>
      </svg>
    );
  } else if (type === "kitesurfing") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M20.306 14.201l-1.185 2.12c-.339.593-1.016 1.102-1.693 1.187l-2.795.593c-.507.085-.93.424-1.27.763l-2.032 2.628c-.677.848-.508 2.034.254 2.713l2.625 2.204c.423.339.593.847.508 1.356l-.17 1.865m6.012-8.223h-3.64a2.44 2.44 0 01-2.202-1.356l-.762-1.696m5.419.34l3.048 5.17m-9.398 5.68l.17-1.102c.084-.508 0-.932-.17-1.356l-1.355-2.798M25.471 7.504s3.98.51 3.81 4.832c-.084 3.222-5.25 6.02-2.455 7.8 3.302 2.034 9.652-12.038-1.355-12.632zm0 0c-2.625-.17-6.011 4.578-3.217 5.171 1.778.34 3.302-3.73 5.842-3.56m-11.51 4.62a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm2.874 16.658c-3.218-1.102-9.907-.509-11.515.17-.593.254-.593 1.186 0 1.356 3.132 1.017 9.821.509 11.43-.085.762-.17.762-1.187.085-1.441z"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M20.306 14.201l-1.185 2.12c-.339.593-1.016 1.102-1.693 1.187l-2.795.593c-.507.085-.93.424-1.27.763l-2.032 2.628c-.677.848-.508 2.034.254 2.713l2.625 2.204c.423.339.593.847.508 1.356l-.17 1.865m6.012-8.223h-3.64a2.44 2.44 0 01-2.202-1.356l-.762-1.696m5.419.34l3.048 5.17m-9.398 5.68l.17-1.102c.084-.508 0-.932-.17-1.356l-1.355-2.798M25.471 7.504s3.98.51 3.81 4.832c-.084 3.222-5.25 6.02-2.455 7.8 3.302 2.034 9.652-12.038-1.355-12.632zm0 0c-2.625-.17-6.011 4.578-3.217 5.171 1.778.34 3.302-3.73 5.842-3.56m-11.51 4.62a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm2.874 16.658c-3.218-1.102-9.907-.509-11.515.17-.593.254-.593 1.186 0 1.356 3.132 1.017 9.821.509 11.43-.085.762-.17.762-1.187.085-1.441z"
        ></path>
      </svg>
    );
  } else if (type === "swimming") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M11.667 18.737l4.224-3.449c.69-.544 1.552-.363 1.983.272l3.793 4.858m-15-.176a2.338 2.338 0 013.344.09l.686.626c.943.984 2.572.895 3.43-.09l.171-.179c.943-1.073 2.572-1.073 3.601 0 .944 1.074 2.658 1.074 3.602 0l.085-.09c.858-1.162 2.401-1.162 3.43-.268l.772.806c.943.895 2.315.895 3.258 0l1.03-.985c.942-.894 2.4-.894 3.257 0m-22.465 5.576c.943.985 2.573.895 3.43-.09l.171-.178c.944-1.074 2.573-1.074 3.602 0 .943 1.074 2.658 1.074 3.601 0l.086-.09a2.368 2.368 0 013.43-.179l.771.806c.944.895 2.316.895 3.259 0m-2.551-9.329a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M11.667 18.737l4.224-3.449c.69-.544 1.552-.363 1.983.272l3.793 4.858m-15-.176a2.338 2.338 0 013.344.09l.686.626c.943.984 2.572.895 3.43-.09l.171-.179c.943-1.073 2.572-1.073 3.601 0 .944 1.074 2.658 1.074 3.602 0l.085-.09c.858-1.162 2.401-1.162 3.43-.268l.772.806c.943.895 2.315.895 3.258 0l1.03-.985c.942-.894 2.4-.894 3.257 0m-22.465 5.576c.943.985 2.573.895 3.43-.09l.171-.178c.944-1.074 2.573-1.074 3.602 0 .943 1.074 2.658 1.074 3.601 0l.086-.09a2.368 2.368 0 013.43-.179l.771.806c.944.895 2.316.895 3.259 0m-2.551-9.329a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        ></path>
      </svg>
    );
  } else if (type === "openWaterSwimming") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M20.417 11.837v2.84m-2.648-5.509h-2.818m10.932 0H23.15m1.11 3.874l-1.964-1.98m-5.722 1.98l1.964-1.98m4.526-1.894c0 1.464-1.11 2.583-2.562 2.583-1.452 0-2.562-1.206-2.562-2.583m-6.273 13.735l4.224-3.448c.69-.544 1.552-.363 1.983.272l3.793 4.858m-15-.176a2.338 2.338 0 013.344.09l.686.626c.943.984 2.572.894 3.43-.09l.171-.179c.943-1.074 2.572-1.074 3.601 0 .944 1.074 2.658 1.074 3.602 0l.085-.09c.858-1.163 2.401-1.163 3.43-.268l.772.805c.943.895 2.315.895 3.258 0l1.03-.984c.942-.895 2.4-.895 3.257 0m-22.465 5.576c.943.984 2.573.895 3.43-.09l.171-.178c.944-1.074 2.573-1.074 3.602 0 .943 1.073 2.658 1.073 3.601 0l.086-.09a2.368 2.368 0 013.43-.179l.771.805c.944.895 2.316.895 3.259 0m-2.551-9.328a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M20.417 11.837v2.84m-2.648-5.509h-2.818m10.932 0H23.15m1.11 3.874l-1.964-1.98m-5.722 1.98l1.964-1.98m4.526-1.894c0 1.464-1.11 2.583-2.562 2.583-1.452 0-2.562-1.206-2.562-2.583m-6.273 13.735l4.224-3.448c.69-.544 1.552-.363 1.983.272l3.793 4.858m-15-.176a2.338 2.338 0 013.344.09l.686.626c.943.984 2.572.894 3.43-.09l.171-.179c.943-1.074 2.572-1.074 3.601 0 .944 1.074 2.658 1.074 3.602 0l.085-.09c.858-1.163 2.401-1.163 3.43-.268l.772.805c.943.895 2.315.895 3.258 0l1.03-.984c.942-.895 2.4-.895 3.257 0m-22.465 5.576c.943.984 2.573.895 3.43-.09l.171-.178c.944-1.074 2.573-1.074 3.602 0 .943 1.073 2.658 1.073 3.601 0l.086-.09a2.368 2.368 0 013.43-.179l.771.805c.944.895 2.316.895 3.259 0m-2.551-9.328a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        ></path>
      </svg>
    );
  } else if (type === "standupPaddling") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M20.77 15l.063 5.636L20 30.833m2.5 0l-1.667-10m-.093 2.978l-3.288-3.233c-.433-.369-.433-1.108-.087-1.477l3.03-3.88c.172-.277.605-.277.865-.092l3.807 3.233a.948.948 0 00.693.277l4.24.831L7.5 30h2.683c.346 0 .778-.185 1.038-.462l.838-1.672M23.333 10a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm8.419 21.452c-4.374-1.105-19.816-.51-21.959.085-.892.255-.803 1.105.09 1.275 4.284.935 19.727.425 21.958-.085.893-.085.893-1.02-.089-1.275z"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M20.77 15l.063 5.636L20 30.833m2.5 0l-1.667-10m-.093 2.978l-3.288-3.233c-.433-.369-.433-1.108-.087-1.477l3.03-3.88c.172-.277.605-.277.865-.092l3.807 3.233a.948.948 0 00.693.277l4.24.831L7.5 30h2.683c.346 0 .778-.185 1.038-.462l.838-1.672M23.333 10a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm8.419 21.452c-4.374-1.105-19.816-.51-21.959.085-.892.255-.803 1.105.09 1.275 4.284.935 19.727.425 21.958-.085.893-.085.893-1.02-.089-1.275z"
        ></path>
      </svg>
    );
  } else if (type === "surfing") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M30.402 16.553l-2.737-2.077c-.498-.415-1.161-.581-1.825-.581h-5.724l-3.898 1.828c-.83.415-1.327 1.246-1.245 2.16l.166 1.91m5.06-5.732l2.572 4.154c.415.665.663 1.33.746 2.077l.83 5.982m-1.244-6.979l-2.987.914c-.746.25-1.244 1.08-1.078 1.828l.166.997m5.143 8.806c.664.167 1.493.333 2.157.416 1.244.249 2.073-1.163 1.41-2.16-1.908-2.991-8.627-8.142-17.254-9.72-.746-.167-1.327.664-.995 1.328 1.244 2.327 5.723 6.896 12.11 9.305.084 0 .166.084.332.084m.083-1.662c-.249.914-.415 3.074 3.235 4.32 0 0-1.99-1.744-1.327-3.655m-1.56-20.514c0 1.38-1.118 2.5-2.497 2.5a2.498 2.498 0 01-2.496-2.5c0-1.38 1.117-2.5 2.496-2.5a2.498 2.498 0 012.496 2.5z"
        ></path>
      </svg>
    );
  } else if (type === "windsurfing") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M19.212 18.365h12.454m-14.382.334l-5.464 1.588a.627.627 0 00-.402.585l.723 3.51c.08.418.482.752.884.752l3.053.168c.563.083.965.501 1.045 1.086v3.427m2.812 0l-.08-3.343M30.22 8.67l-3.937 3.26m3.294 1.671l-2.892 2.34m-6.83 10.949s-.563-15.378 6.99-19.39l3.375 1.254s-1.205 5.6.08 9.61L19.856 26.89zm-6.102-12.17a2.557 2.557 0 01-1.847 3.053 2.446 2.446 0 01-2.997-1.815 2.557 2.557 0 011.846-3.054 2.447 2.447 0 012.998 1.815zm15.905 15.93c-3.938-1.086-17.838-.5-19.767.084-.803.25-.723 1.087.08 1.254 3.857.92 17.758.418 19.767-.084.804-.167.804-1.003-.08-1.253z"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M19.212 18.365h12.454m-14.382.334l-5.464 1.588a.627.627 0 00-.402.585l.723 3.51c.08.418.482.752.884.752l3.053.168c.563.083.965.501 1.045 1.086v3.427m2.812 0l-.08-3.343M30.22 8.67l-3.937 3.26m3.294 1.671l-2.892 2.34m-6.83 10.949s-.563-15.378 6.99-19.39l3.375 1.254s-1.205 5.6.08 9.61L19.856 26.89zm-6.102-12.17a2.557 2.557 0 01-1.847 3.053 2.446 2.446 0 01-2.997-1.815 2.557 2.557 0 011.846-3.054 2.447 2.447 0 012.998 1.815zm15.905 15.93c-3.938-1.086-17.838-.5-19.767.084-.803.25-.723 1.087.08 1.254 3.857.92 17.758.418 19.767-.084.804-.167.804-1.003-.08-1.253z"
        ></path>
      </svg>
    );
  } else {
    return null;
  }
};

IconActivityWater.defaultProps = { className: null };

const { string } = PropTypes;

IconActivityWater.propTypes = {
  className: string,
};

export default IconActivityWater;
