import axios from 'axios';
import { storableError } from '../util/errors';

// ================ Action types ================ //
export const GET_PRIVACY_POLICY_REQUEST = 'app/PrivacyPolicy/GET_PRIVACY_POLICY_REQUEST';
export const GET_PRIVACY_POLICY_SUCCESS = 'app/PrivacyPolicy/GET_PRIVACY_POLICY_SUCCESS';
export const GET_PRIVACY_POLICY_ERROR = 'app/PrivacyPolicy/GET_PRIVACY_POLICY_ERROR';

const initialState = {
  privacyPolicyText: {},
  getPrivacyPolicyInProgress: false,
  getPrivacyPolicySuccess: false,
  getPrivacyPolicyError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_PRIVACY_POLICY_REQUEST:
      return {
        ...state,
        getPrivacyPolicyInProgress: true,
        getPrivacyPolicySuccess: false,
        getPrivacyPolicyError: false,
      };
    case GET_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        getPrivacyPolicyInProgress: false,
        getPrivacyPolicySuccess: true,
        getPrivacyPolicyError: false,
        privacyPolicyText: payload,
      };
    case GET_PRIVACY_POLICY_ERROR:
      return {
        ...state,
        getPrivacyPolicyInProgress: false,
        getPrivacyPolicySuccess: false,
        getPrivacyPolicyError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //
export const getPrivacyPolicyRequest = () => ({ type: GET_PRIVACY_POLICY_REQUEST });
export const getPrivacyPolicySuccess = (data) => {
  return ({type: GET_PRIVACY_POLICY_SUCCESS, payload: data})
};
export const getPrivacyPolicyError = error => ({ type: GET_PRIVACY_POLICY_ERROR, payload: error, error: true });


/* ================ Thunks ================ */



const API_URL = process.env.REACT_APP_API_URL;


export const getPrivacyPolicyText = (lang, type) => (dispatch) => {
  dispatch(getPrivacyPolicyRequest());

  const params = {
    lang,
    type
  };

  return axios.get(`${API_URL}/api/v1/policy`, {params: params})
    .then(function (response) {
      dispatch(getPrivacyPolicySuccess(response.data));
      return response.data;
    })
    .catch(e => {
      dispatch(getPrivacyPolicyError(storableError(e)));
    });
};

