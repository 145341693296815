import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import css from './HelpInfo.module.css';
import { IconHelpInfo } from '../../components';
import IconArrowDown from '../IconArrowDown/IconArrowDown';
import IconsItem from '../IconsItem/IconsItem';

const HelpInfo = props => {
  const { format, iconClass } = props;

  if (format === 'desktop') {
    return (
      <div className={css.marginContext}>
        <IconsItem cat='help'/>
        {/*<IconHelpInfo className={iconClass}/>*/}
        <FormattedMessage id="TopbarDesktop.helpInfo"/>
        <IconArrowDown/>
      </div>
    )
  }
};

const { oneOf, string } = PropTypes;

HelpInfo.defaultProps = {
  className: null,
  format: 'desktop',
};

HelpInfo.propTypes = {
  className: string,
  format: oneOf([ 'desktop', 'mobile' ]),
};

export default HelpInfo;
