import React from 'react';
import { IconCategory } from '../index';

class RatingBlock extends React.Component{
  render() {
    const {rating} = this.props;
    const stars = [];
    for (let i = 1; i<=5; i++) {
      if (i <= rating) {
        stars.push('full');
      }
      else if (Math.floor(rating) !== rating && i - 1 < rating){
        stars.push('half');
      }
      else stars.push('empty');
    }
    return (
      <>
        {stars.map((e, i)=> <IconCategory key={i} cat={e} /> )}
      </>
    );
  }
}

export default RatingBlock;
