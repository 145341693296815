import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import book_1 from './img/book_1.png'
import book_2 from './img/book_2.png'
import book_3 from './img/book_3.png'

import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionHowItWorks.titleLineOne" />
      </div>
      <div className={css.blockQuote}>
        <span className={css.quoteText}>
          <FormattedMessage id="SectionHowItWorks.quoteText" />
        </span>
        <div>
          <span className={css.quoteName}>
            <FormattedMessage id="SectionHowItWorks.quoteName" />
          </span>
          <span className={css.quoteNameAuthor}>
            <FormattedMessage id="SectionHowItWorks.quoteNameAuthor" />
          </span>
        </div>
      </div>

      <div className={css.sectionBlockItem}>
        <img className={css.imgItem} src={book_1} alt='first'/>
        <div className={css.textBlockItem}>
          <span className={css.imgItemText}>01</span>
          <p className={css.titleItem}>
            <FormattedMessage id="SectionHowItWorks.titleFirstText" />
          </p>
          <p className={css.textItem}>
            <FormattedMessage id="SectionHowItWorks.firstText" />
          </p>
        </div>
        <div className={css.textBlockItemSmall}>
          <span className={css.imgItemTextSmall}>01</span>
          <p className={css.textItemSmall}>
            <FormattedMessage id="SectionHowItWorks.titleFirstText" />
          </p>
        </div>
      </div>

      <div className={css.sectionBlockItem}>
        <div className={css.textBlockItem}>
          <span className={css.imgItemText}>02</span>
          <p className={css.titleItem}>
            <FormattedMessage id="SectionHowItWorks.titleSecondText" />
          </p>
          <p className={css.textItem}>
            <FormattedMessage id="SectionHowItWorks.secondText" />
          </p>
        </div>
        <img className={css.imgItem} src={book_2} alt='second'/>
        <div className={css.textBlockItemSmall}>
          <span className={css.imgItemTextSmall}>02</span>
          <p className={css.textItemSmall}>
            <FormattedMessage id="SectionHowItWorks.titleSecondText" />
          </p>
        </div>
      </div>

      <div className={css.sectionBlockItem}>
        <img className={css.imgItem} src={book_3} alt='third'/>
        <div className={css.textBlockItem}>
          <span className={css.imgItemText}>03</span>
          <p className={css.titleItem}>
            <FormattedMessage id="SectionHowItWorks.titleThirdText" />
          </p>
          <p className={css.textItem}>
            <FormattedMessage id="SectionHowItWorks.thirdText" />
          </p>
        </div>
        <div className={css.textBlockItemSmall}>
          <span className={css.imgItemTextSmall}>03</span>
          <p className={css.textItemSmall}>
            <FormattedMessage id="SectionHowItWorks.titleThirdText" />
          </p>
        </div>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
