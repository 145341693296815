import React, { useState } from 'react';
// import LinesEllipsis from 'react-lines-ellipsis';
import css from './ListingPage.module.css';
import { IconCategory } from '../../components';
import {FormattedMessage} from "../../util/reactIntl";

const SectionSpotlight = ({ listing }) => {
  // const [useEllipsis, setUseEllipsis] = useState(true);
  const [showAmenities, setShowAmenities] = useState(true);

  const {
    skill_levels: publicDataSkillLevels = [],
    fitness_levels: publicDataFitnessLevels = [],
    badges: publicDataBadges = [],
  } = listing.attributes.publicData;

  const [arrAmenities, setArrAmenities] = useState(publicDataBadges.slice(0,5));

  // const onTextClick = (e) => {
  //   e.preventDefault();
  //   setUseEllipsis(false)
  // };

  // const summary = listing.attributes.publicData ? props.listing.attributes.publicData.summary : '';

  // TODO include in "What`s included" or show only title
  // const languages = listing.attributes.publicData && listing.attributes.publicData.Languages ? listing.attributes.publicData.Languages : [];
   const handleShowMore = () => {
    setArrAmenities(publicDataBadges)
    setShowAmenities(false)
  }

  return (
    <div className={css.sectionSpotlight}>
      <h1 className={css.spotlightTitle}>
        <FormattedMessage id="SectionSpotlight.spotlightTitle"/>
      </h1>
      <div className={css.desc}>
        {/* TODO: summary */}
        {/* {useEllipsis
          ? <div onClick={(e) => onTextClick(e)} tabIndex='0'>
              <LinesEllipsis
                text={summary}
                style={{ whiteSpace: 'pre-wrap' }}
                maxLine='6'
                ellipsis={<span className={css.readMoreButton}> Read More</span>}
              />
            </div>
          : summary
        } */}
      </div>
      <div className={css.spotlightLists}>
        <div className={css.otherList}>
          {publicDataSkillLevels.length ? <div className={css.otherListLi}>
            <div className={css.value}>
              <span title={'Skill level'} className={css.key}>
                <FormattedMessage id="SectionSpotlight.skillLevel"/>
              </span>
              <div className="App">
                <img src={publicDataSkillLevels[0].icon} alt="React Logo" />
              </div>
              {/* <IconCategory cat={publicDataSkillLevels.length && publicDataSkillLevels[0].title} /> */}
              <span className={css.valueKey}>{publicDataSkillLevels[0].title}</span>
            </div>
          </div> : null}
          {publicDataFitnessLevels.length ? <div className={css.otherListLi}>
            <div className={css.value}>
              <span title={'Fitness Level'} className={css.key}>
                 <FormattedMessage id="SectionSpotlight.fitnessLevel"/>
              </span>
              <div className="App">
                <img src={publicDataFitnessLevels[0].icon} alt="React Logo" />
              </div>
              {/* <IconCategory cat={publicDataFitnessLevels.length && publicDataFitnessLevels[0].title} /> */}
              <span className={css.valueKey}>{publicDataFitnessLevels[0].title}</span>
            </div>
          </div> : null}
        </div>
        <h2 className={css.titleAmenities}><FormattedMessage id="SectionSpotlight.includedOption"/></h2>
        <ul className={css.amenities}>
          {arrAmenities.length > 0 && arrAmenities.map(({ title, icon }, i) => (
            <li key={title + i} className={css.amenity}>
              <img src={icon} />
              {/* <IconCategory cat={e.title} /> */}
              <span className={css.amenityText}>{title}</span>
            </li>
          ))}
        </ul>
        {arrAmenities.length > 5 && showAmenities &&
          <div className={css.buttonShowMore}
             onClick={handleShowMore}>
            <FormattedMessage id="SectionSpotlight.showAll"/>
          </div>}
      </div>
    </div>
  );
};

export default SectionSpotlight;
