import React, { Component } from 'react';
import { string } from 'prop-types';
import {
  intlShape,
  injectIntl,
  FormattedMessage
} from '../../util/reactIntl';
import classNames from 'classnames';
import { ensureListing, dayMounthString } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { NamedLink } from '../../components';
import { ListingFavouritesForm } from '../../forms';
import config from '../../config';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import {ResponsiveImage} from '../';

import css from './FavoriteListingCard.module.css';
import IconHostel from '../IconHostel/IconHostel';


class ListingImage extends Component {

  render() {
    return <ResponsiveImage {...this.props} />;
  }
}

const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

const MIN_LENGTH_FOR_LONG_WORDS = 10;

export const FavoriteListingCardComponent = props => {
  const {
    className,
    rootClassName,
    listing,
    onUpdateProfile,
    currentList,
    isAuthenticated,
    updateLists,
  } = props;
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  const save = async values => await sleep(1000)

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);

  const dateInterval = listing && listing.availabilities && listing.availabilities[0]
  const id = currentListing.listingId;
  const title = currentListing.title;
  const locationName = currentListing.locationName;
  const init = currentList ? currentList.filter(l => l.listingId === id) : [];
  const slug = createSlug(title);

  const firstImage = currentListing.listingImages.length ? currentListing.listingImages[0] : null;
  const images = currentListing.listingImages.map(({url}) => url);

  return (
    <div className={classes}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <div className={css.shareLike}>
            <ListingFavouritesForm
              className={css.checkbox}
              isAuthenticated={isAuthenticated}
              initialValues={{
                [`favourite_${id}`]: init[0]
                  ? init[0][`favourite_${id}`]
                  : false
              }}
              currentList={currentList}
              onSubmit={values => {
                save(values);
                let currentListingId = Object.keys(values)[0];
                let currentListingValue = values[`favourite_${id}`];
                currentListingId = currentListingId.replace('favourite_', '');
                const hasCurrentListing = currentList.filter(l => l.listingId === currentListingId).length > 0;
                if (hasCurrentListing && !currentListingValue) {
                  const removeCurrentListing = currentList.filter(l => l.listingId !== currentListingId);
                  onUpdateProfile({
                    publicData: {
                      listingFavourite: removeCurrentListing
                    }
                  });
                  updateLists()
                }
              }}
              onChange={values => {}}
              listingId={id}
            />

          </div>
          {firstImage ? <div style={{ backgroundImage: `url(${images[0]})` }} className={css.imagePub}/>
            : <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={['landscape-crop', 'landscape-crop2x']}
                sizes={cardRenderSizes}
              />
          }
        </div>
      </div>
      <div className={css.mainInfo}>
        <NamedLink params={{ id, slug }} name="ListingPage" className={css.title}>
          <>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </>
        </NamedLink>
        {dateInterval &&
          <div className={css.rowHostelDate}>
            <span className={css.dateInfoRow}>
              <FormattedMessage
                id="SectionFavorite.dateInterval"
                values={{
                  start: dayMounthString(dateInterval.date_from),
                  stop: dayMounthString(dateInterval.date_to)
              }} />
            </span>
            <div className={css.dateInfoRow}>
              <span>
                <FormattedMessage id="SectionFavorite.hostedBy" />
              </span>
              <IconHostel className={css.iconHostel}/>
              <span className={css.locationName}>{locationName}</span>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

FavoriteListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  categoriesConfig: config.custom.categories,
  amenitiesConfig: config.custom.amenities,
};

FavoriteListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(FavoriteListingCardComponent);
