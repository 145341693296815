import React, { Component } from 'react';
import css from './ItineraryBlock.module.css';
import ItineraryMap from './ItineraryMap';
import ItineraryByDay from './components/ItineraryByDay';

class ItineraryBlock extends Component {
  constructor(props) {
    super(props);

    let mapReattachmentCount = 0;

    if (typeof window !== 'undefined') {
      if (window.mapReattachmentCount) {
        mapReattachmentCount = window.mapReattachmentCount;
      } else {
        window.mapReattachmentCount = 0;
      }
    }

    this.state = { infoCardOpen: null, mapReattachmentCount, activePlace: null };

    this.setActivePlace = this.setActivePlace.bind(this);
  }

  setActivePlace(place){
    this.setState({
      activePlace: place
    })
  }

  render() {
    const { activePlace } = this.state;

    const {
      listing: {
        attributes: {
          publicData: {
            itinerary: publicDataItinerary = [],
          }
        }
      }
    } = this.props;

    const places = publicDataItinerary.map(({ locations, order }) => {
      const coords = locations.map(({ location, order }) => ({ location, order }));
      return { order, locations: coords };
    });

    return (
      <div className={css.itineraryHolder}>
        <ItineraryMap
          places={places}
          mapComponentRefreshToken={this.state.mapReattachmentCount}
          infoCardOpen={this.state.infoCardOpen}
          activePlace={activePlace}
        />
        <ItineraryByDay itinerary={publicDataItinerary} setActivePlace={this.setActivePlace} />
      </div>
    );
  }
}

export default ItineraryBlock;
