import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaTwitter.module.css';

const IconSocialMediaTwitter = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="31" height="27" viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.66957 2.56889C1.85615 2.19862 2.2293 2.10605 2.50916 2.47632C7.08028 8.21547 12.9574 8.77087 14.7299 8.86344C15.0098 8.86344 15.1963 8.58574 15.1963 8.30804C15.1031 7.0121 15.1031 3.77226 18.1816 1.92092C21.9131 -0.208118 24.805 1.92092 25.5513 2.66146C25.6446 2.75402 25.8312 2.84659 26.0178 2.75402C26.5775 2.56889 27.6037 2.19862 28.4433 1.82835C28.9097 1.64322 29.2828 2.19862 29.003 2.56889C28.6298 3.03172 28.2567 3.40199 27.8835 3.77226C27.6037 4.04996 27.697 4.60536 28.1634 4.60536C28.7231 4.60536 29.1896 4.69793 29.5627 4.69793C30.0292 4.69793 30.1224 5.25333 29.8426 5.53103C29.0963 6.08643 28.4433 6.54927 28.0701 6.7344C27.8835 6.82697 27.7902 7.0121 27.8835 7.19723C28.1634 8.77087 28.9097 16.2688 20.7003 22.1931C12.6776 28.0248 5.02794 25.8032 1.66957 24.3221C1.20313 24.137 1.29642 23.489 1.85615 23.3964C5.68096 23.1187 7.64001 21.9154 8.4796 21.1748C8.75947 20.9897 8.66618 20.5269 8.38631 20.3417C8.38631 20.3417 5.96082 20.3417 4.18835 17.935C3.72191 17.3796 4.09506 16.4539 4.84137 16.4539C5.30781 16.4539 5.77425 16.3614 6.33398 16.1762C6.33398 16.1762 2.04272 15.158 1.29642 11.3627C1.01655 10.7148 1.76286 10.1594 2.41588 10.4371C2.88232 10.5296 3.53533 10.7148 4.18835 10.7148C4.18835 10.7148 -0.755919 7.8452 1.66957 2.56889Z" stroke="black" strokeLinecap="10" strokeLinecap="round" strokeLinecap="round"/>
    </svg>
  );
};

IconSocialMediaTwitter.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaTwitter.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaTwitter;
