import React, { useRef, useState } from 'react';
import css from './ListingPage.module.css';
import { FormattedMessage } from '../../util/reactIntl';

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TwitterShareButton,

  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  TwitterIcon,
} from "react-share";
import classNames from 'classnames';
import SharedIcon from './SharedIcon';
import config from "../../config";

const SharedLink = () => {

  const currenPath = typeof window !== 'undefined' && window.location

  const originPath = currenPath.hostname === "localhost"
    ? 'https://staging.activebreak.com'
    : window.location.origin
  const pathname = config.locale === 'en'
    ? currenPath.pathname
    : currenPath.pathname.substr(3)

  const activeUrl = `${originPath}${pathname}`

  const contentBlock = (
    <div className={classNames(css.buttonShared, css.activeShared)}>
      <FormattedMessage id='ListingCard.buttonShare' />
    </div>
  )

  const [ active, setActive ] = useState({})
  const textAreaRef = useRef(null);


  const copyLink = (e) => {
    textAreaRef.current.select();
    document.execCommand('copy');
    e.target.focus();
  }

  const sharedList = [
    {
      label: "Facebook",
      icon: <FacebookIcon size={35} round={true} />,
      date: <FacebookShareButton url={activeUrl}>{contentBlock}</FacebookShareButton>
    },
    {
      label: "Twitter",
      icon: <TwitterIcon size={35} round={true} />,
      date: <TwitterShareButton url={activeUrl}>{contentBlock}</TwitterShareButton>
    },
    {
      label: "Whatsapp",
      icon: <WhatsappIcon size={35} round={true} />,
      date: <WhatsappShareButton url={activeUrl}>{contentBlock}</WhatsappShareButton>
    },
    {
      label: "Mail",
      icon: <EmailIcon size={35} round={true} />,
      date: <EmailShareButton url={activeUrl}>{contentBlock}</EmailShareButton>
    },
  ]

  return (
    <div className={css.contentSHaredModal}>
      <span className={css.modalSHareTitle}>
        <FormattedMessage id='ListingCard.shareTitle' />
      </span>
      <span className={css.modalSHareSubTitle}>
        <FormattedMessage id='ListingCard.shareSubTitle' />
      </span>
      <div className={css.contentUrlLink}>
        <input className={css.shadowInput} value={activeUrl} ref={textAreaRef}/>
        <div className={css.eyeShow} onClick={copyLink}>
          <SharedIcon className={css.iconEye} />
        </div>
      </div>
      <div className={css.sharedIconContainer}>
        { sharedList && sharedList.map( (el, index)=> (
          <div key={el.label} onClick={() => setActive(el)} className={classNames( css.sharedIconItem, {
            [css.activeBorderItemShared]: el.label === active.label,
            [css.itemMargin]: index+1 !== sharedList.length,
          })}>
            {el.icon}
            <span className={classNames(css.textSharedItem, {
              [css.activeText]: el.label === active.label
            })}>{el.label}</span>
          </div>
        ))}
      </div>
      { active && active.date
        ? active.date
        : (
            <div className={classNames(css.buttonShared, css.disabledShared)}>
              <FormattedMessage id='ListingCard.buttonShare' />
            </div>
          )
      }
    </div>
  );
};

export default SharedLink;
