import axios from 'axios';
import { storableError } from '../util/errors';
import config from '../config';
// import {addMarketplaceEntities} from "./marketplaceData.duck";
// import {searchListingsSuccess} from "../containers/SearchPage/SearchPage.duck";

// ================ Action types ================ //
export const GET_CATEGORIES_REQUEST = 'app/Invite/GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'app/Invite/GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'app/Invite/GET_CATEGORIES_ERROR';

export const BECOME_A_HOST_REQUEST = 'app/Invite/BECOME_A_HOST_REQUEST';
export const BECOME_A_HOST_SUCCESS = 'app/Invite/BECOME_A_HOST_SUCCESS';
export const BECOME_A_HOST_ERROR = 'app/Invite/BECOME_A_HOST_ERROR';

const configFilters = config.custom.configFilters;

// ================ Reducer ================ //

const initialState = {
  // availableCategories: [],
  getCategoriesError: null,
  getCategoriesInProgress: false,
  getCategoriesSuccess: false,

  becomeAhostInProgress: false,
  becomeAhostSuccess: false,
  becomeAhostError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case GET_CATEGORIES_REQUEST:
      return {
        ...state,
        getCategoriesSuccess: true,
        getCategoriesInProgress: null,
        getCategoriesError: null,
      };
    case GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        getCategoriesInProgress: false,
        getCategoriesSuccess: true,
        ...payload,
      };
    }
    case GET_CATEGORIES_ERROR:
      return {
        ...state,
        getCategoriesInProgress: false,
        getCategoriesSuccess: false,
        getCategoriesError: payload
      };


    case BECOME_A_HOST_REQUEST:
      return {
        ...state,
        becomeAhostInProgress: true,
        becomeAhostSuccess: null,
        becomeAhostError: null,
      };
    case BECOME_A_HOST_SUCCESS:
      return {
        ...state,
        becomeAhostInProgress: false,
        becomeAhostSuccess: payload,
      };
    case BECOME_A_HOST_ERROR:
      return {
        ...state,
        becomeAhostInProgress: false,
        becomeAhostSuccess: false,
        becomeAhostError: payload,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const getCategoriesRequest = () => ({ type: GET_CATEGORIES_REQUEST });
export const getCategoriesSuccess = data => ({ type: GET_CATEGORIES_SUCCESS, payload: data });
export const getCategoriesError = error => ({ type: GET_CATEGORIES_ERROR, payload: error, error: true });


export const handleBecomeAhostRequest = () => ({ type: BECOME_A_HOST_REQUEST });
export const handleBecomeAhostSuccess = (res) => ({ type: BECOME_A_HOST_SUCCESS, payload: res });
export const handleBecomeAhostError = error => ({ type: BECOME_A_HOST_ERROR, payload: error, error: true });


// ================ Thunks ================ //

// const API_URL = process.env.REACT_APP_API_URL;
const DEFAULT_LANGUAGE = 'de';

export const getCategories = () => async (dispatch, getState, sdk) => {
  dispatch(getCategoriesRequest());

  const lang = (typeof localStorage !== 'undefined' && localStorage.getItem('lang'))
    ? localStorage.getItem('lang')
    : DEFAULT_LANGUAGE;

  const listingParams = {
    page: 1,
    perPage: 100,
    // include: [ 'author', 'author.profileImage', 'images' ],
    'fields.listing': [ 'metadata' ],
    // 'fields.user': [ 'profile.displayName', 'profile.abbreviatedName' ],
    // 'fields.image': [ 'variants.landscape-crop', 'variants.landscape-crop2x', 'variants.square-small', 'variants.square-small2x', ],
    'limit.images': 1,
    meta_locale: lang,
  }

  try {
    const listings = await sdk.listings.query(listingParams);

    const dataForFilters = listings.data.data.reduce((filters, listing) => {
      const { metadata } = listing.attributes;
      const result = {};

      configFilters.forEach((filter) => {
        result[filter] = [...new Set([...(filters[filter] ?? []), ...(metadata[filter] ?? [])])];
      });

      return result;
    }, {});

    dispatch(getCategoriesSuccess(dataForFilters));
  } catch (err) {
    dispatch(getCategoriesError(storableError(err)));
  }

  // const currentCategory = []
  // const getCurrentCategory = () => sdk.listings
  //   .query(listingParams)
  //   .then(response => {
  //     const listings = response.data.data;

  //     const currentCategory = [];
  //     listings.forEach(({ attributes }) => {
  //       if (!!attributes.publicData.listing_languages.find(({ code }) => code === lang)) {
  //         attributes.publicData.categories.forEach(({ title }) => currentCategory.push(title));
  //       }
  //     });

  //     // response && response.data && response.data.data.map(el => {
  //     //   if(el.attributes.publicData.categories && el.attributes.publicData.listing_languages.include(lang)) {
  //     //     el.attributes.publicData.categories.map(k => k.title ? currentCategory.push(k.title) : null)
  //     //   }
  //     // })

  //     dispatch(getCategoriesSuccess(Array.from(new Set(currentCategory))));
  //   })

  // return axios.get(`${API_URL}/api/v1/categories`, { params: { lang: lang } })
  //   .then(function(response) {
  //     getCurrentCategory()
  //     return response.data;
  //   })
  //   .catch(e => {
  //     dispatch(getCategoriesError(storableError(e)));
  //   });
};


export const becomeAhostSubmit = (values) => (dispatch) => {

  dispatch(handleBecomeAhostRequest());

  const prospectVerticalItems = !!values?.prospect_vertical && !!values?.prospect_vertical?.length &&
    values.prospect_vertical.map(item => (
      {
        // "name": "vertical",
        "name": "prospect_vertical",
        "value": item
      }
    ));

  const userInfoItems = {
    fields: [
      {
        "name": "firstname",
        "value": values?.firstname
      },
      {
        "name": "lastname",
        "value": values?.lastname
      },
      {
        "name": "company",
        "value": values?.company
      },
      {
        "name": "website",
        "value": values?.website
      },
      {
        "name": "email",
        "value": values?.email
      },
      {
        "name": "phone",
        "value": values?.phone
      },
    ],
  }

  const fields = userInfoItems.fields.concat(prospectVerticalItems)

  const context = {
    // "hutk": ":hutk", // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
    "pageUri": !!window && window?.location?.href,
    "pageName": values?.pageName || "Book activities everywhere | Activebreak",
  }

  const legalConsentOptions = {
    "consent": { // Include this object when GDPR options are enabled
      "consentToProcess": true,
      "text": "I agree to allow Example Company to store and process my personal data.",
      "communications": [
        {
          "value": !!values?.getInTouch?.length,
          "subscriptionTypeId": 999,
          "text": "I agree to receive marketing communications from Example Company."
        }
      ]
    }
  }

  return axios.post('https://api.hsforms.com/submissions/v3/integration/submit/5440848/946731d4-2d7f-47b4-b35e-aded6a7448e8', { fields, context, legalConsentOptions })
    .then(function (response) {
      dispatch(handleBecomeAhostSuccess(response.data));
      return response.data;
    })
    .then(response => {
      setTimeout(() => {
        dispatch(handleBecomeAhostSuccess(false));
      }, 5000)
    })
    .catch(e => {
      dispatch(handleBecomeAhostError(storableError(e)));
    });
};
