import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './MapPlaceLabel.module.css';

class MapPlaceLabel extends Component {
  shouldComponentUpdate(nextProps) {
    const hasSameActiveStatus = this.props.isActive === nextProps.isActive;
    const hasSameRefreshToken =
      this.props.mapComponentRefreshToken === nextProps.mapComponentRefreshToken;

    return !(hasSameActiveStatus && hasSameRefreshToken);
  }

  render() {
    const {
      className,
      rootClassName,
      onPlaceClicked,
      isActive,
      label,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const priceLabelClasses = classNames(css.priceLabel, { [ css.priceLabelActive ]: isActive });
    const caretClasses = classNames(css.caret, { [ css.caretActive ]: isActive });

    return (
      <button className={classes} onClick={() => onPlaceClicked(label)}>
        <div className={css.caretShadow}/>
        <div className={priceLabelClasses}>{label}</div>
        <div className={caretClasses}/>
      </button>
    );
  }
}

MapPlaceLabel.defaultProps = {
  className: null,
  rootClassName: null,
};

const { func, string } = PropTypes;

MapPlaceLabel.propTypes = {
  className: string,
  rootClassName: string,
  onPlaceClicked: func.isRequired,
};

export default MapPlaceLabel;
