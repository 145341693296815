import React from 'react';
import { Marker } from "react-google-maps";

const CurveMarker = ({ mapProjection, zoom, markers }) => {
  if (!mapProjection) return <div />;
  const curvature = 0.4;
  return markers.map((mm, i) => {
    const p11 = new google.maps.LatLng(mm.lat, mm.lng);
    const p22 = new google.maps.LatLng((markers[i+1] ?? markers[0]).lat, (markers[i+1] ?? markers[0]).lng);
    const p1 = mapProjection.fromLatLngToPoint(p11);
    const p2 = mapProjection.fromLatLngToPoint(p22);
    const e = new google.maps.Point(p2.x - p1.x, p2.y - p1.y); // endpoint
    const m = new google.maps.Point(e.x / 2, e.y / 2); // midpoint
    const o = new google.maps.Point(e.y, -e.x); // orthogonal
    const c = new google.maps.Point(m.x + curvature * o.x, m.y + curvature * o.y);
    const pathDef = "M 0,0 " + "q " + c.x + "," + c.y + " " + e.x + "," + e.y;
    const scale = 1 / Math.pow(2, -zoom);
    const symbol = {
      path: pathDef,
      scale: scale,
      strokeWeight: 2,
      fillColor: "none"
    };
    return <Marker position={p11} clickable={false} icon={symbol} zIndex={0} key={i}/>
  })
};

export default CurveMarker;
