import React, { Component } from 'react';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { withDimensions } from '../../util/contextHelpers';

import Handle from './Handle';
import Track from './Track';
import config from '../../config';
import { propTypes } from '../../util/types';
import { getCurrencySymbol } from '../../util/currency';
import css from './RangeSlider.module.css';

class RangeSliderComponent extends Component {
  constructor(props) {
    super(props);

    const { min, max, handles } = props;
    handles.forEach((h, index) => {
      if (h < min || h > max || (index < handles.length - 1 && h > handles[index + 1])) {
        //throw new Error(
        //  'RangeSlider error: handles need to be given in ascending order and they need to be within min and max values'
        //);
      }
    });

    this.state = { activeHandle: 0 };

    this.toPosition = this.toPosition.bind(this);
    this.toValue = this.toValue.bind(this);
    this.changeActive = this.changeActive.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toPosition(value) {
    const { dimensions, min, max } = this.props;
    const width = dimensions.width;
    const valueOffset = value - min;
    const scale = max - min;
    return Math.round((valueOffset / scale) * width);
  }

  toValue(position) {
    const { dimensions, min, max, step } = this.props;
    const width = dimensions.width;
    const scale = max - min;
    const value = Math.round((position / width) * scale) + min;
    return Math.ceil(value / step) * step;
  }

  changeActive(index) {
    this.setState({ activeHandle: index });
  }

  onChange(position, handleIndex) {
    this.props.onChange(Object.assign([...this.props.handles], { [handleIndex]: position }));
  }

  render() {
    const { handles, min, max, minV, maxV, custom, duration, currentUser } = this.props;

    return (
      <Track handles={handles} valueToPosition={this.toPosition} custom={custom}>
        {duration
          ? ((minV || minV === 0) && <span className={classNames(css.valueMin, { [css.custom]: !custom})}>{maxV}</span>)
          : ((minV || minV === 0) && <span className={classNames(css.valueMin, { [css.custom]: !custom})}>{getCurrencySymbol(currentUser)}{minV}</span>)
        }
        {/* Note: old version, last update 21.09.2021 */}
        {/* {duration ? custom && (maxV && <span className={css.valueMax}>{minV}</span>) : custom && (maxV && <span className={css.valueMax}>{getCurrencySymbol(this.props.currentUser)}{maxV}</span>)} */}
        {duration
          ? (<span className={classNames(css.valueMax, { [css.custom]: !custom})}>{minV}</span>)
          : (maxV && <span className={classNames(css.valueMax, { [css.custom]: !custom})}>{getCurrencySymbol(currentUser)}{maxV}</span>)
        }

        {/*{custom && ((minV || minV === 0) && <span className={css.valueMin}>{formatCurrencyMajorUnit(intl, currencyConfig.currency, minV)}</span>)}*/}

        {/*{custom && (maxV && <span className={css.valueMax}>{formatCurrencyMajorUnit(intl, currencyConfig.currency, maxV)}</span>)}*/}
        {handles.map((h, index) => {
          const classes = classNames({ [css.activeHandle]: this.state.activeHandle === index });
          return (
            <Handle
              key={index}
              className={classes}
              value={h}
              custom={custom}
              min={index === 0 ? min : handles[index - 1]}
              max={index === handles.length - 1 ? max : handles[index + 1]}
              valueToPosition={this.toPosition}
              positionToValue={this.toValue}
              changeActive={() => this.changeActive(index)}
              onChange={value => this.onChange(value, index)}
            />
          );
        })}
      </Track>
    );
  }
}

RangeSliderComponent.defaultProps = {
  min: 0,
  max: 1000,
  step: 1,
  custom: false,
};

RangeSliderComponent.propTypes = {
  handles: arrayOf(number),
  min: number,
  max: number,
  step: number,
  dimensions: shape({
    height: number.isRequired,
    width: number.isRequired,
  }).isRequired,
  custom: bool,
};

const RangeSliderComponentWithDimensions = withDimensions(RangeSliderComponent);

const RangeSlider = props => {
  const { rootClassName, className, custom, ...rest } = props;
  const classes = classNames(custom ? css.rootC : (rootClassName || css.root, className));
  // const classes = classNames(css.rootC);
  return (
    <div className={classes}>
      <RangeSliderComponentWithDimensions custom={custom}{...rest} />
    </div>
  );
};

RangeSlider.defaultProps = {
  rootClassName: null,
  className: null,
  maxV: null,
  minV: null,
  currencyConfig: config.currencyConfig,
  custom: false,
  duration: false
};

RangeSlider.propTypes = {
  rootClassName: string,
  className: string,
  maxV: number,
  minV: number,
  currencyConfig: propTypes.currencyConfig,
  custom: bool,
  duration: bool,
};

export default RangeSlider;
