import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaFacebook.module.css';

const IconSocialMediaFacebook = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width='30' height='30' viewBox='0 0 30 30' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29Z'
        stroke='black' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21 8.1C21 8.1 14.5 6.7 14.5 13.7V28.8' stroke='black' strokeMiterlimit='10'
            strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 16.3H19' stroke='black' strokeMiterlimit='10' strokeLinecap='round'
            strokeLinejoin='round' />
    </svg>
  );
};

IconSocialMediaFacebook.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaFacebook.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaFacebook;
