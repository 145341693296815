import PropTypes from "prop-types";
import React from "react";

const IconYourListing = () => (
  <svg style={{marginRight: 11}} width="17" height="28" viewBox="0 0 28 30" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 7H6M18 11H6M18 15H6M18 19H6M18 23H6M20.4475 29H3.43094C2.09392 29 1 27.9043 1 26.5652V3.43478C1 2.09565 2.09392 1 3.43094 1H20.5691C21.9061 1 23 2.09565 23 3.43478V26.687C22.8785 27.9043 21.7845 29 20.4475 29Z"
      stroke="#101010" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const {string} = PropTypes;

IconYourListing.defaultProps = {
  className: null,
  rootClassName: null,
};

IconYourListing.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconYourListing;
