import unionWith from 'lodash/unionWith';
import moment from "moment/moment";
import { storableError } from '../../util/errors';
import { addMarketplaceEntities, getListingsById } from '../../ducks/marketplaceData.duck';
import { updateProfile } from "../ProfileSettingsPage/ProfileSettingsPage.duck";
import { convertUnitToSubUnit, unitDivisor, minConvertPrice } from '../../util/currency';
import { formatDateStringToUTC, getExclusiveEndDate } from '../../util/dates';
import config from '../../config';
import { parse } from "../../util/urlHelpers";
import { fetchImages } from '../../util/api';

const isActualDay = currentDay => moment(new Date()).format('YYYY-MM-DD') <= currentDay;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/SearchPage/SET_INITIAL_STATE';

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_SIMILAR_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_SIMILAR_LISTINGS_REQUEST';
export const SEARCH_SIMILAR_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_SIMILAR_LISTINGS_SUCCESS';
export const SEARCH_SIMILAR_LISTINGS_ERROR = 'app/SearchPage/SEARCH_SIMILAR_LISTINGS_ERROR';

export const PREFERENCES_REQUEST = 'app/SearchPage/PREFERENCES_REQUEST';
export const PREFERENCES_SUCCESS = 'app/SearchPage/PREFERENCES_SUCCESS';
export const PREFERENCES_ERROR = 'app/SearchPage/PREFERENCES_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const FETCH_LISTINGS_IMAGES_REQUEST = 'app/SearchPage/FETCH_LISTINGS_IMAGES_REQUEST';
export const FETCH_LISTINGS_IMAGES_SUCCESS = 'app/SearchPage/FETCH_LISTINGS_IMAGES_SUCCESS';
export const FETCH_LISTINGS_IMAGES_ERROR = 'app/SearchPage/FETCH_LISTINGS_IMAGES_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  similarListingsSearchParams: null,
  searchInProgress: false,
  searchSuccess: false,
  similarListingsSearchInProgress: false,
  searchListingsError: null,
  similarListingsSearchListingsError: null,
  similarListingsSearchSuccess: false,
  currentPageResultIds: [],
  similarListingsResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  images: [],
  imagesError: null,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchSuccess: false,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        searchSuccess: true,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchSuccess: false, searchListingsError: payload };


    case SEARCH_SIMILAR_LISTINGS_REQUEST:
      return {
        ...state,
        similarListingsSearchParams: payload.searchParams,
        similarListingsSearchInProgress: true,
        similarListingsSearchSuccess: false,
        similarListingsSearchListingsError: null,
      };
    case SEARCH_SIMILAR_LISTINGS_SUCCESS:
      return {
        ...state,
        similarListingsResultIds: resultIds(payload.data),
        similarListingsSearchSuccess: true,
        similarListingsSearchInProgress: false,
      };
    case SEARCH_SIMILAR_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, similarListingsSearchInProgress: false, similarListingsSearchSuccess: false, similarListingsSearchListingsError: payload };


    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };

    case FETCH_LISTINGS_IMAGES_REQUEST:
      return { ...state, imagesError: null };
    case FETCH_LISTINGS_IMAGES_SUCCESS:
      return { ...state, images: payload };
    case FETCH_LISTINGS_IMAGES_ERROR:
      return { ...state, imagesError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});


export const searchSimilarListingsRequest = similarListingsSearchParams => ({
  type: SEARCH_SIMILAR_LISTINGS_REQUEST,
  payload: { similarListingsSearchParams },
});

export const searchSimilarListingsSuccess = response => ({
  type: SEARCH_SIMILAR_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchSimilarListingsError = e => ({
  type: SEARCH_SIMILAR_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchListingsImagesRequest = () => ({ type: FETCH_LISTINGS_IMAGES_REQUEST });
export const fetchListingsImagesSuccess = payload => ({
  type: FETCH_LISTINGS_IMAGES_SUCCESS,
  payload,
});
export const fetchListingsImagesError = e => ({
  type: FETCH_LISTINGS_IMAGES_ERROR,
  error: true,
  payload: e,
});

export const fetchListingsImages = (listingsIds) => async (dispatch) => {
  dispatch(fetchListingsImagesRequest());

  try {
    const response = await fetchImages({ listingsIds: listingsIds.map(({ uuid }) => uuid ) });
    dispatch(fetchListingsImagesSuccess(response.images));

    return response.images;
  } catch(e) {
    dispatch(fetchListingsImagesError(storableError(e)));
  }
}

const filterListingByPrice = async ({ params, apiResponse, sdk, currentUser }) => {
  const { page, per_page, price, start, end, availability, ...rest } = params;
  const paginate = page;
  const perPage = per_page;

  if (!price) return apiResponse;

  const priceAsArray = price.split(',');
  const priceFrom = priceAsArray[0]/100;
  const priceTo = priceAsArray[1]/100;
  const totalPages = apiResponse.data.meta.totalPages;


  if (!priceFrom && !priceTo) return apiResponse;

  let totalListings = [];

  for (let i = 0; i < totalPages; i++) {
    const localParams = {
      ...rest,
      per_page,
      page: i + 1,
    }

    const nextResponse = await sdk.listings.query(localParams);

    totalListings = totalListings.concat(nextResponse.data.data);
  }

  const result = totalListings.filter(listing => {
    const packages = listing.attributes.publicData.packages;

    const result = packages.some(({ rooms, departure_date }) => {

      return !!rooms.find(({ price, available }) => {
        const converted = minConvertPrice(currentUser, price);

        return +converted.amount > priceFrom &&
          +converted.amount < priceTo &&
          isActualDay(departure_date) &&
          available;
      });
    });

    return result;
  });


  apiResponse.data.data = result.slice((paginate - 1) * perPage, perPage * paginate);
  apiResponse.data.meta.totalItems = result.length;
  apiResponse.data.meta.totalPages = Math.ceil(result.length / perPage);
  return apiResponse;
}

const filterListingByDates = async ({ params, apiResponse, sdk, withPrice, currentUser }) => {
  const { page, per_page, start, end, price, ...rest } = params;
  const paginate = page;
  const perPage = per_page;
  const dateFrom = start;
  const dateTo = end;
  const totalPages = apiResponse.data.meta.totalPages;

  if (!dateFrom && !dateTo) {
    return apiResponse;
  }

  let totalListings = [];

  for (let i = 0; i < totalPages; i++) {
    const localParams = {
      ...rest,
      start,
      end,
      page: i + 1,
    }

    const nextResponse = await sdk.listings.query(localParams);

    totalListings = totalListings.concat(nextResponse.data.data);
  }

  const result = totalListings.filter(listing => {
    const packages = listing.attributes.publicData.packages;

    const intersection = packages.some(({ departure_date, return_date, rooms }) => {
      const startDate1 = new Date(dateFrom);
      const endDate1 = new Date(dateTo);

      const startDate2 = new Date(departure_date);
      const endDate2 = new Date(return_date);

      let roomsResult = true;
      if (withPrice && price) {
        const priceAsArray = price.split(',');
        const priceFrom = priceAsArray[0]/100;
        const priceTo = priceAsArray[1]/100;

        roomsResult = !!rooms.find(({ price, available }) => {
          const converted = minConvertPrice(currentUser, price);
          return +converted.amount > priceFrom &&
            +converted.amount < priceTo &&
            isActualDay(departure_date) &&
            available;
        });
      }

      return (startDate2 >= startDate1 && startDate2 <= endDate1) &&
        (endDate2 >= startDate1 && endDate2 <= endDate1) && roomsResult;
    });

    return intersection;
  });

  apiResponse.data.data = result.slice((paginate - 1) * perPage, perPage * paginate);
  apiResponse.data.meta.totalItems = result.length;
  apiResponse.data.meta.totalPages = Math.ceil(result.length / perPage);
  return apiResponse;
}

export const searchListings = (searchParams, search) => async (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const user = getState().user;

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const datesSearchParams = datesParam => {
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesParam && values.length === 2;
    const startDate = hasValues ? values[0] : null;
    const isNightlyBooking = config.bookingUnitType === 'line-item/night';
    const endDate =
      hasValues && isNightlyBooking ? values[1] : hasValues ? getExclusiveEndDate(values[1]) : null;

    return hasValues
      ? {
        start: formatDateStringToUTC(startDate),
        end: formatDateStringToUTC(endDate),
        // Availability can be full or partial. Default value is full.
        availability: 'day-partial',
      }
      : {};
  };

  const durationSearchParams = (duration) => {
    if (!duration) return null;

    const durationAsArray = duration.split(",");
    return durationAsArray.map((item, index) => index === durationAsArray.length - 1 ? +item + 1 : item).join(",");
  }

  const {
    perPage,
    price,
    dates,
    meta_categoriesForFilters,
    meta_badgesForFilters,
    pub_duration_days,
    ...rest
  } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);
  const durationMaybe = durationSearchParams(pub_duration_days);

  let locale = 'de';
  if (typeof window !== 'undefined') {
    locale = localStorage.getItem('lang');
  }

  let params = {
    ...rest,
    // ...priceMaybe,
    // ...datesMaybe,
    meta_locale: locale,
    per_page: perPage,
    ...(meta_categoriesForFilters ? { meta_categoriesForFilters: `has_any:${meta_categoriesForFilters}` } : {}),
    ...(meta_badgesForFilters ? { meta_badgesForFilters: `has_any:${meta_badgesForFilters}` } : {}),
    ...(durationMaybe ? { pub_duration_days: durationMaybe } : {}),
  };

  try {
    let response = await sdk.listings.query(params);

    params = {
      ...params,
      ...priceMaybe,
      ...datesMaybe,
    }

    if (params.price && (params.start || params.end)) {
      response = await filterListingByDates({
        params,
        apiResponse: response,
        sdk,
        withPrice: true,
        currentUser: user?.currentUser
      });
    } else if (params.start || params.end) {
      response = await filterListingByDates({
        params,
        apiResponse: response,
        sdk,
        currentUser: user?.currentUser
      });
    } else if (params.price) {
      response = await filterListingByPrice({
        params,
        apiResponse: response,
        sdk,
        currentUser: user?.currentUser
      });
    }

    await dispatch(addMarketplaceEntities(response));
    await dispatch(searchListingsSuccess(response));

    const recentListingsResultIds = resultIds(response.data);
    const recentListings = getListingsById(getState(), recentListingsResultIds);
    await dispatch(fetchListingsImages(recentListingsResultIds));

    let recentSearches = user?.currentUser?.attributes?.profile?.publicData?.recentSearches ?? [];
    recentSearches.reverse();

    if (recentListings.length > 0 && search && search !== '') {
      await dispatch(updateProfile({
        publicData: {
          recentSearches: [{
            language: locale,
            link: search,
            image: [],
          }, ...recentSearches]
        }
      }));
    }

    return response;
  } catch(e) {
    dispatch(searchListingsError(storableError(e)));
    throw e;
  }

  // return sdk.listings
  //   .query(params)
  //   .then(response => {
  //     dispatch(addMarketplaceEntities(response));
  //     dispatch(searchListingsSuccess(response));

  //     // const language =  (typeof localStorage !== 'undefined' && localStorage.getItem('lang')) ? localStorage.getItem('lang') : 'de';
  //     const recentListingsResultIds = resultIds(response.data);
  //     dispatch(fetchListingsImages(recentListingsResultIds));

  //     const recentListings = getListingsById(getState(), recentListingsResultIds);

  //     // const leng = recentListings[0]?.attributes?.publicData?.Attachments?.length || 0
  //     // const listingImages = recentListings[0] &&
  //     // recentListings[0].attributes.publicData.Attachments[0] &&
  //     // recentListings[0].attributes.publicData.Attachments[leng-1]
  //     //   ? recentListings[0].attributes.publicData.Attachments[leng-1].url
  //     //   : '';

  //     const user = getState().user;
  //     let recentSearches = user?.currentUser?.attributes?.profile?.publicData?.recentSearches ?? [];
  //     recentSearches.reverse();

  //     if (recentListings.length > 0 && search && search !== '') dispatch(updateProfile({
  //       publicData: {
  //         recentSearches: [ {
  //           language,
  //           link: search,
  //           image: []
  //         }, ...recentSearches, ]
  //       }
  //     }));

  //     return response;
  //   })
  //   .catch(e => {
  //     dispatch(searchListingsError(storableError(e)));
  //     throw e;
  //   });
};

export const searchSimilarListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchSimilarListingsRequest(searchParams));

  const category = searchParams.pub_category ? searchParams.pub_category : null;

  const priceSearchParams = priceParam => {
    // const inSubunits = value =>
    //   convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
        price: [values[0], values[1]].join(','),
      }
      : {};
  };

  const datesSearchParams = datesParam => {
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesParam && values.length === 2;
    const startDate = hasValues ? values[ 0 ] : null;
    const isNightlyBooking = config.bookingUnitType === 'line-item/night';
    const endDate =
      hasValues && isNightlyBooking ? values[ 1 ] : hasValues ? getExclusiveEndDate(values[ 1 ]) : null;

    return hasValues
      ? {
        start: formatDateStringToUTC(startDate),
        end: formatDateStringToUTC(endDate),
        // Availability can be full or partial. Default value is full.
        availability: 'day-partial',
      }
      : {};
  };

  const { perPage, price, dates, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);

  let locale = 'de';
  if (typeof window !== 'undefined') {
    locale = localStorage.getItem('lang');
  }

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    pub_listing_language: locale,
    per_page: perPage,
  };
  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchSimilarListingsSuccess(response));
      if (category !== null) {
        const similarListingsResultIds = resultIds(response.data);
        const similarListings = getListingsById(getState(), similarListingsResultIds);
        const latestSearches = similarListings.map(l => {
          const { title } = l.attributes;
          const category = l.attributes.publicData.category;
          const authorName = l.author.attributes.profile.displayName;
          const avatar = l.author.profileImage ? l.author.profileImage.attributes.variants[ "square-small" ] : [];
          const amenities = l.attributes.publicData.Infos ? l.attributes.publicData.Infos.filter(i => i.info_type === 'badges') : [];

          const priceAmount = l.attributes.price && l.attributes.price.amount;
          const priceCurrency = l.attributes.price && l.attributes.price.currency;
          const locationName = l.attributes.publicData.location_name;
          const skillLevels = l.attributes.publicData.SkillLevels;
          const fitnessLevels = l.attributes.publicData.FitnessLevels;
          const listingImages = l.attributes.publicData.Attachments;
          const durationDays = l.attributes.publicData.duration_days;
          const language = (typeof localStorage !== 'undefined' && localStorage.getItem('lang')) ? localStorage.getItem('lang') : 'de';

          const id = l.id.uuid;

          return {
            title,
            category,
            authorName,
            avatar,
            priceAmount,
            priceCurrency,
            locationName,
            skillLevels,
            fitnessLevels,
            listingImages,
            durationDays,
            amenities,
            id,
            language
          }
        });
        dispatch(updateProfile({ publicData: { latestSearches } }))
      }
      return response;
    })
    .catch(e => {
      dispatch(searchSimilarListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};


export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const queryParams = parse(search, {
    latlng: [ 'origin' ],
    latlngBounds: [ 'bounds' ],
  });

  const { page = 1, address, origin, pub_SkillLevels, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};

  return Promise.all([
    dispatch(searchListings({
      ...rest,
      ...originMaybe,
      ...(pub_SkillLevels && {pub_SkillLevels: pub_SkillLevels}),
      page,
      perPage: 20,
      include: [ 'author', 'images', 'author.profileImage' ],
      'fields.listing': [ 'title', 'geolocation', 'price', 'publicData' ],
      'fields.user': [ 'profile.displayName', 'profile.abbreviatedName' ],
      'fields.image': [ 'variants.landscape-crop', 'variants.landscape-crop2x', 'variants.square-small', 'variants.square-small2x', ],
      'limit.images': 1,
    }, search)),
    dispatch(searchSimilarListings({
      ...rest,
      ...originMaybe,
      page: 4,
      perPage: 20,
      include: [ 'author', 'author.profileImage', 'images' ],
      'fields.listing': [ 'title', 'geolocation', 'price', 'publicData' ],
      'fields.user': [ 'profile.displayName', 'profile.abbreviatedName' ],
      'fields.image': [ 'variants.landscape-crop', 'variants.landscape-crop2x', 'variants.square-small', 'variants.square-small2x', ],
      'limit.images': 1,
    }))
  ]);
};
