import React from 'react';

import css from './TrustAndSafetyPage.module.css';
import img1 from './img1.svg';
import img2 from './img2.svg';
import img3 from './img3.svg';
import img4 from './img4.svg';
import img5 from './img5.svg';
import img6 from './img6.svg';

import logo from './img/logo.svg';

import recommendation1 from './img/recommendation1.png';
import recommendation2 from './img/recommendation2.png';
import recommendation3 from './img/recommendation3.png';
import recommendation4 from './img/recommendation4.png';

import {FormattedMessage} from "../../util/reactIntl";
import classNames from "classnames";

const TrustAndSafetyContent = ({intl}) => {

  const TopSection = () => {

    const sectionArr = [
      {
        title: intl.formatMessage({id: 'TrustAndSafetyPage.title1'}),
        content: intl.formatMessage({id: 'TrustAndSafetyPage.content1'}),
        img: img1
      },
      {
        title: intl.formatMessage({id: 'TrustAndSafetyPage.title2'}),
        content: intl.formatMessage({id: 'TrustAndSafetyPage.content2'}),
        img: img2
      },
      {
        title: intl.formatMessage({id: 'TrustAndSafetyPage.title3'}),
        content: intl.formatMessage({id: 'TrustAndSafetyPage.content3'}),
        img: img3
      },
      {
        title: intl.formatMessage({id: 'TrustAndSafetyPage.title4'}),
        content: intl.formatMessage({id: 'TrustAndSafetyPage.content4'}),
        img: img4
      },
      {
        title: intl.formatMessage({id: 'TrustAndSafetyPage.title5'}),
        content: intl.formatMessage({id: 'TrustAndSafetyPage.content5'}),
        img: img5
      },
      {
        title: intl.formatMessage({id: 'TrustAndSafetyPage.title6'}),
        content: intl.formatMessage({id: 'TrustAndSafetyPage.content6'}),
        img: img6
      },
    ]

    const section = (img, title, content) => (
      <div className={css.rootTopSection}>
        <img className={css.imgTopSection} src={img} alt="sectionImg"/>
        <div>
          <section className={css.titleTopSection}>{title}</section>
          <section className={css.contentTopSection}>{content}</section>
        </div>
      </div>
    )

    return (
      <div className={css.containerTopSection}>
        {sectionArr.map(el => section(el.img, el.title, el.content))}
      </div>
    )
  }


  const SectionSendEmail = () => (
    <div className={css.rootSectionSendEmail}>
      <div className={css.sectionDiscount}>
        <div className={css.sectionMount}/>
        <div className={css.sectionMap}>
          <div className={css.imgMap}/>
          <div className={css.textInMap}>
            <span className={css.titleMapSection}>
              <FormattedMessage id="TrustAndSafetyPage.titleMapSection" />
            </span>
            <img className={css.logoMap} src={logo} alt="logo"/>
            <span className={css.subTitleMapSection}>
              <FormattedMessage id="TrustAndSafetyPage.subTitleMapSection" />
          </span>
          </div>
        </div>
      </div>
    </div>
  );

  const SectionLinks = () => {

    const links = [
      {
        key: 'Account Settings',
        link: 'https://staging.activebreak.com/help/booking-and-traveling#account',
      },
      {
        key: 'Bookings',
        link: 'https://staging.activebreak.com/help/booking-and-traveling#booking',
      },
      {
        key: 'Payments & Card Details',
        link: 'https://staging.activebreak.com/help/booking-and-traveling#payments',
      },
      {
        key: 'Travel Information',
        link: 'https://staging.activebreak.com/help/booking-and-traveling#traveling',
      },
    ]

    return (
      <div className={css.rootLinks}>
        <h2 className={css.titleLinksSection}>
          <span>
            <FormattedMessage id="TrustAndSafetyPage.titleLinksSection" />
          </span>
          <span>
            <FormattedMessage id="TrustAndSafetyPage.subTitleLinksSection" />
          </span>
        </h2>
        <div className={css.wrapperLinks}>
          {links.map(el => (
            <a href={el.link} key={el.key} className={css.link}>
              <span className={css.linkText}>{el.key}</span>
            </a>
          ))}
        </div>
      </div>
    )
  }

  const SectionRecommendations = () => {

    const recommendationsList = [
      {
        img: recommendation1,
        title: intl.formatMessage({id: 'TrustAndSafetyPage.recommendationTitle1'}),
        content: intl.formatMessage({id: 'TrustAndSafetyPage.recommendationContent1'}),
        position: 'left'
      },
      {
        img: recommendation2,
        title: intl.formatMessage({id: 'TrustAndSafetyPage.recommendationTitle2'}),
        content: intl.formatMessage({id: 'TrustAndSafetyPage.recommendationContent2'}),
        position: 'right'
      },
      {
        img: recommendation3,
        title: intl.formatMessage({id: 'TrustAndSafetyPage.recommendationTitle3'}),
        content: intl.formatMessage({id: 'TrustAndSafetyPage.recommendationContent3'}),
        position: 'left'
      },
      {
        img: recommendation4,
        title: intl.formatMessage({id: 'TrustAndSafetyPage.recommendationTitle4'}),
        content: intl.formatMessage({id: 'TrustAndSafetyPage.recommendationContent4'}),
        position: 'right'
      },
    ]

    const recommend = ({img, title, content, position}) => (
      <div className={css.sectionBlockItem}>
        <div className={css.textBlockItemSmall}>
            {title}
        </div>
        {position === 'left' && <img className={css.imgItem} src={img} alt='img'/>}
        <div className={css.textBlockItem}>
          <p className={css.titleItem}>
            {title}
          </p>
          <p className={classNames(css.textItem, {
            [css.textItemRight]: position === 'right',
          })}>
            {content}
          </p>
        </div>
        {position === 'right' && <img className={css.imgItem} src={img} alt='img'/>}
      </div>
    )

    return (
        <div className={css.rootRecommendations}>
          <h3 className={css.titleRecommendations}>
            <FormattedMessage id="TrustAndSafetyPage.titleRecommendations" />
          </h3>
          {recommendationsList.map(el => recommend(el))}
        </div>
    )
  }

  return (
    <div className={css.staticPageWrapper}>
      <div className={css.titleBlock}>
        <span className={css.topTitleSection}>
          <FormattedMessage id="TrustAndSafetyPage.titleSection" />
        </span>
      </div>
      <TopSection/>
      <SectionSendEmail/>
      <SectionRecommendations/>
      <SectionLinks/>
    </div>
  );
};

export default TrustAndSafetyContent;
