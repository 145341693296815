import React from 'react';
import classNames from "classnames";
import css from "./CurrentListing.module.css";
import {dayMounthString, ensureListing} from "../../util/data";
import {createSlug} from "../../util/urlHelpers";
import {NamedLink} from "../index";
import {richText} from "../../util/richText";
import {FormattedMessage} from "../../util/reactIntl";
import IconHostel from "../IconHostel/IconHostel";


const MIN_LENGTH_FOR_LONG_WORDS = 10;

const CurrentListing = ({
  className,
  rootClassName,
  listing,
}) => {
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);

  const dateInterval = null;
  const id = currentListing.id.uuid;
  let title = currentListing ? currentListing.attributes.title : '';

  const locationName = currentListing?.attributes?.publicData?.location_name;
  const slug = createSlug(title);

  // let images = [];

  return (
    <div className={classes}>
      <div className={css.imageWrapper}>
        {/* {currentListing?.attributes?.publicData?.Attachments &&
          currentListing?.attributes?.publicData?.Attachments[0] &&
          <img src={images[4]} alt='sdd' className={css.imagePub}/>
        } */}
      </div>
      <div className={css.mainInfo}>
        <NamedLink params={{ id, slug }} name="ListingPage" className={css.title}>
          <>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </>
        </NamedLink>
        {dateInterval &&
          <div className={css.rowHostelDate}>
            <span className={css.dateInfoRow}>
              <FormattedMessage
                id="SectionFavorite.dateInterval"
                values={{
                  start: dayMounthString(dateInterval.date_from),
                  stop: dayMounthString(dateInterval.date_to)
                }} />
            </span>
            <div className={css.dateInfoRow}>
              <span>
                <FormattedMessage id="SectionFavorite.hostedBy" />
              </span>
              <IconHostel className={css.iconHostel}/>
              <span className={css.locationName}>{locationName}</span>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default CurrentListing;
