import React from 'react';
import css from './ContactPage.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';

import img1 from "./img/contactImg1.png"
import img2 from "./img/contactImg2.png"
import img3 from "./img/contactImg3.png"
import img4 from "./img/contactImg4.png"


const ItemImg = ({ img, text, page }) => (
    <NamedLink name={page} className={css.wrapperImg}>
      <img className={css.itemImg} src={img} alt='sectionImg' />
      <h4 className={css.linkText}>{text}</h4>
    </NamedLink>
)

const SectionImage = ({intl}) => {

  const subText1 = intl.formatMessage({ id: 'ContactPage.subText1' })
  const subText2 = intl.formatMessage({ id: 'ContactPage.subText2' })
  const subText3 = intl.formatMessage({ id: 'ContactPage.subText3' })
  const subText4 = intl.formatMessage({ id: 'ContactPage.subText4' })


  return (
    <div className={css.wrapperImage}>
      <h3 className={css.titleImageContact}><FormattedMessage id="ContactPage.titleImgBlock" /></h3>
      <div className={css.lineBlockImg}>
        <ItemImg img={img1} text={subText1} page="AboutPage" />
        <ItemImg img={img2} text={subText2} page="TermsOfServicePage" />
      </div>
      <div className={css.lineBlockImg}>
        <ItemImg img={img3} text={subText3} page="TravelerHelpPage" />
        <ItemImg img={img4} text={subText4} page="HostHelpPage" />
      </div>
    </div>
  );
};

export default SectionImage;
