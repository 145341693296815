import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { IconAccount } from '..';
import css from './Account.module.css'

const Account = props => {
  const { format } = props;

  if (format === 'desktop') {
    return (
      <div className={css.account}>
        <IconAccount />
        <FormattedMessage id= "TopbarDesktop.account" />
      </div>
    )
  }
};

const { oneOf, string } = PropTypes;

Account.defaultProps = {
  className: null,
  format: 'desktop',
};

Account.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Account;
