import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import TagManager from 'react-gtm-module';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { DEFAULT_LOCALE, LANGUAGE_KEY, seTranslates } from '../../util/languages';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
// import { FormattedMessage } from '../../util/reactIntl';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  // SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer, SectionBusinessPartners,
} from '../../components';
import { TopbarContainer } from '../../containers';
import facebookImage from '../../assets/activebreakFacebook.jpg';
import twitterImage from '../../assets/activebreakTwitter.jpg';
import { getCategories, becomeAhostSubmit } from "../../ducks/Categories.duck";

import css from './LandingPage.module.css';
import SectionSendEmail from "../../components/SectionSendEmail/SectionSendEmail";

class LandingPageComponent extends Component {
  state = {
    language: null,
  };

  componentDidMount() {
    this.setState({
      language: localStorage.getItem(LANGUAGE_KEY)
    });
    this.props.onGetCategories();


    if (typeof window !== 'undefined') {

      const tagManagerArgs = {
        dataLayer: {
          userId: '001',
          userProject: 'Activebreak',
          page: 'LandingPage'
        },
        events: {
          pageView: 'pageview',
        },
        page: {
          path: window.location.pathname,
          title: 'LandingPage',
          type: 'landing',
        },
        dataLayerName: 'LandingPageDataLayer'
      };

      TagManager.dataLayer(tagManagerArgs);


      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pageview',
        page: {
          path: window.location.pathname,
          locale: localStorage.getItem(LANGUAGE_KEY), // language code of displayed page
          title: 'LandingPage',
          type: 'landing',
        },
      });
    };
  }

  getPlaceholder = () => {
    return seTranslates[this.state.language].homeDatepickerPlaceholder;
  };

  render() {
    const {
      history,
      intl,
      location,
      scrollingDisabled,
      // availableCategories,
      hubspotFormSubmit,
      becomeAhostSuccess,
      categoriesForFilters,
    } = this.props;
    // Schema for search engines (helps them to understand what this page is about)
    // http://schema.org
    // We are using JSON-LD format
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    const canonicalPath = location.pathname;
    const canonicalRootURL = config.canonicalRootURL;
    const schemaUrl = `${canonicalRootURL}${canonicalPath}`;
    const datepickerPlaceholder = this.state.language ? this.getPlaceholder() : seTranslates[DEFAULT_LOCALE].homeDatepickerPlaceholder;

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          url: schemaUrl,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="LandingPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.heroContainer}>
              <SectionHero
                intl={intl}
                className={css.hero}
                history={history}
                location={location}
                availableCategories={categoriesForFilters}
                datepickerPlaceholder={datepickerPlaceholder}
              />
            </div>
            <ul className={css.sections}>

              {/*  TODO slider with request to client API. Get top 12 listing and show 4 in page with right slider */}
              {/*<li className={css.section}>*/}
              {/*  <div className={css.sectionContentFirstChild}>*/}
              {/*    <SectionLocations />*/}
              {/*  </div>*/}
              {/*</li>*/}

              <li className={css.section}>
                <div className={css.sectionContent}>
                  <SectionHowItWorks />
                </div>
              </li>
              <li>
                <SectionBusinessPartners
                  hubspotFormSubmit={hubspotFormSubmit}
                  becomeAhostSuccess={becomeAhostSuccess}
                  schemaTitle={schemaTitle}
                />
              </li>
              <li className={css.section}>
                <SectionSendEmail intl={intl} />
              </li>
            </ul>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer currentPage="LandingPage" location={location}/>
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}


const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { becomeAhostSuccess } = state.Categories;

  const configFilters = config.custom.configFilters;

  const filters = {};
  configFilters.forEach(f => {
    filters[f] = state.Categories[f] ?? [];
  });

  return {
    scrollingDisabled: isScrollingDisabled(state),
    // availableCategories,
    becomeAhostSuccess,
    ...filters,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetCategories: () => dispatch(getCategories()),
  hubspotFormSubmit: (values, schemaTitle) => dispatch(becomeAhostSubmit(values, schemaTitle)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
