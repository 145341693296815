import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isSignupEmailTakenError } from '../../util/errors';
import { ButtonTabNavHorizontal, Modal, TermsOfService, } from '../../components';
import { LoginForm, SignupForm } from '../../forms';
import { login, authenticationInProgress, signup } from '../../ducks/Auth.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';

import css from './AuthenticationModal.module.css';

export class AuthenticationModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tosModalOpen: false,
      authModalOpen: true,
      showLoginForm: true,
      showSignupForm: false,
    };
  }

  onShowLoginForm = () => {
    this.setState({
      showLoginForm: true,
      showSignupForm: false
    })
  };

  onShowSignupForm = () => {
    this.setState({
      showLoginForm: false,
      showSignupForm: true,
    })
  };

  render() {
    const {
      authInProgress,
      loginError,
      signupError,
      submitLogin,
      submitSignup,
      isAuthModalOpened,
      updateModalData,
      onManageDisableScrolling,
    } = this.props;

    const loginErrorMessage = (
      <div className={css.error}>
        <FormattedMessage id="AuthenticationPage.loginFailed" />
      </div>
    );

    const signupErrorMessage = (
      <div className={css.error}>
        {isSignupEmailTakenError(signupError) ? (
          <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </div>
    );

    // eslint-disable-next-line no-confusing-arrow
    const errorMessage = (error, message) => (error ? message : null);
    const loginOrSignupError = this.state.showLoginForm
      ? errorMessage(loginError, loginErrorMessage)
      : errorMessage(signupError, signupErrorMessage);


    const tabs = [
      {
        text: (
          <h1 className={css.tab}>
            <FormattedMessage id="AuthenticationPage.signupLinkText" />
          </h1>
        ),
        selected: this.state.showSignupForm,
        onClick: this.onShowSignupForm
      },
      {
        text: (
          <h1 className={css.tab}>
            <FormattedMessage id="AuthenticationPage.loginLinkText" />
          </h1>
        ),
        selected: this.state.showLoginForm,
        onClick: this.onShowLoginForm
      },
    ];

    const handleSubmitSignup = values => {
      const { fname, lname, ...rest } = values;
      const params = { firstName: fname.trim(), lastName: lname.trim(), ...rest };
      submitSignup(params);
    };

    return (
      <>
        <Modal
          id="ListingPage.auth"
          contentClassName={css.modalContent}
          isOpen={isAuthModalOpened}
          onClose={() => {
            this.setState({authModalOpen: false});
            updateModalData(false);
          }}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <h2 className={css.termsHeading}>
            <FormattedMessage id="AuthenticationPage.favoriteLogin"/>
          </h2>
          <ButtonTabNavHorizontal className={css.tabs} tabs={tabs} />

          {loginOrSignupError}

          <div className={this.state.showLoginForm ? css.show : css.hide}>
            <LoginForm className={css.form} onSubmit={submitLogin} inProgress={authInProgress}/>
          </div>
          <div className={this.state.showSignupForm ? css.show : css.hide}>
            <SignupForm
              className={css.form}
              onSubmit={handleSubmitSignup}
              inProgress={authInProgress}
              onOpenTermsOfService={() => this.setState({tosModalOpen: true})}
            />
          </div>
        </Modal>

        <Modal
          id="AuthenticationPage.tos"
          isOpen={this.state.tosModalOpen}
          onClose={() => this.setState({tosModalOpen: false})}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.termsWrapper}>
            <h2 className={css.termsHeading}>
              <FormattedMessage id="AuthenticationPage.termsHeading"/>
            </h2>
            <TermsOfService/>
          </div>
        </Modal>
      </>
    );
  }
}

AuthenticationModalComponent.defaultProps = {
  currentUser: null,
  loginError: null,
  signupError: null,
  tab: 'signup',
  sendVerificationEmailError: null,
};

const { bool, func, object, oneOf, shape } = PropTypes;

AuthenticationModalComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  signupError: propTypes.error,
  submitLogin: func.isRequired,
  submitSignup: func.isRequired,
  tab: oneOf(['login', 'signup']),

  onManageDisableScrolling: func.isRequired,

  // from withRouter
  location: shape({ state: object }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError } = state.Auth;
  const { currentUser } = state.user;
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSignup: params => dispatch(signup(params)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationModal = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(AuthenticationModalComponent);

export default AuthenticationModal;
