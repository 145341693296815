/**
 * SearchFiltersPanel can be used to add extra filters to togglable panel in SearchPage.
 * Using this component will need you to enable it in SearchPage and passing needed props to
 * SearchFilters component (which is the default place for SearchFilters).
 *
 *
 * An example how to render MultiSelectFilter for a filter that has it's config passed in
 * the props as newFilter:
 *
 * initialValue for a filter can be resolved with the initialValue and initialValues
 * methods.
 *
 * const initialNewFilterValues = this.initialValues(newFilter.paramName);
 *
 * const newFilterElement = newFilter ? (
 * <SelectMultipleFilter
 *     id="SearchFiltersPanel.newFilter"
 *     name="newFilter"
 *     urlParam={newFilter.paramName}
 *     label={this.props.intl.formatMessage({ id: 'SearchFiltersPanel.newFilterLabel' })}
 *     onSubmit={this.handleSelectMultiple}
 *     liveEdit
 *     options={multiSelectFilterXFromProps}
 *     initialValues={initialNewFilterValues}
 *  />
 * ) : null;
 */

import React, { Component } from 'react';
import { array, func, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import omit from 'lodash/omit';

import routeConfiguration from '../../routeConfiguration';
import {createLocalizeResourceLocatorString, createResourceLocatorString} from '../../util/routes';

import {
  InlineTextButton,
  SelectMultipleFilter,
  SelectMultipleWithIconsFilter,
} from '../../components';
import css from './SearchFiltersPanel.module.css';
// import { parseDateFromISO8601 } from '../../util/dates';
import DurationFilter from '../DurationFilter/DurationFilter';

// Dropdown container can have a positional offset (in pixels)
const FILTER_DROPDOWN_OFFSET = -14;
const RADIX = 10;

class SearchFiltersPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { currentQueryParams: props.urlQueryParams };

    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.handleSelectSingle = this.handleSelectSingle.bind(this);
    this.handleSelectMultiple = this.handleSelectMultiple.bind(this);
    this.initialValue = this.initialValue.bind(this);
    this.initialValues = this.initialValues.bind(this);
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const {
      history,
      urlQueryParams,
      onClosePanel,
    } = this.props;
    history.push(createLocalizeResourceLocatorString(urlQueryParams));

    // Ensure that panel closes (if now changes have been made)
    onClosePanel();
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
    this.props.onClosePanel();
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, onClosePanel } = this.props;

    // const queryParams = omit(urlQueryParams, filterParamNames);
    history.push(createLocalizeResourceLocatorString());

    // Ensure that panel closes (if now changes have been made)
    onClosePanel();

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  handleSelectSingle(urlParam, options) {
    const { urlQueryParams, history } = this.props;
    const queryParams = options && options.length > 0
        ? { ...urlQueryParams, [ urlParam ]: options }
        : omit(urlQueryParams, urlParam);
    history.push(createLocalizeResourceLocatorString(queryParams));
  }


  // handleSelectSingle(urlParam, option) {
  //   const urlQueryParams = this.props.urlQueryParams;
  //   this.setState(prevState => {
  //     const prevQueryParams = prevState.currentQueryParams;
  //     const mergedQueryParams = { ...urlQueryParams, ...prevQueryParams };
  //
  //     // query parameters after selecting the option
  //     // if no option is passed, clear the selection for the filter
  //     const currentQueryParams = option
  //       ? { ...mergedQueryParams, [ urlParam ]: option }
  //       : { ...mergedQueryParams, [ urlParam ]: null };
  //
  //     return { currentQueryParams };
  //   });
  // }

  handleSelectMultiple(urlParam, options) {
    const urlQueryParams = this.props.urlQueryParams;
    this.setState(prevState => {
      const prevQueryParams = prevState.currentQueryParams;
      const mergedQueryParams = { ...urlQueryParams, ...prevQueryParams };

      // query parameters after selecting options
      // if no option is passed, clear the selection from state.currentQueryParams
      const currentQueryParams =
        options && options.length > 0
          ? { ...mergedQueryParams, [ urlParam ]: options.join(',') }
          : { ...mergedQueryParams, [ urlParam ]: null };

      return { currentQueryParams };
    });
  }

  handleSelectOptions = (urlParam, options) => {
    const {urlQueryParams, history} = this.props;
    const queryParams =
      options && options.length > 0
        ? { ...urlQueryParams, [urlParam]: options.join(',') }
        : omit(urlQueryParams, urlParam);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  };;

  handlePrice = (urlParam, range) => {
    const { urlQueryParams, history } = this.props;
    const { minPrice, maxPrice } = range || {};
    const queryParams = minPrice != null && maxPrice != null
        ? { ...urlQueryParams, [ urlParam ]: `${minPrice},${maxPrice}` }
        : omit(urlQueryParams, urlParam);

    history.push(createLocalizeResourceLocatorString(queryParams));
  };

  handleDuration = (urlParam, range) => {
    const { urlQueryParams, history } = this.props;
    // const { minDuration, maxDuration } = range || {};
    // const queryParams = minDuration != null && maxDuration != null
    //     ? { ...urlQueryParams, [ urlParam ]: `${minDuration},${maxDuration}` }
    //     : omit(urlQueryParams, urlParam);
    history.push(createLocalizeResourceLocatorString(urlQueryParams));
  };

  // resolve initial value for a single value filter
  initialValue(paramName) {
    const currentQueryParams = this.state.currentQueryParams;
    const urlQueryParams = this.props.urlQueryParams;

    // initialValue for a select should come either from state.currentQueryParam or urlQueryParam
    const currentQueryParam = currentQueryParams[ paramName ];

    return typeof currentQueryParam !== 'undefined' ? currentQueryParam : urlQueryParams[ paramName ];
  }

  // resolve initial values for a multi value filter
  initialValues(paramName) {
    const currentQueryParams = this.state.currentQueryParams;
    const urlQueryParams = this.props.urlQueryParams;

    const splitQueryParam = queryParam => (queryParam ? queryParam.split(',') : []);

    // initialValue for a select should come either from state.currentQueryParam or urlQueryParam
    const hasCurrentQueryParam = typeof currentQueryParams[ paramName ] !== 'undefined';

    return hasCurrentQueryParam
      ? splitQueryParam(currentQueryParams[ paramName ])
      : splitQueryParam(urlQueryParams[ paramName ]);
  }


  render() {
    const {
      rootClassName,
      className,
      urlQueryParams,
      categoryFilter,
      amenitiesFilter,
      // priceFilter,
      durationRangeFilter,
      skillLevelFilter,
      fitnessLevelFilter,
      intl,
      currentUser,
    } = this.props;


    const classes = classNames(rootClassName || css.root, className);

    const categoryLabel = intl.formatMessage({ id: 'SearchFilters.categoryLabel' });

    const amenitiesLabel = intl.formatMessage({ id: 'SearchFilters.amenitiesLabel' });

    // const initialValue = (queryParams, paramName) => queryParams[ paramName ]

    const initialValues = (queryParams, paramName) => {
      return !!queryParams[ paramName ] ? queryParams[ paramName ].split(',') : [];
    };

    const initialAmenities = amenitiesFilter
      ? initialValues(urlQueryParams, amenitiesFilter.paramName)
      : null;

    const initialCategory = categoryFilter
      ? initialValues(urlQueryParams, categoryFilter.paramName)
      : null;

    const initialFitness = fitnessLevelFilter
      ? initialValues(urlQueryParams, fitnessLevelFilter.paramName)
      : null;

    const fitnessLabel = intl.formatMessage({ id: 'SearchFilters.fitnessLevelLabel' });

    const initialSkills = skillLevelFilter
      ? initialValues(urlQueryParams, skillLevelFilter.paramName)
      : null;

    const skillsLabel = intl.formatMessage({ id: 'SearchFilters.skillLevelLabel' });

    const fitnessFilterElement = fitnessLevelFilter ? (
      <SelectMultipleWithIconsFilter
        id={'SearchFilters.fitnessLevelFilter'}
        name="fitness"
        urlParam={fitnessLevelFilter.paramName}
        mode={fitnessLevelFilter.mode}
        label={fitnessLabel}
        onSubmit={this.handleSelectOptions}
        options={fitnessLevelFilter.options}
        initialValues={initialFitness}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      />
    ) : null;

    const skillsFilterElement = skillLevelFilter ? (
      <SelectMultipleWithIconsFilter
        id={'SearchFilters.skillLevelFilter'}
        name="skills"
        urlParam={skillLevelFilter.paramName}
        label={skillsLabel}
        onSubmit={this.handleSelectOptions}
        options={skillLevelFilter.options}
        initialValues={initialSkills}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      />
    ) : null;

    const initialRangeValue = (queryParams, paramName, type) => {
      const price = queryParams[ paramName ];
      const valuesFromParams = !!price ? price.split(',').map(v => Number.parseInt(v, RADIX)) : [];

      return !!price && valuesFromParams.length === 2
        ? {
          [ `min${type}` ]: valuesFromParams[ 0 ],
          [ `max${type}` ]: valuesFromParams[ 1 ],
        }
        : null;
    };

    // const initialDateRangeValue = (queryParams, paramName) => {
    //   const dates = queryParams[ paramName ];
    //   const rawValuesFromParams = !!dates ? dates.split(',') : [];
    //   const valuesFromParams = rawValuesFromParams.map(v => parseDateFromISO8601(v));
    //   return !!dates && valuesFromParams.length === 2
    //       ? { dates: { startDate: valuesFromParams[ 0 ], endDate: valuesFromParams[ 1 ] }}
    //       : { dates: null };
    // };

    // let initialPriceRange = priceFilter
    //   ? initialRangeValue(urlQueryParams, priceFilter.paramName, 'Price')
    //   : null;

    const initialDurationRange = durationRangeFilter
      ? initialRangeValue(urlQueryParams, durationRangeFilter.paramName, 'Duration')
      : null;

    // const priceFilterElement = priceFilter ? (
    //   <PriceFilter
    //     id="SearchFilters.priceFilter"
    //     urlParam={priceFilter.paramName}
    //     onSubmit={this.handlePrice}
    //     {...priceFilter.config}
    //     initialValues={initialPriceRange}
    //     contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    //     currentuser={currentUser}
    //     custom
    //   />
    // ) : null;

    const durationFilterElement = durationRangeFilter ? (
      <DurationFilter
        currentUser={currentUser}
        id="SearchFilters.durationFilter"
        urlParam={durationRangeFilter.paramName}
        onSubmit={this.handleDuration}
        {...durationRangeFilter.config}
        initialValues={initialDurationRange}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        custom
      />
    ) : null;

    // const categoryFilterElement = categoryFilter ? (
    //   <SelectSingleFilter
    //     urlParam={categoryFilter.paramName}
    //     label={categoryLabel}
    //     onSelect={this.handleSelectSingle}
    //     liveEdit
    //     options={categoryFilter.options}
    //     initialValue={initialCategory}
    //     contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    //   />
    // ) : null;

    const categoryFilterElement = categoryFilter && categoryFilter.options.length ? (
      <SelectMultipleFilter
        id={'SearchFilters.categoryFilter'}
        name="category"
        urlParam={categoryFilter.paramName}
        label={categoryLabel}
        onSubmit={this.handleSelectOptions}
        liveEdit
        options={categoryFilter.options}
        initialValues={initialCategory}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      />
    ) : null;

    const amenitiesFilterElement = amenitiesFilter && amenitiesFilter.options.length ? (
      <SelectMultipleFilter
        id={'SearchFiltersPanel.amenitiesFilter'}
        name="amenities"
        urlParam={amenitiesFilter.paramName}
        label={amenitiesLabel}
        onSubmit={this.handleSelectOptions}
        liveEdit
        options={amenitiesFilter.options}
        initialValues={initialAmenities}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      />
    ) : null;

    return (
      <div className={classes}>
        <div className={css.filtersWrapper}>
          {/* Add filters here */}
          {categoryFilterElement}
          {amenitiesFilterElement}
          {/*{priceFilterElement}*/}
          {durationFilterElement}
          <div className={css.customFilters}>
            {skillsFilterElement}
            {fitnessFilterElement}
          </div>
        </div>
        <div className={css.footer}>
          <InlineTextButton rootClassName={css.resetAllButton} onClick={this.resetAll}>
            <FormattedMessage id={'SearchFiltersPanel.resetAll'}/>
          </InlineTextButton>
          <InlineTextButton rootClassName={css.cancelButton} onClick={this.cancelFilters}>
            <FormattedMessage id={'SearchFiltersPanel.cancel'}/>
          </InlineTextButton>
          <InlineTextButton rootClassName={css.applyButton} onClick={this.applyFilters}>
            <FormattedMessage id={'SearchFiltersPanel.apply'}/>
          </InlineTextButton>
        </div>
      </div>
    );
  }
}

SearchFiltersPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  filterParamNames: [],
};

SearchFiltersPanelComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  onClosePanel: func.isRequired,
  filterParamNames: array,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const SearchFiltersPanel = injectIntl(withRouter(SearchFiltersPanelComponent));

export default SearchFiltersPanel;
