import React from 'react';

const SharedIcon = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      width='28'
      height='28'
      viewBox='0 0 28 28'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path fillRule='evenodd' clipRule='evenodd'
            d='M7.5 7.10672C7.5 6.77342 7.77412 6.5 8.10497 6.5H15.895C16.2259 6.5 16.5 6.77342 16.5 7.10672V10V17.647C16.4581 17.9512 16.1688 18.2273 15.8398 18.2273H8.10497C7.77412 18.2273 7.5 17.9539 7.5 17.6206V7.10672ZM17.5 9.5V7.10672C17.5 6.22263 16.7796 5.5 15.895 5.5H8.10497C7.22035 5.5 6.5 6.22263 6.5 7.10672V17.6206C6.5 18.5046 7.22035 19.2273 8.10497 19.2273H10.5V21.6206C10.5 22.5046 11.2204 23.2273 12.105 23.2273H19.8398C20.7187 23.2273 21.4182 22.52 21.4975 21.7256C21.4992 21.7091 21.5 21.6925 21.5 21.6759V11.1067C21.5 10.2226 20.7796 9.5 19.895 9.5H17.5ZM17.5 10.5V17.6759C17.5 17.6925 17.4992 17.7091 17.4975 17.7256C17.4182 18.52 16.7187 19.2273 15.8398 19.2273H11.5V21.6206C11.5 21.9538 11.7741 22.2273 12.105 22.2273H19.8398C20.1688 22.2273 20.4581 21.9512 20.5 21.647V11.1067C20.5 10.7734 20.2259 10.5 19.895 10.5H17.5Z'
            fill='#101010' />
    </svg>
  );
};

export default SharedIcon;
