import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {fetchCurrentUser} from "../../ducks/user.duck";

// ================ Action types ================ //


export const SEARCH_RECENT_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_RECENT_LISTINGS_REQUEST';
export const SEARCH_RECENT_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_RECENT_LISTINGS_SUCCESS';
export const SEARCH_RECENT_LISTINGS_ERROR = 'app/SearchPage/SEARCH_RECENT_LISTINGS_ERROR';

export const SEARCH_RELATED_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_RELATED_LISTINGS_REQUEST';
export const SEARCH_RELATED_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_RELATED_LISTINGS_SUCCESS';
export const SEARCH_RELATED_LISTINGS_ERROR = 'app/SearchPage/SEARCH_RELATED_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  recentData: [],
  searchRecentParams: null,
  searchRecentInProgress: false,
  searchRecentSuccess: false,

  relatedData: [],
  searchRelatedParams: null,
  searchRelatedInProgress: false,
  searchRelatedSuccess: false,

  searchListingsError: null,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_RECENT_LISTINGS_REQUEST:
      return {
        ...state,
        searchRecentParams: payload.searchParams,
        searchRecentInProgress: true,
      };
    case SEARCH_RECENT_LISTINGS_SUCCESS:
      return {
        ...state,
        searchRecentSuccess: true,
        recentData: resultIds(payload.data),
        searchRecentInProgress: false,
      };
    case SEARCH_RECENT_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        searchRecentInProgress: false,
        searchRecentSuccess: false,
        searchListingsError: payload
      };


    case SEARCH_RELATED_LISTINGS_REQUEST:
      return {
        ...state,
        searchRelatedParams: payload.searchParams,
        searchRelatedInProgress: true,
      };
    case SEARCH_RELATED_LISTINGS_SUCCESS:
      return {
        ...state,
        searchRelatedSuccess: true,
        relatedData: resultIds(payload),
        searchRelatedInProgress: false,
      };
    case SEARCH_RELATED_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        searchRelatedInProgress: false,
        searchRelatedSuccess: false,
        searchListingsError: payload
      };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //


export const searchResentListingRequest = params => ({
  type: SEARCH_RECENT_LISTINGS_REQUEST,
  payload: { params },
});

export const searchResentListingsSuccess = response => ({
  type: SEARCH_RECENT_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchResentListingsError = e => ({
  type: SEARCH_RECENT_LISTINGS_ERROR,
  error: true,
  payload: e,
});



export const searchRelatedListingRequest = params => ({
  type: SEARCH_RELATED_LISTINGS_REQUEST,
  payload: { params },
});

export const searchRelatedListingsSuccess = response => ({
  type: SEARCH_RELATED_LISTINGS_SUCCESS,
  payload: {data: response} ,
});

export const searchRelatedListingsError = e => ({
  type: SEARCH_RELATED_LISTINGS_ERROR,
  error: true,
  payload: e,
});


export const recentSearches = (params) => (dispatch, getState, sdk) => {
  dispatch(searchResentListingRequest(params));
  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchResentListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchResentListingsError(storableError(e)));
      throw e;
    });
}

export const relatedActivities = (params, favoritesList) => (dispatch, getState, sdk) => {
  dispatch(searchRelatedListingRequest(params));
  return sdk.listings
    .query(params)
    .then(res => {
      dispatch(addMarketplaceEntities(res));
      const response = res && res.data && res?.data?.data.filter(l => !favoritesList.includes(l.id.uuid))
      dispatch(searchRelatedListingsSuccess(response));

      return res;
    })
    .catch(e => {
      dispatch(searchRelatedListingsError(storableError(e)));
      throw e;
    });
}


const getSearchParams = search => {
  const paramsForSearch = [
    'pub_category',
    'pub_amenities',
    'price',
    'dates',
    'keywords',
    'pub_SkillLevels',
    'pub_FitnessLevels',
    'pub_duration_days'
  ]
  let result = {}
  if(search && !search.length) return {}
  const searchParams = new URLSearchParams(search)
  paramsForSearch.map(el => {
    if(searchParams.get(el)) {
      result[el] = searchParams.get(el)
    }
  })
  return result
}

const lastLink = arr => {
  if (arr && !arr.length) return ''
  let {language, link} = arr && arr[0] || {}
  return link
}

const getFavoriteParams = params => {
  let country = []
  let category = []
  if(params && !params.length) return {}
  params && params.map(el => {
    country.push(el.country)
    category.push(el.category)
  })
  return {
    'pub_category': [...new Set(category)],
    'pub_country': [...new Set(country)]
  }
}


export const loadData = (params, search) => (dispatch, getState, sdk) => {
   return Promise.all([dispatch(fetchCurrentUser())])
     .then(res => {
       const { currentUser } = getState().user
       // const recentData = currentUser &&
       //   currentUser.attributes?.profile?.publicData &&
       //   currentUser.attributes.profile.publicData?.recentSearches &&
       //   lastLink(currentUser.attributes.profile.publicData.recentSearches)
       // const recentParams = getSearchParams(recentData)

       const favoritesList = currentUser &&
       currentUser.attributes?.profile &&
       currentUser.attributes.profile.publicData &&
       currentUser.attributes.profile.publicData.listingFavourite
         ? currentUser.attributes.profile.publicData.listingFavourite.reverse()
         : [];

       const arrFavoritesList = favoritesList.map(el => el.listingId)
       const favoriteParams = getFavoriteParams(favoritesList)

       let locale = 'de';
       if (typeof window !== 'undefined') {
         locale = localStorage.getItem('lang');
       }

       return Promise.all([
         // dispatch(recentSearches({
         //   ...recentParams,
         //   page: 1,
         //   perPage: 20,
         //   include: [ 'author', 'images', 'author.profileImage' ],
         //   'fields.listing': [ 'title', 'geolocation', 'price', 'publicData' ],
         //   'fields.user': [ 'profile.displayName', 'profile.abbreviatedName' ],
         //   'fields.image': [ 'variants.landscape-crop', 'variants.landscape-crop2x', 'variants.square-small', 'variants.square-small2x', ],
         //   'limit.images': 1,
         // })),
         dispatch(relatedActivities({
           pub_listing_language: locale,
           ...favoriteParams,
           page: 1,
           perPage: 20,
           include: [ 'author', 'images', 'author.profileImage' ],
           'fields.listing': [ 'title', 'geolocation', 'price', 'publicData' ],
           'fields.user': [ 'profile.displayName', 'profile.abbreviatedName' ],
           'fields.image': [ 'variants.landscape-crop', 'variants.landscape-crop2x', 'variants.square-small', 'variants.square-small2x', ],
           'limit.images': 1,
         }, arrFavoritesList)),
       ]);
     })
};
