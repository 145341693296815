import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {ListingCard, NamedLink, PaginationLinks} from '../../components';
import css from './SearchResultsPanel.module.css';
import {createSlug} from "../../util/urlHelpers";
import { FormattedMessage } from '../../util/reactIntl';
import { animateScroll as scroll} from 'react-scroll'

const SearchResultsPanel = ( {
  className,
  rootClassName,
  listings,
  pagination,
  search,
  setActiveListing,
  isAuthenticated,
  currentUser,
  onUpdateProfile,
  children,
  listingsImages,
}) => {
  const classes = classNames(rootClassName || css.root, className);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const handleScroll = () => {
      scroll.scrollToTop({
        spy: true,
        smooth: true,
        hashSpy: true,
        duration: 1000,
      });
  }

  const getImagesByListing = (listingId, images) =>
    images.filter(({listing_id}) => listing_id === listingId);

  return (
    <div className={classes}>
      <div className={css.listingCards}>
        {listings.map(l => (
          <NamedLink
            key={l?.id.uuid}
            className={css.listingCard}
            name="ListingPage"
            params={{
              id: l?.id.uuid,
              slug: createSlug(l?.attributes.title)
          }}>
            <ListingCard
              currentUser={currentUser}
              listing={l}
              iconShareClass={css.iconShare}
              renderSizes={cardRenderSizes}
              isAuthenticated={isAuthenticated}
              setActiveListing={setActiveListing}
              onUpdateProfile={onUpdateProfile}
              images={getImagesByListing(l.id.uuid, listingsImages)}
            />
          </NamedLink>
        ))}
        {children}
      </div>
      {listings.length ?
        <div
          className={css.blockMapRedirect}
          onClick={handleScroll}
        >
          <span className={css.imgMapRedirect} />
          <FormattedMessage id='SearchPage.mapRedirect' />
        </div> : null}
      {paginationLinks}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;
