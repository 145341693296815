import en from './en';
import de from './de';
import fr from './fr';
import es from './es';

export {
  en,
  de,
  fr,
  es
};
