import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './SectionBusinessPartners.module.css';
import boardLarge from "../../assets/lodka.png";
import boardSmall from "../../assets/lodka22.png";
import { HostActivity } from "../index";

import firstPartner from "./img/image 13.png";
import secondPartner from "./img/image 14.png";
import thirdPartner from "./img/image 15.png";


const SectionBusinessPartners = props => {

  const { hubspotFormSubmit, becomeAhostSuccess, schemaTitle } = props

  return (
    <div className={css.root}>
      <div className={css.sectionBoard}>
        <img className={css.sectionBoardImg} src={boardLarge} alt="bord" />
        <img className={css.sectionBoardImgSmall} src={boardSmall} alt="bord" />
        <div className={css.sectionContent}>
          <h1 className={css.sectionBoardTitle}>
            <FormattedMessage id="SectionHowItWorks.boardTitleSection" />
          </h1>
          <p className={css.sectionBoardText}>
            <FormattedMessage id="SectionHowItWorks.boardSubTitleSection" />
          </p>
          <HostActivity
            linkClassName={css.createListingButton}
            hubspotFormSubmit={hubspotFormSubmit}
            becomeAhostSuccess={becomeAhostSuccess}
            schemaTitle={schemaTitle}
          />
        </div>
      </div>
      <div className={css.sectionPartners}>
        <h1 className={css.titleSectionPartners}>
          <FormattedMessage id="SectionBusinessPartners.TitlePartners" />
        </h1>
        <div className={css.blockImgPartners}>
          <img src={firstPartner} alt="first" />
          <img src={secondPartner} alt="second" />
          <img src={thirdPartner} alt="third" />
        </div>
      </div>
    </div>
  );
};

export default SectionBusinessPartners;
