import React, { useState } from 'react';
import { string } from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { formatMoney, convertPrice } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ensureListing, ensureCurrentUser, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { NamedLink, AuthenticationModal } from '../../components';
import { IconCheckbox } from '../FieldCheckboxLikeButton/FieldCheckboxLikeButton';
import { ListingFavouritesForm } from '../../forms';
import config from '../../config';
import { InlineShareButtons } from 'sharethis-reactjs';
import {
  IconCategory,
  IconAccount,
  IconShare,
  IconMarker
} from '..';
import { pathByRouteName } from "../../util/routes";
import routeConfiguration from "../../routeConfiguration";

// styles
import css from './ProfileListingCard.module.css';

const { Money } = sdkTypes;
const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  }
  return {};
};

const getMinPackagesPrice = (packages) => {
  let minPrice;

  packages.forEach(({rooms}) => {
    rooms.forEach(({price, available}) => {
      if ((!minPrice && available) || (available && price.amount < minPrice)) {
        minPrice = price.amount;
      }
    });
  });

  return minPrice;
}

const getDepartureDate = (packages) => {
  let minDate;

  packages.forEach(({rooms, departure_date}) => {
    const isAvailable = rooms.find(({available}) => available);

    const timeInMls = new Date(departure_date).getTime();

    if (
      (!minDate && isAvailable) ||
      (isAvailable && timeInMls < new Date(minDate).getTime())
    ) {
      minDate = departure_date;
    }
  });

  return minDate;
}

export const ProfileListingCardComponent = props => {
  const [ index, setIndex ] = useState(0);
  const [ isShareOpened, setShareOpened ] = useState(false);
  const [ isAuthModalOpened, setAuthModalOpened ] = useState(false);
  const {
    className,
    rootClassName,
    intl,
    listing,
    onUpdateProfile,
    currentUser,
    isAuthenticated,
    categoriesConfig
  } = props;

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  const save = async values => {
    await sleep(1000)
  };

  // Panel width relative to the viewport
  // const panelMediumWidth = 50;
  // const panelLargeWidth = 62.5;
  // const cardRenderSizes = [
  //   '(max-width: 767px) 100vw',
  //   `(max-width: 1023px) ${panelMediumWidth}vw`,
  //   `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
  //   `${panelLargeWidth / 3}vw`,
  // ].join(', ');

  const user = ensureCurrentUser(currentUser);
  // const currentUserLoaded = !!user.id;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const avatar = currentListing && currentListing.author.profileImage ? currentListing.author.profileImage.attributes.variants[ "square-small" ] : [];
  const listingFavourite = currentListing ? user.attributes.profile.publicData?.listingFavourite ?? [] : [];

  const id = currentListing.id.uuid;
  const {
    title = '',
    price,
    publicData: {
      fitness_levels = [],
      skill_levels = [],
      duration_days,
      guided,
      categories = [],
      themes = [],
      packages = [],
    } = {}
  } = currentListing.attributes;

  const partnerId = author.id.uuid;
  const minPrice = getMinPackagesPrice(packages);
  const departureDate = getDepartureDate(packages);

  const convertedPrice = convertPrice(currentUser, price);
  const convertedPerDayPrice = convertedPrice.amount / duration_days;
  const convertedPerDayPriceMoney = new Money(convertedPerDayPrice, convertedPrice.currency);
  let { priceTitle } = priceData(convertedPerDayPriceMoney, intl);
  const formattedPerDayPrice = formatMoney(intl, convertedPerDayPriceMoney);
  const locationName = currentListing.attributes.publicData.location_name;
  const skillLevels = currentListing.attributes.publicData && currentListing.attributes.publicData.SkillLevels && typeof currentListing.attributes.publicData.SkillLevels[ 0 ] !== 'object' ? currentListing.attributes.publicData.SkillLevels[ 0 ] : [];
  const fitnessLevels = currentListing.attributes.publicData.FitnessLevels && typeof currentListing.attributes.publicData.FitnessLevels[ 0 ] !== 'object' ? currentListing.attributes.publicData.FitnessLevels[ 0 ] : [];
  const category = currentListing.attributes.publicData.category;
  const init = listingFavourite ? listingFavourite.filter(l => l.listingId === id) : [];
  const slug = createSlug(title);
  const originPathName = typeof window !== 'undefined' ? window.location.origin : 'https://staging.activebreak.com';
  const pathname = pathByRouteName('ListingPage', routeConfiguration(), { id, slug });
  const listingImages = currentListing.attributes.publicData.Attachments;
  const amenities = listing.attributes.publicData && listing.attributes.publicData.Infos ? listing.attributes.publicData.Infos.filter(i => i.info_type === 'badges') : [];

  let images = [];
  if (listingImages
    && listingImages[0]
    && listingImages[0].url) {
    listingImages.forEach(e => images.push(e.url));
  }

  const categoryLabel = (categories, key) => {
    const cat = categories.find(c => c.key === key);
    return cat ? cat.label : key;
  };

  const imagesControls = images.length > 1 ? <>
    <button className={css.imageButtonPrev} onClick={() => {
      if (index === 0) setIndex(images.length - 1);
      else setIndex(index - 1)
    }}/>
    <button className={css.imageButtonNext} onClick={() => {
      if (index === images.length - 1) setIndex(0);
      else setIndex(index + 1)
    }}/>
    </> : null;

  return (
    <div className={classes} onMouseLeave={() => setShareOpened(false)}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <div className={css.shareLike}>
            {isAuthenticated ?
              <ListingFavouritesForm
                iconClassName={css.shareIcon}
                className={css.checkbox}
                isAuthenticated={isAuthenticated}
                initialValues={{
                  [ `favourite_${id}` ]: init[ 0 ] ? init[ 0 ][ `favourite_${id}` ] : false
                }}
                listingFavourite={listingFavourite}
                onSubmit={values => {
                  save(values);
                  let currentListingId = Object.keys(values)[ 0 ];
                  let currentListingValue = values[ `favourite_${id}` ];
                  currentListingId = currentListingId.replace('favourite_', '');
                  const hasCurrentListing = listingFavourite.filter(l => l.listingId === currentListingId).length > 0;

                  if (!hasCurrentListing && currentListingValue) {
                    onUpdateProfile({
                      publicData: {
                        listingFavourite: [ ...listingFavourite, {
                          title,
                          categories,
                          authorName,
                          avatar,
                          themes,
                          locationName,
                          skill_levels,
                          fitness_levels,
                          listingImages: [],
                          guided,
                          listingId: id,
                          duration_days,
                          partner_id: partnerId,
                          price: minPrice,
                          departure_date: departureDate,
                          // price: { amount: price.amount, currency: price.currency},
                          [ `favourite_${id}` ]: true
                        } ]
                      }
                    });
                  }

                  if (hasCurrentListing && !currentListingValue) {
                    const removeCurrentListing = listingFavourite.filter(l => l.listingId !== currentListingId);
                    onUpdateProfile({
                      publicData: {
                        listingFavourite: removeCurrentListing
                      }
                    });
                  }
                }}
                onChange={values => {
                }}
                listingId={id}
              /> :
              <>
                <IconCheckbox onCheckboxClick={() => setAuthModalOpened(!isAuthModalOpened)}/>
                <AuthenticationModal isAuthModalOpened={isAuthModalOpened} />
              </>
            }
            {isShareOpened &&
            <div className={css.shareButtonsHolder}>
              <InlineShareButtons
                config={{
                  enabled: true,
                  networks: [
                    'facebook',
                    'twitter',
                    'pinterest',
                    'whatsapp',
                    'email'
                  ],
                  image: images[ 0 ],
                  description: 'custom text',
                  title: title,
                  message: originPathName + pathname,
                  subject: 'Take a look at the listing',
                  username: 'custom twitter handle',
                  top: 0,
                  url: originPathName + pathname,
                  show_mobile: true,
                  show_toggle: false,
                }}
              />
            </div>}

            <div onClick={() => setShareOpened(!isShareOpened)}>
              <IconShare/>
            </div>
          </div>
          <div style={{ backgroundImage: `url(${images[ index ]})` }} className={css.imagePub}>
              {imagesControls}
          </div>
        </div>
      </div>
      <div className={css.mainInfo}>
        <NamedLink params={{ id, slug }} name="ListingPage" className={css.title}>
          <>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </>
        </NamedLink>
        <div className={css.topInfoRow}>
          <div className={css.topInfoItem}>
            <span className={css.categoryItem}>
              <span className={css.skitouringIcon}>
                <IconCategory cat={category}/>
              </span>
              {categoryLabel(categoriesConfig, category)}
            </span>
          </div>
          <div className={css.topInfoItem}>
            <span className={css.locationItem}>
              <IconMarker/>
              <span>{locationName}</span>
            </span>
          </div>
          <div className={css.topInfoItem}>
            <span className={css.avatarItem}>
              {avatar.url ?
                <img src={avatar.url} className={css.avatarImage}/> :
                <IconAccount/>}
              {authorName}
            </span>
          </div>
        </div>
        <div className={css.priceInfoRow} title={priceTitle}>
          <span className={css.priceLabel}>
            <FormattedMessage id="BookingPanel.perDay" />
          </span>
          <span className={css.priceValue}>{formattedPerDayPrice}</span>
        </div>
        <ul className={css.amenitiesInfoRow}>
          {amenities.length > 0 && amenities.map(e => {
            return (
              <li key={e.info_title} className={css.amenityItem}>
                <IconCategory cat={e.info_title}/>
                <span className={css.amenityText}>{e.info_title}</span>
              </li>
            );
          })}
        </ul>
        <div className={css.skillsInfoRow}>
          <div className={css.skillsInfoRowItem}>
            <IconCategory cat={skillLevels.length && skillLevels[ 0 ].title}/>
            {skillLevels.length && skillLevels[ 0 ].title}
          </div>
          <div className={css.skillsInfoRowItem}>
            <IconCategory cat={fitnessLevels.length && fitnessLevels[ 0 ].title}/>
            {fitnessLevels.length && fitnessLevels[ 0 ].title}
          </div>
        </div>
      </div>
    </div>
  );
};

ProfileListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  categoriesConfig: config.custom.categories,
  amenitiesConfig: config.custom.amenities,
};

ProfileListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(ProfileListingCardComponent);
