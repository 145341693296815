import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { NamedLink } from '../../components';

import css from './SectionLocations.module.css';

import Tarifa from './images/Tarifa.png';
import Rovaniemi from './images/Rovaniemi.png';
import Scotland from './images/Scotland.png';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery, num) => {
  const nameText = <span className={css.locationName}>{name}</span>;

  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id={`SectionLocations.listingsInLocation${num}`}
          values={{ location: nameText }}
        />
      </div>
    </NamedLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className, favorites } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {!favorites && <div className={css.title}><FormattedMessage id="SectionLocations.title" /></div>}
      {favorites && <div className={css.favTitle}>
        <span className={css.favSpan}><FormattedMessage id="SectionLocationsFav.span" /></span>
        <FormattedMessage id="SectionLocationsFav.title" />
      </div>}
      <div className={css.locations}>
        {locationLink(
          'Tarifa',
          Tarifa,
          '?address=11380%20Tarifa%2C%20Cádiz%2C%20Spain&bounds=36.0203594%2C-5.597980799999999%2C36.008551%2C-5.6113367&origin=36.0143209%2C-5.604449699999999&pub_category=kitesurfing',
          1
        )}
        {locationLink(
          'Rovaniemi',
          Rovaniemi,
          '?address=Rovaniemi%2C%20Finland&bounds=67.18452510000002%2C27.3266785%2C66.1553745%2C24.7368712&origin=66.50394779999999%2C25.7293906&pub_category=skitouring',
          2
        )}
        {locationLink(
          'Scotland',
          Scotland,
          '?address=Scotland%2C%20UK&bounds=60.8607515%2C-0.7246751%2C54.6332381%2C-8.6500072&origin=56.49067119999999%2C-4.2026458&pub_category=trekking',
          3
        )}
      </div>
    </div>
  );
};

SectionLocations.defaultProps = {
  rootClassName: null,
  className: null,
  favorites: false,
};

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
