import React, { useState } from 'react';
import { string } from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { formatMoney, convertPrice } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ensureCurrentUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { NamedLink } from '../../components';
import { ListingFavouritesForm } from '../../forms';
import config from '../../config';
import { InlineShareButtons } from 'sharethis-reactjs';
import {
  IconCategory,
  IconShare,
  IconMarker,
} from '..';

import css from './SimilarListingCard.module.css';
import { pathByRouteName } from "../../util/routes";
import routeConfiguration from "../../routeConfiguration";

const { Money } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'SimilarListingCard.unsupportedPrice' },
        { currency: price ? price.currency : '' }
      ),
      priceTitle: intl.formatMessage(
        { id: 'SimilarListingCard.unsupportedPriceTitle' },
        { currency: price ? price.currency : '' }
      ),
    };
  }
  return {};
};

export const SimilarListingCardComponent = props => {
  const [ index, setIndex ] = useState(0);
  const [ isShareOpened, setShareOpened ] = useState(false);
  const {
    className,
    rootClassName,
    intl,
    listing,
    currentUser,
    onUpdateProfile,
    isAuthenticated,
    categoriesConfig
  } = props;

  const {
    attributes: {
      publicData: {
        categories = [],
        fitness_levels = [],
        skill_levels = [],
        duration_days,
        guided,
        themes = [],
      } = {}
    }
  } = listing || {};

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  const save = async values => {
    await sleep(1000)
  };
  const user = ensureCurrentUser(currentUser);
  const id = listing.id;
  const listingFavourite = user.attributes.profile.publicData?.listingFavourite ?? [];
  const init = listingFavourite ? listingFavourite.filter(l => l.listingId === id) : [];

  const classes = classNames(rootClassName || css.root, className);
  const MIN_LENGTH_FOR_LONG_WORDS = 10;
  let title = listing.title;
  const slug = createSlug(title);

  const priceAmount = listing.priceAmount;
  const priceCurrency = listing.priceCurrency;
  const durationDays = listing.durationDays;
  const price = new Money(priceAmount, priceCurrency);
  const convertedPrice = convertPrice(currentUser, price);
  const convertedPerDayPrice = convertedPrice.amount / durationDays;
  const convertedPerDayPriceMoney = new Money(convertedPerDayPrice, convertedPrice.currency);
  const { priceTitle } = priceData(convertedPerDayPriceMoney, intl);
  const formattedPerDayPrice = formatMoney(intl, convertedPerDayPriceMoney);

  const category = listing.category;
  const images = listing.listingImages;
  // const listingImages = listing.listingImages;
  const authorName = listing.authorName;
  const locationName = listing.locationName;
  const avatar = listing.avatar;
  const { skillLevels, fitnessLevels } = listing;
  const categoryLabel = (categories, key) => {
    const cat = categories.find(c => c.key === key);
    return cat ? cat.label : key;
  };
  const amenities = listing.amenities ? listing.amenities : [];
  const originPathName = typeof window !== 'undefined' ? window.location.origin : 'https://staging.activebreak.com';
  const pathname = pathByRouteName('ListingPage', routeConfiguration(), { id, slug });
  return (
    <div className={classes} onMouseLeave={() => setShareOpened(false)}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <div style={{ backgroundImage: images[ index ] ? `url(${images[ index ].url})` : 'none' }}
               className={css.imagePub}>
            <div className={css.shareLike}>
              <ListingFavouritesForm
                iconClassName={css.shareIcon}
                className={css.checkbox}
                isAuthenticated={isAuthenticated}
                initialValues={{ [ `favourite_${id}` ]: init[ 0 ] ? init[ 0 ][ `favourite_${id}` ] : false }}
                listingFavourite={listingFavourite}
                onSubmit={values => {
                  save(values);
                  let currentListingId = Object.keys(values)[ 0 ];
                  let currentListingValue = values[ `favourite_${id}` ];
                  currentListingId = currentListingId.replace('favourite_', '');
                  const hasCurrentListing = listingFavourite.filter(l => l.listingId === currentListingId).length > 0;

                  if (!hasCurrentListing && currentListingValue) {
                    onUpdateProfile({
                      publicData: {
                        listingFavourite: [ ...listingFavourite, {
                          title,
                          categories,
                          authorName,
                          avatar,
                          fitness_levels,
                          locationName,
                          skill_levels,
                          guided,
                          listingImages: [],
                          duration_days,
                          themes,
                          listingId: id,
                          [ `favourite_${id}` ]: true
                        } ]
                      }
                    });
                  }

                  if (hasCurrentListing && !currentListingValue) {
                    const removeCurrentListing = listingFavourite.filter(l => l.listingId !== currentListingId);
                    onUpdateProfile({
                      publicData: {
                        listingFavourite: removeCurrentListing
                      }
                    });
                  }
                }}
                onChange={values => {
                }}
                listingId={id}
              />

              {isShareOpened &&
              <div className={css.shareButtonsHolder}>
                <InlineShareButtons
                  config={{
                    enabled: true,
                    networks: [
                      'facebook',
                      'twitter',
                      'pinterest',
                      'whatsapp',
                      'email'
                    ],
                    image: images[ 0 ].url,
                    description: 'custom text',
                    title: title,
                    message: originPathName + pathname,
                    subject: 'Take a look at the listing',
                    username: 'custom twitter handle',
                    top: 0,
                    url: originPathName + pathname,
                    show_mobile: true,
                    show_toggle: false,
                  }}
                />
              </div>}

              <div onClick={() => setShareOpened(!isShareOpened)}>
                <IconShare/>
              </div>

            </div>

            <button className={css.imageButtonPrev} onClick={() => {
              if (index === 0) setIndex(images.length - 1);
              else setIndex(index - 1)
            }}/>

            <button className={css.imageButtonNext} onClick={() => {
              if (index === images.length - 1) setIndex(0);
              else setIndex(index + 1)
            }}/>

          </div>
        </div>
      </div>
      <div className={css.mainInfo}>
        <NamedLink params={{ id, slug }} name="ListingPage" className={css.title}>
          <>
          {richText(title, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })}
          </>
        </NamedLink>
        <div className={css.topInfoRow}>
          <div className={css.topInfoItem}>
            <span className={css.categoryItem}>
              <span className={css.skitouringIcon}>
                <IconCategory cat={category}/>
              </span>
              {categoryLabel(categoriesConfig, category)}
            </span>
          </div>
          <div className={css.topInfoItem}>
            <span className={css.locationItem}>
              <IconMarker/>
              {locationName}
            </span>
          </div>
          <div className={css.topInfoItem}>
            <span className={css.avatarItem}>
              {/*{avatar}*/}
              {authorName}
            </span>
          </div>
        </div>
        <div className={css.priceInfoRow} title={priceTitle}>
          <span className={css.priceLabel}><FormattedMessage id="BookingPanel.perDay"/></span>
          <span className={css.priceValue}>{formattedPerDayPrice}</span>
        </div>
        <ul className={css.amenitiesInfoRow}>
          {amenities.length > 0 && amenities.map(e => (
              <li key={e.info_title} className={css.amenityItem}>
                <IconCategory cat={e.info_title}/>
                <span className={css.amenityText}>{e.info_title}</span>
              </li>
            ))}
        </ul>
        <div className={css.skillsInfoRow}>
          <div className={css.skillsInfoRowItem}>
            <IconCategory cat={skillLevels.length > 0 ? skillLevels[ 0 ].title : null}/>
            {skillLevels.length > 0 ? skillLevels[ 0 ].title : null}
          </div>
          <div className={css.skillsInfoRowItem}>
            <IconCategory cat={fitnessLevels.length > 0 ? fitnessLevels[ 0 ].title : null}/>
            {fitnessLevels.length > 0 ? fitnessLevels[ 0 ].title : null}
          </div>
        </div>
      </div>
    </div>
  );
};

SimilarListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  categoriesConfig: config.custom.categories,
  amenitiesConfig: config.custom.amenities,
};

SimilarListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(SimilarListingCardComponent);
