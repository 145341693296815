import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import Slider from "react-slick";
import { SimilarListingCard } from '../../components';

import css from './SectionSimilar.module.css';

const SectionSimilar = props => {
  const {
    rootClassName,
    className,
    onUpdateProfile,
    currentUser,
    isAuthenticated,
    listings
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const similarListings = currentUser && currentUser.attributes.profile.publicData.latestSearches ? currentUser.attributes.profile.publicData.latestSearches : [];
  const lang =  (typeof localStorage !== 'undefined' && localStorage.getItem('lang')) ? localStorage.getItem('lang') : 'de';
  const similarListingsFiltered = similarListings.filter( r => r.language === lang);

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div
        className={css.sliderNextArrow}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        className={css.sliderPrevArrow}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        }
      }
    ]
  };

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionExploreSimilar.title"/>
      </div>
      <div className={css.locations}>
        {!listings ? <div> There is no similar </div> :
          <Slider
            {...settings}
            className={css.slickTrack}>
            {similarListingsFiltered.length > 0 ? similarListingsFiltered.map((l, i) => {
                return (<SimilarListingCard
                  listing={l}
                  isAuthenticated={isAuthenticated}
                  onUpdateProfile={onUpdateProfile}
                  currentUser={currentUser}
                  key={i}
                />)
              }
            ) : null}
          </Slider>
        }
      </div>
    </div>
  );
};

SectionSimilar.defaultProps = {
  listings: [],
  rootClassName: null,
  className: null,
  currentUser: null
};

const { string } = PropTypes;

SectionSimilar.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionSimilar;
