import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { Form, PrimaryButton, FieldRadioButton } from '../../components';

import css from './CommunicationsForm.module.css';

const RESET_TIMEOUT = 800;

class CommunicationsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.resetTimeoutId = null;
    this.submittedValues = {};
  }
  componentWillUnmount() {
    window.clearTimeout(this.resetTimeoutId);
  }
  render() {
    const {
      intl
    } = this.props
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            currentUser,
            handleSubmit,
            inProgress,
            ready,
            form,
            values,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          const classes = classNames(rootClassName || css.root, className);

          const dailyTitle = intl.formatMessage({ id: 'CommunicationsForm.dailyTitle' });
          const weeklyTitle = intl.formatMessage({ id: 'CommunicationsForm.weeklyTitle' });
          const monthlyTitle = intl.formatMessage({ id: 'CommunicationsForm.monthlyTitle' });
          const no_newsTitle = intl.formatMessage({ id: 'CommunicationsForm.no_newsTitle' });

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <FieldRadioButton
                id="daily"
                name="newsletter"
                label={dailyTitle}
                value="daily"
                // showAsRequired={showAsRequired}
              />
              <FieldRadioButton
                id="weekly"
                name="newsletter"
                label={weeklyTitle}
                value="weekly"
                // showAsRequired={showAsRequired}
              />
              <FieldRadioButton
                id=""
                name="newsletter"
                label={monthlyTitle}
                value="monthly"
                // showAsRequired={showAsRequired}
              />
              <FieldRadioButton
                id=""
                name="newsletter"
                label={no_newsTitle}
                value="no_news"
                // showAsRequired={showAsRequired}
              />
              <div className={css.divider}></div>
              <div className={css.buttonHolder}>
                <PrimaryButton
                  type="submit"
                  inProgress={inProgress}
                  ready={ready}
                >
                  <FormattedMessage id="CommunicationsForm.saveChanges" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

CommunicationsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  formId: null,
};

const { bool, string } = PropTypes;

CommunicationsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,
  intl: intlShape.isRequired,
  formId: string,
};

const CommunicationsForm = compose(injectIntl)(CommunicationsFormComponent);
CommunicationsForm.displayName = 'CommunicationsForm';

export default CommunicationsForm;
