import React from 'react';

const EyeIcon = () => {
  return (
    <svg width="31" height="18" viewBox="0 0 31 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5002 14.9577C18.7218 14.9577 21.3335 12.346 21.3335 9.12435C21.3335 5.90269 18.7218 3.29102 15.5002 3.29102C12.2785 3.29102 9.66683 5.90269 9.66683 9.12435C9.66683 12.346 12.2785 14.9577 15.5002 14.9577Z" stroke="#101010" strokeMiterlimit="10"/>
      <path d="M15.5002 0.791016C10.2663 0.791016 5.53569 3.0197 2.31485 6.60497C1.00638 8.05846 1.00638 10.1902 2.31485 11.6437C5.53569 15.229 10.2663 17.4577 15.5002 17.4577C20.734 17.4577 25.4646 15.229 28.6855 11.6437C29.9939 10.1902 29.9939 8.05846 28.6855 6.60497C25.4646 3.0197 20.734 0.791016 15.5002 0.791016Z" stroke="#101010" strokeMiterlimit="10"/>
    </svg>
  )
}

export default EyeIcon;
