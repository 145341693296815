import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { IconActivity } from '../../components';

import css from './FieldCheckbox.module.css';

const IconCheckbox = props => {
  const { className, checkedClassName, boxClassName, labelClassName } = props;
  const labelClasses = classNames(labelClassName || css.label, className);

  return (
    <svg className={className} width="14" height="14" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(2 2)">
          <path
            className={checkedClassName || css.checked}
            d="M9.9992985 1.5048549l-.0194517 6.9993137C9.977549 9.3309651 9.3066522 10 8.4798526 10H1.5001008c-.8284271 0-1.5-.6715729-1.5-1.5l-.000121-7c0-.8284271.6715728-1.5 1.5-1.5h.000121l6.9993246.0006862c.8284272.000067 1.4999458.671694 1.499879 1.5001211a1.5002208 1.5002208 0 0 1-.0000059.0040476z"
          />
          <path
            className={boxClassName || css.box}
            strokeWidth="2"
            d="M10.9992947 1.507634l-.0194518 6.9993137C10.9760133 9.8849417 9.8578519 11 8.4798526 11H1.5001008c-1.3807119 0-2.5-1.1192881-2.5-2.4999827L-1.0000202 1.5c0-1.3807119 1.119288-2.5 2.500098-2.5l6.9994284.0006862c1.3807118.0001115 2.4999096 1.11949 2.4997981 2.5002019-.0000018.003373-.0000018.003373-.0000096.0067458z"
          />
        </g>
        <path
          d="M5.636621 10.7824771L3.3573694 8.6447948c-.4764924-.4739011-.4764924-1.2418639 0-1.7181952.4777142-.473901 1.251098-.473901 1.7288122 0l1.260291 1.1254782 2.8256927-4.5462307c.3934117-.5431636 1.1545778-.6695372 1.7055985-.278265.5473554.3912721.6731983 1.150729.2797866 1.6951077l-3.6650524 5.709111c-.2199195.306213-.5803433.5067097-.9920816.5067097-.3225487 0-.6328797-.1263736-.8637952-.3560334z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
};

const IconCheckboxDecor = props => {
  const { className, checkedClassName, boxClassName, labelClassName } = props;
  const labelClasses = classNames(labelClassName || css.label, className);

  return (
    <svg className={className} width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
      <path className={checkedClassName || css.checkedDecor} d="M3.95375 0.607422C2.0825 0.607422 0.59375 2.09617 0.59375 3.96742V17.4074C0.59375 19.2787 2.0825 20.7674 3.95375 20.7674H17.3937C19.265 20.7674 20.7537 19.2787 20.7537 17.4074V5.88742L19.7937 6.99742V17.4074C19.7937 18.7518 18.7381 19.8074 17.3937 19.8074H3.95375C2.60938 19.8074 1.55375 18.7518 1.55375 17.4074V3.96742C1.55375 2.62305 2.60938 1.56742 3.95375 1.56742H16.6287L17.3937 0.607422H3.95375ZM19.4338 2.40742L10.1487 13.3574L6.24875 9.71242L5.58875 10.4174L9.86375 14.3924L10.2387 14.7374L10.5538 14.3624L20.1537 3.02242L19.4338 2.40742Z" fill="black"/>
      <path className={boxClassName || css.boxDecor} d="M0.519531 4.35474C0.519531 2.24416 2.23049 0.533203 4.34107 0.533203H16.8211C18.9317 0.533203 20.6426 2.24416 20.6426 4.35474V16.8347C20.6426 18.9453 18.9317 20.6563 16.8211 20.6563H4.34107C2.23049 20.6563 0.519531 18.9453 0.519531 16.8347V4.35474ZM4.34107 1.45628C2.74029 1.45628 1.44261 2.75396 1.44261 4.35474V16.8347C1.44261 18.4355 2.74029 19.7332 4.34107 19.7332H16.8211C18.4218 19.7332 19.7195 18.4355 19.7195 16.8347V4.35474C19.7195 2.75396 18.4218 1.45628 16.8211 1.45628H4.34107Z" fill="black"/>
    </svg>
  );
};

IconCheckbox.defaultProps = { className: null, checkedClassName: null, boxClassName: null };

IconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string };

const FieldCheckboxComponent = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    textClassName,
    labelClassName,
    checkboxWrapperClassName,
    id,
    label,
    useSuccessColor,
    isDecorCheck,
    iconKey,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className, {[css.rootWithIcon] : !!iconKey});

  // This is a workaround for a bug in Firefox & React Final Form.
  // https://github.com/final-form/react-final-form/issues/134
  const handleOnChange = (input, event) => {
    const { onBlur, onChange } = input;
    onChange(event);
    onBlur(event);
  };

  const successColorVariantMaybe = useSuccessColor
    ? {
        checkedClassName: css.checkedSuccess,
        boxClassName: css.boxSuccess,
      }
    : {};

  const labelClasses = classNames(labelClassName || css.label, className);
  const checkboxWrapperClasses = classNames(checkboxWrapperClassName || css.checkboxWrapper, className);


  return (
    <span className={classes}>
      <Field type="checkbox" {...rest}>
        {props => {
          const input = props.input;
          return (
            <input
              id={id}
              className={css.input}
              {...input}
              onChange={event => handleOnChange(input, event)}
            />
          );
        }}
      </Field>
      <label htmlFor={id} className={css.label}>
        <span className={css.checkboxWrapper}>
          {iconKey ? <IconActivity className={svgClassName} type={iconKey} /> : isDecorCheck
            ? <IconCheckboxDecor className={svgClassName} {...successColorVariantMaybe} />
            : <IconCheckbox className={svgClassName} {...successColorVariantMaybe} />
          }
        </span>
        <span className={classNames(css.text, textClassName || css.textRoot)}>{label}</span>
      </label>
    </span>
  );
};

FieldCheckboxComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  label: null,
};

FieldCheckboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string,
};

export default FieldCheckboxComponent;
