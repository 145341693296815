import React from 'react';
import { Switch } from 'react-router';
import { useIntl } from 'react-intl';
import appRoutes from '../translations/appRoutes';

export const LocalizedSwitch = ({ children }) => {
  /**
   * inject params and formatMessage through hooks, so we can localize the route
   */
  const { formatMessage, locale } = useIntl();

  /**
   *
   * @param path can be string, undefined or string array
   * @returns Localized string path or path array
   */
  function localizeRoutePath(pageName) {
    switch (typeof pageName) {
      case 'undefined':
        return undefined;
      // case 'object':
      //   return path.map((key) => `/${locale}` + formatMessage({ id: key }));
      default: {
        const isFallbackRoute = pageName === '*';
        const localizedPath = isFallbackRoute
          ? pageName
          : `${locale === 'en' ? '' : '/'+locale}` + formatMessage({ id: appRoutes[pageName] });

        return localizedPath;
      }
    }
  }

  /**
   * Apply localization to all routes
   * Also checks if all children elements are <Route /> components
   */
  return (
    <Switch>
      {React.Children.map(children, (child) => {
        return React.isValidElement(child)
          ? React.cloneElement(child, {
              ...child.props,
              path: localizeRoutePath(child.key),
            })
          : child
        }
      )}
    </Switch>
  );
};
