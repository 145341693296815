import React, { useState } from 'react';
import { func, shape } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, FieldCheckboxLikeButton, AuthenticationModal } from '../../components';
import { IconCheckbox } from '../../components/FieldCheckboxLikeButton/FieldCheckboxLikeButton';

import css from './ListingFavouritesForm.css';
import AutoSave from './AutoSave';

export const ListingFavouritesFormComponent = (props) => {
  const [isAuthModalOpened, setAuthModalOpened] = useState(false);

  const updateModalData = (value) => {
    setAuthModalOpened(value);
  };

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          className,
          checkedClassName,
          checkedClassNameBorder,
          handleSubmit,
          currentUser,
          onChange,
          listingId,
          isAuthenticated,
          iconClassName,
          svgClassName,
          label,
        } = fieldRenderProps;

        const classes = classNames(css.root, className);
        return (
          <Form
            onClick={e => e.stopPropagation()}
            onSubmit={handleSubmit}
            className={classes}
            onChange={onChange}
          >
            <AutoSave
              debounce={1000}
              save={handleSubmit}
            />
            {isAuthenticated
              ? <FieldCheckboxLikeButton
                  className={iconClassName}
                  checkedClassName={checkedClassName}
                  checkedClassNameBorder={checkedClassNameBorder}
                  svgClassName={svgClassName}
                  currentUser={currentUser}
                  id={`favourite_${listingId}`}
                  name={`favourite_${listingId}`}
                  label={label}
                />
              : (
                <>
                  <IconCheckbox onCheckboxClick={() => setAuthModalOpened(!isAuthModalOpened)} />
                  <AuthenticationModal
                    updateModalData={updateModalData}
                    isAuthModalOpened={isAuthModalOpened}
                  />
                </>
              )}
          </Form>
        );
      }}
    />
  );
};

ListingFavouritesFormComponent.defaultProps = { fetchErrors: null };

ListingFavouritesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(ListingFavouritesFormComponent);
