import React, { Component } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import HeroSearchForm from '../../forms/HeroSearchForm/HeroSearchForm';

// Styles
import css from './SectionHero.module.css';

class SectionHero extends Component{
  state = {
    focusedInput: null,
    startDate: null,
    endDate: null,
    selectedValue: null,
  };

  render() {
    const { rootClassName, className, availableCategories, intl, datepickerPlaceholder } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    return (
      <div className={classes}>
        <div className={css.heroContent}>
          <h1 className={css.heroMainTitle}>
            <FormattedMessage id="SectionHero.title" />
          </h1>
          <h1 className={css.heroMainTitleMobile}>
            <FormattedMessage id="SectionHero.titleMobile" />
          </h1>
          <HeroSearchForm
            intl={intl}
            datepickerPlaceholder={datepickerPlaceholder}
            availableCategories={availableCategories}
          />
        </div>
      </div>
    );
  }
}

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
