import React from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import classNames from 'classnames';

import css from './ListingPage.module.css';

const ListingHashNav = ({ location, listing, intl }) => {
  const {
    attributes: {
      publicData: {
        reviews = [],
        faqs = [],
      }
    }
  } = listing;

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const spotlightTitle = intl.formatMessage({ id: 'SectionSpotlight.spotlightTitle' });
  const tripInsightsTitle = intl.formatMessage({ id: 'SectionSpotlight.tripInsightsTitle' });
  const packageOptionsTitle = intl.formatMessage({ id: 'SectionSpotlight.packageOptionsTitle' });
  const detailsTitle = intl.formatMessage({ id: 'SectionSpotlight.detailsTitle' });
  const FAQsTitle = intl.formatMessage({ id: 'SectionSpotlight.FAQsTitle' });
  const ReviewsTitle = intl.formatMessage({ id: 'SectionSpotlight.ReviewsTitle' });
  const similarTitle = intl.formatMessage({ id: 'SectionSpotlight.similarTitle' });

  const navItems = [
    { link: '#spotlight', name: spotlightTitle, show: true },
    { link: '#insights', name: tripInsightsTitle, show: true },
    { link: '#availability', name: packageOptionsTitle, show: true },
    { link: '#details', name: detailsTitle, show: true },
    { link: 'faq', name: FAQsTitle, show: faqs.length > 0 ? true : false },
    { link: '#reviews', name: ReviewsTitle, show: reviews.length > 0 ? true : false },
    { link: '#similar', name: similarTitle, show: false },
  ];

  return navItems.map(item => {
    return (
      <div key={item.link}>
        {item.show && (
          <NavLink
            className={location.hash === item.link ? classNames(css.selected, css.navItem) : css.navItem}
            to={item.link}
            smooth
            scroll={el => scrollWithOffset(el)}
            activeClassName={css.selected}
          >
            {item.name}
          </NavLink>
        )}
      </div>
    )
  })
};

export default ListingHashNav;
