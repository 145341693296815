import React, { Component } from 'react';
import { DynamicGoogleMapWithMultyMarkers } from '../../components';

class SurroundingsBlock extends Component {
  render() {
    const { listing } = this.props;
    return(
      <DynamicGoogleMapWithMultyMarkers listing={listing} location={this.props.location} />
    )
  }
}

export default SurroundingsBlock;
