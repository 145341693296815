import React from 'react';
import PropTypes from 'prop-types';

import css from './IconActivity.module.css';

const IconActivityFoot = props => {
  const { className, type } = props;

  if (type === "walking") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M26.236 21.091L22.7 19.543a2.416 2.416 0 01-1.285-1.386l-1.686-4.726-.402 7.579c0 .407.08.814.241 1.14l5.22 10.758m-11.887-.652l4.338-3.668c.562-.489.883-1.059 1.044-1.792l1.044-5.542m4.277-11.68a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm-5.562 4.02l-.241 4.89c0 .325-.241.57-.482.733-.562.244-1.687.489-2.73-.163-.242-.163-.483-.49-.402-.815 0-1.141.32-4.238 2.73-5.46.563-.245 1.205.244 1.125.815z"
        ></path>
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M26.236 21.091L22.7 19.543a2.416 2.416 0 01-1.285-1.386l-1.686-4.726-.402 7.579c0 .407.08.814.241 1.14l5.22 10.758m-11.887-.652l4.338-3.668c.562-.489.883-1.059 1.044-1.792l1.044-5.542m4.277-11.68a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm-5.562 4.02l-.241 4.89c0 .325-.241.57-.482.733-.562.244-1.687.489-2.73-.163-.242-.163-.483-.49-.402-.815 0-1.141.32-4.238 2.73-5.46.563-.245 1.205.244 1.125.815z"
        ></path>
      </svg>
    );
  } else if (type === "running") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M15.504 17.628l1.388-5.147c.26-.785 1.129-1.309 1.91-1.047l5.035 1.658m0 0v4.973c0 .872.694 1.483 1.476 1.483H30m-6.163-6.456l-14.67 20.24m6.944-4.536l6.945-3.577c.694-.349.78-1.309.173-1.745l-4.34-3.228M29.255 9.097a2.507 2.507 0 01-1.833 3.029 2.493 2.493 0 01-3.021-1.829 2.507 2.507 0 011.833-3.028 2.493 2.493 0 013.02 1.828z"
        ></path>
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M15.504 17.628l1.388-5.147c.26-.785 1.129-1.309 1.91-1.047l5.035 1.658m0 0v4.973c0 .872.694 1.483 1.476 1.483H30m-6.163-6.456l-14.67 20.24m6.944-4.536l6.945-3.577c.694-.349.78-1.309.173-1.745l-4.34-3.228M29.255 9.097a2.507 2.507 0 01-1.833 3.029 2.493 2.493 0 01-3.021-1.829 2.507 2.507 0 011.833-3.028 2.493 2.493 0 013.02 1.828z"
        ></path>
      </svg>
    );
  } else if (type === "canyoning") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M23.364 14.288l-10.44-.165m0 0l-2.692 2.568c-.897.828-1.142 2.153-.734 3.313l1.631 4.39m1.795-10.271l.734 7.123a1.807 1.807 0 002.447 1.16l3.018-1.657c.652-.331 1.468-.248 1.957.332l4.16 4.224m-6.036 5.633l-.244-4.722c0-.745-.408-1.408-.98-1.822l-2.283-1.74m8.646-15.986l-2.12 7.538-12.073 9.94c-1.142.91-1.305 2.65-.408 3.81l.898 1.16c.979 1.242 1.06 2.981.326 4.306l-.408.746m9.299-.58c.897-1.74 3.426-4.473 5.057-5.384l.653-.331c1.06-.58 1.713-1.657 1.631-2.9l-.081-1.656c0-.994.326-1.905.978-2.65l.082-.083c.652-.746 1.06-1.74 1.06-2.734v-1.325c0-1.988.408-3.396 3.263-4.639m-8.89 20.128c.733-.828 1.712-1.573 2.528-2.07l.57-.332c1.55-.662 2.448-1.905 2.53-3.727l-.082-2.32c0-1.076.734-1.656.734-1.656m-3.1 10.437c.327-.332.816-.58 1.224-.746l.49-.248c.489-.166 1.06-.58 1.304-.994m-4.241-10.354l.49-.746c.489-.745.815-1.656.815-2.65v-1.574a4.61 4.61 0 011.305-3.23c.245-.249.57-.58.897-.746m-4.894 5.384s-.163 1.822-1.794 3.148m-6.257-9.594a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        ></path>
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M23.364 14.288l-10.44-.165m0 0l-2.692 2.568c-.897.828-1.142 2.153-.734 3.313l1.631 4.39m1.795-10.271l.734 7.123a1.807 1.807 0 002.447 1.16l3.018-1.657c.652-.331 1.468-.248 1.957.332l4.16 4.224m-6.036 5.633l-.244-4.722c0-.745-.408-1.408-.98-1.822l-2.283-1.74m8.646-15.986l-2.12 7.538-12.073 9.94c-1.142.91-1.305 2.65-.408 3.81l.898 1.16c.979 1.242 1.06 2.981.326 4.306l-.408.746m9.299-.58c.897-1.74 3.426-4.473 5.057-5.384l.653-.331c1.06-.58 1.713-1.657 1.631-2.9l-.081-1.656c0-.994.326-1.905.978-2.65l.082-.083c.652-.746 1.06-1.74 1.06-2.734v-1.325c0-1.988.408-3.396 3.263-4.639m-8.89 20.128c.733-.828 1.712-1.573 2.528-2.07l.57-.332c1.55-.662 2.448-1.905 2.53-3.727l-.082-2.32c0-1.076.734-1.656.734-1.656m-3.1 10.437c.327-.332.816-.58 1.224-.746l.49-.248c.489-.166 1.06-.58 1.304-.994m-4.241-10.354l.49-.746c.489-.745.815-1.656.815-2.65v-1.574a4.61 4.61 0 011.305-3.23c.245-.249.57-.58.897-.746m-4.894 5.384s-.163 1.822-1.794 3.148m-6.257-9.594a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        ></path>
      </svg>
    );
  } else if (type === "iceClimbing") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M27.925 6.92l-5.062 5.214 2.158 2.44-2.656 3.784m0 0l5.062 5.55-5.726 5.382m.664-10.932l3.984-5.383M21.7 29.29l.498 3.028h-5.311l.166 1.009M21.7 29.29l7.47-6.896m-15.934-7.737l-.996 7.317c.332 1.009 1.41 1.514 2.323 1.093l2.822-1.598c.58-.336 1.41-.252 1.909.252l2.987 3.028 1.909-1.934m-4.564-6.308l-1.992.925a1.525 1.525 0 01-1.743-.336l-2.49-2.439h2.241c.415 0 .83-.168 1.162-.504l2.49-2.44m.995 17.83H17.47l-.166-2.86c0-.672-.415-1.345-.913-1.766l-2.158-1.598m5.312-5.802l.83-3.196s.995-.925 1.908.336M18.05 6.668s-1.162 8.83-5.643 15.222m6.639-8.83l.83-3.196s.995-.925 1.908.336M19.627 29.88v1.01m-1.328-1.01v1.01m-1.162 1.429h7.469m-.581-8.746l.747.756m-1.743.253l.83.757M15 10.26a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        ></path>
      </svg>
    );
  } else if (type === "hiking") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M27.031 18.518V8.407m-2.969 3.217l2.97-3.294L30 11.624m-5.938 3.523l2.97-3.217L30 15.147m-7.565 6.37l-3.517-1.549a2.411 2.411 0 01-1.278-1.385l-1.68-4.726-.399 7.578c0 .408.08.815.24 1.141l5.196 10.757m-10.483-17.95c.442-.974 1.149-1.95 2.298-2.482.618-.266 1.325.178 1.237.887l-.265 5.32c0 .354-.266.62-.53.797-.62.266-1.856.532-3.005-.177-.089-.089-.177-.089-.265-.177a.806.806 0 01-.266-.62v-.533m-.551 14.283l4.316-3.667c.56-.489.88-1.06 1.04-1.793l1.038-5.541M19.841 10a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm-9.857 8.31c1.325 0 1.679-1.242 1.855-1.951.089-.443-.265-.887-.707-.887h-.883s-.884 0-1.06 1.508c-.178 1.507.795 1.33.795 1.33z"
        ></path>
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M27.031 18.518V8.407m-2.969 3.217l2.97-3.294L30 11.624m-5.938 3.523l2.97-3.217L30 15.147m-7.565 6.37l-3.517-1.549a2.411 2.411 0 01-1.278-1.385l-1.68-4.726-.399 7.578c0 .408.08.815.24 1.141l5.196 10.757m-10.483-17.95c.442-.974 1.149-1.95 2.298-2.482.618-.266 1.325.178 1.237.887l-.265 5.32c0 .354-.266.62-.53.797-.62.266-1.856.532-3.005-.177-.089-.089-.177-.089-.265-.177a.806.806 0 01-.266-.62v-.533m-.551 14.283l4.316-3.667c.56-.489.88-1.06 1.04-1.793l1.038-5.541M19.841 10a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm-9.857 8.31c1.325 0 1.679-1.242 1.855-1.951.089-.443-.265-.887-.707-.887h-.883s-.884 0-1.06 1.508c-.178 1.507.795 1.33.795 1.33z"
        ></path>
      </svg>
    );
  } else if (type === "mountaineering") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M11.917 10.787l4.833 3.617c.417.253.917.337 1.333.085l5.334-3.029m-6 3.281v4.963l-1.667 7.571m1.75-7.402l1.917.336c.666.168 1.333.589 1.583 1.262l1.25 2.944m.083-9.59l2.917-7.487m.083-.084s.667-.505 1.834.505c1.166 1.01 2.5.252 2.5.252l-1.334 2.86s-1.416.59-2.333-.588c-.917-1.178-1.75-.421-1.75-.421m-15.5 23.12l.333-1.093c.167-.42.5-.757 1-.926l1.75-.42.5-.252L14 29.029a.753.753 0 01.583-.253l3.167-.589c.25-.084.5-.168.667-.336l1.416-1.43c.25-.252.5-.337.834-.42l1.75-.169c.416 0 .833.168 1.166.505l3.5 4.374c.167.252.417.337.667.42l2.167.59c.25.084.5.252.666.42l.667.757m-11-23.312a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        ></path>
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M11.917 10.787l4.833 3.617c.417.253.917.337 1.333.085l5.334-3.029m-6 3.281v4.963l-1.667 7.571m1.75-7.402l1.917.336c.666.168 1.333.589 1.583 1.262l1.25 2.944m.083-9.59l2.917-7.487m.083-.084s.667-.505 1.834.505c1.166 1.01 2.5.252 2.5.252l-1.334 2.86s-1.416.59-2.333-.588c-.917-1.178-1.75-.421-1.75-.421m-15.5 23.12l.333-1.093c.167-.42.5-.757 1-.926l1.75-.42.5-.252L14 29.029a.753.753 0 01.583-.253l3.167-.589c.25-.084.5-.168.667-.336l1.416-1.43c.25-.252.5-.337.834-.42l1.75-.169c.416 0 .833.168 1.166.505l3.5 4.374c.167.252.417.337.667.42l2.167.59c.25.084.5.252.666.42l.667.757m-11-23.312a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        ></path>
      </svg>
    );
  } else if (type === "viaFerrata") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M20.445 13.728l-8.82-.082c-3.348.82-2.777 3.201-1.225 3.858l4.328 1.724m-3.103-5.5l-.98 6.895a1.814 1.814 0 002.45 1.15l3.022-1.642c.653-.329 1.47-.247 1.96.328l4.165 4.187m-5.962 5.582l-.245-4.68c0-.738-.408-1.395-.98-1.805l-2.205-1.724M24.365 9.377L9.91 21.28m12.005 5.336l-2.613-2.545m-5.227 6.32l2.695 2.628m5.553-16.09l3.022 2.955m1.143-6.895l-2.776-2.873M30 9.376l-2.45 1.068c-.327.164-.572.41-.572.821l-.571 4.761c0 .164 0 .329.081.493l.572 1.642c.163.492 0 .985-.408 1.23l-2.614 1.479c-.408.246-.571.738-.408 1.23l.735 1.971a.876.876 0 01-.163.985l-3.675 3.53c-.164.246-.327.575-.245.82l.326 1.643c.082.492-.163.985-.653 1.149l-5.88 1.97m1.57-25a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        ></path>
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M20.445 13.728l-8.82-.082c-3.348.82-2.777 3.201-1.225 3.858l4.328 1.724m-3.103-5.5l-.98 6.895a1.814 1.814 0 002.45 1.15l3.022-1.642c.653-.329 1.47-.247 1.96.328l4.165 4.187m-5.962 5.582l-.245-4.68c0-.738-.408-1.395-.98-1.805l-2.205-1.724M24.365 9.377L9.91 21.28m12.005 5.336l-2.613-2.545m-5.227 6.32l2.695 2.628m5.553-16.09l3.022 2.955m1.143-6.895l-2.776-2.873M30 9.376l-2.45 1.068c-.327.164-.572.41-.572.821l-.571 4.761c0 .164 0 .329.081.493l.572 1.642c.163.492 0 .985-.408 1.23l-2.614 1.479c-.408.246-.571.738-.408 1.23l.735 1.971a.876.876 0 01-.163.985l-3.675 3.53c-.164.246-.327.575-.245.82l.326 1.643c.082.492-.163.985-.653 1.149l-5.88 1.97m1.57-25a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        ></path>
      </svg>
    );
  } else if (type === "rockClimbing") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M22.995 13.713l-4.028 1.724c-.576.247-1.233.247-1.809-.082l-3.408-1.642 9.163-4.682m-9.163 4.72l-1.114 7.27a1.829 1.829 0 002.467 1.15l3.042-1.642c.657-.328 1.48-.246 1.973.329l3.946 3.942m-5.755 5.338l-.247-4.27c0-.74-.41-1.397-.986-1.807l-2.302-1.725m2.795 11.415l.74-1.56 3.206-.41-.41-4.19 5.096-1.56.33-2.956-4.111-3.86-.33-2.382 4.934-2.3-3.618-2.545 1.727-3.778m.41 6.98l3.207-1.477m-3.7 12.647l3.618-1.068m-8.386 6.653l3.289-.411M18.474 5.418s-1.48 8.952-5.59 15.85m2.533-12.2a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        ></path>
      </svg>
    );
  } else if (type === "trekking") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M11.65 33.333l8.356-3.767c.383-.154.613-.461.69-.846l2.836-11.84c.077-.23.383-.384.613-.23l.537.23c.23.077.537 0 .613-.308l2.3-7.227c.153-.461.843-.461.92 0l1.686 6.459c.077.307.384.46.69.307l.307-.154c.307-.153.613 0 .69.308l.613 2.998m-6.21-5.458l.307.153c.384.231.92.231 1.304 0 .306-.23.69-.23.996-.153l.69.23m-8.816 6.766l-3.373-1.46a2.29 2.29 0 01-1.226-1.308l-1.687-3.979-.383 6.593c0 .385-.077.846.23 1.077l3.373 2.46c.613.461.843 1.23.613 1.922l-.537 1.922m-5.749 2.384l2.008-8.984M9.284 13.01l-1.917 2c-.306.307-.536.768-.536 1.23l-.154 1.383c-.076.462.23.846.69.923l4.983.307m15.55 11.021V19.726m-2.99 3.229l2.99-3.306 2.99 3.306m-5.98 3.537l2.99-3.23 2.99 3.23M19.041 10a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm-7.456 1.395c.536 0 1.073.461.996 1.076l-.23 7.15c0 .308-.23.539-.46.692-.536.231-1.61.462-2.606-.153-.23-.154-.46-.462-.383-.77 0-.46.536-5.535.766-7.073-.076-.538.46-.922.997-.922h.92zm1.073-1.615c0 .807-.652 1.46-1.457 1.46a1.459 1.459 0 01-1.456-1.46c0-.807.652-1.46 1.456-1.46.805 0 1.457.653 1.457 1.46z"
        ></path>
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M11.65 33.333l8.356-3.767c.383-.154.613-.461.69-.846l2.836-11.84c.077-.23.383-.384.613-.23l.537.23c.23.077.537 0 .613-.308l2.3-7.227c.153-.461.843-.461.92 0l1.686 6.459c.077.307.384.46.69.307l.307-.154c.307-.153.613 0 .69.308l.613 2.998m-6.21-5.458l.307.153c.384.231.92.231 1.304 0 .306-.23.69-.23.996-.153l.69.23m-8.816 6.766l-3.373-1.46a2.29 2.29 0 01-1.226-1.308l-1.687-3.979-.383 6.593c0 .385-.077.846.23 1.077l3.373 2.46c.613.461.843 1.23.613 1.922l-.537 1.922m-5.749 2.384l2.008-8.984M9.284 13.01l-1.917 2c-.306.307-.536.768-.536 1.23l-.154 1.383c-.076.462.23.846.69.923l4.983.307m15.55 11.021V19.726m-2.99 3.229l2.99-3.306 2.99 3.306m-5.98 3.537l2.99-3.23 2.99 3.23M19.041 10a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm-7.456 1.395c.536 0 1.073.461.996 1.076l-.23 7.15c0 .308-.23.539-.46.692-.536.231-1.61.462-2.606-.153-.23-.154-.46-.462-.383-.77 0-.46.536-5.535.766-7.073-.076-.538.46-.922.997-.922h.92zm1.073-1.615c0 .807-.652 1.46-1.457 1.46a1.459 1.459 0 01-1.456-1.46c0-.807.652-1.46 1.456-1.46.805 0 1.457.653 1.457 1.46z"
        ></path>
      </svg>
    );
  } else if (type === "trailRunning") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M10.49 18.798l1.203-4.492c.226-.685.978-1.142 1.654-.913l4.362 1.446m0 0v4.34c0 .76.602 1.293 1.279 1.293h4.06M17.71 14.84L5 32.5m6.016-3.959l6.016-3.12c.602-.305.677-1.142.15-1.523l-3.76-2.817m10.371-3.317l.604-2.383c.075-.23.376-.385.601-.23l.526.23c.226.077.526 0 .601-.308l2.256-7.227c.15-.461.826-.461.901 0l1.654 6.458c.075.308.376.462.677.308l.3-.154c.301-.154.602 0 .677.308l.601 2.998m-6.088-5.459l.3.154c.376.23.902.23 1.278 0 .3-.23.677-.23.977-.154l.677.231m.067 16.19V18.578m-2.932 3.229l2.932-3.306 2.931 3.306m-5.863 3.537l2.932-3.23 2.931 3.23M22.323 10.74a2.517 2.517 0 01-1.836 3.034 2.483 2.483 0 01-3.016-1.826 2.517 2.517 0 011.835-3.033 2.483 2.483 0 013.017 1.826z"
        ></path>
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M10.49 18.798l1.203-4.492c.226-.685.978-1.142 1.654-.913l4.362 1.446m0 0v4.34c0 .76.602 1.293 1.279 1.293h4.06M17.71 14.84L5 32.5m6.016-3.959l6.016-3.12c.602-.305.677-1.142.15-1.523l-3.76-2.817m10.371-3.317l.604-2.383c.075-.23.376-.385.601-.23l.526.23c.226.077.526 0 .601-.308l2.256-7.227c.15-.461.826-.461.901 0l1.654 6.458c.075.308.376.462.677.308l.3-.154c.301-.154.602 0 .677.308l.601 2.998m-6.088-5.459l.3.154c.376.23.902.23 1.278 0 .3-.23.677-.23.977-.154l.677.231m.067 16.19V18.578m-2.932 3.229l2.932-3.306 2.931 3.306m-5.863 3.537l2.932-3.23 2.931 3.23M22.323 10.74a2.517 2.517 0 01-1.836 3.034 2.483 2.483 0 01-3.016-1.826 2.517 2.517 0 011.835-3.033 2.483 2.483 0 013.017 1.826z"
        ></path>
      </svg>
    );
  } else {
    return null;
  }
};

IconActivityFoot.defaultProps = { className: null };

const { string } = PropTypes;

IconActivityFoot.propTypes = {
  className: string,
};

export default IconActivityFoot;
