import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconRuning.module.css';

const IconRuning = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width={37}
      height={32}
      viewBox="0 0 37 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_499_6292)">
        <path
          d="M11.4585 2.39453C9.74741 2.50933 6.09707 3.31295 4.2719 8.47905C4.15783 8.93826 4.2719 9.39747 4.61412 9.74187C5.41263 10.5455 7.2378 12.1527 11.4585 14.2192C17.7325 17.3188 15.4511 19.3853 21.2688 22.1405C25.8317 24.3218 31.4213 22.7145 33.2464 21.4517C34.159 20.8777 33.9309 19.3853 31.3072 19.2705C28.7976 19.1557 26.0598 17.4336 24.3488 14.2192C23.208 12.0379 22.0673 9.62707 21.4969 8.36425C21.2688 7.90504 20.6984 7.56063 20.1281 7.67543C18.0747 8.13464 13.0555 8.82345 12.3711 3.19815C12.257 2.73894 11.9148 2.39453 11.4585 2.39453Z"
          stroke="black"
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.5887 21.2213C33.5887 21.2213 32.3339 26.1578 24.5769 26.3874C16.82 26.617 15.1089 20.5325 9.29115 18.6957C3.47343 16.8589 -0.0628349 13.5296 2.67492 10.2003C3.35935 9.39671 4.15787 8.9375 4.15787 8.9375"
          stroke="black"
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_499_6292">
          <rect
            width={36}
            height={31}
            fill="white"
            transform="translate(0.732422 0.394531)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

IconRuning.defaultProps = { className: null, rootClassName: null };

const { string } = PropTypes;

IconRuning.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconRuning;
