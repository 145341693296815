import React from 'react';
import PropTypes from 'prop-types';

const IconAccount = () => {
  return (
    <svg style={{ marginRight: 15 }} width='13' height='26' viewBox='0 0 16 26' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.8524 4.82605C13.5217 7.50137 11.8485 10.1767 9.17127 10.8455C6.49408 11.5144 3.81688 9.95375 3.14758 7.27843C2.47829 4.6031 4.15153 1.81631 6.82873 1.14748C9.50592 0.478651 12.1831 2.15073 12.8524 4.82605Z'
        stroke='black' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M8 31L2 31C1.44772 31 1 30.5523 1 30L1.00002 21.0727C1.00002 17.076 4.07577 13.7454 8.00002 13.7454C11.9243 13.7454 15 16.965 15 21.0727V30'
        stroke='black' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

const { string } = PropTypes;

IconAccount.defaultProps = {
  className: null,
  rootClassName: null,
};

IconAccount.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconAccount;
