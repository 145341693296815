import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
} from '../../components';
import { CommunicationsForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import css from './CommunicationsPage.module.css';
import { updateProfile } from "../ProfileSettingsPage/ProfileSettingsPage.duck";
import {ensureCurrentUser} from "../../util/data";

export const CommunicationsPageComponent = props => {
  const {
    currentUser,
    onUpdateProfile,
    scrollingDisabled,
    updateInProgress,
    updateProfileError,
    intl,
    location,
    values
  } = props;

  const handleSubmit = values => {
    console.log(values)
    const { newsletter } = values;

    const updatedValues = {
      publicData: { newsletter }
    };

    onUpdateProfile(updatedValues);
  };

  const user = ensureCurrentUser(currentUser);
  const { publicData } = user.attributes.profile;
  // console.log(publicData)

  const newsletter = publicData !== undefined ? publicData.newsletter : '';
  const initialValues = { newsletter };
  const communicationForm =
    currentUser && currentUser.id ? (
      <CommunicationsForm
        className={css.form}
        initialValues={initialValues}
        currentUser={currentUser}
        onSubmit={handleSubmit}
        updateInProgress={updateInProgress}
        intl={intl}
        updateProfileError={updateProfileError}
      />
    ) : null;

  const title = intl.formatMessage({ id: 'CommunicationsPage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="CommunicationsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="CommunicationsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav currentTab="CommunicationsPage" />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="CommunicationsPage.heading" />
            </h1>
            <h2><FormattedMessage id={"CommunicationsPage.subheading"}/></h2>
            <p className={css.description}><FormattedMessage id={"CommunicationsPage.newsletterDescription"}/></p>
            {communicationForm}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer currentPage="CommunicationsPage" location={location}/>
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

CommunicationsPageComponent.defaultProps = {
  updateProfileError: null,
  currentUser: null,
};

const { func, bool } = PropTypes;

CommunicationsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  onUpdateProfile: func.isRequired,
  scrollingDisabled: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser } = state.user;
  const {
    updateInProgress,
    updateProfileError,
  } = state.ProfileSettingsPage;
  return {
    currentUser,
    updateInProgress,
    updateProfileError,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const CommunicationsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(CommunicationsPageComponent);

export default CommunicationsPage;
