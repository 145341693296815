import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, FieldCheckbox } from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';

import css from './BecomeAHostForm.module.css';
import config from '../../config';
// import * as normalizePhoneNumberUS from "../PayoutDetailsForm/normalizePhoneNumberUS";
// import {minLength, phoneEmptyRequired, phoneFormatValid} from "../../util/validators";
// import {parseNew} from "../PayoutDetailsForm/normalizePhoneNumberUS";

const BecomeAHostFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        // formId,
        handleSubmit,
        inProgress,
        intl,
        invalid,
        // listingTitle,
        // authorDisplayName,
        // sendEnquiryError,
        values,
        schemaTitle
      } = fieldRenderProps;

      const firstNameLabel = intl.formatMessage({ id: 'BecomeAHostForm.firstNameLabel' });
      const firstNamePlaceholder = intl.formatMessage({ id: 'BecomeAHostForm.firstNamePlaceholder' });

      const lastNameLabel = intl.formatMessage({ id: 'BecomeAHostForm.lastNameLabel' });
      const lastNamePlaceholder = intl.formatMessage({ id: 'BecomeAHostForm.lastNamePlaceholder' });

      const companyNameLabel = intl.formatMessage({ id: 'BecomeAHostForm.companyNameLabel' });
      const companyNamePlaceholder = intl.formatMessage({ id: 'BecomeAHostForm.companyNamePlaceholder' });

      const websiteLabel = intl.formatMessage({ id: 'BecomeAHostForm.websiteLabel' });
      const websitePlaceholder = intl.formatMessage({ id: 'BecomeAHostForm.websitePlaceholder' });

      const emailLabel = intl.formatMessage({ id: 'BecomeAHostForm.emailLabel' });
      const emailPlaceholder = intl.formatMessage({ id: 'BecomeAHostForm.emailPlaceholder' });

      const phoneNumberLabel = intl.formatMessage({ id: 'BecomeAHostForm.phoneNumberLabel' });
      const phoneNumberPlaceholder = intl.formatMessage({ id: 'BecomeAHostForm.phoneNumberPlaceholder' });
      const phoneNumberRequired = intl.formatMessage({ id: 'PayoutDetailsForm.personalPhoneRequired' });
      // const phoneNumberForUSRequired = validators.minLength(
      //   phoneNumberRequired,
      //   12
      // );
      const phoneRequired = validators.phoneEmptyRequired(phoneNumberRequired);


      const messageRequiredMessage = intl.formatMessage({ id: 'EnquiryForm.messageRequired', });
      const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

      const emailRequiredMessage = intl.formatMessage({
        id: 'LoginForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'LoginForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const isChecked = values && values.getInTouch && values.getInTouch.length;
      const submitDisabled = invalid || !isChecked ||submitInProgress;

      const {
        waterOptions,
        bikeOptions,
        footOptions,
        snowOptions,
        otherOptions
      } = config.custom;

      const siteTitle = config.siteTitle;
      const labelCheckBox = intl.formatMessage({ id: 'BecomeAHostForm.labelCheckBox' })

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <h2 className={css.heading}>
            <FormattedMessage id="BecomeAHostForm.heading" />
          </h2>

          <div className={css.fieldsHolder}>
            <FieldTextInput
              type="text"
              id='firstname'
              name="firstname"
              className={css.field}
              label={firstNameLabel}
              placeholder={firstNamePlaceholder}
              validate={messageRequired}
            />
            <FieldTextInput
              type="text"
              id='lastname'
              name="lastname"
              className={css.field}
              label={lastNameLabel}
              placeholder={lastNamePlaceholder}
              validate={messageRequired}
            />
          </div>

          <FieldTextInput
            type="text"
            id='company'
            name="company"
            className={css.field}
            label={companyNameLabel}
            placeholder={companyNamePlaceholder}
            validate={messageRequired}
          />
          <FieldTextInput
            type="text"
            id='website'
            name="website"
            className={css.field}
            label={websiteLabel}
            placeholder={websitePlaceholder}
            validate={messageRequired}
          />

          <div className={css.fieldsHolder}>
            <FieldTextInput
              className={css.field}
              type="email"
              id='email'
              name="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <FieldTextInput
              type="text"
              id='phone'
              name="phone"
              className={css.field}
              // format={normalizePhoneNumberUS.format}
              // parse={normalizePhoneNumberUS.parseNew}
              // parse={normalizePhoneNumberUS.parse}
              label={phoneNumberLabel}
              placeholder={phoneNumberPlaceholder}
              validate={phoneRequired}
            />
          </div>

          <div className={css.options}>
            <h3 className={css.optionsTitle}>
              <FormattedMessage id="BecomeAHostForm.selectYourActivity" />
            </h3>
            {/* <p className={css.optionsSubTitle}>
              <FormattedMessage id="BecomeAHostForm.max3activites" />
            </p> */}

            <div className={css.optionsSection}>
              <div className={classNames(css.optionsSectionTitle, css.optionsSectionTitleWater)}>
                <FormattedMessage id="BecomeAHostForm.waterSection" />
              </div>
              <div className={css.optionsHolder}>
                {waterOptions.map(option => {
                  return (
                    <FieldCheckbox
                      key={option.translationKey}
                      id={option.translationKey}
                      className={css.textInputRow}
                      name="prospect_vertical"
                      label={intl.formatMessage({ id: `BecomeAHostForm.${option.translationKey}` })}
                      value={option.key}
                      iconKey={option.translationKey}
                    />
                  )
                })}
              </div>
            </div>

            <div className={css.optionsSection}>
              <div className={classNames(css.optionsSectionTitle, css.optionsSectionTitleBike)}>
                <FormattedMessage id="BecomeAHostForm.bikeSection" />
              </div>
              <div className={css.optionsHolder}>
                {bikeOptions.map(option => {
                  return (
                    <FieldCheckbox
                      key={option.translationKey}
                      id={option.translationKey}
                      className={classNames(css.textInputRow, css.textInputRowBike)}
                      name="prospect_vertical"
                      label={intl.formatMessage({ id: `BecomeAHostForm.${option.translationKey}` })}
                      value={option.key}
                      iconKey={option.translationKey}
                    />
                  )
                })}
              </div>
            </div>

            <div className={css.optionsSection}>
              <div className={classNames(css.optionsSectionTitle, css.optionsSectionTitleFoot)}>
                <FormattedMessage id="BecomeAHostForm.footSection" />
              </div>
              <div className={css.optionsHolder}>
                {footOptions.map(option => {
                  return (
                    <FieldCheckbox
                      key={option.translationKey}
                      id={option.translationKey}
                      className={css.textInputRow}
                      name="prospect_vertical"
                      label={intl.formatMessage({ id: `BecomeAHostForm.${option.translationKey}` })}
                      value={option.key}
                      iconKey={option.translationKey}
                    />
                  )
                })}
              </div>
            </div>

            <div className={css.optionsSection}>
              <div className={classNames(css.optionsSectionTitle, css.optionsSectionTitleSnow)}>
                <FormattedMessage id="BecomeAHostForm.winterSection" />
              </div>
              <div className={css.optionsHolder}>
                {snowOptions.map(option => {
                  return (
                    <FieldCheckbox
                      key={option.translationKey}
                      id={option.translationKey}
                      className={css.textInputRow}
                      name="prospect_vertical"
                      label={intl.formatMessage({ id: `BecomeAHostForm.${option.translationKey}` })}
                      value={option.key}
                      iconKey={option.translationKey}
                    />
                  )
                })}
              </div>
            </div>

            <div className={css.optionsSection}>
              <div className={classNames(css.optionsSectionTitle, css.optionsSectionTitleOther)}>
                <FormattedMessage id="BecomeAHostForm.otherSection" />
              </div>
              <div className={css.optionsHolder}>
                {otherOptions.map(option => {
                  return (
                    <FieldCheckbox
                      key={option.translationKey}
                      id={option.translationKey}
                      className={css.textInputRow}
                      name="prospect_vertical"
                      label={intl.formatMessage({ id: `BecomeAHostForm.${option.translationKey}` })}
                      value={option.key}
                      iconKey={option.translationKey}
                    />
                  )
                })}
              </div>
            </div>
          </div>

          <FieldTextInput
            type="hidden"
            id='pageName'
            name="pageName"
            className={css.fieldHidden}
            defaultValue={schemaTitle || intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle })}
          />

          <div className={css.bottomDescription}>
            <p className={css.bottomDescriptionItem}>
              <FormattedMessage id="BecomeAHostForm.bottomDescription1" />
            </p>
            <FieldCheckbox
              id="getInTouch"
              className={css.getInTouch}
              name="getInTouch"
              label={labelCheckBox}
              // label={"I agree to receive other communications from Activebreak GmbH."}
              value={'true'}
            />
            <p className={css.bottomDescriptionItem}>
              <FormattedMessage id="BecomeAHostForm.bottomDescription2" />
            </p>
          </div>


          <div className={css.buttonHolder}>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="BecomeAHostForm.button" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

BecomeAHostFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

BecomeAHostFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string,
  authorDisplayName: string,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const BecomeAHostForm = compose(injectIntl)(BecomeAHostFormComponent);

BecomeAHostForm.displayName = 'BecomeAHostForm';

export default BecomeAHostForm;
