/*global google*/
import React, { PureComponent } from "react"
import { compose, withProps, withHandlers, withState } from "recompose"
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { number, object, shape } from 'prop-types';
import css from './Map.module.css';
import { ReviewRating } from '../../components';

const DynamicGoogleMapWithMultyMarkersComponent = compose(
  withProps({
    loadingElement: <div className={css.surraundedBlockHolder} style={{ height: `100%` }} />,
    containerElement: <div className={css.mapHolder} />,
    mapElement: <div className={css.mapRoot} />,
  }),
  withGoogleMap,
  withState('places', 'updatePlaces', ''),
  withHandlers((props) => {
    const refs = {
      map: undefined,
    };
    return {
      onMapMounted: () => ref => {
        refs.map = ref
      },
      fetchPlaces: ({ updatePlaces }) => () => {
        const location = new window.google.maps.LatLng(props.location.lat, props.location.lng);
        const service = new window.google.maps.places.PlacesService(refs.map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED);
        const request = {
          location: location,
          radius: '500',
          fields: ['name', 'rating', 'formatted_phone_number', 'geometry', 'opening_hours'],
          types: [
            'store',
            'groceries',
            'bakery',
            'clothing_store',
            'convenience_store',
            'furniture_store',
            'hardware_store',
            'home_goods_store',
            'jewelry_store',
            'supermarket',
            'shopping_mall',
            'shoe_store',
            'pet_store',
            'liquor_store',
            'pharmacy',
            'bicycle_store',
            'book_store',
            'cafe',
            'drugstore',
            'restaurant',
            'meal_takeaway',
            'meal_delivery'
          ]
        };
        service.nearbySearch(request, (results, status) => {
          if (status == google.maps.places.PlacesServiceStatus.OK) {
            updatePlaces(results);
          }
        })
      }
    }
  }),
)((props) => {
  return (
    <div className={css.infoPlaces}>
      <div>
        {props.places && props.places.map((place, i) => {
          const type = place.types && place.types[0].replace(/_/g, " ");
          return (
            <div key={i}>
              <div className={css.titleHolder}>
                <img className={css.titleIcon} src={place.icon} alt={'icon'}/>
                <span className={css.title}>{place.name}</span>
              </div>
              <div className={css.ratingHolder}>
                <span className={css.rating}>{place.rating}</span>
                <ReviewRating
                  rating={place.rating}
                  className={css.mobileReviewRating}
                  reviewStarClassName={css.reviewRatingStar}
                />
                <span className={css.ratingTotal}>({place.user_ratings_total})</span>
              </div>
              <div className={css.dataHolder}>
                <span className={css.type}>{type}</span>
                <span className={css.separator}>•</span>
                <span className={css.address}>{place.vicinity}</span>
              </div>
            </div>
          )
        })}
      </div>
      <div className={css.mapWrapper}>
        <GoogleMap
          onTilesLoaded={props.fetchPlaces}
          ref={props.onMapMounted}
          onBoundsChanged={props.fetchPlaces}
          defaultZoom={7}
          defaultCenter={{ lat: props.location.lat, lng: props.location.lng }}
        >
          {props.places && props.places.map((place, i) => {
            const iconMarker = new window.google.maps.MarkerImage(place.icon, null, null, null, new window.google.maps.Size(16, 16));
            return <Marker className={css.icon} key={i} icon={iconMarker} position={{lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}}/>
          })}
        </GoogleMap>
      </div>
    </div>
  )
});

export default class DynamicGoogleMapWithMultyMarkers extends PureComponent {
  render() {
    return (
      <DynamicGoogleMapWithMultyMarkersComponent location={this.props.location} />
    )
  }
}

DynamicGoogleMapWithMultyMarkers.defaultProps = {
  location: null,
  center: null,
};

DynamicGoogleMapWithMultyMarkers.propTypes = {
  location: object,
  center: shape({
    lat: number,
    lng: number,
  }),
};
