import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  Favorite,
  Account,
  NamedLink,
  HelpInfo,
  HostActivity,
  IconAccount,
  IconGlobe,
  IconSetting,
  LanguageSelect,
  CurrencySelect,
  IconLogOut,
  Button,
} from '../../components';

// import { TopbarSearchForm } from '../../forms';

import css2 from '../Topbar/Topbar.module.css';
import IconArrowDown from '../IconArrowDown/IconArrowDown';
import MenuIcon from '../Topbar/MenuIcon';
import css from './TopbarDesktop.module.css';
import config from '../../config';
import IconYourListing from "../IconYourListing/IconYourListing";

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    // currentUserHasListings,
    // notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    // onSearchSubmit,
    // initialSearchFormValues,
    // history,
    location,
    becomeAhostSuccess,
    hubspotFormSubmit
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const lang = config.locale === 'en' ? null : config.locale;

  const authenticatedOnClientSide = mounted && isAuthenticated;
  // const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  // const search = (
  //   <TopbarSearchForm
  //     className={css.searchLink}
  //     desktopInputRoot={css.topbarSearchWithLeftPadding}
  //     onSubmit={onSearchSubmit}
  //     initialValues={initialSearchFormValues}
  //   />
  // );

  // const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  // const inboxLink = authenticatedOnClientSide ? (
  //   <NamedLink
  //     className={css.inboxLink}
  //     name="InboxPage"
  //     params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
  //   >
  //     <span className={css.inbox}>
  //       <FormattedMessage id="TopbarDesktop.inbox" />
  //       {notificationDot}
  //     </span>
  //   </NamedLink>
  // ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu className={css.menu}>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <IconYourListing />
            <FormattedMessage id="ManageListingsPage.yourListings" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <IconAccount />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <IconSetting />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <IconLogOut />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  // const signupLink = isAuthenticatedOrJustHydrated ? null : (
  //   <NamedLink name="SignupPage" className={css.signupLink}>
  //     <span className={css.signup}>
  //       <FormattedMessage id="TopbarDesktop.signup" />
  //     </span>
  //   </NamedLink>
  // );

  // const loginLink = isAuthenticatedOrJustHydrated ? null : (
  //   <NamedLink name="LoginPage" className={css.loginLink}>
  //     <span className={css.login}>
  //       <FormattedMessage id="TopbarDesktop.login" />
  //     </span>
  //   </NamedLink>
  // );

  const hostActivity = (
    <div className={css.headerButton}>
      <HostActivity
        becomeAhostSuccess={becomeAhostSuccess}
        hubspotFormSubmit={hubspotFormSubmit}
      />
    </div>
  );

  const hostActivityLink = (
    <div className={css.headerLinkF}>
      <HostActivity
        linkClassName={css.hostLink}
        becomeAhostSuccess={becomeAhostSuccess}
        hubspotFormSubmit={hubspotFormSubmit}
      />
    </div>
  );

  const helpInfo = (
    <Menu>
      <MenuLabel className={css.helpInfoLabel} isOpenClassName={css.profileMenuIsOpen}>
        <HelpInfo iconClass={css.iconFav} format="desktop" />
      </MenuLabel>
      <MenuContent>
        <MenuItem key="travelerHelp">
          <NamedLink name="TravelerHelpPage" className={css.helpMenuLink}>
            <span className={css.helpMenuLinkText}>
              <FormattedMessage id="TopbarDesktop.travelerHelp" />
            </span>
          </NamedLink>

        </MenuItem>
        <MenuItem key="hostHelp">
          <NamedLink name="HostHelpPage" className={css.helpMenuLink}>
            <span className={css.helpMenuLinkText}>
              <FormattedMessage id="TopbarDesktop.hostHelp" />
            </span>
          </NamedLink>

        </MenuItem>
        <MenuItem key="about">
          <NamedLink name="AboutPage" className={css.helpMenuLink}>
            <span className={css.helpMenuLinkText}>
              <FormattedMessage id="TopbarDesktop.about" />
            </span>
          </NamedLink>

        </MenuItem>
        <MenuItem key="trastSafety">
          <NamedLink name="TrustAndSafetyPage" className={css.helpMenuLink}>
            <span className={css.helpMenuLinkText}>
              <FormattedMessage id="TopbarDesktop.trastSafety" />
            </span>
          </NamedLink>

        </MenuItem>
      </MenuContent>
    </Menu>
  );

  const account = !authenticatedOnClientSide ? (
    <Menu className={css.accountMenu}>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Account format="desktop" />
        <IconArrowDown />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent} useDefaultStyles={false}>
        <MenuItem key="SignInTraveler">
          <NamedLink name="LoginPage" className={css.loginLink}>
            <span className={css.login}>
              <FormattedMessage id="TopbarDesktop.loginTraveler" />
            </span>
          </NamedLink>
        </MenuItem>

        <MenuItem key="SignUp">
          <NamedLink name="SignupPage" className={css.signupLink}>
            <span className={css.signup}>
              <FormattedMessage id="TopbarDesktop.signup" />
            </span>
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const burgerMenu = (
    <Menu className={css.humbMenu}>
      <MenuLabel>
        <Button
          rootClassName={css2.menu}
          title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
        >
          <MenuIcon className={css.menuIcon} desktop />
        </Button>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent} useDefaultStyles={false}>
        <MenuItem className={classNames(css.menuItem, css.menuItemDrop)} key={"Favorites"}>
          <NamedLink className={css.headerLinkF} name="FavoritesPage" params={{ lang }}>
            <Favorite
              format="desktop"
              className={css.account}
              iconsClass={css.iconFav}
            />
          </NamedLink>
        </MenuItem>

        <MenuItem className={!authenticatedOnClientSide ? classNames(css.menuItem, css.menuItemDrop) : classNames(css.menuItem, css.menuItemHidden)} key="SignInTraveler">
          {!authenticatedOnClientSide && <NamedLink name="LoginPage" className={css.accLink}>
            <span className={css.login}>
              <FormattedMessage id="TopbarDesktop.loginTraveler" />
            </span>
          </NamedLink>}
        </MenuItem>

        <MenuItem className={!authenticatedOnClientSide ? classNames(css.menuItem, css.menuItemDrop) : classNames(css.menuItem, css.menuItemHidden)} key="SignUp">
          {!authenticatedOnClientSide && <NamedLink name="SignupPage" className={css.accLink}>
            <span className={css.signup}>
              <FormattedMessage id="TopbarDesktop.signup" />
            </span>
          </NamedLink>}
        </MenuItem>

        <MenuItem className={authenticatedOnClientSide ? classNames(css.menuItem, css.menuItemDrop) : classNames(css.menuItem, css.menuItemHidden)} key="logout">
          {authenticatedOnClientSide && <InlineTextButton rootClassName={css.accLink} onClick={onLogout}>
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>}
        </MenuItem>

        <MenuItem className={classNames(css.menuItem, css.menuItemDrop)} key="Help">
          {helpInfo}
        </MenuItem>
        <MenuItem className={classNames(css.menuItem, css.menuItemDrop)} key="locality">
          <div className={css.menuLocality}>
            <IconGlobe />
            <FormattedMessage id="TopbarMobileMenu.localeCategory" />
          </div>
          <div className={css.menuLanguageCurrency}>
            <LanguageSelect currentPage={currentPage} location={location} />
            <CurrencySelect />
          </div>
        </MenuItem>
        <MenuItem className={classNames(css.menuItem, css.menuItemDrop)} key="HostActivity">
          {hostActivityLink}
        </MenuItem>
      </MenuContent>
    </Menu>
  )



  return (
    <nav className={classes}>
      <div className={css.blockLinkActivitiesLarge}>
        <NamedLink className={css.logoLink} name="LandingPage">
          <Logo
            format="desktop"
            className={css.logo}
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
          />
        </NamedLink>
        {/*<NamedLink name="SearchPage" className={css.sectionActivitiesItem}>*/}
        {/*  <span className={css.activitiesItem}>*/}
        {/*    <FormattedMessage id="TopbarDesktop.activities" />*/}
        {/*  </span>*/}
        {/*</NamedLink>*/}
        {/*  <NamedLink name="SearchPage" className={css.sectionActivitiesItem}>*/}
        {/*  <span className={css.activitiesItem}>*/}
        {/*    <FormattedMessage id="TopbarDesktop.destinations" />*/}
        {/*  </span>*/}
        {/*  </NamedLink>*/}
        {/*  <NamedLink name="SearchPage" className={css.sectionActivitiesItem}>*/}
        {/*  <span className={css.activitiesItem}>*/}
        {/*    <FormattedMessage id="TopbarDesktop.bestOffers" />*/}
        {/*  </span>*/}
        {/*  </NamedLink>*/}
        {/*</div>*/}
        {/*<div className={css.blockLinkActivitiesSmole}>*/}
        {/*  <NamedLink className={css.logoLink} name="LandingPage">*/}
        {/*    <Logo*/}
        {/*      format="desktop"*/}
        {/*      className={css.logo}*/}
        {/*      alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}*/}
        {/*    />*/}
        {/*  </NamedLink>*/}
        {/*  <Menu className={css.accountMenu}>*/}
        {/*    <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>*/}
        {/*      <p className={css.menuActivities}>*/}
        {/*        <FormattedMessage id="TopbarDesktop.activities" />*/}
        {/*      </p>*/}
        {/*    </MenuLabel>*/}
        {/*    <MenuContent className={css.profileMenuContent}>*/}
        {/*      <MenuItem key="SignInTraveler">*/}
        {/*        <NamedLink name="SearchPage" className={css.sectionActivitiesItem}>*/}
        {/*            <span className={css.activitiesItem}>*/}
        {/*              <FormattedMessage id="TopbarDesktop.activities" />*/}
        {/*            </span>*/}
        {/*        </NamedLink>*/}
        {/*      </MenuItem>*/}
        {/*      <MenuItem key="destinations">*/}
        {/*        <NamedLink name="SearchPage" className={css.sectionActivitiesItem}>*/}
        {/*            <span className={css.activitiesItem}>*/}
        {/*              <FormattedMessage id="TopbarDesktop.destinations" />*/}
        {/*            </span>*/}
        {/*        </NamedLink>*/}
        {/*      </MenuItem>*/}
        {/*      <MenuItem key="bestOffers">*/}
        {/*        <NamedLink name="SearchPage" className={css.sectionActivitiesItem}>*/}
        {/*            <span className={css.activitiesItem}>*/}
        {/*              <FormattedMessage id="TopbarDesktop.bestOffers" />*/}
        {/*            </span>*/}
        {/*        </NamedLink>*/}
        {/*      </MenuItem>*/}
        {/*    </MenuContent>*/}
        {/*  </Menu>*/}
      </div>

      <div className={css.buttonsHolder}>
        {/*<NamedLink className={css.headerLinkF} name="FavoritesPage" params={{ lang }}>*/}
        {/*  <Favorite*/}
        {/*    format="desktop"*/}
        {/*    className={css.account}*/}
        {/*  />*/}
        {/*</NamedLink>*/}
        {profileMenu}
        {account}
        {hostActivity}
        {burgerMenu}
      </div>
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
