import React from 'react';

const HELP_ICON = 'help'
const LANGUAGE_ICON = 'language'

const IconsItem = ({ cat }) => {
  switch (cat) {
    case null:
      return null;
    case HELP_ICON:
      return (
        <svg style={{ marginRight: 5 }} width="22" height="20" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 9.21619H15.6C15.8571 9.21619 16.1143 9.49997 16.1143 9.78375L16.0286 19.054C16.0286 19.527 16.3714 20 16.8857 20H18" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M15.9286 7C16.2047 7 16.4286 6.77614 16.4286 6.5C16.4286 6.22386 16.2047 6 15.9286 6C15.6524 6 15.4286 6.22386 15.4286 6.5C15.4286 6.77614 15.6524 7 15.9286 7Z" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M15.9239 1C7.70051 1 1 6.88268 1 13.9721C1 16.6871 1.91371 19.1006 3.58883 21.2123L2.82741 28L10.1371 25.8883C11.9645 26.6425 13.9442 26.9441 16.0761 26.9441C24.2995 26.9441 31 21.0615 31 13.9721C31 6.88268 24.2995 1 15.9239 1Z" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      );
    case LANGUAGE_ICON:
      return (
        <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.6062 27C14.4075 27 14.2089 27 13.911 27C6.76027 27 1 21.2 1 14C1 6.8 6.76027 1 13.911 1C21.0616 1 26.8219 6.8 26.8219 14C26.8219 15.1 26.7226 16.2 26.4247 17.3" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M21.0617 30C16.6918 30 13.1165 26.8 13.1165 22.8C13.1165 21.3 13.613 19.9 14.5069 18.8L14.1096 15L17.9829 16.1C18.976 15.7 19.9692 15.5 21.161 15.5C25.5308 15.5 29.1062 18.7 29.1062 22.7C29.0069 26.8 25.4315 30 21.0617 30Z" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M18.4794 24.5H23.3459" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M17.1884 21.5H24.5377" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M26.5 12.4C24.2157 13.5 25.3322 11.3 22.5513 12.4C18.678 14 18.7774 8.00001 15.6986 8.90001C13.2157 9.60001 13.613 5.00001 17.5856 5.40001C19.5719 5.60001 17.4863 3.50001 18.976 2.20001" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M1.29797 15.4C1.29797 15.4 4.17811 12.8 5.96578 18.1C7.55482 22.8 10.435 19.3 9.24318 16.1C8.34934 13.5 10.3356 12.3 10.137 11.3C9.93838 10.3 8.7466 10.5 7.85277 10.5C6.26373 10.7 6.36304 8.5 8.25003 8.5C11.5274 8.5 11.8254 5.9 10.2363 5C9.24318 4.5 7.55482 3.8 7.95208 2.5" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      );
    default:
      return null
  }
};

export default IconsItem;
