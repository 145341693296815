import React, { Component } from 'react';
import Tabs, { TabPane } from "rc-tabs";
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import LinesEllipsis from 'react-lines-ellipsis';
import { IconCategory } from '../../components';
import moment from 'moment';
import classNames from 'classnames';
// import parse from 'html-react-parser';

// styles
import css from './ListingPage.module.css';
import {FormattedMessage} from "../../util/reactIntl";

class SectionReview extends Component {

  state = {
    useEllipsis: [true, true, true, true, true],
    showMore: false,
    reviewsLength: []
  };

  showAllReviews = () => {
    this.setState({
      reviewsLength: this.props?.listing?.attributes?.publicData?.reviews,
      showMore: true
    })
  }

  onTextClick = (e, i) => {
    e.preventDefault();
    const { useEllipsis } = this.state;
    useEllipsis[i] = !useEllipsis[i];
    this.setState({
      useEllipsis
    })
  };

  componentDidMount() {
    const {
      listing: {
        attributes: {
          publicData: {
            reviews = [],
          }
        }
      }
    } = this.props;

    this.setState({ reviewsLength: reviews.slice(0, 3)})
  }

  render() {
    const {
      listing: {
        attributes: {
          publicData: {
            reviews = [],
            faqs = [],
          }
        }
      },
      intl,
    } = this.props;

    const ratingBuilder = (rate) => {
      let rating = [];
      for (let i = 1; i < 6; i++) {
        if (rate >= i) rating.push('full');
        else rating.push('empty');
      }
      return rating;
    };

    const callback = key => {};

    if (typeof window === 'undefined') {
      return null;
    }
    const { default: parse } = require('html-react-parser'); // eslint-disable-line global-require

    const reviewsTotalRating = reviews.reduce((accumulator, currentValue) => ( accumulator + +currentValue.ratings[0]?.rating ?? 0), 0);
    const reviewsAverageRating = Math.round(reviewsTotalRating / reviews.length);

    const reviewsTitle = intl.formatMessage({ id: 'SectionSpotlight.ReviewsTitle' });
    const readMore = intl.formatMessage({ id: 'SectionTabs.readMore' });
    const FAQsTitle = intl.formatMessage({ id: 'SectionSpotlight.FAQsTitle' });

    return (
      <div className={classNames(css.reviewsSectionListing, css.blockReviews)}>
        <Tabs
          defaultActiveKey="1"
          onChange={callback}
          className={css.reviewBlock}
          renderTabBar={() => <ScrollableInkTabBar/>}
          renderTabContent={() => <TabContent/>}
        >
          {reviews.length > 0 && <TabPane tab={reviewsTitle} key="1">
            <div className={classNames(css.reviewsSubHeading, css.reviewsBlockDetails)}>
              <IconCategory cat="oneStar"/>
              <span className={css.reviewTotalCount}>{reviewsAverageRating?.toFixed(2)} </span>
              <FormattedMessage
                className={css.reviewTotalInfo}
                id="ListingPage.reviewsLabel"
                values={{ count: reviews.length }}
              />
            </div>
            <ul>
              {this.state.reviewsLength.map((review, index) => <li className={css.reviewHolder} key={index}>
                <div className={css.reviewTopHolder}>
                  {review.author_profile_pic && review.author_profile_pic.length
                    ? (
                      <div className={css.reviewAvatarHolder}>
                        <div style={{ backgroundImage: `url(${review.author_profile_pic[0].url})` }} className={css.reviewAvatar}/>
                      </div>
                    ) : (
                      <div>
                        <div className={css.reviewAvatarDefault}>{(review.author ?? review.reviewer).charAt(0)}</div>
                      </div>
                    )}
                  <div className={css.reviewContent}>
                    <div>
                      <div className={css.reviewContentText}>
                        {this.state.useEllipsis[index]
                          ? (
                            <div onClick={(e) => this.onTextClick(e, index)} tabIndex='0'>
                              <LinesEllipsis
                                text={review.description}
                                style={{whiteSpace: 'pre-wrap',}}
                                maxLine='2'
                                ellipsis={readMore}
                              />
                            </div>
                          )
                          : review.description
                        }
                      </div>
                    </div>

                    <div className={css.reviewBottomHolder}>
                      <span className={css.reviewBottomInfoName}>{review.reviewer}</span>
                      <div className={css.blockDageReviews}>
                        <span className={css.pointStyle}>•</span>
                        <span className={css.reviewBottomInfoDate}>{moment(review.review_date).format('MMMM YYYY')}</span>
                        <span className={css.pointStyle}>•</span>
                      </div>
                      <div className={css.ratingHolder}>
                        {review.ratings.map((rating, i) => (
                          <div key={`rating_${i + 1}`} className={css.ratingStarHolder}>
                            {ratingBuilder(rating.rating * 1).map((rat, i) => <IconCategory key={i} cat={rat}/>)}</div>))
                        }
                      </div>
                    </div>
                  </div>

                </div>
              </li>)}
            </ul>
            {reviews.length > 2 && !this.state.showMore &&
              <div className={css.showMoreReviews} onClick={ this.showAllReviews }>
                <p><FormattedMessage id="UserCard.moreReviews" /></p>
              <div className={css.arrowShowMore}>
                <span className={css.arrowOne}> </span>
                <span className={css.arrowTwo}> </span>
              </div>
            </div>}
          </TabPane>}
          {faqs.length > 0 && <TabPane tab={FAQsTitle} key="2">
            {faqs.map((f, i) => {
              return <ul key={i}>
                <li>
                  <h3 className={css.faqTitle}>Q:&nbsp;{parse(f.question)}</h3>
                  <span className={css.faqDescr}>{parse(f.answer)}</span>
                </li>
              </ul>
            })}
          </TabPane>}
        </Tabs>
      </div>
    );
  }
}

export default SectionReview;
