import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import link from './clipboard-202302021343-0xbjs.png'
import css from './IconMessage.module.css';

const IconMessage = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    // <img className={classes} alt='message' src={link}/>
    // <svg
    //   className={classes}
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="512"
    //   height="512"
    //   x="0"
    //   y="0"
    //   enableBackground="new 0 0 512 512"
    //   version="1.1"
    //   viewBox="0 0 512 512"
    //   xmlSpace="preserve"
    // >
    //   <path d="M448 64H64C28.656 64 0 92.656 0 128v256c0 35.344 28.656 64 64 64h384c35.344 0 64-28.656 64-64V128c0-35.344-28.656-64-64-64zM342.656 234.781l135.469-116.094c.938 3 1.875 6 1.875 9.313v256c0 2.219-.844 4.188-1.281 6.281l-136.063-155.5zM448 96c2.125 0 4 .813 6 1.219L256 266.938 58 97.219C60 96.813 61.875 96 64 96h384zM33.266 390.25C32.828 388.156 32 386.219 32 384V128c0-3.313.953-6.313 1.891-9.313L169.313 234.75 33.266 390.25zM64 416c-3.234 0-6.172-.938-9.125-1.844l138.75-158.563 51.969 44.531C248.578 302.719 252.297 304 256 304s7.422-1.281 10.406-3.875l51.969-44.531 138.75 158.563c-2.937.905-5.875 1.843-9.125 1.843H64z"></path>
    // </svg>
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      x="0"
      y="0"
      enableBackground="new 0 0 950 950"
      version="1.1"
      viewBox="0 0 950 950"
      xmlSpace="preserve"
    >v
    <g transform="translate(0.000000,957.000000) scale(0.100000,-0.100000)"
       fill="#000000" stroke="none">
      <path d="M3367 9229 c-58 -14 -108 -43 -153 -91 -23 -24 -361 -427 -752 -896
-552 -662 -714 -863 -726 -899 -18 -54 -20 -154 -3 -200 40 -108 147 -183 262
-183 75 0 117 13 171 54 23 17 250 281 505 588 l464 556 5 -2061 5 -2062 28
-57 c32 -66 70 -101 146 -136 45 -21 64 -24 120 -20 112 8 189 59 244 162 l22
41 5 2067 5 2066 430 -516 c501 -602 523 -627 590 -658 60 -28 150 -32 215 -9
148 52 227 234 163 376 -23 52 -1443 1761 -1511 1820 -60 51 -160 76 -235 58z"/>
      <path d="M1550 6379 c-166 -12 -332 -60 -502 -145 -312 -157 -576 -460 -686
-789 -72 -213 -66 -30 -70 -2145 -2 -1399 0 -1928 8 -1990 39 -284 167 -550
368 -762 198 -209 437 -344 727 -411 89 -21 102 -21 1965 -25 1947 -3 1989 -2
2151 38 451 114 836 471 983 911 65 196 60 25 64 2144 3 2129 6 2009 -63 2220
-115 351 -364 642 -692 807 -155 79 -267 116 -418 139 -55 8 -244 12 -605 13
-524 0 -525 0 -575 -22 -69 -32 -131 -95 -155 -160 -54 -144 11 -298 155 -364
50 -23 54 -23 595 -29 595 -6 581 -5 717 -68 199 -93 360 -274 437 -489 l31
-87 3 -1890 c2 -1867 2 -1890 -18 -1970 -72 -286 -303 -522 -590 -602 -62 -17
-159 -18 -1955 -18 -1796 0 -1893 1 -1955 18 -162 45 -325 154 -431 287 -69
87 -108 158 -146 265 l-28 80 -3 1883 c-2 1851 -2 1885 18 1968 63 268 279
502 545 591 l80 27 545 6 545 5 55 26 c64 30 112 74 143 133 31 58 31 193 0
251 -30 56 -89 110 -148 136 -49 22 -58 22 -525 23 -261 1 -518 -2 -570 -5z"/>
    </g>
</svg>
  );
};

IconMessage.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconMessage.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconMessage;
