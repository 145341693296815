import React from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import classNames from 'classnames';

import css from './AboutPage.module.css';

const SideBarNav = (props) => {
  const { location, intl } = props;

  const howText = intl.formatMessage({ id: 'AboutPage.navItem1' });
  const whatText = intl.formatMessage({ id: 'AboutPage.navItem2' });
  const valuesText = intl.formatMessage({ id: 'AboutPage.navItem3' });
  const communityText = intl.formatMessage({ id: 'AboutPage.navItem4' });

  const howSlug = intl.formatMessage({ id: 'AboutPage.howSlug' });
  const whatSlug = intl.formatMessage({ id: 'AboutPage.whatSlug' });
  const valuesSlug = intl.formatMessage({ id: 'AboutPage.valuesSlug' });
  const communitySlug = intl.formatMessage({ id: 'AboutPage.communitySlug' });

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -110;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const navItems = [
    { link: `#${howSlug}`, text: howText, type: 'subtitle', className: css.subtitle },
    { link: `#${whatSlug}`, text: whatText, type: 'subtitle', className: css.subtitle },
    { link: `#${valuesSlug}`, text: valuesText, type: 'subtitle', className: css.subtitle },
    { link: `#${communitySlug}`, text: communityText, type: 'subtitle', className: css.subtitle },
  ];

  return navItems.map(item => {
    return (
      <li className={css.navItem} key={item.link}>
      {<NavLink
        className={
          location.hash === item.link && item.type === 'title' ? classNames(css.selected, css.title)
          : location.hash === item.link && item.type === 'subtitle' ? classNames(css.selected, css.subtitle)
          : item.type === 'title' ? css.title
          : item.type === 'subtitle' ? css.subtitle
          : css.subtitle
        }
        to={item.link}
        smooth
        scroll={el => scrollWithOffset(el)} activeClassName={css.selected}>
        {item.text}
      </NavLink>}
      </li>
    )
  })
};

export default SideBarNav;
