import React from "react";
import { OverlayView } from "react-google-maps";
import MapPlaceLabel from '../MapPlaceLabel/MapPlaceLabel';

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height),
})

const MapSingleLabelWithOverlay = ({
  position,
  isActive,
  className,
  label,
  onPlaceClicked,
  mapComponentRefreshToken,
}) => {
    return (
      <OverlayView
        key={Math.random()}
        position={position}
        getPixelPositionOffset={getPixelPositionOffset}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <MapPlaceLabel
          isActive={isActive}
          className={className}
          onPlaceClicked={onPlaceClicked}
          mapComponentRefreshToken={mapComponentRefreshToken}
          label={label}
        />
      </OverlayView>
    );
}


export default MapSingleLabelWithOverlay;
