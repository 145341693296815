import React, { useEffect } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { IconCategory, BookingPanel } from '../../components';
import TagManager from 'react-gtm-module';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import css from './ListingPage.module.css';
import { ListingFavouritesForm } from '../../forms';
import { ensureCurrentUser, ensureOwnListing } from '../../util/data';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import letter from '../../assets/leter.png';
import { LANGUAGE_KEY } from '../../util/languages';

const getMinPackagesPrice = (packages) => {
  let minPrice;

  packages.forEach(({rooms}) => {
    rooms.forEach(({price, available}) => {
      if ((!minPrice && available) || (available && price.amount < minPrice)) {
        minPrice = price.amount;
      }
    });
  });

  return minPrice;
}

const getDepartureDate = (packages) => {
  let minDate;

  packages.forEach(({rooms, departure_date}) => {
    const isAvailable = rooms.find(({available}) => available);

    const timeInMls = new Date(departure_date).getTime();

    if (
      (!minDate && isAvailable) ||
      (isAvailable && timeInMls < new Date(minDate).getTime())
    ) {
      minDate = departure_date;
    }
  });

  return minDate;
}

const SectionHeading = ({
  priceTitle,
  richTitle,
  hostLink,
  currentUser,
  intl,
  isOwnListing,
  onUpdateProfile,
  isAuthenticated,
  listing,
  formattedPrice,
  onSubmit,
  titleBook,
  subTitle,
  authorDisplayName,
  onManageDisableScrolling,
  timeSlots,
  fetchTimeSlotsError,
  differenceInDays,
  formattedPerDayPrice,
  days,
  onFetchTransactionLineItems,
  lineItems,
  fetchLineItemsInProgress,
  fetchLineItemsError,
  showShared,
  showCustomTopbarButton,
  images
}) => {

  useEffect(() => {
    if (typeof window !== 'undefined' && listing) {
      const {
        duration_days,
        skill_levels: publicDataSkillLevels = [],
        themes: publicDataTheme = [],
        categories: publicDataCategories = [],
        listing_languages = [],
        packages = [],
      } = listing.attributes?.publicData || {};

      const partnerId = listing.author.id.uuid;

      const minPrice = getMinPackagesPrice(packages);
      const departureDate = getDepartureDate(packages);
      const schemaCategories = publicDataCategories.map(({title}) => title).join('|');
      const schemaThemes = publicDataTheme.join('|');
      const schemaSkillLevels = publicDataSkillLevels.map(({ title }) => title).join('|');
      const listingLanguages = listing_languages.map(({code}) => code);

      const item = [{
        item_name: title, // Name or ID is required.
        item_id: id,
        price: minPrice,
        currency: config.currency,
        discount: 0,
        item_brand: partnerId,
        item_category: schemaCategories,
        item_category2: schemaThemes,
        item_category3: schemaSkillLevels,
        item_category4: duration_days,
        item_category5: `${departureDate.slice(0,4)}|${departureDate.slice(5,7)}`,
        item_variant: listingLanguages,
        item_list_name: 'ListingPageDataLayer', // page on which the list was displayed on.
        item_list_id: `${window.location.pathname}${window.location.search}`, // Search string & parameters from URL
        location_id: '[XXX]',
        index: 1, // index of item in list starting at 1
        quantity: 1 // can be hard-coded, as no selection for quantity has been made yet.
      }];

      const tagManagerArgs = {
        dataLayer: {
          event: "view_item",
          ecommerce: {
            items: item
          }
        },
        events: {
          pageView: 'pageview',
        },
        page: {
          path: window.location.pathname,
          locale: localStorage.getItem(LANGUAGE_KEY), // language code of displayed page
          title: 'ListingPage',
          type: 'listing',
        },
        dataLayerName: 'ListingPageDataLayer',
      };

      TagManager.dataLayer(tagManagerArgs);
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          items: item
        },
        page: {
          path: window.location.pathname,
          locale: localStorage.getItem(LANGUAGE_KEY), // language code of displayed page
          title: 'ListingPage',
          type: 'listing',
        },
      });
    }
  }, [])

  const {
    publicData: {
      reviews: publicDataReviews = [],
      skill_levels: publicDataSkillLevels = [],
      fitness_levels: publicDataFitnessLevels = [],
      categories: publicDataCategories = [],
      guided,
      duration_days,
      themes = [],
      packages = [],
    },
    title,
  } = listing.attributes;
  const partnerId = listing.author.id.uuid;
  const minPrice = getMinPackagesPrice(packages);
  const departureDate = getDepartureDate(packages);

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  const save = async values => await sleep(1000);

  const user = ensureCurrentUser(currentUser);
  const currentListing = ensureOwnListing(listing);
  const id = currentListing.id.uuid;
  const listingFavourite = currentListing ? user.attributes.profile?.publicData?.listingFavourite ?? [] : [];
  const init = listingFavourite ? listingFavourite.filter(l => l.listingId === id) : [];
  const avatar = currentListing && currentListing.author.profileImage ? currentListing.author.profileImage.attributes.variants['square-small'] : [];
  const authorName = currentListing.author.attributes.profile.displayName;

  const reviewsTotalRating = publicDataReviews.reduce((accumulator, currentValue) => (accumulator + +currentValue.ratings[0]?.rating ?? 0), 0);

  const reviewsAverageRating = reviewsTotalRating && publicDataReviews.length
    ? Math.round(reviewsTotalRating / publicDataReviews.length)
    : 0;

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const addFavorites = intl.formatMessage({id: 'LoginForm.addFavorites'});

  const unitTranslationKey = isNightly
    ? 'ListingPage.perNight'
    : isDaily
    ? 'ListingPage.perDay'
    : 'ListingPage.perUnit';

  return (
    <div className={css.sectionHeading}>
      <div className={css.desktopPriceContainer}>
        <div className={css.desktopPriceValue} title={priceTitle}>{formattedPrice}</div>
        <div className={css.desktopPerUnit}>
          <FormattedMessage id={unitTranslationKey} />
        </div>
      </div>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.titleSection}>
          <div className={css.author}>
            <div className={css.titleAuthor}>
              <span className={css.contactWrapper}>
                {publicDataCategories.map(({title}, i) =>
                  <span key={i}>{title}{publicDataCategories.length - 1 !== i ? " | " : " "}</span>
                )}
              </span>
            </div>
            <div>
              <FormattedMessage id="ListingPage.hostedBy" values={{ name: hostLink }} />
            </div>
            <div className={css.authorHolder}>
              {/* <div className={css.reviewsSubHeading}> */}
              <IconCategory cat="oneStar" />
              <span className={css.reviewTotalCount}>{reviewsAverageRating?.toFixed(2)}</span>
              <NavLink to="#reviews">
                <FormattedMessage
                  className={css.reviewTotalCount}
                  id='ListingPage.reviewsLabel'
                  values={{ count: publicDataReviews.length }}
                />
              </NavLink>
              {/* </div> */}
            </div>
          </div>
          <div className={css.linkBlock}>
            <div className={css.shareButton} onClick={showShared}>
              <img className={css.imgLetter} src={letter} alt='letter' />
              <FormattedMessage id='ListingPage.share' />
            </div>
              <ListingFavouritesForm
                className={css.textFavorite}
                svgClassName={css.checkbox}
                isAuthenticated={isAuthenticated}
                iconClassName={css.iconHart}
                label={addFavorites}
                initialValues={{
                  [`favourite_${id}`]: init[0]
                    ? init[0][`favourite_${id}`]
                    : false,
                }}
                onSubmit={values => {
                  save(values);
                  let currentListingId = Object.keys(values)[0];
                  let currentListingValue = values[`favourite_${id}`];
                  currentListingId = currentListingId.replace('favourite_', '');
                  const hasCurrentListing = listingFavourite.filter(l => l.listingId === currentListingId).length > 0;
                  if (!hasCurrentListing && currentListingValue) {
                    onUpdateProfile({
                      publicData: {
                        listingFavourite: [...listingFavourite, {
                          title,
                          authorName,
                          avatar,
                          skill_levels: publicDataSkillLevels,
                          fitness_levels: publicDataFitnessLevels,
                          listingImages: images,
                          listingId: id,
                          duration_days,
                          categories: publicDataCategories,
                          guided,
                          themes,
                          partner_id: partnerId,
                          price: minPrice,
                          departure_date: departureDate,
                          [`favourite_${id}`]: true,
                        }],
                      },
                    });
                  }

                  if (hasCurrentListing && !currentListingValue) {
                    const removeCurrentListing = listingFavourite.filter(l => l.listingId !== currentListingId);
                    onUpdateProfile({
                      publicData: {
                        listingFavourite: removeCurrentListing,
                      },
                    });
                  }
                }}
                onChange={() => {}}
                listingId={id}
              />
          </div>
        </div>
      </div>
      {showCustomTopbarButton && (
        <BookingPanel
          listing={currentListing}
          isOwnListing={isOwnListing}
          unitType={unitType}
          currentUser={currentUser}
          onSubmit={onSubmit}
          title={titleBook}
          subTitle={subTitle}
          authorDisplayName={authorDisplayName}
          onManageDisableScrolling={onManageDisableScrolling}
          timeSlots={timeSlots}
          fetchTimeSlotsError={fetchTimeSlotsError}
          differenceInDays={differenceInDays}
          formattedPerDayPrice={formattedPerDayPrice}
          formattedPrice={formattedPrice}
          days={days}
          onFetchTransactionLineItems={onFetchTransactionLineItems}
          lineItems={lineItems}
          fetchLineItemsInProgress={fetchLineItemsInProgress}
          fetchLineItemsError={fetchLineItemsError}
          isMobile
        />
      )}
    </div>
  );
};

export default SectionHeading;
