import React, {Component} from 'react';
import {array, bool, func, number, object, string} from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import merge from 'lodash/merge';
import { propTypes } from '../../util/types';
import {
  SearchResultsPanel,
  SearchFilters,
  SearchFiltersMobile,
  SearchFiltersPanel,
} from '../../components';
import { validFilterParams } from './SearchPage.helpers';
import css from './SearchPage.module.css';

class MainPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchFiltersPanelOpen: false,
      categoryFilterName: 'Activity'
    };
    this.setCategoryFilterName = this.setCategoryFilterName.bind(this);
  }
  setCategoryFilterName(value) {
    this.setState({ categoryFilterName: value });
  }

  render() {
    const {
      className,
      rootClassName,
      urlQueryParams,
      sort,
      listings,
      searchInProgress,
      searchListingsError,
      searchParamsAreInSync,
      onActivateListing,
      onManageDisableScrolling,
      onOpenModal,
      onCloseModal,
      onMapIconClick,
      isAuthenticated,
      pagination,
      searchParamsForPagination,
      showAsModalMaxWidth,
      primaryFilters,
      secondaryFilters,
      currentUser,
      onUpdateProfile,
      currentSearchParams,
      listingsImages,
    } = this.props;

    const isSearchFiltersPanelOpen = !!secondaryFilters && this.state.isSearchFiltersPanelOpen;

    const filters = merge({}, primaryFilters, secondaryFilters);
    const selectedFilters = validFilterParams(urlQueryParams, filters);
    const selectedFiltersCount = Object.keys(selectedFilters).length;

    // const selectedSecondaryFilters = secondaryFilters
    //   ? validFilterParams(urlQueryParams, secondaryFilters)
    //   : {};

    const countFilter = [
      // 'pub_category',
      // 'pub_amenities',
      // 'pub_category',
      'pub_duration_days',
      // 'pub_FitnessLevels',
      'meta_categoriesForFilters',
      'meta_badgesForFilters',
      // 'meta_locale',
      // 'pub_SkillLevels',
      'pub_listing_language',
    ]
    const countSearch = (where, what) => {
      let count = 0
      for(let i=0; i<what.length; i++){
        if(where.indexOf(what[i]) !== -1) count++;
      }
      return count;
    }
    // const searchFiltersPanelSelectedCount = Object.keys(selectedSecondaryFilters).length;
    const searchFiltersPanelSelectedCount = countSearch(Object.keys(urlQueryParams), countFilter);

    const searchFiltersPanelProps = !!secondaryFilters
      ? {
        isSearchFiltersPanelOpen: this.state.isSearchFiltersPanelOpen,
        toggleSearchFiltersPanel: isOpen => {
          this.setState({ isSearchFiltersPanelOpen: isOpen });
        },
        searchFiltersPanelSelectedCount,
      }
      : {};

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems = searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : 0;
    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync && hasPaginationInfo;

    const classes = classNames(rootClassName || css.searchResultContainer, className);

    const filterParamNames = Object.values(filters).map(f => f.paramName);
    const secondaryFilterParamNames = secondaryFilters
      ? Object.values(secondaryFilters).map(f => f.paramName)
      : [];


    return (
      <div className={classes}>
        <SearchFilters
          className={css.searchFilters}
          currentUser={currentUser}
          urlQueryParams={urlQueryParams}
          sort={sort}
          listingsAreLoaded={listingsAreLoaded}
          resultsCount={totalItems}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          onManageDisableScrolling={onManageDisableScrolling}
          onUpdateProfile={onUpdateProfile}
          listings={listings}
          categoryFilterName={this.state.categoryFilterName}
          setCategoryFilterName={this.setCategoryFilterName}
          currentSearchParams={currentSearchParams}
          {...searchFiltersPanelProps}
          {...primaryFilters}
        />
        <SearchFiltersMobile
          categoryFilterName={this.state.categoryFilterName}
          setCategoryFilterName={this.setCategoryFilterName}
          className={css.searchFiltersMobile}
          urlQueryParams={urlQueryParams}
          sort={sort}
          listings={listings}
          onUpdateProfile={onUpdateProfile}
          currentUser={currentUser}
          listingsAreLoaded={listingsAreLoaded}
          resultsCount={totalItems}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          showAsModalMaxWidth={showAsModalMaxWidth}
          onMapIconClick={onMapIconClick}
          onManageDisableScrolling={onManageDisableScrolling}
          onOpenModal={onOpenModal}
          onCloseModal={onCloseModal}
          filterParamNames={filterParamNames}
          selectedFiltersCount={selectedFiltersCount}
          {...primaryFilters}
          {...secondaryFilters}
        />
        {isSearchFiltersPanelOpen ? (
          <div className={classNames(css.searchFiltersPanel)}>
            <SearchFiltersPanel
              urlQueryParams={urlQueryParams}
              sort={sort}
              listingsAreLoaded={listingsAreLoaded}
              onClosePanel={() => this.setState({ isSearchFiltersPanelOpen: false })}
              filterParamNames={secondaryFilterParamNames}
              currentUser={this.props.currentUser}
              {...secondaryFilters}
            />
          </div>
        ) : (
          <div
            className={classNames(css.listings, {
              [css.newSearchInProgress]: !listingsAreLoaded,
            })}
          >
            {searchListingsError ? (
              <h2 className={css.error}>
                <FormattedMessage id="SearchPage.searchError" />
              </h2>
            ) : null}
            <SearchResultsPanel
              className={css.searchListingsPanel}
              listings={listings}
              pagination={listingsAreLoaded ? pagination : null}
              search={searchParamsForPagination}
              isAuthenticated={isAuthenticated}
              setActiveListing={onActivateListing}
              currentUser={this.props.currentUser}
              urlQueryParams={urlQueryParams}
              onUpdateProfile={onUpdateProfile}
              listingsImages={listingsImages}
            />
          </div>
        )}
      </div>
    );
  }
}

MainPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  resultsCount: 0,
  pagination: null,
  searchParamsForPagination: {},
  // primaryFilters: null,
  // secondaryFilters: null,
};

MainPanel.propTypes = {
  className: string,
  rootClassName: string,

  urlQueryParams: object.isRequired,
  listings: array,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParamsAreInSync: bool.isRequired,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  onMapIconClick: func.isRequired,
  pagination: propTypes.pagination,
  searchParamsForPagination: object,
  showAsModalMaxWidth: number.isRequired,
  // primaryFilters: arrayOf(propTypes.filterConfig),
  // secondaryFilters: arrayOf(propTypes.filterConfig),
};

export default MainPanel;
