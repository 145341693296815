import React from 'react';
import PropTypes from 'prop-types';

import css from './IconMarker.module.css';

const IconMarker = props => {

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px" y="0px"
      viewBox="0 0 512 512"
      width="32px"
      height="20px"
    >
      <g>
        <g>
          <path d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173.004,321.035
			c6.636,7.391,18.222,7.378,24.846,0c7.065-7.868,173.004-194.147,173.004-321.035C441.425,83.182,358.244,0,256,0z M256,469.729
			c-55.847-66.338-152.035-197.217-152.035-284.301c0-83.834,68.202-152.036,152.035-152.036s152.035,68.202,152.035,152.035
			C408.034,272.515,311.861,403.37,256,469.729z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M256,92.134c-51.442,0-93.292,41.851-93.292,93.293s41.851,93.293,93.292,93.293s93.291-41.851,93.291-93.293
			S307.441,92.134,256,92.134z M256,245.328c-33.03,0-59.9-26.871-59.9-59.901s26.871-59.901,59.9-59.901s59.9,26.871,59.9,59.901
			S289.029,245.328,256,245.328z"/>
        </g>
      </g>

    </svg>

  );
};

const { string } = PropTypes;

IconMarker.defaultProps = {
  className: null,
};

IconMarker.propTypes = {
  className: string,
};

export default IconMarker;
