import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { FavoriteListingCard } from '../../components';

import css from './SectionFavorite.module.css';

const SectionFavorite = props => {
  const {
    rootClassName,
    className,
    classNameItem,
    classNamelocations,
    onUpdateProfile,
    currentUser,
    currentList,
    isAuthenticated,
    updateLists,
    subTitle
  } = props;

  const user = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!user.id;
  const classes = classNames(rootClassName || css.root, className);
  const classesLocation = classNames(css.locations, classNamelocations);
  const classesItem = classNames(css.cardItem, classNameItem);

  return (
    <div className={classes}>
      <div className={css.title}>{subTitle}</div>
      <div className={classesLocation}>
            {currentUserLoaded && currentList && currentList.map(l =>
              <FavoriteListingCard
                className={classesItem}
                currentUser={currentUser}
                currentList={currentList}
                onUpdateProfile={onUpdateProfile}
                isAuthenticated={isAuthenticated}
                listing={l}
                key={l.listingId}
                updateLists={updateLists}
              />
            )}
      </div>
    </div>
  );
};

SectionFavorite.defaultProps = {
  listings: [],
  rootClassName: null,
  className: null,
  currentUser: null
};

const { string } = PropTypes;

SectionFavorite.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionFavorite;
