import React from 'react';
import css from './CheckMark.module.css';

class CheckMark extends React.Component {
  static defaultProps = {
    checked: false,
  };

  render() {
    const {checked} = this.props;
    return (
      <span className={checked ? css.checkMarkChecked : css.checkMark}>

      </span>
    );
  }
}

export default CheckMark;
