import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '../../containers';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer, Iubenda, TopbarImgWrapper, LayoutWrapperContent,
} from '../../components';
import config from '../../config';
import cookies from './cookies.png'

import TagManager from "react-gtm-module";
import css from './CookiePolicyPage.module.css';
import { LANGUAGE_KEY } from "../../util/languages";
import {getPrivacyPolicyText} from "../../ducks/PrivacyPolicy.duck";

const CookiePolicyPageComponent = props => {
  const { scrollingDisabled, intl, onGetPrivacyPolicyText, location, privacyPolicyText } = props;

  useEffect(() => {

    let lang = 'en';
    if(typeof localStorage !== 'undefined') {
      lang = localStorage.getItem(LANGUAGE_KEY) === 'en' ? 'en' : localStorage.getItem(LANGUAGE_KEY) === 'de' ? 'deu'  : 'en';
    }

    onGetPrivacyPolicyText(lang, 'cookie');

    if(typeof window !== 'undefined' && document !== 'undefined') {
      const tagManagerArgs = {
        dataLayer: {
          userId: '001',
          userProject: 'Activebreak',
          page: 'CookiePolicyPage'
        },
        events: {
          pageView: 'pageview',
        },
        page: {
          path: window.location.pathname,
          title: 'CookiePolicyPage',
          type: 'terms',
        },
        dataLayerName: 'CookiePolicyPageDataLayer'
      };

      TagManager.dataLayer(tagManagerArgs);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pageview',
        page: {
          path: window.location.pathname,
          locale: localStorage.getItem(LANGUAGE_KEY), // language code of displayed page
          title: 'CookiePolicyPage',
          type: 'terms',
        },
      });
    }
  }, []);

  const tabs = [
    {
      text: intl.formatMessage({ id: 'TermsOfServicePage.imprintTabTitle' }),
      selected: false,
      linkProps: {
        name: 'ImprintPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'TermsOfServicePage.privacyTabTitle' }),
      selected: false,
      linkProps: {
        name: 'PrivacyPolicyPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'TermsOfServicePage.cookieTabTitle' }),
      selected: true,
      linkProps: {
        name: 'CookiePolicyPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'TermsOfServicePage.tosTabTitle' }),
      selected: false,
      linkProps: {
        name: 'TermsOfServicePage',
      },
    },
  ];

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'CookiePolicyPage.schemaTitle' }, { siteTitle });
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: schemaTitle,
  };

  let iubendaEn = {
    title: "Cookie Policy",
    text: "Cookie Policy",
    idForm: "privacy-policy/48988523/cookie-policy",
  }
  let iubendaDe = {
    title: "Cookie-Richtlinie",
    text: "Cookie-Richtlinie",
    idForm: "privacy-policy/84666627/cookie-policy",
  }

  let iubendaId
  if(typeof localStorage !== 'undefined') {
    iubendaId = localStorage.getItem(LANGUAGE_KEY) === 'de'
      ? iubendaDe
      : iubendaEn
  }

  const topText = intl.formatMessage({ id: 'CookiePolicyPage.topText' })
  const subText = intl.formatMessage({ id: 'CookiePolicyPage.subText' })


  return (
    <Page title={schemaTitle} scrollingDisabled={scrollingDisabled} schema={schema}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="CookiePolicyPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperContent>
          <TopbarImgWrapper
            topBar
            img={cookies}
            topText={topText}
            subText={subText}
          />
        </LayoutWrapperContent>
        <LayoutWrapperSideNav className={css.wrapperSideNav} tabs={tabs} />
        <LayoutWrapperMain className={css.wrapperContent}>
          <div className={css.content}>
            <Iubenda iubendaId={iubendaId} />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer currentPage="CookiePolicyPage" location={location}/>
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const { bool, func } = PropTypes;

CookiePolicyPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  onGetPrivacyPolicyText: func,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { privacyPolicyText } = state.PrivacyPolicy;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    privacyPolicyText,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetPrivacyPolicyText: (lang, type) => dispatch(getPrivacyPolicyText(lang, type)),
});

const PrivacyPolicyPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CookiePolicyPageComponent);

export default PrivacyPolicyPage;
