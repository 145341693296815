import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { arrayOf, array, bool, func, node, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED } from '../../util/types';
// import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile } from '../../components';
import { createSlug } from '../../util/urlHelpers';

import css from './BookingPanel.module.css';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    // titleClassName,
    listing,
    formattedPerDayPrice,
    formattedPrice,
    title,
    authorDisplayName,
    onManageDisableScrolling,
    history,
    location,
    days,
    isMobile,
    showCustomTopbar,
    // showCustomTopbarButton
  } = props;

  // const partnerListingUrl = listing.attributes && listing.attributes.publicData && listing.attributes.publicData.partner_listing_url;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm = hasListingState && !isClosed;
  const isBook = !!parse(location.search).book;

  const classes = classNames(rootClassName || css.root, className);

  const ROOT_URL = process.env.REACT_APP_CANONICAL_ROOT_URL;
  const slug = listing?.attributes?.title && createSlug(listing.attributes.title);
  const id = listing?.id?.uuid;

  return (
    <div className={classes}>
      {!isMobile && !showCustomTopbar &&
        <ModalInMobile
          className={css.stickyModal}
          containerClassName={css.modalContainer}
          id='BookingDatesFormInModal'
          isModalOpenOnMobile={isBook}
          onClose={() => closeBookModal(history, location)}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.modalHeading}>
            <h1 className={css.title}>{title}</h1>
            <div className={css.author}>
              <FormattedMessage id='BookingPanel.hostedBy' values={{ name: authorDisplayName }} />
            </div>
          </div>

          <div className={css.bookingHeading}>
            <div className={css.fromValue}>
              <span className={css.headingOfInfo}>
                <FormattedMessage id='BookingPanel.priceFrom' />
              </span>
              <span className={classNames(css.fromValueNumber, {
                [css.bigNumber]: formattedPrice.length > 10,
              })}>{formattedPrice} </span>
              <span className={css.headingOfInfo}>
                <FormattedMessage id='BookingPanel.person' />
              </span>
            </div>
            <div className={css.headingOfInfo} title={formattedPerDayPrice}>
              <span>
                ({days}&nbsp;
                <FormattedMessage id='BookingPanel.days' />
                /{days - 1}&nbsp;
                <FormattedMessage id='BookingPanel.nights' />
                )
              </span>
            </div>

          </div>
          {showBookingDatesForm ? (
            <a className={css.bookButton} href={`${ROOT_URL}/l/${slug}/${id}#availability`}>
              <FormattedMessage id='BookingDatesForm.requestToBook' />
            </a>
          ) : null}
          <div className={css.lowWikiText}>
            <span className={css.lowWikiTextLow}>
              <FormattedMessage id='UserCard.processedWikinger' values={{ displayName: authorDisplayName }} />
            </span>
            {/* <span className={css.lowWikiTextBolt}>
              <FormattedMessage id='UserCard.processedWikingerBolt' />
            </span> */}
          </div>

        </ModalInMobile>
      }

      {isMobile && <div className={css.openBookingForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValueBlock}>
            <span className={css.headingOfInfo}>
              <FormattedMessage id='BookingPanel.priceFrom' />
            </span>
            <span className={classNames(css.priceValue, {
              [css.bigNumber]: formattedPrice.length > 11,
              [css.veryBigNumber]: formattedPrice.length > 13,
            })}>{formattedPrice} </span>
            <span className={css.perUnit}>
              <FormattedMessage id='BookingPanel.person' />
            </span>
          </div>
          <div className={css.headingOfInfo} title={formattedPerDayPrice}>
            <span>
              ({days}&nbsp;
              <FormattedMessage id='BookingPanel.days' />
              /{days - 1}&nbsp;
              <FormattedMessage id='BookingPanel.nights' />
              )
            </span>
          </div>
        </div>
        <div>
          {showBookingDatesForm ? (
            // <a className={css.bookButton} href={partnerListingUrl} target='_blank'>
            //   <FormattedMessage id='BookingDatesForm.requestToBook' />
            // </a>
            <a className={css.bookButton} href={`${ROOT_URL}/l/${slug}/${id}#availability`}>
              <FormattedMessage id='BookingDatesForm.requestToBook' />
            </a>
          ) : isClosed ? (
            <div className={css.closedListingButton}>
              <FormattedMessage id='BookingPanel.closedListingButtonText' />
            </div>
          ) : null}
          <div className={css.lowWikiText}>
            <span className={css.lowWikiTextLow}>
              <FormattedMessage id='UserCard.processedWikinger' values={{ displayName: authorDisplayName }}/>
            </span>
            {/* <span className={css.lowWikiTextBolt}>
              <FormattedMessage id='UserCard.processedWikingerBolt' />
            </span> */}
          </div>
        </div>
      </div>}
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl,
)(BookingPanel);
