import config from '../../config';
import { createResourceLocatorString } from '../../util/routes';
import { createSlug } from '../../util/urlHelpers';
import routeConfiguration from '../../routeConfiguration';

export const createSearchResultSchema = (listings, address, intl) => {
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const searchAddress = address || intl.formatMessage({ id: 'SearchPage.schemaMapSearch' });
  const schemaDescription = intl.formatMessage({ id: 'SearchPage.schemaDescription' });
  const schemaTitle = intl.formatMessage(
    { id: 'SearchPage.schemaTitle' },
    { searchAddress, siteTitle }
  );
  const schemaProducts = listings.map((l, i) => {
    const title = l !== undefined && l.attributes !== undefined ? l.attributes.title : '';
    const price = l !== undefined && l.attributes !== undefined ? l.attributes.price.amount : 0;
    const priceCurrency = l !== undefined && l.attributes !== undefined ? l.attributes.price.currency : 'EUR';
    const description = l !== undefined && l.attributes !== undefined ? l.attributes.publicData.description : '';
    const Reviews = l !== undefined && l.attributes !== undefined ? l.attributes.publicData : [];
    let ratingsArray = Reviews.map(r => r.ratings);
    const reviewCount = Reviews.length;
    ratingsArray = ratingsArray.reduce((a, b) => {
      return a.concat(b);
    }, []);
    const ratings = ratingsArray && ratingsArray.length > 0 && ratingsArray.map(a => {
      if(a.rating) return a.rating
    });
    const bestRating = ratings && ratings.length !== 0 ? ratings.reduce((a, b) => (parseInt(a) + parseInt(b))) / ratings.length : 0;
    const pathToItem = createResourceLocatorString('ListingPage', routeConfiguration(), {
      id: l.listingId,
      slug: createSlug(title),
    });

    return {
      '@context': 'http://schema.org',
      '@type': 'Product',
      aggregateRating: {
        "@type": "AggregateRating",
        bestRating: 5,
        ratingValue: bestRating,
        reviewCount,
        worstRating: 1
      },
      brand: {
        "@type": "Thing",
        name: title
      },
      description,
      image: "https://www.wikinger-reisen.de/bilder/reisen/soca-fluss-ost17320_rdax_630x420_50.jpg",
      name: title,
      offers: {
        "@type": "Offer",
        availability: "InStock",
        price,
        priceCurrency,
        priceValidUntil: price,
        url: `${config.canonicalRootURL}${pathToItem}`,
      },
      url: `${config.canonicalRootURL}${pathToItem}`,
      sku: "844"
    }
  });

  return {
    title: schemaTitle,
    description: schemaDescription,
    schema: schemaProducts
  };
};
