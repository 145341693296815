import React, { Component } from 'react';
import css from './ListingPage.module.css';
import { HostsBlock, SurroundingsBlock, ActivityBlock, ItineraryBlock } from '../../components';
import { initialize } from '../../util/googleMaps';
import Tabs, { TabPane } from 'rc-tabs';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import {FormattedMessage} from "../../util/reactIntl";
import classNames from "classnames";


class SectionTabs extends Component {
  componentDidMount() {
    const { listing } = this.props;
    const lat = listing.attributes.geolocation?.lat ?? null;
    const lng = listing.attributes.geolocation?.lng ?? null;
    if (lat && lng) initialize(lat, lng);
  }

  render() {
    const callback = key => {};

    const {
      listing: {
        attributes: {
          publicData: {
            guides: publicDataGuides = [],
            accommodations: publicDataAccommodations = [],
            itinerary: publicDataItinerary = [],
          },
          geolocation,
        },
      },
      intl
    } = this.props;
    const listing = this.props.listing;

    const activityCenterTitle = intl.formatMessage({ id: 'SectionTabs.activityCenterTitle' });
    const locationsTitle = intl.formatMessage({ id: 'SectionTabs.locationsTitle' });
    const itineraryTitle = intl.formatMessage({ id: 'SectionTabs.itineraryTitle' });
    const hostsTitle = intl.formatMessage({ id: 'SectionTabs.hostsTitle' });

    return (
      <div className={css.sectionTabs}>
        <h3 className={classNames(css.spotlightTitle, css.spotlightTitleMargin)}>
          <FormattedMessage id="UserCard.tripInsightsTitle" />
        </h3>
        <Tabs className={css.customTabs} defaultActiveKey="1" onChange={callback} renderTabBar={() => <ScrollableInkTabBar />} renderTabContent={() => <TabContent />}>
          {publicDataItinerary.length > 0 && <TabPane className={css.customTabPane} tab={itineraryTitle} key="1">
            <ItineraryBlock listing={listing} />
          </TabPane>}
          {publicDataAccommodations.length > 0 && <TabPane tab={activityCenterTitle} key="2">
            <ActivityBlock listing={listing} />
          </TabPane>}
          {geolocation && <TabPane tab={locationsTitle} key="3">
            <SurroundingsBlock location={geolocation} listing={listing}/>
          </TabPane>}
          {publicDataGuides.length > 0 && <TabPane tab={hostsTitle} key="4">
            <HostsBlock listing={listing} />
          </TabPane>}
        </Tabs>
      </div>
    );
  }
}

export default SectionTabs;
