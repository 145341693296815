import React from 'react';
import css from "./SectionList.module.css";
import { ensureCurrentUser } from "../../util/data";
import classNames from "classnames";
import CurrentListing from "./CurrentListing";


const SectionList = ({
                       favoritesList,
                       rootClassName,
                       className,
                       classNameItem,
                       classNamelocations,
                       onUpdateProfile,
                       currentUser,
                       currentList,
                       isAuthenticated,
                       topTitle,
                       subTitle
                     }) => {

  const user = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!user.id;
  const classes = classNames(rootClassName || css.root, className);
  const classesLocation = classNames(css.locations, classNamelocations);
  const classesItem = classNames(css.cardItem, classNameItem);

  return (
    <div className={classes}>
      <h1 className={css.topTitle}>{topTitle}</h1>
      <div className={css.title}>{subTitle}</div>
      <div className={classesLocation}>
        {currentUserLoaded && currentList && currentList.map(l =>
          <CurrentListing
            favoritesList={favoritesList}
            className={classesItem}
            currentUser={currentUser}
            currentList={currentList}
            onUpdateProfile={onUpdateProfile}
            isAuthenticated={isAuthenticated}
            listing={l}
            key={l.id.uuid}
          />
        )}
      </div>
    </div>
  );
};

export default SectionList;
