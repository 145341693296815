import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Footer,
  LayoutSingleColumn, LayoutWrapperContent,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page, TopbarImgWrapper,
} from '../../components';
import config from '../../config';
// import ContactForm from './ContactForm';
import { localLang } from '../../util/data';


import css from './ContactPage.module.css';
import HubspotForm from "react-hubspot-form";
import {TopbarContainer} from "../index";


import imgTop from './img/topImg-min.png';
import SectionImage from './SectionImage';


const ContactPageComponent = props => {
  const { scrollingDisabled, intl, location } = props;

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'ContactPage.schemaTitle' }, { siteTitle });
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: schemaTitle,
  };

  const topText = intl.formatMessage({ id: 'ContactPage.topText' })
  const subText = intl.formatMessage({ id: 'ContactPage.subText' })
  const nextText = intl.formatMessage({ id: 'ContactPage.nextText' })

  // TODO for fr and es the same form (if client give new - need changed)

  const formId = {
    en: '509d7dc4-f653-4f51-9654-cc43009efadc',
    es: '509d7dc4-f653-4f51-9654-cc43009efadc',
    fr: '509d7dc4-f653-4f51-9654-cc43009efadc',
    de: '11cd7a69-b67a-4719-a49d-00e6c4774144'
  }


  return (
    <Page title={schemaTitle} scrollingDisabled={scrollingDisabled} schema={schema}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ContactPage"/>
        </LayoutWrapperTopbar>
        <LayoutWrapperContent>
          <TopbarImgWrapper
            classWrapperNameImg={css.contentText}
            img={imgTop}
            topText={topText}
            subText={subText}
            nextText={nextText}
          />
        </LayoutWrapperContent>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <HubspotForm
            portalId='5440848'
            formId={localLang(formId)}
            onSubmit={() => {}}
            onReady={() => {}}
            loading={<div>Loading...</div>}
          />

        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <SectionImage intl={intl} />
          <Footer currentPage="ContactPage" location={location}/>
        </LayoutWrapperFooter>
      </LayoutSingleColumn>

    </Page>
  );
};

const { bool } = PropTypes;

ContactPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const ContactPage = compose(
  connect(mapStateToProps),
  injectIntl
)(ContactPageComponent);

export default ContactPage;
