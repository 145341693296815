import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import Modal from '../Modal/Modal';
import classNames from 'classnames';
import { BecomeAHostForm } from '../../forms';
// import HubspotForm from 'react-hubspot-form'

import css from './HostActivity.module.css';

const HostActivity = (props) => {
  const { linkClassName, hubspotFormSubmit, becomeAhostSuccess, schemaTitle } = props;
  const [isOpen, setIsOpen] = useState(false);
  const onManageDisableScrolling = (componentId, scrollingDisabled = false) => {
    // We are just checking the value for now
    console.log('Toggling Modal - scrollingDisabled currently:', componentId, scrollingDisabled);
  };


  const successForm = (
    <div className={css.successForm}>
      <h2 className={css.successFormTitle}>
        Thank you for your interest in Activebreak!
      </h2>
      <p className={css.successFormDescription}>
        We'll get in touch shortly to discuss how we can best work together.
      </p>
    </div>
  )

  return (
    <>
      <a onClick={() => setIsOpen(true)} className={classNames(css.hostActivityButton, linkClassName)}>
        <FormattedMessage id='TopbarDesktop.hostActivity' />
      </a>
      <Modal
        // id={'host-activity-form'}
        id="BecomeAHostForm"
        isOpen={isOpen}
        onManageDisableScrolling={onManageDisableScrolling}
        onClose={() => setIsOpen(false)}
        closeButtonMessage={' '}
        containerClassName={css.container}
      >
        <div className={css.hubspotWrapper}>
          {becomeAhostSuccess ? successForm : (
            <BecomeAHostForm
              onSubmit={(values) => { hubspotFormSubmit(values) }}
              schemaTitle={schemaTitle}
            />
          )}
          {/*<HubspotForm*/}
          {/*  portalId='5440848'*/}
          {/*  formId='946731d4-2d7f-47b4-b35e-aded6a7448e8'*/}
          {/*  onSubmit={() => {}}*/}
          {/*  onReady={() => {}}*/}
          {/*  loading={<div>Loading...</div>}*/}
          {/*/>*/}
        </div>
      </Modal>
    </>
  )
};

const { oneOf, string } = PropTypes;

HostActivity.defaultProps = {
  className: null,
  format: 'desktop',
};

HostActivity.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default HostActivity;
