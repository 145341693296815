import React, { useEffect } from 'react';

const Iubenda = ({ iubendaId }) => {
  const {
    idForm,
    title,
    text
  } = iubendaId || {}
  useEffect(() => {
    let s = document.createElement('script');
    let tag = document.getElementsByTagName('script')[0];
    s.src = 'https://cdn.iubenda.com/iubenda.js';
    tag.parentNode.insertBefore(s, tag);
  }, []);

  return (
    <a
      href={`https://www.iubenda.com/${idForm}`}
      className='iubenda-white no-brand iubenda-noiframe iubenda-embed iub-no-markup iubenda-noiframe iub-body-embed'
      title={title}
    >
      {text}
    </a>
  );
};

export default Iubenda;
