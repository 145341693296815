import React from 'react';
import { string } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import { Menu, MenuLabel, MenuContent, MenuItem } from '../../components';
import {
  findRouteByRouteName,
  localizePathByRouteName,
  getUrlParams,
  getCurrentLanguage,
  findHash,
} from '../../util/routes';
import translate from './icons/Translate.svg';
import css from './LanguageSelect.module.css';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import appRoutes from '../../translations/appRoutes';
import * as languages from '../../translations/index';
import {translateOptions} from "../../marketplace-custom-config";

export const LanguageSelect = ({ type, currentPage, location }) => {

  const localStorageExist = typeof window !== 'undefined' && typeof window.localStorage !== 'undefined';
  const lang = localStorageExist ? localStorage.getItem('lang') : config.locale;

  const handleToggleLanguage = (langKey) => (e) => {
    e.preventDefault();
    const { pathname: urlPath, search: urlSearch, hash } = location;
    const currentLang = getCurrentLanguage(urlPath);

    if (langKey !== currentLang && localStorageExist) {
      const routes = routeConfiguration();
      const routeName = findRouteByRouteName(currentPage, routes);

      const matchingRoute = languages[langKey][appRoutes[routeName.name]];
      const routeWithLang = `${langKey === 'en' ? '' : '/'+langKey}${matchingRoute}`;
      const params = getUrlParams(urlPath, routeName.path);
      let pathname = localizePathByRouteName(routeWithLang, params);

      if (hash) {
        const translatedHash = findHash(currentLang, langKey, hash);
        pathname = `${pathname}#${translatedHash}`;
      }
      if (urlSearch) {
        pathname = `${pathname}${urlSearch}`;
      }

      localStorage.setItem('lang', langKey);
      config.locale = langKey;
      window.location.replace(pathname);
    }
  }

  const langMenu = <Menu contentPosition={'left'} className={css.languageCurrencyMenu}>
    <MenuLabel className={css.menuLabel}>
      {localStorageExist && !type
        ? <span className={css.selectedLang}>{lang}</span>
        : <img src={translate} alt='' width={'20'} />
      }
    </MenuLabel>
    <MenuContent className={css.menuContent}>
      {translateOptions.map(({key, label}) => (
        <MenuItem key={key}>
          <button
            className={css.menuItem}
            onClick={handleToggleLanguage(key)}
          >
            <span className={localStorageExist && lang === key
              ? css.menuItemBorderSelected
              : css.menuItemBorder}
            />
            {label}
          </button>
        </MenuItem>
      ))}
    </MenuContent>
  </Menu>;

  return (
    <div className={css.langMenuHolder}>
      {langMenu}
    </div>
  );
};

LanguageSelect.defaultProps = {
  rootClassName: null,
  className: null,
};

LanguageSelect.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape,
};


export default LanguageSelect;
