import React from 'react';
import ReactCarousel from 'react-alice-carousel';

import css from './ListingPage.module.css';

let SectionImages = ({ images = [] }) => {
  const items = images.map(({ url }) => <img key={url} src={url} role="presentation" /> );

  return (
    <div className={css.sectionImages}>
      <ReactCarousel
        mouseTrackingEnabled={true}
        dotsDisabled={true}
        swipeDisabled={false}
        preservePosition={true}
        fadeOutAnimation={true}
        autoHeight={false}
        buttonsDisabled={true}
        items={items}
        slideToIndex={1}
        autoPlay={true}
        showSlideInf={true}
        autoPlayInterval={5000}
        infinite
        mouseTracking
        autoWidth
        ref={(el) => (SectionImages = el)}
        responsive={
          {
            0: { items: 1 },
            1024: { items: 2 },
            1600: { items: 3 },
          }
        }
      >
      </ReactCarousel>
      <button className={css.imageSecButtonPrev} onClick={() => SectionImages.slidePrev()} />
      <button className={css.imageSecButtonNext} onClick={() => SectionImages.slideNext()} />
    </div >
  );
};

export default SectionImages;
