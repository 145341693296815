import React, { Component } from 'react';
import css from './HostsBlock.module.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading, AccordionItemPanel,
} from 'react-accessible-accordion';
// import LinesEllipsis from 'react-lines-ellipsis';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'

class HostsBlock extends Component {
  state = {
    maxLine: 3,
    useEllipsis: true
  };

  onTextClick = (e) => {
    e.preventDefault();
    this.setState({useEllipsis: false});
  };

  render() {
    const {
      listing: {
        attributes: {
          publicData: {
            guides = []
          }
        }
      }
    } = this.props;

    return (
      <div className={css.hostsHolder}>
        <ul className={css.hostsDesktop}>
          {guides.map((e, i) => <li className={css.host} key={i}>
            <div className={css.hostPhoto} style={{ backgroundImage: `url(${e.images[0].url})` }}/>
            <div className={css.textHolder}>
              <span className={css.hostName}>{`${e.first_name} ${e.last_name}`}</span>
              <span className={css.hostText}>{e.categories[0]}</span>
              <p className={css.hostText} dangerouslySetInnerHTML={{ __html: e.description }}></p>
            </div>
          </li>)}
        </ul>
        <ul className={css.hostsMobile}>
          {guides.map((e, i) => <li className={css.host} key={i}>
            <Accordion allowZeroExpanded={true} className={css.accordion}>
              <AccordionItem key={i}>
                <AccordionItemHeading className={css.accordionHeading}>
                  <AccordionItemButton className={css.accordionButton}>
                    <>
                      <div className={css.hostPhoto} style={{ backgroundImage: `url(${e.images[0].url})` }}/>
                      <div className={css.dataAccHolder}>
                        <span className={css.hostName}>{`${e.first_name} ${e.last_name}`}</span>
                        <span className={css.hostText}>{e.categories[0]}</span>
                      </div>
                    </>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className={css.textHolder}>
                    {this.state.useEllipsis
                      ? (
                          <div onClick={(e) => this.onTextClick(e)} tabIndex='0'>
                            {/* <LinesEllipsis
                              text={e.description}
                              style={{ whiteSpace: 'pre-wrap' }}
                              maxLine='6'
                              ellipsis='...read more'
                            /> */}
                            <HTMLEllipsis
                              ellipsis='...read more'
                              style={{ whiteSpace: 'pre-wrap' }}
                              maxLine='6'
                              unsafeHTML={e.description}
                            />
                          </div>
                        )
                      : <p className={css.hostText} dangerouslySetInnerHTML={{ __html: e.description }}></p>
                    }
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </li>)}
        </ul>
      </div>
    );
  }
}

export default HostsBlock;
