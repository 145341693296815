import React from "react";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import { compose, withProps, lifecycle } from "recompose";
import CurveMarker from './components/CurveMarker/CurveMarker';
import CustomMarker from './components/CustomMarker/CustomMarker';

const REACT_APP_GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const Markers = (props)  => {
  return (
    <div>
      <CustomMarker
        map={props.map}
        coordinates={props.coordinates}
        activePlace={props.activePlace}
        infoCardOpen={props.infoCardOpen}
        onPlaceClicked={() => {}}
        mapComponentRefreshToken={props.mapComponentRefreshToken}
      />
      <CurveMarker
        markers={props.coordinates}
        mapProjection={props.mapProjection}
        zoom={props.zoom}
      />
    </div>
  )
}

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: "100%"}} />,
    containerElement: <div style={{ height: "100vh" }} />,
    mapElement: <div style={{ height: "100%" }} />
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};
      this.setState({
        mapProjection: null,
        zoom: 7,
        mapCopy: refs.map,
        onMapMounted: ref => {
          refs.map = ref;

          this.setState({
            mapCopy: ref,
          });
        },
        projectionChanged: () => {
          this.setState({
            mapProjection: refs.map.getProjection()
          });
        },
        onZoomChanged: () => {
          this.setState({
            zoom: refs.map.getZoom()
          });
        }
      });
    }
  }),
  withScriptjs,
  withGoogleMap
)(props => {

  return (
  <GoogleMap
    defaultCenter={{ lat: props.coordinates[0].lat, lng: props.coordinates[0].lng }}
    ref={props.onMapMounted}
    onProjectionChanged={props.projectionChanged}
    zoom={props.zoom}
    onZoomChanged={props.onZoomChanged}
  >
    <Markers
      mapProjection={props.mapProjection}
      zoom={props.zoom}
      coordinates={props.coordinates}
      map={props.mapCopy}
      mapComponentRefreshToken={props.mapComponentRefreshToken}
      infoCardOpen={props.infoCardOpen}
      activePlace={props.activePlace}
    />
  </GoogleMap>
)});


const ItineraryMap = ({ places, mapComponentRefreshToken, activePlace }) => {
  const coordinates = places.map(({locations, order}) => {
    if (!locations[0]) return null;

    return {
      lat: locations[0].location.lat,
      lng: locations[0].location.lon,
      order: order,
    }
  }).filter(Boolean);

  return (
      <MyMapComponent
        coordinates={coordinates}
        mapComponentRefreshToken={mapComponentRefreshToken}
        activePlace={activePlace}
      />
  );
};

export default ItineraryMap;
