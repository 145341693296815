import TagManager from 'react-gtm-module';

export const trackUserEvents = (params) => {
  const { ecommerce } = params;
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
    events: { ecommerce }
  };

  TagManager.initialize(tagManagerArgs)
};

