import React, { useEffect } from 'react';
// import config from '../../config';
// import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  // ExternalLink,
  LayoutWrapperContent,
  TopbarImgWrapper,
} from '../../components';

import css from './TravelerHelpPage.module.css';
import TagManager from "react-gtm-module";
import { FormattedMessage, injectIntl } from "../../util/reactIntl";
import SideBarNav from "./SideBarNav";

import imgTop from './img/imgTop.png'
import img1 from './img/img1.jpg'
import img2 from './img/img2.jpg'
import img3 from './img/img3.jpg'
import img4 from './img/img4.jpg'
import img5 from './img/img5.jpg'
import classNames from 'classnames';
import { LANGUAGE_KEY } from '../../util/languages';


const TravelerHelpPage = ({ intl, location }) => {
  // const { siteTwitterHandle, siteFacebookPage } = config;
  // const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  useEffect(() => {
    if(typeof window !== 'undefined') {
      const tagManagerArgs = {
        dataLayer: {
          userId: '001',
          userProject: 'Activebreak',
          page: 'TravelerHelpPage'
        },
        events: {
          pageView: 'pageview',
        },
        page: {
          path: window.location.pathname,
          title: 'Traveler Help Page',
          type: 'support',
        },
        dataLayerName: 'TravelerHelpPageDataLayer',
      };

      TagManager.dataLayer(tagManagerArgs);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pageview',
        page: {
          path: window.location.pathname,
          locale: localStorage.getItem(LANGUAGE_KEY), // language code of displayed page
          title: 'Traveler Help Page',
          type: 'support',
        },
      });
    }
  });

  const topText = intl.formatMessage({ id: 'TravelerHelpPage.topText' })
  const subText = intl.formatMessage({ id: 'TravelerHelpPage.subText' })
  const nextText = intl.formatMessage({ id: 'TravelerHelpPage.nextText' })
  const title = intl.formatMessage({ id: "TravelerHelpPage.title" });

  const aboutSlug = intl.formatMessage({ id: 'TravelerHelpPage.aboutSlug' });
  const whySlug = intl.formatMessage({ id: 'TravelerHelpPage.whySlug' });
  const signSlug = intl.formatMessage({ id: 'TravelerHelpPage.signSlug' });
  const adventuresSlug = intl.formatMessage({ id: 'TravelerHelpPage.adventuresSlug' });
  const locationsSlug = intl.formatMessage({ id: 'TravelerHelpPage.locationsSlug' });
  const languagesSlug = intl.formatMessage({ id: 'TravelerHelpPage.languagesSlug' });
  const experienceSlug = intl.formatMessage({ id: 'TravelerHelpPage.experienceSlug' });
  const searchingSlug = intl.formatMessage({ id: 'TravelerHelpPage.searchingSlug' });
  const filteringSlug = intl.formatMessage({ id: 'TravelerHelpPage.filteringSlug' });
  const bookingSlug = intl.formatMessage({ id: 'TravelerHelpPage.bookingSlug' });
  const pricingSlug = intl.formatMessage({ id: 'TravelerHelpPage.pricingSlug' });
  const durationSlug = intl.formatMessage({ id: 'TravelerHelpPage.durationSlug' });
  const feesSlug = intl.formatMessage({ id: 'TravelerHelpPage.feesSlug' });
  const includedSlug = intl.formatMessage({ id: 'TravelerHelpPage.includedSlug' });
  const requestSlug = intl.formatMessage({ id: 'TravelerHelpPage.requestSlug' });
  const responseSlug = intl.formatMessage({ id: 'TravelerHelpPage.responseSlug' });
  const acceptSlug = intl.formatMessage({ id: 'TravelerHelpPage.acceptSlug' });
  const declineSlug = intl.formatMessage({ id: 'TravelerHelpPage.declineSlug' });
  const bindSlug = intl.formatMessage({ id: 'TravelerHelpPage.bindSlug' });
  const statusSlug = intl.formatMessage({ id: 'TravelerHelpPage.statusSlug' });
  const changeSlug = intl.formatMessage({ id: 'TravelerHelpPage.changeSlug' });
  const requirementsSlug = intl.formatMessage({ id: 'TravelerHelpPage.requirementsSlug' });
  const qualificationsSlug = intl.formatMessage({ id: 'TravelerHelpPage.qualificationsSlug' });
  const skillsSlug = intl.formatMessage({ id: 'TravelerHelpPage.skillsSlug' });
  const ageSlug = intl.formatMessage({ id: 'TravelerHelpPage.ageSlug' });
  const childrenSlug = intl.formatMessage({ id: 'TravelerHelpPage.childrenSlug' });
  const guestsSlug = intl.formatMessage({ id: 'TravelerHelpPage.guestsSlug' });
  const someoneSlug = intl.formatMessage({ id: 'TravelerHelpPage.someoneSlug' });
  const othersSlug = intl.formatMessage({ id: 'TravelerHelpPage.othersSlug' });
  const dietarySlug = intl.formatMessage({ id: 'TravelerHelpPage.dietarySlug' });
  const paymentsSlug = intl.formatMessage({ id: 'TravelerHelpPage.paymentsSlug' });
  const payments_optionsSlug = intl.formatMessage({ id: 'TravelerHelpPage.payments_optionsSlug' });
  const payments_timingSlug = intl.formatMessage({ id: 'TravelerHelpPage.payments_timingSlug' });
  const currencySlug = intl.formatMessage({ id: 'TravelerHelpPage.currencySlug' });
  const securitySlug = intl.formatMessage({ id: 'TravelerHelpPage.securitySlug' });
  const invoicesSlug = intl.formatMessage({ id: 'TravelerHelpPage.invoicesSlug' });
  const questionsSlug = intl.formatMessage({ id: 'TravelerHelpPage.questionsSlug' });
  const travelingSlug = intl.formatMessage({ id: 'TravelerHelpPage.travelingSlug' });
  const preparationSlug = intl.formatMessage({ id: 'TravelerHelpPage.preparationSlug' });
  const levelsSlug = intl.formatMessage({ id: 'TravelerHelpPage.levelsSlug' });
  const meetingSlug = intl.formatMessage({ id: 'TravelerHelpPage.meetingSlug' });
  const problems_meetingSlug = intl.formatMessage({ id: 'TravelerHelpPage.problems_meetingSlug' });
  const communicationsSlug = intl.formatMessage({ id: 'TravelerHelpPage.communicationsSlug' });
  const messagingSlug = intl.formatMessage({ id: 'TravelerHelpPage.messagingSlug' });
  const reading_messagesSlug = intl.formatMessage({ id: 'TravelerHelpPage.reading_messagesSlug' });
  const messaging_hostSlug = intl.formatMessage({ id: 'TravelerHelpPage.messaging_hostSlug' });
  const message_safetySlug = intl.formatMessage({ id: 'TravelerHelpPage.message_safetySlug' });
  const contactSlug = intl.formatMessage({ id: 'TravelerHelpPage.contactSlug' });
  const contact_activebreakSlug = intl.formatMessage({ id: 'TravelerHelpPage.contact_activebreakSlug' });
  const reviewsSlug = intl.formatMessage({ id: 'TravelerHelpPage.reviewsSlug' });
  const reviewersSlug = intl.formatMessage({ id: 'TravelerHelpPage.reviewersSlug' });
  const how_to_reviewSlug = intl.formatMessage({ id: 'TravelerHelpPage.how_to_reviewSlug' });
  const modifying_reviewsSlug = intl.formatMessage({ id: 'TravelerHelpPage.modifying_reviewsSlug' });
  const missing_reviewsSlug = intl.formatMessage({ id: 'TravelerHelpPage.missing_reviewsSlug' });
  const cancellationsSlug = intl.formatMessage({ id: 'TravelerHelpPage.cancellationsSlug' });
  const cancellation_policiesSlug = intl.formatMessage({ id: 'TravelerHelpPage.cancellation_policiesSlug' });
  const host_cancelsSlug = intl.formatMessage({ id: 'TravelerHelpPage.host_cancelsSlug' });
  const refundsSlug = intl.formatMessage({ id: 'TravelerHelpPage.refundsSlug' });
  const accountSlug = intl.formatMessage({ id: 'TravelerHelpPage.accountSlug' });
  const resettingSlug = intl.formatMessage({ id: 'TravelerHelpPage.resettingSlug' });
  const deleting_accountSlug = intl.formatMessage({ id: 'TravelerHelpPage.deleting_accountSlug' });
  const notificationSlug = intl.formatMessage({ id: 'TravelerHelpPage.notificationSlug' });
  const misuseSlug = intl.formatMessage({ id: 'TravelerHelpPage.misuseSlug' });

  // prettier-ignore
  return (
    <StaticPage
      title={title}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Traveler help',
        description: 'Traveler help',
        name: 'Traveler help page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="TravelerHelpPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperContent>
          <TopbarImgWrapper
            img={imgTop}
            topText={topText}
            subText={subText}
            nextText={nextText}
          />
        </LayoutWrapperContent>
        <LayoutWrapperMain className={css.staticPageWrapper}>

          <div className={css.contentWrapper}>
            <div className={css.contentSide}>
              <ul className={css.navList}>
                <SideBarNav intl={intl} location={location}/>
              </ul>

            </div>

            <div className={css.contentMain}>
              <h2 id={aboutSlug}><FormattedMessage id="TravelerHelpPage.subtitle"/></h2>
              <h3 id={whySlug}><FormattedMessage id="TravelerHelpPage.subSubTitle1"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text1"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text2"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text3"/></p>

              <h3 id={signSlug} ><FormattedMessage id="TravelerHelpPage.subSubTitle2"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text4"/>
              <FormattedMessage id="TravelerHelpPage.text5"/>
              <FormattedMessage id="TravelerHelpPage.text6"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text7"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text8"/></p>

              <h3 id={adventuresSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle3"/></h3>
              <ul className={css.listBullets}>
                <li><FormattedMessage id="TravelerHelpPage.text9"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem1"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem2"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem3"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem4"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem5"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem6"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem7"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem8"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem19"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem10"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem11"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem12"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem13"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem14"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem15"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem16"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem17"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem18"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem19"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem20"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem21"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem22"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem23"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem24"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem25"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem26"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem27"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem28"/></li>
              </ul>
              <p><FormattedMessage id="TravelerHelpPage.text10"/></p>

              <h3 id={locationsSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle4"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text11"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text12"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text13"/></p>

              <h3 id={languagesSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle5"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text14"/></p>

              <TopbarImgWrapper
                img={img1}
                className={css.imgBlock}
              />

              <h2 id={experienceSlug}><FormattedMessage id="TravelerHelpPage.subtitle1"/></h2>
              <h3 id={searchingSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle6"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text15"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text16"/></p>

              <ul className={css.listNumbers}>
                <li><FormattedMessage id="TravelerHelpPage.listItem29"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem30"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem31"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem32"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem33"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem34"/></li>
              </ul>

              <h3 id={filteringSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle7"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text17"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text18"/></p>
              <ul className={css.listBullets}>
                <li><FormattedMessage id="TravelerHelpPage.listItem35"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem36"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem37"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem38"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem39"/></li>
              </ul>
              <p><FormattedMessage id="TravelerHelpPage.text19"/></p>
              <ul className={css.listBullets}>
                <li><FormattedMessage id="TravelerHelpPage.listItem40"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem41"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem42"/></li>
              </ul>

              <TopbarImgWrapper
                img={img2}
                className={css.imgBlock}
              />

              <h2 id={bookingSlug}><FormattedMessage id="TravelerHelpPage.subtitle2"/></h2>
              <h3 id={pricingSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle8"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text20"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text21"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text22"/></p>

              <h3 id={durationSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle9"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text23"/></p>

              <h3 id={feesSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle10"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text24"/></p>

              <h3 id={includedSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle11"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text25"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text26"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text27"/></p>

              <h3 id={requestSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle12"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text28"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text29"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text30"/></p>

              <h3 id={responseSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle13"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text31"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text32"/></p>

              <h3 id={acceptSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle14"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text33"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text34"/></p>

              <h3 id={declineSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle15"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text35"/></p>

              <h3 id={bindSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle16"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text36"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text37"/></p>

              <h3 id={statusSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle17"/></h3>
              <p className={css.customMarginBottom}><FormattedMessage id="TravelerHelpPage.text38"/></p>
              <ul className={classNames(css.listBullets, css.customMarginTop)}>
                <li><FormattedMessage id="TravelerHelpPage.listItem43"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem44"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem45"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem46"/></li>
              </ul>
              <p><FormattedMessage id="TravelerHelpPage.text39"/></p>

              <h3 id={changeSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle18"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text40"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text41"/></p>

              <h3 id={requirementsSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle19"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text42"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text43"/></p>

              <h3 id={qualificationsSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle20"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text44"/></p>

              <h3 id={skillsSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle21"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text45"/></p>

              <h3 id={ageSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle22"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text46"/></p>

              <h3 id={childrenSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle23"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text47"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text48"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text49"/></p>

              <h3 id={guestsSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle24"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text50"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text51"/></p>

              <h3 id={someoneSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle25"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text52"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text53"/></p>

              <h3 id={othersSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle26"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text54"/></p>

              <h3 id={dietarySlug}><FormattedMessage id="TravelerHelpPage.subSubTitle27"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text55"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text56"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text57"/></p>

              <TopbarImgWrapper
                img={img3}
                className={css.imgBlock}
              />

              <h2 id={paymentsSlug}><FormattedMessage id="TravelerHelpPage.subtitle3"/></h2>
              <h3 id={payments_optionsSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle28"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text58"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text59"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text60"/></p>

              <h3 id={payments_timingSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle29"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text61"/></p>

              <h3 id={currencySlug}><FormattedMessage id="TravelerHelpPage.subSubTitle30"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text62"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text63"/></p>

              <h3 id={securitySlug}><FormattedMessage id="TravelerHelpPage.subSubTitle31"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text64"/></p>

              <h3 id={invoicesSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle32"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text65"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text66"/></p>

              <h3 id={questionsSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle33"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text67"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text68"/></p>

              <TopbarImgWrapper
                img={img4}
                className={css.imgBlock}
              />

              <h2 id={travelingSlug}><FormattedMessage id="TravelerHelpPage.subtitle4"/></h2>
              <h3 id={preparationSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle34"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text69"/></p>
              <p className={css.customMarginBottom}><FormattedMessage id="TravelerHelpPage.text70"/></p>
              <ul className={classNames(css.listBullets, css.customMarginTop)}>
                <li><FormattedMessage id="TravelerHelpPage.listItem47"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem48"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem49"/></li>
              </ul>

              <h3 id={levelsSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle35"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text71"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text72"/></p>

              <h3 id={meetingSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle36"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text73"/></p>

              <h3 id={problems_meetingSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle37"/></h3>
              <p className={css.customMarginBottom}><FormattedMessage id="TravelerHelpPage.text74"/></p>
              <ul className={classNames(css.listBullets, css.customMarginTop)}>
                <li><FormattedMessage id="TravelerHelpPage.listItem50"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem51"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem52"/></li>
              </ul>

              <h2 id={communicationsSlug}><FormattedMessage id="TravelerHelpPage.subtitle5"/></h2>
              <h3 id={messagingSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle38"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text75"/></p>

              <h3 id={reading_messagesSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle39"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text76"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text77"/></p>

              <h3 id={messaging_hostSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle40"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text78"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text79"/></p>

              <h3 id={message_safetySlug}><FormattedMessage id="TravelerHelpPage.subSubTitle41"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text80"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text81"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text82"/></p>

              <h3 id={contactSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle42"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text83"/></p>

              <h3 id={contact_activebreakSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle43"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text84"/></p>

              <h2 id={reviewsSlug}><FormattedMessage id="TravelerHelpPage.subtitle6"/></h2>
              <p><FormattedMessage id="TravelerHelpPage.text85"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text86"/></p>
              <p className={css.customMarginBottom}><FormattedMessage id="TravelerHelpPage.text87"/></p>
              <ul className={classNames(css.listBullets, css.customMarginTop)}>
                <li><FormattedMessage id="TravelerHelpPage.listItem53"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem54"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem55"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem56"/></li>
              </ul>

              <h3 id={reviewersSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle44"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text88"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text89"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text90"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text91"/></p>

              <h3 id={how_to_reviewSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle45"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text92"/></p>

              <h3 id={modifying_reviewsSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle46"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text93"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text94"/></p>

              <h3 id={missing_reviewsSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle47"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text95"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text96"/></p>

              <TopbarImgWrapper
                img={img5}
                className={css.imgBlock}
              />

              <h2 id={cancellationsSlug}><FormattedMessage id="TravelerHelpPage.subtitle7"/></h2>
              <h3 id={cancellation_policiesSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle48"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text97"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text98"/></p>

              <h3 id={host_cancelsSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle49"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text99"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text100"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text101"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text102"/></p>

              <h3 id={refundsSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle50"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text103"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text104"/></p>

              <h2 id={accountSlug}><FormattedMessage id="TravelerHelpPage.subtitle8"/></h2>
              <h3 id={resettingSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle51"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text105"/></p>
              <p><FormattedMessage id="TravelerHelpPage.text106"/></p>

              <h3 id={deleting_accountSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle52"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text107"/></p>

              <h3 id={misuseSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle53"/></h3>
              <p className={css.customMarginBottom}><FormattedMessage id="TravelerHelpPage.text108"/></p>
              <ul className={classNames(css.listBullets, css.customMarginTop)}>
                <li><FormattedMessage id="TravelerHelpPage.listItem57"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem58"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem59"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem60"/></li>
              </ul>
              <p className={css.customMarginBottom}><FormattedMessage id="TravelerHelpPage.text109"/></p>
              <ul className={classNames(css.listBullets, css.customMarginTop)}>
                <li><FormattedMessage id="TravelerHelpPage.listItem61"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem62"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem63"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem64"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem65"/></li>
                <li><FormattedMessage id="TravelerHelpPage.listItem66"/></li>
              </ul>
              <p><FormattedMessage id="TravelerHelpPage.text110"/></p>

              <h3 id={notificationSlug}><FormattedMessage id="TravelerHelpPage.subSubTitle54"/></h3>
              <p><FormattedMessage id="TravelerHelpPage.text111"/></p>

            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer currentPage="TravelerHelpPage" location={location}/>
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default injectIntl(TravelerHelpPage);
