import React from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import classNames from 'classnames';

import css from './TravelerHelpPage.module.css';

const SideBarNav = (props) => {
  const { location, intl } = props;

  const navItem1 = intl.formatMessage({ id: 'TravelerHelpPage.navItem1' });
  const navItem2 = intl.formatMessage({ id: 'TravelerHelpPage.navItem2' });
  const navItem3 = intl.formatMessage({ id: 'TravelerHelpPage.navItem3' });
  const navItem4 = intl.formatMessage({ id: 'TravelerHelpPage.navItem4' });
  const navItem5 = intl.formatMessage({ id: 'TravelerHelpPage.navItem5' });
  const navItem6 = intl.formatMessage({ id: 'TravelerHelpPage.navItem6' });
  const navItem7 = intl.formatMessage({ id: 'TravelerHelpPage.navItem7' });
  const navItem8 = intl.formatMessage({ id: 'TravelerHelpPage.navItem8' });
  const navItem9 = intl.formatMessage({ id: 'TravelerHelpPage.navItem9' });
  const navItem10 = intl.formatMessage({ id: 'TravelerHelpPage.navItem10' });
  const navItem11 = intl.formatMessage({ id: 'TravelerHelpPage.navItem11' });
  const navItem12 = intl.formatMessage({ id: 'TravelerHelpPage.navItem12' });
  const navItem13 = intl.formatMessage({ id: 'TravelerHelpPage.navItem13' });
  const navItem14 = intl.formatMessage({ id: 'TravelerHelpPage.navItem14' });
  const navItem15 = intl.formatMessage({ id: 'TravelerHelpPage.navItem15' });
  const navItem16 = intl.formatMessage({ id: 'TravelerHelpPage.navItem16' });
  const navItem17 = intl.formatMessage({ id: 'TravelerHelpPage.navItem17' });
  const navItem18 = intl.formatMessage({ id: 'TravelerHelpPage.navItem18' });
  const navItem19 = intl.formatMessage({ id: 'TravelerHelpPage.navItem19' });
  const navItem20 = intl.formatMessage({ id: 'TravelerHelpPage.navItem20' });
  const navItem21 = intl.formatMessage({ id: 'TravelerHelpPage.navItem21' });
  const navItem22 = intl.formatMessage({ id: 'TravelerHelpPage.navItem22' });
  const navItem23 = intl.formatMessage({ id: 'TravelerHelpPage.navItem23' });
  const navItem24 = intl.formatMessage({ id: 'TravelerHelpPage.navItem24' });
  const navItem25 = intl.formatMessage({ id: 'TravelerHelpPage.navItem25' });
  const navItem26 = intl.formatMessage({ id: 'TravelerHelpPage.navItem26' });
  const navItem27 = intl.formatMessage({ id: 'TravelerHelpPage.navItem27' });
  const navItem28 = intl.formatMessage({ id: 'TravelerHelpPage.navItem28' });
  const navItem29 = intl.formatMessage({ id: 'TravelerHelpPage.navItem29' });
  const navItem30 = intl.formatMessage({ id: 'TravelerHelpPage.navItem30' });
  const navItem31 = intl.formatMessage({ id: 'TravelerHelpPage.navItem31' });
  const navItem32 = intl.formatMessage({ id: 'TravelerHelpPage.navItem32' });
  const navItem33 = intl.formatMessage({ id: 'TravelerHelpPage.navItem33' });
  const navItem34 = intl.formatMessage({ id: 'TravelerHelpPage.navItem34' });
  const navItem35 = intl.formatMessage({ id: 'TravelerHelpPage.navItem35' });
  const navItem36 = intl.formatMessage({ id: 'TravelerHelpPage.navItem36' });
  const navItem37 = intl.formatMessage({ id: 'TravelerHelpPage.navItem37' });
  const navItem38 = intl.formatMessage({ id: 'TravelerHelpPage.navItem38' });
  const navItem39 = intl.formatMessage({ id: 'TravelerHelpPage.navItem39' });
  const navItem40 = intl.formatMessage({ id: 'TravelerHelpPage.navItem40' });
  const navItem41 = intl.formatMessage({ id: 'TravelerHelpPage.navItem41' });
  const navItem42 = intl.formatMessage({ id: 'TravelerHelpPage.navItem42' });
  const navItem43 = intl.formatMessage({ id: 'TravelerHelpPage.navItem43' });
  const navItem44 = intl.formatMessage({ id: 'TravelerHelpPage.navItem44' });
  const navItem45 = intl.formatMessage({ id: 'TravelerHelpPage.navItem45' });
  const navItem46 = intl.formatMessage({ id: 'TravelerHelpPage.navItem46' });
  const navItem47 = intl.formatMessage({ id: 'TravelerHelpPage.navItem47' });
  const navItem48 = intl.formatMessage({ id: 'TravelerHelpPage.navItem48' });
  const navItem49 = intl.formatMessage({ id: 'TravelerHelpPage.navItem49' });
  const navItem50 = intl.formatMessage({ id: 'TravelerHelpPage.navItem50' });
  const navItem51 = intl.formatMessage({ id: 'TravelerHelpPage.navItem51' });
  const navItem52 = intl.formatMessage({ id: 'TravelerHelpPage.navItem52' });
  const navItem53 = intl.formatMessage({ id: 'TravelerHelpPage.navItem53' });
  const navItem54 = intl.formatMessage({ id: 'TravelerHelpPage.navItem54' });
  const navItem55 = intl.formatMessage({ id: 'TravelerHelpPage.navItem55' });
  const navItem56 = intl.formatMessage({ id: 'TravelerHelpPage.navItem56' });
  const navItem57 = intl.formatMessage({ id: 'TravelerHelpPage.navItem57' });
  const navItem58 = intl.formatMessage({ id: 'TravelerHelpPage.navItem58' });
  const navItem59 = intl.formatMessage({ id: 'TravelerHelpPage.navItem59' });
  const navItem60 = intl.formatMessage({ id: 'TravelerHelpPage.navItem60' });
  const navItem61 = intl.formatMessage({ id: 'TravelerHelpPage.navItem61' });
  const navItem62 = intl.formatMessage({ id: 'TravelerHelpPage.navItem62' });
  const navItem63 = intl.formatMessage({ id: 'TravelerHelpPage.navItem63' });

  const aboutSlug = intl.formatMessage({ id: 'TravelerHelpPage.aboutSlug' });
  const whySlug = intl.formatMessage({ id: 'TravelerHelpPage.whySlug' });
  const signSlug = intl.formatMessage({ id: 'TravelerHelpPage.signSlug' });
  const adventuresSlug = intl.formatMessage({ id: 'TravelerHelpPage.adventuresSlug' });
  const locationsSlug = intl.formatMessage({ id: 'TravelerHelpPage.locationsSlug' });
  const languagesSlug = intl.formatMessage({ id: 'TravelerHelpPage.languagesSlug' });
  const experienceSlug = intl.formatMessage({ id: 'TravelerHelpPage.experienceSlug' });
  const searchingSlug = intl.formatMessage({ id: 'TravelerHelpPage.searchingSlug' });
  const filteringSlug = intl.formatMessage({ id: 'TravelerHelpPage.filteringSlug' });
  const bookingSlug = intl.formatMessage({ id: 'TravelerHelpPage.bookingSlug' });
  const pricingSlug = intl.formatMessage({ id: 'TravelerHelpPage.pricingSlug' });
  const durationSlug = intl.formatMessage({ id: 'TravelerHelpPage.durationSlug' });
  const feesSlug = intl.formatMessage({ id: 'TravelerHelpPage.feesSlug' });
  const includedSlug = intl.formatMessage({ id: 'TravelerHelpPage.includedSlug' });
  const requestSlug = intl.formatMessage({ id: 'TravelerHelpPage.requestSlug' });
  const responseSlug = intl.formatMessage({ id: 'TravelerHelpPage.responseSlug' });
  const acceptSlug = intl.formatMessage({ id: 'TravelerHelpPage.acceptSlug' });
  const declineSlug = intl.formatMessage({ id: 'TravelerHelpPage.declineSlug' });
  const bindSlug = intl.formatMessage({ id: 'TravelerHelpPage.bindSlug' });
  const statusSlug = intl.formatMessage({ id: 'TravelerHelpPage.statusSlug' });
  const changeSlug = intl.formatMessage({ id: 'TravelerHelpPage.changeSlug' });
  const requirementsSlug = intl.formatMessage({ id: 'TravelerHelpPage.requirementsSlug' });
  const qualificationsSlug = intl.formatMessage({ id: 'TravelerHelpPage.qualificationsSlug' });
  const skillsSlug = intl.formatMessage({ id: 'TravelerHelpPage.skillsSlug' });
  const ageSlug = intl.formatMessage({ id: 'TravelerHelpPage.ageSlug' });
  const childrenSlug = intl.formatMessage({ id: 'TravelerHelpPage.childrenSlug' });
  const guestsSlug = intl.formatMessage({ id: 'TravelerHelpPage.guestsSlug' });
  const someoneSlug = intl.formatMessage({ id: 'TravelerHelpPage.someoneSlug' });
  const othersSlug = intl.formatMessage({ id: 'TravelerHelpPage.othersSlug' });
  const dietarySlug = intl.formatMessage({ id: 'TravelerHelpPage.dietarySlug' });
  const paymentsSlug = intl.formatMessage({ id: 'TravelerHelpPage.paymentsSlug' });
  const payments_optionsSlug = intl.formatMessage({ id: 'TravelerHelpPage.payments_optionsSlug' });
  const payments_timingSlug = intl.formatMessage({ id: 'TravelerHelpPage.payments_timingSlug' });
  const currencySlug = intl.formatMessage({ id: 'TravelerHelpPage.currencySlug' });
  const securitySlug = intl.formatMessage({ id: 'TravelerHelpPage.securitySlug' });
  const invoicesSlug = intl.formatMessage({ id: 'TravelerHelpPage.invoicesSlug' });
  const questionsSlug = intl.formatMessage({ id: 'TravelerHelpPage.questionsSlug' });
  const travelingSlug = intl.formatMessage({ id: 'TravelerHelpPage.travelingSlug' });
  const preparationSlug = intl.formatMessage({ id: 'TravelerHelpPage.preparationSlug' });
  const levelsSlug = intl.formatMessage({ id: 'TravelerHelpPage.levelsSlug' });
  const meetingSlug = intl.formatMessage({ id: 'TravelerHelpPage.meetingSlug' });
  const problems_meetingSlug = intl.formatMessage({ id: 'TravelerHelpPage.problems_meetingSlug' });
  const communicationsSlug = intl.formatMessage({ id: 'TravelerHelpPage.communicationsSlug' });
  const messagingSlug = intl.formatMessage({ id: 'TravelerHelpPage.messagingSlug' });
  const reading_messagesSlug = intl.formatMessage({ id: 'TravelerHelpPage.reading_messagesSlug' });
  const messaging_hostSlug = intl.formatMessage({ id: 'TravelerHelpPage.messaging_hostSlug' });
  const message_safetySlug = intl.formatMessage({ id: 'TravelerHelpPage.message_safetySlug' });
  const contactSlug = intl.formatMessage({ id: 'TravelerHelpPage.contactSlug' });
  const contact_activebreakSlug = intl.formatMessage({ id: 'TravelerHelpPage.contact_activebreakSlug' });
  const reviewsSlug = intl.formatMessage({ id: 'TravelerHelpPage.reviewsSlug' });
  const reviewersSlug = intl.formatMessage({ id: 'TravelerHelpPage.reviewersSlug' });
  const how_to_reviewSlug = intl.formatMessage({ id: 'TravelerHelpPage.how_to_reviewSlug' });
  const modifying_reviewsSlug = intl.formatMessage({ id: 'TravelerHelpPage.modifying_reviewsSlug' });
  const missing_reviewsSlug = intl.formatMessage({ id: 'TravelerHelpPage.missing_reviewsSlug' });
  const cancellationsSlug = intl.formatMessage({ id: 'TravelerHelpPage.cancellationsSlug' });
  const cancellation_policiesSlug = intl.formatMessage({ id: 'TravelerHelpPage.cancellation_policiesSlug' });
  const host_cancelsSlug = intl.formatMessage({ id: 'TravelerHelpPage.host_cancelsSlug' });
  const refundsSlug = intl.formatMessage({ id: 'TravelerHelpPage.refundsSlug' });
  const accountSlug = intl.formatMessage({ id: 'TravelerHelpPage.accountSlug' });
  const resettingSlug = intl.formatMessage({ id: 'TravelerHelpPage.resettingSlug' });
  const deleting_accountSlug = intl.formatMessage({ id: 'TravelerHelpPage.deleting_accountSlug' });
  const notificationSlug = intl.formatMessage({ id: 'TravelerHelpPage.notificationSlug' });
  const misuseSlug = intl.formatMessage({ id: 'TravelerHelpPage.misuseSlug' });

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -110;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const navItems = [
    { link: `#${aboutSlug}`, text: navItem1, type: 'title', className: css.title },
    { link: `#${whySlug}`, text: navItem2, type: 'subtitle', className: css.subtitle },
    { link: `#${signSlug}`, text: navItem3, type: 'subtitle', className: css.subtitle },
    { link: `#${adventuresSlug}`, text: navItem4, type: 'subtitle', className: css.subtitle },
    { link: `#${locationsSlug}`, text: navItem5, type: 'subtitle', className: css.subtitle },
    { link: `#${languagesSlug}`, text: navItem6, type: 'subtitle', className: css.subtitle },
    { link: `#${experienceSlug}`, text: navItem7, type: 'title', className: css.title },
    { link: `#${searchingSlug}`, text: navItem8, type: 'subtitle', className: css.subtitle },
    { link: `#${filteringSlug}`, text: navItem9, type: 'subtitle', className: css.subtitle },
    { link: `#${bookingSlug}`, text: navItem10, type: 'title', className: css.title },
    { link: `#${pricingSlug}`, text: navItem11, type: 'subtitle', className: css.subtitle },
    { link: `#${durationSlug}`, text: navItem12, type: 'subtitle', className: css.subtitle },
    { link: `#${feesSlug}`, text: navItem13, type: 'subtitle', className: css.subtitle },
    { link: `#${includedSlug}`, text: navItem14, type: 'subtitle', className: css.subtitle },
    { link: `#${requestSlug}`, text: navItem15, type: 'subtitle', className: css.subtitle },
    { link: `#${responseSlug}`, text: navItem16, type: 'subtitle', className: css.subtitle },
    { link: `#${acceptSlug}`, text: navItem17, type: 'subtitle', className: css.subtitle },
    { link: `#${declineSlug}`, text: navItem18, type: 'subtitle', className: css.subtitle },
    { link: `#${bindSlug}`, text: navItem19, type: 'subtitle', className: css.subtitle },
    { link: `#${statusSlug}`, text: navItem20, type: 'subtitle', className: css.subtitle },
    { link: `#${changeSlug}`, text: navItem21, type: 'subtitle', className: css.subtitle },
    { link: `#${requirementsSlug}`, text: navItem22, type: 'subtitle', className: css.subtitle },
    { link: `#${qualificationsSlug}`, text: navItem23, type: 'subtitle', className: css.subtitle },
    { link: `#${skillsSlug}`, text: navItem24, type: 'subtitle', className: css.subtitle },
    { link: `#${ageSlug}`, text: navItem25, type: 'subtitle', className: css.subtitle },
    { link: `#${childrenSlug}`, text: navItem26, type: 'subtitle', className: css.subtitle },
    { link: `#${guestsSlug}`, text: navItem27, type: 'subtitle', className: css.subtitle },
    { link: `#${someoneSlug}`, text: navItem28, type: 'subtitle', className: css.subtitle },
    { link: `#${othersSlug}`, text: navItem29, type: 'subtitle', className: css.subtitle },
    { link: `#${dietarySlug}`, text: navItem30, type: 'subtitle', className: css.subtitle },
    { link: `#${paymentsSlug}`, text: navItem31, type: 'title', className: css.title },
    { link: `#${payments_optionsSlug}`, text: navItem32, type: 'subtitle', className: css.subtitle },
    { link: `#${payments_timingSlug}`, text: navItem33, type: 'subtitle', className: css.subtitle },
    { link: `#${currencySlug}`, text: navItem34, type: 'subtitle', className: css.subtitle },
    { link: `#${securitySlug}`, text: navItem35, type: 'subtitle', className: css.subtitle },
    { link: `#${invoicesSlug}`, text: navItem36, type: 'subtitle', className: css.subtitle },
    { link: `#${questionsSlug}`, text: navItem37, type: 'subtitle', className: css.subtitle },
    { link: `#${travelingSlug}`, text: navItem38, type: 'title', className: css.title },
    { link: `#${preparationSlug}`, text: navItem39, type: 'subtitle', className: css.subtitle },
    { link: `#${levelsSlug}`, text: navItem40, type: 'subtitle', className: css.subtitle },
    { link: `#${meetingSlug}`, text: navItem41, type: 'subtitle', className: css.subtitle },
    { link: `#${problems_meetingSlug}`, text: navItem42, type: 'subtitle', className: css.subtitle },
    { link: `#${communicationsSlug}`, text: navItem43, type: 'title', className: css.title },
    { link: `#${messagingSlug}`, text: navItem44, type: 'subtitle', className: css.subtitle },
    { link: `#${reading_messagesSlug}`, text: navItem45, type: 'subtitle', className: css.subtitle },
    { link: `#${messaging_hostSlug}`, text: navItem46, type: 'subtitle', className: css.subtitle },
    { link: `#${message_safetySlug}`, text: navItem47, type: 'subtitle', className: css.subtitle },
    { link: `#${contactSlug}`, text: navItem48, type: 'subtitle', className: css.subtitle },
    { link: `#${contact_activebreakSlug}`, text: navItem49, type: 'subtitle', className: css.subtitle },
    { link: `#${reviewsSlug}`, text: navItem51, type: 'title', className: css.title },
    { link: `#${reviewersSlug}`, text: navItem50, type: 'subtitle', className: css.subtitle },
    { link: `#${how_to_reviewSlug}`, text: navItem52, type: 'subtitle', className: css.subtitle },
    { link: `#${modifying_reviewsSlug}`, text: navItem53, type: 'subtitle', className: css.subtitle },
    { link: `#${missing_reviewsSlug}`, text: navItem54, type: 'subtitle', className: css.subtitle },
    { link: `#${cancellationsSlug}`, text: navItem56, type: 'title', className: css.title },
    { link: `#${cancellation_policiesSlug}`, text: navItem55, type: 'subtitle', className: css.subtitle },
    { link: `#${host_cancelsSlug}`, text: navItem57, type: 'subtitle', className: css.subtitle },
    { link: `#${refundsSlug}`, text: navItem58, type: 'subtitle', className: css.subtitle },
    { link: `#${accountSlug}`, text: navItem60, type: 'title', className: css.title },
    { link: `#${resettingSlug}`, text: navItem59, type: 'subtitle', className: css.subtitle },
    { link: `#${deleting_accountSlug}`, text: navItem61, type: 'subtitle', className: css.subtitle },
    { link: `#${notificationSlug}`, text: navItem62, type: 'subtitle', className: css.subtitle },
    { link: `#${misuseSlug}`, text: navItem63, type: 'subtitle', className: css.subtitle },
  ];

  return navItems.map(item => {
    return (
      <li className={css.navItem} key={item.link}>
      {<NavLink
        className={
          location.hash === item.link && item.type === 'title' ? classNames(css.selected, css.title)
          : location.hash === item.link && item.type === 'subtitle' ? classNames(css.selected, css.subtitle)
          : item.type === 'title' ? css.title
          : item.type === 'subtitle' ? css.subtitle
          : css.subtitle
        }
        to={item.link}
        smooth
        scroll={el => scrollWithOffset(el)} activeClassName={css.selected}>
        {item.text}
      </NavLink>}
      </li>
    )
  })
};

export default SideBarNav;
