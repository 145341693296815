import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import { Form, RangeSlider } from '../../components';
import css from './DurationFilterForm.module.css';
import { formatCurrencyMajorUnit } from '../../util/currency';

const DEBOUNCE_WAIT_TIME = 400;

// Helper function to parse value for min handle
// Value needs to be between slider's minimum value and current maximum value
const parseMin = (min, currentMax) => value => {
  const parsedValue = Number.parseInt(value, 10);
  if (isNaN(parsedValue)) {
    return '';
  }
  return parsedValue < min ? min : parsedValue > currentMax ? currentMax : parsedValue;
};

// Helper function to parse value for max handle
// Value needs to be between slider's max value and current minimum value
const parseMax = (max, currentMin) => value => {
  const parsedValue = Number.parseInt(value, 10);
  if (isNaN(parsedValue)) {
    return '';
  }
  return parsedValue > max ? max : parsedValue;
  // return parsedValue < currentMin ? currentMin : parsedValue > max ? max : parsedValue;
};

// DurationFilterForm component
const DurationFilterFormComponent = props => {
  const { liveEdit, onChange, onSubmit, onCancel, onClear, plain, custom, ...rest } = props;

  if (liveEdit && !onChange) {
    throw new Error('DurationFilterForm: if liveEdit is true you need to provide onChange function');
  }

  if (!liveEdit && !(onCancel && onClear && onSubmit)) {
    throw new Error(
      'DurationFilterForm: if liveEdit is false you need to provide onCancel, onClear, and onSubmit functions'
    );
  }

  const handleChange = debounce(
    formState => {
      if (formState.dirty) {
        const { minDuration, maxDuration, ...restValues } = formState.values;
        onChange({
          minDuration: minDuration === '' ? rest.min : minDuration,
          maxDuration: maxDuration === '' ? rest.max : maxDuration,
          ...restValues,
        });
      }
    },
    DEBOUNCE_WAIT_TIME,
    { leading: false, trailing: true }
  );

  const handleSubmit = values => {
    const { minDuration, maxDuration, ...restValues } = values;
    return onSubmit({
      minDuration: minDuration === '' ? rest.min : minDuration,
      maxDuration: maxDuration === '' ? rest.max : maxDuration,
      ...restValues,
    });
  };

  const formCallbacks = liveEdit
    ? { onSubmit: () => null }
    : { onSubmit: handleSubmit, onCancel, onClear };

  return (
    <FinalForm
      {...rest}
      {...formCallbacks}
      render={formRenderProps => {
        const {
          form,
          handleSubmit,
          id,
          showAsPopup,
          onClear,
          onCancel,
          isOpen,
          contentRef,
          style,
          intl,
          values,
          min,
          max,
          step,
        } = formRenderProps;
        const { minDuration: minDurationRaw, maxDuration: maxDurationRaw } = values;
        const minDuration = typeof minDurationRaw !== 'string' ? minDurationRaw : min;
        const maxDuration = typeof maxDurationRaw !== 'string' ? maxDurationRaw : max;

        const handleCancel = () => {
          // reset the final form to initialValues
          form.reset();
          onCancel();
        };

        const clear = intl.formatMessage({ id: 'DurationFilterForm.clear' });
        const cancel = intl.formatMessage({ id: 'DurationFilterForm.cancel' });
        const submit = intl.formatMessage({ id: 'DurationFilterForm.submit' });

        const classes = classNames(css.root, {
          [css.popup]: showAsPopup,
          [css.isOpenAsPopup]: showAsPopup && isOpen,
          [css.plain]: !showAsPopup,
          [css.isOpen]: !showAsPopup && isOpen,
        });

        const handleMaxPrice = e => {
          if(values.maxDuration < values.minDuration) {
            form.change("maxDuration", values.minDuration)
          }
        }

        return (
          <Form
            className={classes}
            onSubmit={handleSubmit}
            tabIndex="0"
            contentRef={contentRef}
            style={{ ...style }}
          >
            <div className={custom ? css.contentWrapperH : css.contentWrapper}>
              {/*<span className={css.label}>*/}
              {/*  <FormattedMessage id="DurationFilterForm.label" />*/}
              {/*</span>*/}
              <div className={css.inputsWrapper}>
                <Field
                  className={css.minPrice}
                  id={`${id}.minDuration`}
                  name="minDuration"
                  component="input"
                  type="number"
                  placeholder={min}
                  min={min}
                  max={max}
                  step={step}
                  parse={parseMin(min, maxDuration)}
                />
                <span className={css.priceSeparator}>-</span>
                <Field
                  onBlur={handleMaxPrice}
                  className={css.maxPrice}
                  id={`${id}.maxDuration`}
                  name="maxDuration"
                  component="input"
                  type="number"
                  placeholder={max}
                  min={min}
                  max={max}
                  step={step}
                  parse={parseMax(max, minDuration)}
                />
              </div>
            </div>

            <div className={css.sliderWrapper}>
              {/*{plain && <><span className={css.priceSliderSpanL}>{minDuration}</span><span className={css.priceSliderSpanR}>{maxDuration}</span></>}*/}
              <RangeSlider
                maxV={minDuration}
                minV={maxDuration}
                custom={custom}
                min={min}
                max={max}
                step={step}
                handles={[minDuration, maxDuration]}
                intl={intl}
                duration
                onChange={handles => {
                  form.change('minDuration', handles[0]);
                  form.change('maxDuration', handles[1]);
                }}
              />
            </div>

            {liveEdit ? (
              <FormSpy onChange={handleChange} subscription={{ values: true, dirty: true }} />
            ) : (
              <div className={css.buttonsWrapper}>
                <button className={css.clearButton} type="button" onClick={onClear}>
                  {clear}
                </button>
                <button className={css.cancelButton} type="button" onClick={handleCancel}>
                  {cancel}
                </button>
                <button className={css.submitButton} type="submit">
                  {submit}
                </button>
              </div>
            )}
          </Form>
        );
      }}
    />
  );
};

DurationFilterFormComponent.defaultProps = {
  liveEdit: false,
  showAsPopup: false,
  isOpen: false,
  contentRef: null,
  style: null,
  min: 0,
  step: 1,
  onCancel: null,
  onChange: null,
  onClear: null,
  onSubmit: null,
  plain: false,
  custom: false,
};

DurationFilterFormComponent.propTypes = {
  id: string.isRequired,
  liveEdit: bool,
  showAsPopup: bool,
  onCancel: func,
  onChange: func,
  onClear: func,
  onSubmit: func,
  isOpen: bool,
  contentRef: func,
  style: object,
  min: number.isRequired,
  max: number.isRequired,
  step: number,
  plain: bool,
  custom: bool,

  // form injectIntl
  intl: intlShape.isRequired,
};

const DurationFilterForm = injectIntl(DurationFilterFormComponent);

export default DurationFilterForm;
