import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaInstagram.module.css';

const IconSocialMediaInstagram = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.6 1H23.4C26.5 1 29 3.5 29 6.6V23.4C29 26.5 26.5 29 23.4 29H6.6C3.5 29 1 26.5 1 23.4V6.6C1 3.5 3.5 1 6.6 1Z" stroke="black" strokeMiterlimit="10"/>
      <path d="M14.8 21.7C18.3899 21.7 21.3 18.7899 21.3 15.2C21.3 11.6102 18.3899 8.70001 14.8 8.70001C11.2102 8.70001 8.30005 11.6102 8.30005 15.2C8.30005 18.7899 11.2102 21.7 14.8 21.7Z" stroke="black" strokeMiterlimit="10"/>
      <path d="M22.8 5.90002C22.2 5.90002 21.8 6.30002 21.8 6.90002C21.8 7.50002 22.2 7.90002 22.8 7.90002C23.4 7.90002 23.8 7.50002 23.8 6.90002C23.8 6.30002 23.4 5.90002 22.8 5.90002Z" fill="black"/>
    </svg>
  );
};

IconSocialMediaInstagram.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaInstagram.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaInstagram;
