import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldCheckboxLikeButton.module.css';

export const IconCheckbox = props => {
  const { className, checkedClassName, onCheckboxClick, checkedClassNameBorder } = props;
  return (
    <svg
      onClick={onCheckboxClick}
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      // width='48' height='48' viewBox='0 0 48 48'
      width="30" height="26" viewBox="0 0 30 26"
    >
        <path className={classNames(checkedClassName, css.checked)} d="M29 8.35409C29 4.25681 25.6189 1 21.4981 1C19.0679 1 16.8491 2.2607 15.4755 4.04669C15.2642 4.36187 14.7358 4.36187 14.4189 4.04669C13.1509 2.2607 10.9321 1 8.50189 1C4.38113 1 1 4.25681 1 8.35409C1 16.1284 13.3623 23.6926 14.8415 24.9533C14.9472 25.0584 14.9472 24.9533 14.9472 24.9533C16.5321 23.7977 28.8943 16.1284 29 8.35409Z" stroke="#101010" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>

      {/*<path className={classNames(checkedClassName, css.checked)}*/}
      {/*      d='M38 19.5C38 15.157 33.7823 11.9927 29.3672 11.9927C26.7634 11.9927 25.3766 13.329 23.9049 15.2221C23.4049 15.2221 23.5 15.2221 23.434 15.2221C22.0755 13.329 19.0087 11.4839 16.4049 11.4839C11.9898 11.4839 7.40491 15.4448 7.40491 19.7878C7.40491 28.0283 20.6502 36.0461 22.2351 37.3824C22.5059 37.6107 22.8112 37.3824 22.8112 37.3824C24.5093 36.1575 37.8868 27.7405 38 19.5Z'*/}
      {/*      fill='#EC5E69' />*/}
      {/*<path className={classNames(checkedClassNameBorder, css.checkedBorder)}*/}
      {/*      d='M38 19.3541C38 15.2568 34.6189 12 30.4981 12C28.0679 12 25.8491 13.2607 24.4755 15.0467C24.2642 15.3619 23.7358 15.3619 23.4189 15.0467C22.1509 13.2607 19.9321 12 17.5019 12C13.3811 12 10 15.2568 10 19.3541C10 27.1284 22.3623 34.6926 23.8415 35.9533C23.9262 36.0255 24 35.9533 24 35.9533C25.5849 34.7977 37.8943 27.1284 38 19.3541Z'*/}
      {/*      stroke='black' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />*/}
    </svg>
  );
};

IconCheckbox.defaultProps = {
  className: null,
  checkedClassName: null,
  boxClassName: null
};

IconCheckbox.propTypes = {
  className: string,
  checkedClassName: string,
  boxClassName: string
};

const FieldCheckboxLikeButton = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    checkedClassName,
    checkedClassNameBorder,
    textClassName,
    id,
    label,
    currentUser,
    useSuccessColor,
    ...rest
  } = props;

  const classes = classNames(currentUser !== null ? css.logged : css.unLogged, className);
  const checkboxProps = {
    id,
    className: css.input,
    component: 'input',
    type: 'checkbox',
    ...rest,
  };

  const successColorVariantMaybe = useSuccessColor
    ? {
      checkedClassName: css.checkedSuccess,
      boxClassName: css.boxSuccess,
    }
    : {};

  return (
    <span className={classes}>
      <Field {...checkboxProps} />
      <label htmlFor={id} className={css.label}>
        <span className={css.checkboxWrapper}>
          <IconCheckbox
            className={svgClassName}
            checkedClassName={checkedClassName}
            checkedClassNameBorder={checkedClassNameBorder}
            {...successColorVariantMaybe}
          />
        </span>
        <span className={classNames(css.text, textClassName || css.textRoot)}>{label}</span>
      </label>
    </span>
  );
};

FieldCheckboxLikeButton.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  label: null,
};

FieldCheckboxLikeButton.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string,
};

export default FieldCheckboxLikeButton;
