import React, { Component, useState } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import {formatMoney, minConvertPrice} from '../../util/currency';
import { ensureListing, ensureUser, ensureCurrentUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { ListingFavouritesForm } from '../../forms';

import {
  IconCategory,
  NamedLink,
  ResponsiveImage
} from '..';
import css from './ListingCard.module.css';

import { IconCheckbox } from '../FieldCheckboxLikeButton/FieldCheckboxLikeButton';
import { types as sdkTypes } from "../../util/sdkLoader";
import {LANGUAGE_KEY} from "../../util/languages";
import TagManager from "react-gtm-module";
import moment from "moment/moment";
const MIN_LENGTH_FOR_LONG_WORDS = 10;
const { Money } = sdkTypes;

const priceData = (price, intl) => {
  if (!price) return {};

  price.amount *= 100;

  const formattedPrice = formatMoney(intl, price);
  return { formattedPrice, priceTitle: formattedPrice }
}

const isActualDay = currentDay => moment(new Date()).format('YYYY-MM-DD') <= currentDay;

const getMinPrice = (packages) => {
  let minPrice;

  packages.forEach(({ rooms }) => {
    rooms.forEach(({ price, available }) => {
      if ((!minPrice && available) || (available && price.amount < minPrice.amount)) {
        minPrice = price;
      }
    });
  });

  return minPrice;
}

const getDepartureDate = (packages) => {
  let minDate;

  packages.forEach(({rooms, departure_date}) => {
    const isAvailable = rooms.find(({available}) => available);

    const timeInMls = new Date(departure_date).getTime();

    if (
      (!minDate && isAvailable) ||
      (isAvailable && timeInMls < new Date(minDate).getTime())
    ) {
      minDate = departure_date;
    }
  });

  return minDate;
}

const ratingBuilder = (rate) => {
  let rating = [];
  for (let i = 1; i < 6; i++) {
    if (rate >= i) rating.push('full');
    else rating.push('empty');
  }
  return rating;
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}

const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = ({
  className,
  rootClassName,
  isAuthenticated,
  intl,
  listing,
  renderSizes,
  setActiveListing,
  currentUser,
  onUpdateProfile,
  images = [],
}) => {
  const [ index, setIndex ] = useState(0);
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;

  const {
    title = '',
    publicData: {
      duration_days: durationDays,
      packages = [],
      themes = [],
      description_long = "",
      skill_levels = [],
      fitness_levels = [],
      reviews = [],
      badges = [],
      categories = [],
      guided,
    }
  } = currentListing.attributes;

  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;

  const firstImage = images.length > 0 ? images[0] : null;

  const minArrPrices = packages.filter(({departure_date}) => isActualDay(departure_date));
  const price = minArrPrices.length ? getMinPrice(minArrPrices) : getMinPrice(packages);
  const departureDate = getDepartureDate(packages);

  const convertedPrice = minConvertPrice(currentUser, price);
  const convertedPerDayPrice = convertedPrice.amount / durationDays;
  const conevertedPriceMoney = new Money(convertedPrice.amount, convertedPrice.currency);
  const convertedPerDayPriceMoney = new Money(convertedPerDayPrice * 100, convertedPrice.currency);
  let { formattedPrice } = priceData(conevertedPriceMoney, intl);
  const formattedPerDayPrice = formatMoney(intl, convertedPerDayPriceMoney);

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  const save = async values => await sleep(1000);

  const user = ensureCurrentUser(currentUser);
  const listingFavourite = currentListing ? user.attributes.profile.publicData?.listingFavourite ?? [] : [];
  const init = listingFavourite ? listingFavourite.filter(l => l.listingId === id) : [];
  const avatar = currentListing && currentListing.author.profileImage ? currentListing.author.profileImage.attributes.variants[ "square-small" ] : [];

  const ratingsArray = reviews.map(r => r.ratings).reduce((a, b) => {
    return a.concat(b);
  }, []);
  const ratings = ratingsArray.map(a => {
    if (a.rating) return a.rating
  });
  const averageRating = ratings.length !== 0 ? ratings.reduce((a, b) => (parseInt(a) + parseInt(b))) / ratings.length : 0;

  // const priceAmount = price.price_value;
  // const priceCurrency = price.price_currency;

  const handleSearchItemDatalayer = () => {
    if (typeof window !== 'undefined') {
      const currentlyUrl = typeof window !== 'undefined' && window.location.pathname;

      const dataLayerItemList = {
          item_name: title,
          item_id: id,
          price: price.price_value,
          currency: price.price_currency,
          // item_brand: joinItem(themes),
          item_category: categories.map(({title}) => title).join("|"),
          item_category2: themes.join("|"),
          item_category3: skill_levels.map(({title}) => title).join("|"),
          item_category4: durationDays,
          // item_variant: languageName,
          item_list_name: "Search Results",
          item_list_id: window.location.pathname,
          index: 1,
          quantity: 1
      }

      const tagManagerArgs = {
        dataLayer: {
          event: "view_item_list",
          ecommerce: {
            item_list_name: 'Search Results', // page on which the list was displayed on: "Landing Page", "Favorites Page", "Search Results"
            item_list_id: currentlyUrl, // Search string & parameters from URL
            items: dataLayerItemList,
          },
        },
        events: {
          pageView: 'pageview',
        },
        page: {
          path: window.location.pathname,
          locale: localStorage.getItem(LANGUAGE_KEY), // language code of displayed page
          title: 'Search Results Page',
          type: 'catalog',
        },
        dataLayerName: 'CartListDataLayer',
      };

      TagManager.dataLayer(tagManagerArgs);
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "view_item",
        // gtm: {uniqueEventId: 15, priorityId: 1, start: 1663826025838},
        // developer_id: {dZTJkMz: true},
        url_passthrough: true,
        ads_data_redaction: true,
        pagePath: window.location.pathname,
        pageTitle: "SearchPage",
        ecommerce: {
          item_list_name: 'Search Results', // page on which the list was displayed on: "Landing Page", "Favorites Page", "Search Results"
          item_list_id: window.location.pathname,
          items: dataLayerItemList,
        },
      });
    }
  }

  const handleMoveImages = (direction, index) => (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (direction === "left") {
      if (index === 0) {
        setIndex(images.length - 1);
      } else {
        setIndex(index - 1);
      }
    } else if (direction === "right") {
      if (index === images.length - 1) {
        setIndex(0);
      } else {
        setIndex(index + 1);
      }
    }
  }

  return (
    <div
      onClick={handleSearchItemDatalayer}
      className={classes}
      onMouseEnter={() => setActiveListing(currentListing.id)}
      onMouseLeave={() => setActiveListing(null)}
    >
      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper}>
          <div className={css.shareLike}>
            {isAuthenticated ?
              <ListingFavouritesForm
                isAuthenticated={isAuthenticated}
                initialValues={{ [ `favourite_${id}` ]: init[ 0 ] ? init[ 0 ][ `favourite_${id}` ] : false }}
                onSubmit={values => {
                  save(values);
                  let currentListingId = Object.keys(values)[0];
                  let currentListingValue = values[ `favourite_${id}` ];
                  currentListingId = currentListingId.replace('favourite_', '');
                  const hasCurrentListing = listingFavourite.filter(l => l.listingId === currentListingId).length > 0;

                  if (!hasCurrentListing && currentListingValue) {
                    onUpdateProfile({
                      publicData: {
                        listingFavourite: [ ...listingFavourite, {
                          title,
                          authorName,
                          avatar,
                          skill_levels,
                          fitness_levels,
                          listingImages: images,
                          listingId: id,
                          duration_days: durationDays,
                          categories,
                          guided,
                          themes,
                          departure_date: departureDate,
                          price: price.amount,
                          [ `favourite_${id}` ]: true
                        }]
                      }
                    });
                  }
                  if (hasCurrentListing && !currentListingValue) {
                    const removeCurrentListing = listingFavourite.filter(l => l.listingId !== currentListingId);
                    onUpdateProfile({
                      publicData: {
                        listingFavourite: removeCurrentListing
                      }
                    })
                  }
                }}
                onChange={() => {}}
                listingId={id}
              /> :
              <NamedLink name='LoginPage' className={css.legalLink}>
                <IconCheckbox />
              </NamedLink>
            }
          </div>

          {images.length ? (
            <div className={css.imgContainer}>
              <button className={css.imageButtonPrev} onClick={handleMoveImages("left", index)} />
              <img src={images[index]?.url} className={css.listingImg} />
              <button className={css.imageButtonNext} onClick={handleMoveImages("right", index)} />
            </div>
          ) : (
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={[ 'landscape-crop', 'landscape-crop2x' ]}
              sizes={renderSizes}
            />
          )}
        </div>
      </div>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.mobileTitleBlock}>
            <NamedLink
              className={css.title}
              name="ListingPage"
              params={{ id, slug }}
            >
              <h2 className={css.title}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </h2>
            </NamedLink>
            <div className={css.mobilePrice}>
              <span className={css.headingOfInfo}>
                <FormattedMessage id="BookingPanel.priceFromMobile" />
              </span>
              <span className={css.fromValueNumber}>{formattedPrice}</span>
            </div>
          </div>
          <div className={css.reviews}>
            <div className={css.ratingStarHolder}>
              {ratingBuilder(averageRating * 1).map((rat, i) => <IconCategory key={i} cat={rat} />)}
            </div>
            <span className={css.reviewsCounter}>{reviews.length} Reviews</span>
          </div>
          <div className={css.descriptionHolder} dangerouslySetInnerHTML={{__html: description_long }} />
          <ul className={css.amenities}>
            {badges.map(({ title, icon }) => (
              <li key={title} className={css.amenity}>
                <img src={icon} />
                <span className={css.amenityText}>{title}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className={css.info2}>
          <div className={css.price}>
            <div className={css.duration} title={'Duration'}>
              <span className={css.headingOfInfo}>
                <FormattedMessage id="DurationFilter.label" />
              </span>
              <span className={css.infoContent}>{durationDays}d/{durationDays - 1}n</span>
            </div>
            <div className={css.fromValue}>
              <span className={css.headingOfInfo}>
                <FormattedMessage id="BookingPanel.priceFrom" />
              </span>
              <span className={classNames(css.fromValueNumber, {
                [css.bigNumber]: formattedPrice.length > 11
              })}>{formattedPrice}</span>
            </div>
            <div className={css.priceValue} title={formattedPerDayPrice}>
              <span className={css.headingOfInfo}>
                <FormattedMessage id="BookingPanel.perDay" />
              </span>
              <span className={css.infoContent}>{formattedPerDayPrice}</span>
            </div>
          </div>
          <div className={css.otherList}>
            {skill_levels.length ? <div className={css.otherListLi}>
              <p title={'Skill level'} className={css.headingOfInfo}>
                <FormattedMessage id="SectionSpotlight.skillLevel"/>
              </p>
              {skill_levels.map(({ icon, title }) => (
                <div key={title}>
                  <img src={icon} alt="React Logo" />
                  <span className={css.valueKeySkill}>{title}</span>
                </div>
              ))}
            </div> : null}
            {fitness_levels.length ? <div className={css.otherListLi}>
              <p title={'Fitness Level'} className={css.headingOfInfo}>
                <FormattedMessage id="SectionSpotlight.fitnessLevel"/>
              </p>
              {fitness_levels.map(({icon, title}) => (
                <div key={title}>
                  <img src={icon} alt="React Logo" />
                  <span className={css.valueKeySkill}>{title}</span>
                </div>
              ))}
            </div> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default  injectIntl(ListingCardComponent);
