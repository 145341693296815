import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {formatMoney, minConvertPrice} from '../../util/currency';
import { ensureListing } from '../../util/data';
import { types as sdkTypes } from "../../util/sdkLoader";

import css from './SearchMapPriceLabel.module.css';
import moment from "moment";

const { Money } = sdkTypes;

const priceData = (price, intl) => {
  if (price) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  }
  return {};
};

const isActualDay = currentDay => moment(new Date()).format('YYYY-MM-DD') <= currentDay;

const getMinPrice = (packages) => {
  let minPrice;

  packages.forEach(({ rooms }) => {
    rooms.forEach(({ price }) => {
      if (!minPrice || minPrice.amount > price.amount) {
        minPrice = price;
      }
    });
  });

  return minPrice;
}

class SearchMapPriceLabel extends Component {
  shouldComponentUpdate(nextProps) {
    const currentListing = ensureListing(this.props.listing);
    const nextListing = ensureListing(nextProps.listing);
    const isUser = currentListing.currentUser === nextListing.currentUser;
    const isSameListing = currentListing.id.uuid === nextListing.id.uuid;
    // const hasSamePrice = currentListing.attributes.price === nextListing.attributes.price;
    const hasSameActiveStatus = this.props.isActive === nextProps.isActive;
    const hasSameRefreshToken =
      this.props.mapComponentRefreshToken === nextProps.mapComponentRefreshToken;

    return !(isSameListing && hasSameActiveStatus && hasSameRefreshToken && !isUser);
  }

  render() {
    const {
      className,
      rootClassName,
      intl,
      listing,
      onListingClicked,
      isActive,
      currentUser
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const priceLabelClasses = classNames(css.priceLabel, { [ css.priceLabelActive ]: isActive });
    const caretClasses = classNames(css.caret, { [ css.caretActive ]: isActive });

    const currentListing = ensureListing(listing);
    const {
      publicData: {
        packages = [],
      }
    } = currentListing.attributes;

    const minArrPrices = packages.filter(({departure_date}) => isActualDay(departure_date));
    const price = minArrPrices.length ? getMinPrice(minArrPrices) : getMinPrice(packages);

    const convertedPrice = minConvertPrice(currentUser, price);
    const conevertedPriceMoney = new Money(convertedPrice.amount, convertedPrice.currency);
    let { formattedPrice } = priceData(conevertedPriceMoney, intl);

    return (
      <button className={classes} onClick={() => onListingClicked(currentListing)}>
        <div className={css.caretShadow}/>
        <div className={priceLabelClasses}>{formattedPrice}</div>
        <div className={caretClasses}/>
      </button>
    );
  }
}

SearchMapPriceLabel.defaultProps = {
  className: null,
  rootClassName: null,
};

const { func, string } = PropTypes;

SearchMapPriceLabel.propTypes = {
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
  onListingClicked: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SearchMapPriceLabel);
