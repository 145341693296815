import React from 'react';
import PropTypes from 'prop-types';

import css from './IconActivity.module.css';

const IconActivitySnow = props => {
  const { className, type } = props;


  if (type === "crossCountrySkiing") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M24.083 30.945l1.167-5.228c.167-.6-.083-1.285-.583-1.714l-3.334-2.657c-1-.686-1.333-1.971-.916-3.085l1.666-5.748m0 0l3.25 2.834c.334.343.834.343 1.25.171l3.084-1.457m-7.584-1.548L18 12.09c-.5-.086-1 .171-1.167.6l-1.25 2.914m-4.416 11.913l6.583-1.543a.762.762 0 00.5-.514l2.5-4.542m8.333-6.343l-2 14.4M16.333 15.26L6.25 21.003m11.583 10.028h10.084s1.916.086 2.5-1.371M6.75 23.232l6.75 7.028s1.25 1.457 2.667.857M26.833 8.762a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        ></path>
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M24.083 30.945l1.167-5.228c.167-.6-.083-1.285-.583-1.714l-3.334-2.657c-1-.686-1.333-1.971-.916-3.085l1.666-5.748m0 0l3.25 2.834c.334.343.834.343 1.25.171l3.084-1.457m-7.584-1.548L18 12.09c-.5-.086-1 .171-1.167.6l-1.25 2.914m-4.416 11.913l6.583-1.543a.762.762 0 00.5-.514l2.5-4.542m8.333-6.343l-2 14.4M16.333 15.26L6.25 21.003m11.583 10.028h10.084s1.916.086 2.5-1.371M6.75 23.232l6.75 7.028s1.25 1.457 2.667.857M26.833 8.762a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        ></path>
      </svg>
    );
  } else if (type === "snowshoeing") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M24.439 31.341l1.163-5.05c.166-.58-.084-1.241-.582-1.655l-3.322-2.566c-.996-.663-1.329-1.904-.913-2.98l1.716-5.754m0 0l3.183 2.939c.333.331.831.331 1.246.165l3.073-1.407M22.5 13.336l-4.125-.207c-.498-.083-.996.166-1.162.58l-1.246 2.814m-2.491 13.41l4.982-2.648c.582-.331.997-.828 1.163-1.407l1.495-4.222m8.305-6.126l-2.16 11.34M16.715 16.193L6.667 21.738m15.031 9.686h3.488s1.91.083 2.492-1.325m-15.862-1.573l2.076 2.153s1.245 1.407 2.657.828M27.197 10a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        ></path>
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M24.439 31.341l1.163-5.05c.166-.58-.084-1.241-.582-1.655l-3.322-2.566c-.996-.663-1.329-1.904-.913-2.98l1.716-5.754m0 0l3.183 2.939c.333.331.831.331 1.246.165l3.073-1.407M22.5 13.336l-4.125-.207c-.498-.083-.996.166-1.162.58l-1.246 2.814m-2.491 13.41l4.982-2.648c.582-.331.997-.828 1.163-1.407l1.495-4.222m8.305-6.126l-2.16 11.34M16.715 16.193L6.667 21.738m15.031 9.686h3.488s1.91.083 2.492-1.325m-15.862-1.573l2.076 2.153s1.245 1.407 2.657.828M27.197 10a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        ></path>
      </svg>
    );
  } else if (type === "freeriding") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M23.585 29.165l1.18-3.755c.253-.92-.253-1.841-1.18-2.092l-3.459-1.34c-1.687-.418-2.53-2.343-1.687-3.933l4.06-4.296-4.313 1.367c-.759.251-.759 1.34 0 1.674l5.314 2.26-13.917-3.432m8.856 4.938l2.7 4.352c.337.585.506 1.255.337 1.924l-1.18 4.436m-6.58-3.766l9.615 5.774s1.603 1.005 2.868.084m-12.652-4.017h14.34s1.94.084 2.53-1.339M10.174 13.526l9.868 4.017m7.024-7.961a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        ></path>
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M23.585 29.165l1.18-3.755c.253-.92-.253-1.841-1.18-2.092l-3.459-1.34c-1.687-.418-2.53-2.343-1.687-3.933l4.06-4.296-4.313 1.367c-.759.251-.759 1.34 0 1.674l5.314 2.26-13.917-3.432m8.856 4.938l2.7 4.352c.337.585.506 1.255.337 1.924l-1.18 4.436m-6.58-3.766l9.615 5.774s1.603 1.005 2.868.084m-12.652-4.017h14.34s1.94.084 2.53-1.339M10.174 13.526l9.868 4.017m7.024-7.961a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        ></path>
      </svg>
    );
  } else if (type === "heliSkiing") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M27.5 20.415l-6.156-7.22-3.99.921c-.733.168-1.385.67-1.792 1.339l-1.71 3.096m7.398-5.219l-3.49 4.215c-.814 1.59 0 3.514 1.63 3.933l3.338 1.339c.896.25 1.384 1.171 1.14 2.091l-1.14 4.021m-5.049-8.79l2.606 4.351c.326.586.489 1.255.326 1.925l-1.14 4.435m-6.27-3.766l9.364 5.774s1.547 1.004 2.769.084m-12.378-4.017H26.8s1.873.084 2.443-1.339m-14.821-8.117L7.5 11.187m19.625 9.874l5.375-8.87M10.106 7.335l.25.972c.083.353.417.618.75.618h2m0 2.74h3.75s.916 0 1.583-1.06m-3-3.094V5.832m2.834 0h-5.75m4.416 3.624c-.666 0-1.5-.442-1.583-1.591M26.18 9.169a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm-10.075.905h-1.75c-.667 0-1.167-.53-1.167-1.237 0-.707.5-1.237 1.167-1.237h1.75c.666 0 1.166.53 1.166 1.237.084.707-.5 1.238-1.166 1.238z"
        ></path>
      </svg>
    );
  } else if (type === "skiTouring") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M16.508 31.955l-.933-.772a.64.64 0 01-.158-.649l2.36-4.014c.239-.405.397-.892.477-1.379l2.54-12.167-3.651 2.19a3.028 3.028 0 00-1.27 1.542l-1.35 3.406m9.366 7.463l.397-4.056a1.416 1.416 0 00-1.112-1.541l-4.047-.73m1.746-8.274l2.936 2.677c.318.325.794.406 1.27.243l3.572-1.054m-10 14.032l9.523-1.865s1.509-.162 1.826-1.46m-19.683 7.787l9.524-1.866s1.508-.162 1.826-1.46m-13.334-1.46l7.064-8.68m12.936-4.866L26.587 25.79M24.214 8.264a2.53 2.53 0 01-1.84 3.04 2.47 2.47 0 01-3.009-1.821 2.53 2.53 0 011.84-3.04 2.47 2.47 0 013.009 1.821z"
        ></path>
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M16.508 31.955l-.933-.772a.64.64 0 01-.158-.649l2.36-4.014c.239-.405.397-.892.477-1.379l2.54-12.167-3.651 2.19a3.028 3.028 0 00-1.27 1.542l-1.35 3.406m9.366 7.463l.397-4.056a1.416 1.416 0 00-1.112-1.541l-4.047-.73m1.746-8.274l2.936 2.677c.318.325.794.406 1.27.243l3.572-1.054m-10 14.032l9.523-1.865s1.509-.162 1.826-1.46m-19.683 7.787l9.524-1.866s1.508-.162 1.826-1.46m-13.334-1.46l7.064-8.68m12.936-4.866L26.587 25.79M24.214 8.264a2.53 2.53 0 01-1.84 3.04 2.47 2.47 0 01-3.009-1.821 2.53 2.53 0 011.84-3.04 2.47 2.47 0 013.009 1.821z"
        ></path>
      </svg>
    );
  } else if (type === "splitboarding") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M28.333 15.452l-2.67-2.056c-.486-.329-1.133-.575-1.78-.575h-5.666l-3.723 1.809c-.81.41-1.295 1.233-1.214 2.137l.162 1.974m4.937-5.756l2.509 4.111c.404.658.647 1.316.728 2.056l1.133 8.386m-1.537-9.29l-2.914.904c-.728.246-1.214 1.069-1.052 1.808l.405 2.631m-5.261-1.973l11.492 9.045s1.862 1.397 2.833.822M21.032 8.332a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm-8.966 12.875c-1.052 1.233.243 2.467.81 3.042l9.55 7.482s1.942 1.727 4.046 0c1.861-1.562 0-1.726 0-1.726s-.81-.082-2.428-1.316a3788.307 3788.307 0 00-9.388-7.317s-1.538-1.398-2.59-.165z"
        ></path>
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.4"
          d="M28.333 15.452l-2.67-2.056c-.486-.329-1.133-.575-1.78-.575h-5.666l-3.723 1.809c-.81.41-1.295 1.233-1.214 2.137l.162 1.974m4.937-5.756l2.509 4.111c.404.658.647 1.316.728 2.056l1.133 8.386m-1.537-9.29l-2.914.904c-.728.246-1.214 1.069-1.052 1.808l.405 2.631m-5.261-1.973l11.492 9.045s1.862 1.397 2.833.822M21.032 8.332a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm-8.966 12.875c-1.052 1.233.243 2.467.81 3.042l9.55 7.482s1.942 1.727 4.046 0c1.861-1.562 0-1.726 0-1.726s-.81-.082-2.428-1.316a3788.307 3788.307 0 00-9.388-7.317s-1.538-1.398-2.59-.165z"
        ></path>
      </svg>
    );
  } else if (type === "telemark") {
    return (
      <svg
        className={className || css.root}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M15.749 28.453l.169-1.094c.084-.42.507-.757.93-.673l3.72.758c.422.084.76-.253.591-.674l-1.268-4.207c-.084-.42-.169-.758-.084-1.178l.676-7.05c0-.337.423-.42.592-.168l2.029 2.59c.169.252.507.504.76.673l3.044 1.599m-6.491-4.862l-3.4 1.58c-.592.336-.845 1.094-.676 1.767l.76 2.524m.254 10.435l2.03-3.282c.168-.336.253-.673.337-1.01l.085-4.291m7.693-3.703l-3.72 6.564m-5.833-4.88L10 22.646m.17 4.797l10.06 2.02s1.86.504 2.705-.758m-11.413.841l10.06 2.02s1.86.505 2.705-.757M24.384 10a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        ></path>
      </svg>
    );
  } else {
    return null;
  }
};

IconActivitySnow.defaultProps = { className: null };

const { string } = PropTypes;

IconActivitySnow.propTypes = {
  className: string,
};

export default IconActivitySnow;
